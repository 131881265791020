import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

//PROPS TYPE
import { MilestoneProps } from './Milestone.props';

//COMPONENTS
import Icon from 'components/Icon';
import RenderHtml from 'components/RenderHtml';
import Proofpoint from 'components/ProofPoint';
import Conditions from 'components/Conditions';
import VideoPreview from 'components/VideoPreview';

//STYLED
import {
  MilestoneContainer,
  MilestoneTitle,
  MilestoneIconBox,
  MilestoneIcon,
  MilestoneImage,
  MilestoneProofPoints,
  MilestoneLocation,
} from './Milestone.styled';

const Milestone = ({ milestone }: MilestoneProps) => {
  const { t: productTranslation } = useTranslation('product');

  const $milestoneParent = useRef<HTMLDivElement>(null);

  return (
    <MilestoneContainer ref={$milestoneParent} id={`milestone-${milestone.pk}`}>
      <MilestoneTitle>
        <MilestoneIconBox>
          <MilestoneIcon src={milestone.icon} alt={milestone.name} />
        </MilestoneIconBox>
        {milestone.name}
      </MilestoneTitle>
      {milestone.proofPoints.filter(
        (proofpoint) =>
          proofpoint &&
          proofpoint.icon &&
          proofpoint.icon.icon &&
          !proofpoint.icon.icon.includes('c_')
      ).length > 0 && (
        <MilestoneProofPoints>
          {milestone.proofPoints.map(
            (proofpoint) =>
              proofpoint.shouldShowWidget && (
                <Proofpoint key={proofpoint.pk} proof={proofpoint} />
              )
          )}
        </MilestoneProofPoints>
      )}
      {milestone.location && (
        <MilestoneLocation>
          <Icon iconName="point" /> {milestone.location.name}
        </MilestoneLocation>
      )}
      {milestone.proofPoints.length > 0 &&
        milestone.proofPoints.map(
          (proofpoint) =>
            (!proofpoint.shouldShowWidget ||
              (proofpoint.shouldShowWidget &&
                proofpoint.conditions.length !== 0)) && (
              <Conditions key={proofpoint.pk} proofpoint={proofpoint} />
            )
        )}

      {milestone.video && (
        <VideoPreview
          url={milestone.video.url}
          name={milestone.name}
          parent={$milestoneParent}
          id={milestone.pk as string}
        />
      )}
      {!milestone.video && !milestone.image.url.includes('placeholder') && (
        <MilestoneImage src={milestone.image?.url} alt={milestone.name} />
      )}
      {milestone.description && (
        <RenderHtml text={milestone.description} margin="0" />
      )}
    </MilestoneContainer>
  );
};

export default Milestone;
