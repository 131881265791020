import styled from 'styled-components';
import { sm } from 'theme/Theme.breackoints';

export const AppMobileMenuContainer = styled.div<{
  active: boolean;
}>`
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: -100%;
  width: 100%;
  max-width: 100vw;
  max-height: 100vh;
  height: 100%;
  z-index: 9999;
  background-color: ${({ theme }) => theme.colors.brand};
  padding: 1.5rem 1.5rem 2.5rem;
  transition: all 0.25s ease-in-out;
  ${sm`
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    ${({ active }) =>
      active &&
      `
      left: 0;
    `}
  `}
`;

export const AppMobileMenuHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const AppMobileMenuBrand = styled.div`
  width: 4rem;
`;
export const AppMobileMenuLogo = styled.img`
  max-width: 100%;
`;
export const AppMobileMenuClose = styled.div`
  width: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    stroke: ${({ theme }) => theme.colors.white};
  }
`;

export const AppMobileMenuContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
export const AppMobileMenuLink = styled.a<{
  textTransform?: 'uppercase' | 'initial';
  margin?: string;
}>`
  font-weight: bold;
  color: ${({ theme }) => theme.colors.white};
  ${({ margin = 0 }) => margin && `margin: ${margin}`};
`;

export const AppMobileMenuFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const AppMobileMenuSocial = styled.div`
  max-width: 12rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${sm`
    width: 100%;
    margin: 0 0 2rem;
  `}
`;
export const AppMobileMenuNetwork = styled.a`
  svg {
    color: white;
    width: 1.25rem;
  }
`;
