import { createSlice } from '@reduxjs/toolkit';
import { ProductMilestone } from 'types/product';

const initialState = {
  isOpen: false,
  milestone: {} as ProductMilestone,
};

const cycleLifeModalSlice = createSlice({
  name: 'cycleLifeModal',
  initialState,
  reducers: {
    openModal: (state: typeof initialState, action) => {
      return {
        ...action.payload,
        isOpen: true,
      };
    },
    closeModal: () => initialState,
  },
});

export const { openModal, closeModal } = cycleLifeModalSlice.actions;

export default cycleLifeModalSlice;
