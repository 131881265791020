import React from 'react';

//PROPS TYPE
import { LoadingSpinnerProps } from './LoaderSpinner.props';

//STYLES
import {
  Loading,
  LoadingInner,
  LoadingSpinnerContent,
  LoadingMessage,
} from './LoaderSpinner.styled';

const LoaderSpinner = ({ message, isLoading, margin }: LoadingSpinnerProps) => {
  return isLoading ? (
    <LoadingSpinnerContent margin={margin}>
      <Loading>
        <LoadingInner />
        <LoadingInner />
        <LoadingInner />
      </Loading>
      {message && <LoadingMessage>{message}</LoadingMessage>}
    </LoadingSpinnerContent>
  ) : (
    <></>
  );
};

export default LoaderSpinner;
