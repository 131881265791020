import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

//UTILS
import { getInitials } from 'utils/get-initials';

//ASSETS
import VerifiedIcon from 'assets/images/verified.png';

//STORE
import { AppState } from 'store';
import { closeModal } from 'store/proofPointDetailModal';
import { openModal as openMediaEvidenceModal } from 'store/mediaEvidenceModal';
import { openModal as openEvidenceModal } from 'store/evidenceModal';
import { openModal as openBlockchainDetailModal } from 'store/blockchainDetailModal';

//PROPS TYPE
import { ProofPointEvidence } from 'types/product';

//COMPONETNS
import Modal from 'components/Modal';
import Avatar from 'components/Avatar';
import Button from 'components/Button';

//STYLES
import { ModalContent } from 'components/Modal/Modal.styled';
import {
  ProofPointDetailModalContainer,
  ProofPointDetailModalHeader,
  ProofPointDetailModalName,
  ProofPointDetailModalText,
  ProofPointDetailModalIcon,
  ProofPointDetailModalVerified,
  ProofPointDetailModalContent,
  ProofPointDetailModalContentTitle,
  ProofPointDetailModalContentText,
  ProofPointDetailModalEvidences,
  ProofPointDetailModalEvidencesLink,
  ProofPointDetailModalEvidencesButton,
  ProofPointDetailModalAction,
} from './ProofPointDetailModal.styled';

interface ProofPointDetailModalProps {
  wine?: boolean;
}
const ProofPointDetailModal = ({ wine }: ProofPointDetailModalProps) => {
  const { t } = useTranslation('modalProofDetail');

  const dispatch = useDispatch();

  const { isOpen, proof } = useSelector(
    (state: AppState) => state.proofPointDetailModal
  );
  const { blockStats } = useSelector((state: AppState) => state.blockStats);

  const handleCloseModal = useCallback(() => {
    dispatch(closeModal());
  }, [dispatch]);

  const handleOpenEvidenceModal = useCallback(
    (evidence: ProofPointEvidence) => {
      dispatch(
        openEvidenceModal({
          evidence,
        })
      );
    },
    [dispatch]
  );

  const handleOpenMediaEvidenceModal = useCallback(
    (media: { name: string; url: string }) => {
      dispatch(
        openMediaEvidenceModal({
          media: media,
        })
      );
    },
    [dispatch]
  );

  const handleOpenBlochainDetailModal = useCallback(() => {
    dispatch(
      openBlockchainDetailModal({
        proof,
      })
    );
  }, [dispatch, proof]);

  return (
    <Modal isOpen={isOpen} close={handleCloseModal} wine={wine}>
      <ModalContent>
        <ProofPointDetailModalContainer wine={wine}>
          <ProofPointDetailModalHeader>
            <ProofPointDetailModalIcon src={VerifiedIcon} />
            <ProofPointDetailModalVerified>
              {t('content.verified')}
            </ProofPointDetailModalVerified>
            {proof && proof.name && (
              <ProofPointDetailModalName>
                {t('content.proofPointForName', {
                  name: proof.name,
                }).toLowerCase()}
              </ProofPointDetailModalName>
            )}
            {proof && proof.verifier && (
              <ProofPointDetailModalText>
                <span>{t('content.proofPointForBy')}</span>
                {proof && proof.verifier && proof.verifier.logo && (
                  <Avatar
                    size={20}
                    url={proof.verifier.logo.url}
                    text={getInitials(proof.verifier.name || '')}
                  />
                )}{' '}
                <strong>{proof.verifier.name}</strong>
              </ProofPointDetailModalText>
            )}
          </ProofPointDetailModalHeader>
          <ProofPointDetailModalContent>
            <ProofPointDetailModalContentTitle>
              {t('content.evidences')}
            </ProofPointDetailModalContentTitle>
            {(proof && proof.evidences && proof.evidences.length > 0) ||
            (proof &&
              proof.mediaEvidences &&
              proof.mediaEvidences.length > 0) ? (
              <ProofPointDetailModalEvidences>
                {proof.mediaEvidences.length > 0 &&
                  proof.mediaEvidences.map((media, index) => {
                    return media.type === 'WEB' ? (
                      <ProofPointDetailModalEvidencesLink
                        href={media.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        key={`${index}-${media.name}`}
                      >
                        {media.name}
                      </ProofPointDetailModalEvidencesLink>
                    ) : (
                      <ProofPointDetailModalEvidencesButton
                        onClick={() => {
                          handleOpenMediaEvidenceModal({
                            name: media.name,
                            url: media.url,
                          });
                        }}
                        key={`${index}-${media.name}`}
                      >
                        {media.name}
                      </ProofPointDetailModalEvidencesButton>
                    );
                  })}
                {proof.evidences.length > 0 &&
                  proof.evidences.map((evidendce, index) => {
                    return (
                      <ProofPointDetailModalEvidencesButton
                        onClick={() => {
                          handleOpenEvidenceModal(evidendce);
                        }}
                        key={index}
                      >
                        {evidendce.evidenceName
                          ? evidendce.evidenceName
                          : evidendce.pk}
                      </ProofPointDetailModalEvidencesButton>
                    );
                  })}
              </ProofPointDetailModalEvidences>
            ) : (
              <ProofPointDetailModalContentText>
                Esta prueba no posee evidencias
              </ProofPointDetailModalContentText>
            )}

            <ProofPointDetailModalAction>
              <Button
                text={t('content.viewOnBlockchain')}
                onClick={() => {
                  if (!blockStats.errors) {
                    handleOpenBlochainDetailModal();
                  }
                }}
                wine
                outline
                disabled={!!blockStats.errors && blockStats.errors.length > 0}
              />
            </ProofPointDetailModalAction>
          </ProofPointDetailModalContent>
        </ProofPointDetailModalContainer>
      </ModalContent>
    </Modal>
  );
};

export default ProofPointDetailModal;
