import React from 'react';

//UTILS
import { getInitials } from 'utils/get-initials';

//PROPS TYPE
import { AvatarProps, AvatarSize } from './Avatar.props';

//STYLES
import { AvatarImage, AvatarIcon, AvatarText } from './Avatar.styled';

const Avatar = ({
  text,
  url,
  size = 'small',
  sizeText = 'small',
  color = 'fontSecondary',
  textProps,
  ...props
}: AvatarProps) => {
  if (url) return <AvatarImage src={url} size={size} {...props} />;
  else
    return (
      <AvatarIcon size={size} {...props} color={color}>
        <AvatarText {...textProps}>{getInitials(text)}</AvatarText>
      </AvatarIcon>
    );
};

export default Avatar;
