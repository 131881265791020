import React from 'react';

//PROPS TYPE
import { LoadingProps } from './Loading.props';

//COMPONENTS
import LoaderSpinner from 'components/LoaderSpinner';

//STYLES
import { LoadingContainer } from './Loading.styled';

const Loading = ({ backgroundColor, isLoading }: LoadingProps) => {
  return isLoading ? (
    <LoadingContainer backgroundColor={backgroundColor}>
      <LoaderSpinner isLoading={isLoading} margin="0" />
    </LoadingContainer>
  ) : (
    <></>
  );
};

export default Loading;
