import React, { useState } from 'react';

//COMPONENTS
import SkeletonLoader from 'components/SkeletonLoader';

const SkeletonReviews = () => {
  const [reviewList] = useState([1, 2, 3, 4, 5]);

  return (
    <SkeletonLoader
      width="100%"
      height="auto"
      margin="0 0 2.5rem"
      background="transparent"
    >
      <SkeletonLoader
        width="12.5rem"
        height="1rem"
        display="block"
        margin="0 0 0.75rem"
      />
      <SkeletonLoader
        width="12rem"
        height="1rem"
        display="block"
        margin="0 0 1.25rem"
      />
      <SkeletonLoader
        width="6rem"
        height="1rem"
        display="block"
        margin="0 0 1.25rem"
      />
      <SkeletonLoader
        width="100%"
        height="auto"
        margin="0 0 2.5rem"
        background="transparent"
      >
        {reviewList.map((review, index) => (
          <SkeletonLoader
            key={`${index}-review`}
            width="100%"
            height="auto"
            background="transparent"
            display="flex"
            flex={{
              align: 'center',
            }}
            margin="0 0 0.5rem"
          >
            <SkeletonLoader
              width="auto"
              height="auto"
              background="transparent"
              display="flex"
              flex={{
                align: 'center',
              }}
              margin="0 0.5rem 0 0"
            >
              <SkeletonLoader
                width="0.875rem"
                height="0.875rem"
                margin="0 0.5rem 0 0"
              />
              <SkeletonLoader
                width="0.875rem"
                height="0.875rem"
                margin="0 0.5rem 0 0"
              />
              <SkeletonLoader
                width="0.875rem"
                height="0.875rem"
                margin="0 0.5rem 0 0"
              />
              <SkeletonLoader
                width="0.875rem"
                height="0.875rem"
                margin="0 0.5rem 0 0"
              />
              <SkeletonLoader
                width="0.875rem"
                height="0.875rem"
                margin="0 0.5rem 0 0"
              />
            </SkeletonLoader>
            <SkeletonLoader
              width="11.5rem"
              height="0.25rem"
              margin="0 1rem 0 0"
            />
            <SkeletonLoader width="2rem" height="0.875rem" />
          </SkeletonLoader>
        ))}
      </SkeletonLoader>

      <SkeletonLoader width="9.75rem" height="3.125rem" margin="0 0 2.5rem" />
    </SkeletonLoader>
  );
};

export default SkeletonReviews;
