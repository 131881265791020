import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

//ASSETS
import LogoImage from 'assets/images/logo.png';

//PROPS TYPE
import { Icons } from 'components/Icon/Icon.props';

//COMPONENTS
import Icon from 'components/Icon';

//STYLES
import {
  AppFooterContainer,
  AppFooterWrapper,
  AppFooterTop,
  AppFooterBottom,
  AppFooterSocial,
  AppFooterLink,
  AppFooterLegal,
  AppFooterMail,
  AppFooterLogo,
  AppFooterLogoImage,
  AppFooterLogoText,
  AppFooterSocialLink,
} from './AppFooter.styled';

interface SocialLinksMap {
  iconName: Icons;
  link: string;
}

const AppFooter = () => {
  const { t } = useTranslation('footer');

  const year = new Date().getFullYear();

  const SOCIAL_LINKS: SocialLinksMap[] = useMemo(
    () => [
      {
        iconName: 'facebook',
        link: 'https://www.facebook.com/swearitsoftware/',
      },
      {
        iconName: 'instagram',
        link: 'https://www.instagram.com/swearit_/',
      },
      {
        iconName: 'youtube',
        link: 'https://www.youtube.com/channel/UC-smPk49x3o90Di00egGQPg',
      },
      {
        iconName: 'linkedin',
        link: 'https://www.linkedin.com/in/swearit/',
      },
      {
        iconName: 'twitter',
        link: 'https://twitter.com/swearit_',
      },
    ],
    []
  );

  return (
    <AppFooterContainer>
      <AppFooterWrapper>
        <AppFooterTop>
          <AppFooterLink href="https://swearit.io" target="_blank">
            {t('content.aboutSwearit')}
          </AppFooterLink>
          <AppFooterSocial>
            {SOCIAL_LINKS.map((socialLink) => (
              <AppFooterSocialLink
                href={socialLink.link}
                key={socialLink.link}
                target="blank"
              >
                <Icon iconName={socialLink.iconName} />
              </AppFooterSocialLink>
            ))}
          </AppFooterSocial>
        </AppFooterTop>
        <AppFooterBottom>
          <AppFooterLogo>
            <AppFooterLogoImage src={LogoImage} alt="Swearit" />
            <AppFooterLogoText>© {year}</AppFooterLogoText>
          </AppFooterLogo>
          <AppFooterMail>
            <AppFooterLink href="mailto:hello@swearit.io">
              hello@swearit.io
            </AppFooterLink>
          </AppFooterMail>
          <AppFooterLegal>
            <AppFooterLink href="https://swearit.io/legal" target="_blank">
              {t('content.legal')}
            </AppFooterLink>
          </AppFooterLegal>
        </AppFooterBottom>
      </AppFooterWrapper>
    </AppFooterContainer>
  );
};

export default AppFooter;
