import React, { useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
//UTILS
import { getTypeFile } from 'utils/get-type-file';
//ASSETS
import ErrorHandlerGraphic from 'assets/images/error-handler-graphic.png';
//STORE
import { AppState } from 'store';
import { closeModal } from 'store/evidenceModal';
//PROPS TYPE
import { EvidenceFile, EvidenceModalProps } from './EvidenceModal.props';
//COMPONENTS
import Modal from 'components/Modal';
import Viewer, { Worker } from '@phuocng/react-pdf-viewer';
import Loading from 'components/Loading';
//STYLES
import {
  EvidenceModalContainer,
  EvidenceModalContent,
  EvidenceModalViewerContainer,
  EvidenceModalImage,
  EvidenceModalVideo,
  EvidenceModalLoading,
  EvidenceModalNotFile,
  EvidenceModalNotFileTitle,
  EvidenceModalNotFileLink,
  EvicenceModalError,
  EvicenceModalErrorImage,
  EvicenceModalErrorText,
  EvicenceModalErrorSubText,
} from './EvidenceModal.styled';

const EvidenceModal = ({ wine }: EvidenceModalProps) => {
  const { t } = useTranslation('modalEvidence');

  const dispatch = useDispatch();

  const { evidence, isOpen } = useSelector(
    (state: AppState) => state.evidenceModal
  );

  const [evidenceFile, setEvidenceFile] = useState<EvidenceFile>(
    {} as EvidenceFile
  );
  const [isLoadingFile, setIsLoadingFile] = useState(true);
  const [hasError, setHasError] = useState<boolean>(false);

  useEffect(() => {
    setIsLoadingFile(true);
    if (isOpen) {
      fetch(evidence.file)
        .then((response) => {
          return response.blob().then((blob) => {
            return {
              contentType: response.headers.get('Content-Type'),
              raw: blob,
            };
          });
        })
        .then(({ raw, contentType }) => {
          setIsLoadingFile(false);
          const reader = new FileReader();
          reader.onload = () => reader.result;
          reader.readAsDataURL(raw);
          reader.onload = () => {
            if (getTypeFile(raw.type)?.name !== 'none') {
              setEvidenceFile({
                type: getTypeFile(raw.type),
                result: reader.result,
              });
            }
          };
        })
        .catch((error) => {
          setIsLoadingFile(false);
          setHasError(true);
        });
    }
  }, [evidence, isOpen]);

  const handleCloseModal = useCallback(() => {
    dispatch(closeModal());
    setEvidenceFile({} as EvidenceFile);
  }, [dispatch]);

  const EvidenveRender = () => {
    if (evidenceFile && evidenceFile.type && evidenceFile.result) {
      switch (evidenceFile.type.name) {
        case 'pdf':
          return (
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.4.456/build/pdf.worker.min.js">
              <EvidenceModalViewerContainer>
                <Viewer fileUrl={evidenceFile.result as string} />
              </EvidenceModalViewerContainer>
            </Worker>
          );
        case 'image':
          return (
            <EvidenceModalImage
              src={evidenceFile.result as string}
              alt={evidenceFile.type.name}
            />
          );
        case 'video':
          return (
            <EvidenceModalVideo
              src={evidenceFile.result as string}
              controls
              autoPlay
            ></EvidenceModalVideo>
          );
        default:
          return (
            <EvidenceModalNotFile>
              <EvidenceModalNotFileTitle>
                You can download this file here
              </EvidenceModalNotFileTitle>
              <EvidenceModalNotFileLink href={evidence.file} target="_blanck">
                {evidence.file}
              </EvidenceModalNotFileLink>
            </EvidenceModalNotFile>
          );
      }
    }
  };

  return (
    <Modal isOpen={isOpen} close={handleCloseModal} padding="0" wine={wine}>
      <EvidenceModalContainer>
        {isLoadingFile && (
          <EvidenceModalLoading>
            <Loading isLoading={isLoadingFile} />
          </EvidenceModalLoading>
        )}
        {hasError && (
          <EvicenceModalError>
            <EvicenceModalErrorImage src={ErrorHandlerGraphic} />
            <EvicenceModalErrorText>
              {t('content.textError')}
            </EvicenceModalErrorText>
            <EvicenceModalErrorSubText>
              {t('content.subTextError')}
            </EvicenceModalErrorSubText>
          </EvicenceModalError>
        )}
        <EvidenceModalContent show={!isLoadingFile}>
          {EvidenveRender()}
        </EvidenceModalContent>
        {/* {(() => {
          
        })()} */}
      </EvidenceModalContainer>
    </Modal>
  );
};

export default EvidenceModal;
