import React, { useState, useEffect, useRef } from 'react';
import { useMediaQuery } from '@react-hook/media-query';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { useDispatch, useSelector } from 'react-redux';
import ReactToPrint from 'react-to-print';
//QUERIES
import { stepsProductQuery } from 'queries/product';
//STORE
import { AppState } from 'store';
import { setMobileNav } from 'store/mobilenav';
//PROPS TYPE
import { MetaHeadProps } from 'components/MetaHead/MetaHead';
import { ProductVars } from 'screens/Product/Product.props';
import { ProductData } from 'types/product';
//ASSETS
import placeholderImage from 'assets/images/placeholder255x255.png';
//COMPONENTS
import AppLayoutWine from 'module/AppLayoutWine';
import Share from 'screens/Wine/components/Share';
import Transitions from './components/Transitions';
import ProductSkeleton from 'screens/Product/components/ProductSkeleton';
//STYLES
import { AppLayoutWineWrapper } from 'module/AppLayoutWine/AppLayoutWine.styled';
import {
  WineContent,
  WineContentAction,
  WineContentBodyInformation,
  WineContentTitle,
  WineContentiSeparator,
  WineContentShareList,
  WineContentShareItem,
  WineContentBody,
  WineContentShare,
} from 'screens/Wine/Wine.styled';
import {
  CertificatePrintIcon,
  CertificateBottle,
  WineContentBodyCertificate,
  CertificateInformation,
  CertificateLogo,
  CertificatePrintArea,
  CertificateBottleDates,
  CertificateBottleDate,
  CertificateBottleDescriptionTitle,
  CertificateBottleDescription,
  CertificateBottleDescriptionLink,
} from './Certificate.styled';

const SOCIALS: Record<string, string> = {
  facebook: 'fb',
  twitter: 'tw',
  instagram: 'in',
  youtube: 'yb',
  linkedin: 'ln',
};

const Certificate = () => {
  const { certificateId } = useParams();
  const dispatch = useDispatch();
  const printArea = useRef<HTMLDivElement>(null);

  const matches = useMediaQuery('only screen and (max-width: 920px)');
  const matchesMd = useMediaQuery('only screen and (max-width: 768px)');
  const matchesXs = useMediaQuery('only screen and (max-width: 575px)');

  const [metaInformation, setMetaInformation] = useState<MetaHeadProps>({});

  const { translateSelect } = useSelector(
    (state: AppState) => state.translateModal
  );

  const { data: resStepsProduct, loading, error, refetch } = useQuery<
    ProductData,
    ProductVars
  >(stepsProductQuery, {
    variables: {
      pk: certificateId,
      language:
        translateSelect && translateSelect.length > 0
          ? translateSelect.find((lang) => lang.idProduct === certificateId)
              ?.short_name
          : '',
    },
  });

  useEffect(() => {
    if (
      resStepsProduct &&
      resStepsProduct.product &&
      resStepsProduct.product.data
    ) {
      dispatch(setMobileNav('info'));
      setMetaInformation({
        title: resStepsProduct.product.data.name,
        description: resStepsProduct.product.data.description,
        image:
          resStepsProduct.product.data.images.length > 0
            ? resStepsProduct.product.data.images[0].url
            : '',
        url: window.location.href,
      });
    }
  }, [resStepsProduct, dispatch]);

  return (
    <AppLayoutWine
      loading={loading}
      skeletonLoader={<ProductSkeleton matches={matchesMd} />}
      metas={metaInformation}
      errorType={
        resStepsProduct &&
        resStepsProduct.product &&
        resStepsProduct.product.error
      }
      errorUrl={
        resStepsProduct &&
        resStepsProduct.product &&
        resStepsProduct.product.url
      }
      type="organization"
      id={certificateId}
    >
      <WineContent>
        <AppLayoutWineWrapper>
          <WineContentBody>
            {!matchesXs &&
              resStepsProduct &&
              resStepsProduct.product &&
              resStepsProduct.product.data &&
              resStepsProduct.product.data.organization &&
              resStepsProduct.product.data.organization.socialChannels &&
              resStepsProduct.product.data.organization.socialChannels.length >
                0 && (
                <WineContentShare>
                  <WineContentShareList>
                    {resStepsProduct.product.data.organization.socialChannels.map(
                      (social) => (
                        <WineContentShareItem
                          key={social.pk}
                          href={social.url}
                          target="_blank"
                        >
                          {SOCIALS[social.type.toLowerCase()]}
                        </WineContentShareItem>
                      )
                    )}
                  </WineContentShareList>
                </WineContentShare>
              )}
            {resStepsProduct &&
              resStepsProduct.product &&
              resStepsProduct.product.data &&
              resStepsProduct.product.data.organization && (
                <WineContentBodyCertificate>
                  <CertificatePrintArea ref={printArea}>
                    <WineContentBodyInformation certificate>
                      <CertificateInformation>
                        <WineContentTitle style={{ margin: '0 0 2rem' }}>
                          {resStepsProduct.product.data.name}
                        </WineContentTitle>
                        {resStepsProduct.product.data.workflow && (
                          <CertificateBottle>
                            {resStepsProduct.product.data.workflow.name}
                          </CertificateBottle>
                        )}

                        {resStepsProduct &&
                          resStepsProduct.product &&
                          resStepsProduct.product.data &&
                          resStepsProduct.product.data.steps.length > 0 &&
                          resStepsProduct.product.data.steps.filter(
                            (step) => !!step.stepDate
                          ).length > 0 && (
                            <CertificateBottleDates>
                              {resStepsProduct.product.data.steps.map(
                                (step, index) => (
                                  <CertificateBottleDate
                                    key={`${step.pk}-${index}`}
                                    withBorder={index !== 0}
                                  >
                                    {step.name}
                                    <span>{step.stepDate}</span>
                                  </CertificateBottleDate>
                                )
                              )}
                            </CertificateBottleDates>
                          )}
                        <WineContentiSeparator style={{ maxWidth: '345px' }} />
                        <div>
                          <CertificateBottleDescriptionTitle>
                            Certificado de calidad de Corpinnat
                          </CertificateBottleDescriptionTitle>
                          {/* {resStepsProduct &&
                            resStepsProduct.product &&
                            resStepsProduct.product.data && (
                              <CertificateBottleDescription>
                                {
                                  resStepsProduct.product.data.workflow
                                    .description
                                }
                              </CertificateBottleDescription>
                            )} */}

                          <CertificateBottleDescriptionLink disabled={true}>
                            Ver registro en blockchain
                          </CertificateBottleDescriptionLink>
                        </div>
                      </CertificateInformation>
                      <CertificateLogo
                        src={
                          resStepsProduct.product.data.organization.logo.url
                            ? resStepsProduct.product.data.organization.logo.url
                            : placeholderImage
                        }
                      />
                    </WineContentBodyInformation>
                    <WineContentiSeparator style={{ maxWidth: '100%' }} />
                    {resStepsProduct &&
                      resStepsProduct.product &&
                      resStepsProduct.product.data &&
                      resStepsProduct.product.data.steps.length > 0 && (
                        <Transitions
                          steps={resStepsProduct.product.data.steps}
                        />
                      )}
                  </CertificatePrintArea>

                  <WineContentAction justify="space-between" margin="0 0 2rem">
                    <Share
                      type="product"
                      product={resStepsProduct.product.data}
                    />
                    <ReactToPrint
                      trigger={() => <CertificatePrintIcon iconName="print" />}
                      content={() => printArea.current}
                    />
                  </WineContentAction>
                </WineContentBodyCertificate>
              )}
          </WineContentBody>
        </AppLayoutWineWrapper>
      </WineContent>
    </AppLayoutWine>
  );
};

export default Certificate;
