import styled from "styled-components"
import { Text } from "components/Text"

export const ProductTabsContainer = styled.div`
  margin-bottom: 2rem;
`
export const ProductTabsHeader = styled.div<{size: number;}>`
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  position: relative;
  top: 1px;
`
export const ProductTabsHead = styled(Text)<{active: boolean; fullWidth?: boolean;}>`
  font-size: 10px;
  line-height: 10px;
  color: ${({ theme }) => theme.colors.brand};
  text-transform: uppercase;
  font-weight: bold;
  height: 60px;
  padding: 0 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-right: 1px solid ${({ theme }) => theme.colors.border};
  border-top: 1px solid ${({ theme }) => theme.colors.border};
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  background-color: ${({ theme }) => theme.colors.backgroundGray};
  &:first-child {
    border-left: 1px solid ${({ theme }) => theme.colors.border};
  }
  &:hover {
    background-color: ${({ theme }) => theme.colors.white};
  }

  ${({ active, theme }) => active && `
      color: ${theme.colors.active};
      background-color: ${theme.colors.white};
      border-bottom: none;
  `}

  ${({ fullWidth }) => fullWidth && `
    width: 100%;
  `}
`
export const ProductTabsBody = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.border};
  padding: 1rem;
`
export const ProductTabsItem = styled.div<{active: boolean;}>`

  ${({ active }) => !active && `
    display: none;
  `}
`
export const ProductTabsMobileContainer = styled.div``
export const ProductTabsMobileItem = styled.div``