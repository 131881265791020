import { useQuery } from '@apollo/react-hooks';
import { useMediaQuery } from '@react-hook/media-query';
import React, { useCallback, useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
//QUERIES
import { productQuery } from 'queries/product';
//STORE
import { AppState } from 'store';
import { setMobileNav } from 'store/mobilenav';
import { setOrganizationInfo } from 'store/organization';
import { setProduct } from 'store/product';
import { openModal, setTranslations } from 'store/tramslateModal';
//PROPS TYPE
import { MetaHeadProps } from 'components/MetaHead/MetaHead';
import { ProductVars } from 'screens/Product/Product.props';
import { ProductData } from 'types/product';
//PROPS TYPE
//COMPONENTS
import BlockchainDetailModal from 'components/BlockchainDetailModal';
import EvidenceModal from 'components/EvidenceModal';
import MediaEvicenceModal from 'components/MediaEvidenceModal/MediaEvicenceModal';
import ProofPointDetailModal from 'components/ProofPointDetailModal';
import ProofPointModal from 'components/ProofPointModal';
import RenderHtml from 'components/RenderHtml';
import AppLayoutWine from 'module/AppLayoutWine';
import ProductSkeleton from 'screens/Product/components/ProductSkeleton';
import CycleLife from './components/CycleLife';
import Pictures from './components/Pictures';
import Seals from './components/Seals';
import Share from './components/Share';
import Tabs from './components/Tabs';
import Tags from './components/Tags';
//STYLES
import Icon from 'components/Icon/Icon';
import TranslateModal from 'components/TranslateModal/TranslateModal';
import { AppLayoutWineWrapper } from 'module/AppLayoutWine/AppLayoutWine.styled';
import {
  WineContent,
  WineContentBody,
  WineContentBodyContainer,
  WineContentBodyImages,
  WineContentBodyInformation,
  WineContentLimkWeb,
  WineContentShare,
  WineContentShareItem,
  WineContentShareList,
  WineContentTitle,
  WineContentiParagraph,
  WineContentiSeparator,
  WineContentiUnLink,
} from './Wine.styled';
import CycleLifeModal from './components/CycleLifeModal';

const SOCIALS: Record<string, string> = {
  facebook: 'fb',
  twitter: 'tw',
  whatsapp: 'wa',
  instagram: 'in',
  youtube: 'yb',
  linkedin: 'ln',
  tiktok: 'tt',
};

const Wine = () => {
  const { wineId } = useParams();
  const dispatch = useDispatch();

  const matches = useMediaQuery('only screen and (max-width: 920px)');
  const matchesMd = useMediaQuery('only screen and (max-width: 768px)');
  const matchesXs = useMediaQuery('only screen and (max-width: 575px)');

  const { translateSelect } = useSelector(
    (state: AppState) => state.translateModal
  );

  const [metaInformation, setMetaInformation] = useState<MetaHeadProps>({});

  const { data: resProduct, loading, error, refetch } = useQuery<
    ProductData,
    ProductVars
  >(productQuery, {
    variables: {
      pk: wineId,
      language:
        translateSelect && translateSelect.length > 0
          ? translateSelect.find((lang) => lang.idProduct === wineId)
              ?.short_name
          : '',
    },
  });
  console.log(translateSelect, 'translateSelect');
  // const {
  //   data: resRelatedProduct,
  //   loading: loadingRelated,
  //   error: errorRelated,
  //   refetch: refetchRelated,
  // } = useQuery<ProductRelatedData, ProductsRelatedVars>(relatedProductQuery, {
  //   variables: {
  //     id: wineId,
  //     language:
  //       translateSelect && translateSelect.length > 0
  //         ? translateSelect.find((lang) => lang.idProduct === wineId)
  //             ?.short_name
  //         : '',
  //   },
  // });

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname,
      title: 'wine',
    });
  }, []);

  useEffect(() => {
    if (resProduct && resProduct.product && resProduct.product.data) {
      dispatch(setMobileNav('info'));
      setMetaInformation({
        title: resProduct.product.data.name,
        description: resProduct.product.data.description,
        image:
          resProduct.product.data.images.length > 0
            ? resProduct.product.data.images[0].url
            : '',
        url: window.location.href,
      });
    }
  }, [resProduct, dispatch]);

  useEffect(() => {
    if (resProduct && resProduct.product && resProduct.product.data) {
      dispatch(
        setOrganizationInfo({
          name: resProduct.product.data.organization.name,
          logo: resProduct.product.data.organization.logo.url,
        })
      );
      dispatch(setProduct(resProduct.product.data));
    }
  }, [dispatch, resProduct]);

  useEffect(() => {
    const selectLanguage = translateSelect.find(
      (lang) => lang.idProduct === wineId
    );
    console.log(selectLanguage, 'selectLanguage');
    if (
      !selectLanguage &&
      resProduct &&
      resProduct.product &&
      resProduct.product.data &&
      resProduct.product.data.translationStatus &&
      Object.keys(JSON.parse(resProduct.product.data.translationStatus))
        .length > 1
    ) {
      dispatch(
        openModal({
          isOpen: true,
          translations: JSON.parse(resProduct.product.data.translationStatus),
          isHiddenClose: true,
        })
      );
    } else if (
      selectLanguage &&
      resProduct &&
      resProduct.product &&
      resProduct.product.data &&
      resProduct.product.data.translationStatus &&
      Object.keys(JSON.parse(resProduct.product.data.translationStatus))
        .length > 1
    ) {
      dispatch(
        setTranslations({
          translations: JSON.parse(resProduct.product.data.translationStatus),
        })
      );
    }
  }, [translateSelect, resProduct, dispatch, wineId]);

  const handleRefetchQuery = useCallback(() => {
    refetch();
  }, [refetch]);

  const { isOpen, proof, wineProductLanguage } = useSelector(
    (state: AppState) => state.proofPointDetailModal
  );

  const details = resProduct?.product?.data?.tabs[0]?.nutritionFact?.details!;
  const [alcoholicStrengthTitle, setAlcoholicStrengthTitle] = useState();
  const [alcoholicStrength, setAlcoholicStrength] = useState();

  useEffect(() => {
    if (details) {
      const detailsList = JSON.parse(details);
      detailsList.map((detail: any, index: number) => {
        if (detail.title === 'alcoholicStrength') {
          setAlcoholicStrengthTitle(
            detail.translated_title[wineProductLanguage?.shortName!]
          );
          setAlcoholicStrength(detail.description);
        }
      });
    }
  }, [details, wineProductLanguage]);

  return (
    <AppLayoutWine
      loading={loading}
      skeletonLoader={<ProductSkeleton matches={matchesMd} />}
      metas={metaInformation}
      errorType={
        resProduct && resProduct.product && resProduct.product.errors
          ? resProduct.product.errors.message
          : resProduct && resProduct.product && resProduct.product.error
          ? resProduct.product.error
          : undefined
      }
      errorUrl={resProduct && resProduct.product && resProduct.product.url}
      logo={
        resProduct &&
        resProduct.product &&
        resProduct.product.data &&
        resProduct.product.data.organization &&
        resProduct.product.data.organization.logo.url
      }
      hideOrgData={
        resProduct &&
        resProduct.product &&
        resProduct.product.data &&
        resProduct.product.data.hideFromOrgMirror
      }
      type="wine"
      id={wineId}
    >
      <WineContent>
        <AppLayoutWineWrapper>
          <WineContentBody>
            {!matchesXs &&
              resProduct &&
              resProduct.product &&
              resProduct.product.data &&
              resProduct.product.data.organization &&
              resProduct.product.data.organization.socialChannels &&
              resProduct.product.data.organization.socialChannels.length > 0 &&
              !resProduct.product.data.hideFromOrgMirror && (
                <WineContentShare>
                  <WineContentShareList>
                    {resProduct.product.data.organization.socialChannels.map(
                      (social) => (
                        <WineContentShareItem
                          key={social.pk}
                          href={social.url}
                          target="_blank"
                        >
                          {SOCIALS[social.type.toLowerCase()]}
                        </WineContentShareItem>
                      )
                    )}
                  </WineContentShareList>
                </WineContentShare>
              )}
            {resProduct && resProduct.product && resProduct.product.data && (
              <WineContentBodyContainer>
                {!matches &&
                  resProduct.product.data &&
                  resProduct.product.data.images &&
                  resProduct.product.data.images.length > 0 && (
                    <WineContentBodyImages>
                      <Pictures
                        images={resProduct.product.data.images.map(
                          (image) => image.url
                        )}
                      />
                    </WineContentBodyImages>
                  )}

                <WineContentBodyInformation>
                  {resProduct.product.data &&
                    resProduct.product.data.organization &&
                    !resProduct.product.data.hideFromOrgMirror && (
                      <WineContentiUnLink
                      // to={{
                      //   pathname: `/winery/${resProduct.product.data.organization.pk}/`,
                      //   search: `?wineId=${resProduct.product.data.pk}`,
                      // }}
                      >
                        {resProduct.product.data.organization.name}
                      </WineContentiUnLink>
                    )}
                  {resProduct.product.data &&
                    resProduct.product.data.organization &&
                    !resProduct.product.data.hideFromOrgMirror &&
                    !!resProduct.product.data.organization.url && (
                      <div style={{ display: 'flex' }}>
                        <WineContentLimkWeb
                          href={resProduct.product.data.organization.url}
                          target="_blank"
                        >
                          <Icon iconName="link" />{' '}
                          {resProduct.product.data.organization.url
                            .replace('https://', '')
                            .replace('http://', '')
                            .replace('www.', '')}
                        </WineContentLimkWeb>
                      </div>
                    )}
                  <WineContentTitle>
                    {resProduct.product.data && resProduct.product.data.name}
                  </WineContentTitle>
                  {/* <WineContentOrganization>
                    {resProduct.product.data &&
                      resProduct.product.data.organization && (
                        <WineContentOrganizationImage
                          src={resProduct.product.data.organization.logo.url}
                        />
                      )}

                    <WineContentiLink
                      to={{
                        pathname: `/certificate/${resProduct.product.data.pk}/`,
                      }}
                    >
                      Saber más
                    </WineContentiLink>
                  </WineContentOrganization> */}
                  <WineContentiSeparator />

                  {resProduct.product.data &&
                    resProduct.product.data.description && (
                      <WineContentiParagraph maxWidth="500px" margin="0 0 2rem">
                        <RenderHtml
                          text={resProduct.product.data.description}
                          wine
                        />
                      </WineContentiParagraph>
                    )}

                  {resProduct.product.data.featuredProofPoints &&
                    resProduct.product.data.featuredProofPoints.length > 0 && (
                      <Tags
                        margin="0 0 1.5rem"
                        proofs={resProduct.product.data.featuredProofPoints}
                      />
                    )}
                  {alcoholicStrengthTitle && alcoholicStrength ? (
                    <div>
                      {alcoholicStrengthTitle + ' '} : {' ' + alcoholicStrength}
                    </div>
                  ) : (
                    <></>
                  )}

                  {resProduct.product.data.featuredProofPoints &&
                    resProduct.product.data.featuredProofPoints.length > 0 && (
                      <Seals
                        margin="0 0 1.5rem"
                        proofs={resProduct.product.data.featuredProofPoints}
                      />
                    )}

                  {matches &&
                    resProduct.product.data.images &&
                    resProduct.product.data.images.length > 0 && (
                      <WineContentBodyImages>
                        <Pictures
                          images={resProduct.product.data.images.map(
                            (image) => image.url
                          )}
                        />
                      </WineContentBodyImages>
                    )}
                  {resProduct.product.data.tabs &&
                    resProduct.product.data.tabs.length > 0 && (
                      <Tabs tabs={resProduct.product.data.tabs} />
                    )}
                  <Share type="product" product={resProduct.product.data} />
                </WineContentBodyInformation>
              </WineContentBodyContainer>
            )}
          </WineContentBody>
        </AppLayoutWineWrapper>
        {resProduct &&
          resProduct.product &&
          resProduct.product.data &&
          resProduct.product.data.journey &&
          resProduct.product.data.journey.milestones &&
          resProduct.product.data.journey.milestones.length > 0 && (
            <CycleLife
              milestones={resProduct.product.data.journey.milestones}
            />
          )}

        <AppLayoutWineWrapper>
          {/* {resProduct &&
            resProduct.product &&
            resProduct.product.data &&
            !resProduct.product.data.hideFromOrgMirror && (
              <WineContentFooter>
                {resProduct.product.data.organization && (
                  <WineContentiLink
                    to={{
                      pathname: `/winery/${resProduct.product.data.organization.pk}/`,
                      search: `?wineId=${resProduct.product.data.pk}`,
                    }}
                    margin="0 0 2rem;"
                  >
                    Saber más sobre {resProduct.product.data.organization.name}
                  </WineContentiLink>
                )}
              </WineContentFooter>
            )} */}
        </AppLayoutWineWrapper>
        <ProofPointModal wine />
        <ProofPointDetailModal wine />
        <MediaEvicenceModal wine />
        <EvidenceModal wine />
        <BlockchainDetailModal type="product" wine />
        <CycleLifeModal />
        <TranslateModal
          refetch={handleRefetchQuery}
          type="product"
          id={wineId}
        />
      </WineContent>
    </AppLayoutWine>
  );
};

export default Wine;
