import React, { useEffect, Suspense } from 'react';
import { useDispatch } from 'react-redux';
import { useQuery, useMutation } from '@apollo/react-hooks';
import Theme from 'theme';

//QUERIES
import { blockNitiveStatsQuery } from 'queries/blocknitive';
import { shortUrlCreate } from 'queries/link';

//PROPS TYPE
import { AppProps } from './App.props';
import { BlocknitiveStatsData } from 'types/blocknitive';

//STORE
import { setBlockState } from 'store/blocknitive';
import { setLink } from 'store/link';

//STYLED
import { AppContainer } from './App.styled';

const App = ({ children }: AppProps) => {
  const dispatch = useDispatch();

  const { data, loading: loadingBlocknitive, error } = useQuery<
    BlocknitiveStatsData
  >(blockNitiveStatsQuery);

  const [shortUrlCreateMutation] = useMutation(shortUrlCreate, {
    awaitRefetchQueries: true,
    refetchQueries: ['link'],
  });

  useEffect(() => {
    if (data && !loadingBlocknitive) {
      dispatch(
        setBlockState({
          data: data,
        })
      );
    }
    if (error && error.graphQLErrors.length > 0) {
      dispatch(
        setBlockState({
          data: null,
          errors: error.graphQLErrors,
        })
      );
    }
  }, [dispatch, data, error, loadingBlocknitive]);

  useEffect(() => {
    const currentUrl = encodeURI(window.location.href);

    if (currentUrl) {
      shortUrlCreateMutation({
        variables: {
          shortUrlInput: {
            originalURL: currentUrl,
          },
        },
      })
        .then((res) => {
          if (res && res.data && res.data.shortUrlCreate) {
            dispatch(setLink(res.data.shortUrlCreate));
          }
        })
        .catch((error) => {
          dispatch(setLink(currentUrl));
        });
    }
  }, [dispatch, shortUrlCreateMutation]);

  return (
    <Theme>
      <AppContainer>
        <Suspense fallback={null}>{children}</Suspense>
      </AppContainer>
    </Theme>
  );
};

export default App;
