import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import _ from 'lodash';
//PROPS TYPE
import { ProductMobileViewProps } from './ProductMobileView.props';
//STORE
import { AppState } from 'store';
//COMPONENTS
import SectionHeader from 'components/SectionHeader';
import FeatureProofPoints from 'components/FeatureProofPoints';
import Seals from 'components/Seals';
import ProductImages from 'components/ProductImages';
import CustomerReviews from 'components/CustomerReviews';
import RenderHtml from 'components/RenderHtml';
import MilestoneList from 'components/MilestoneList';
import RelatedProducts from 'components/RelatedProducts';
import ProductTabs from 'components/ProductTabs';
//STYLES
import {
  ProductSection,
  ProductGrid,
  ProductContent,
  ProductSideBar,
  ProductInfoTitle,
  ProductInfoData,
} from '../../Product.styled';

const ProductMobileView = ({
  product,
  relatedProducts,
}: ProductMobileViewProps) => {
  const { t } = useTranslation('product');

  const location = useLocation();

  const { tab } = useSelector((state: AppState) => state.mobileNav);
  const lotInternalId = useSelector(
    (state: AppState) => state.product.lotInternalId
  );

  return (
    <ProductSection>
      <ProductGrid>
        {!_.isEmpty(product) && (
          <ProductContent>
            {(() => {
              switch (tab) {
                case 'info':
                  return (
                    <>
                      <SectionHeader text={t('header.productInfo')} />
                      {product &&
                        product.featuredProofPoints &&
                        product.featuredProofPoints.length > 0 && (
                          <FeatureProofPoints
                            featuredProofs={product.featuredProofPoints}
                          />
                        )}

                      {product && product.featuredProofPoints.length > 0 && (
                        <Seals featuredProofs={product.featuredProofPoints} />
                      )}
                      {location.pathname.includes('/lot/') && (
                        <>
                          <ProductInfoTitle>
                            {t('content.productLots')}
                          </ProductInfoTitle>
                          <ProductInfoData>{lotInternalId}</ProductInfoData>
                        </>
                      )}
                      {product && product.description && (
                        <>
                          <ProductInfoTitle>
                            {t('content.productDescription')}
                          </ProductInfoTitle>
                          <RenderHtml text={product.description} />
                        </>
                      )}

                      {product && product.images && product.images.length > 0 && (
                        <>
                          <ProductInfoTitle>
                            {t('content.productImages')}
                          </ProductInfoTitle>
                          <ProductImages images={product.images} />
                        </>
                      )}
                      {product && product.tabs && product.tabs.length > 0 && (
                        <ProductTabs tabs={product.tabs} />
                      )}

                      {relatedProducts &&
                        relatedProducts.products &&
                        relatedProducts.products.length > 0 && (
                          <>
                            <ProductInfoTitle>
                              {t('content.productRelated')}
                            </ProductInfoTitle>
                            <RelatedProducts
                              products={relatedProducts.products}
                            />
                          </>
                        )}
                    </>
                  );
                case 'reviews':
                  return (
                    <>
                      {product && (
                        <CustomerReviews
                          reviewStats={product.reviewStats}
                          reviews={product.reviews.edges}
                        />
                      )}
                    </>
                  );
                case 'share':
                  return (
                    <div>
                      <SectionHeader text="COMPARTIR" />
                    </div>
                  );
                default:
                  return (
                    <ProductSideBar>
                      <SectionHeader text={t('header.productJourney')} />
                      {product && (
                        <MilestoneList
                          milestones={product.journey.milestones}
                        />
                      )}
                    </ProductSideBar>
                  );
              }
            })()}
          </ProductContent>
        )}
      </ProductGrid>
    </ProductSection>
  );
};

export default ProductMobileView;
