import React, { useState } from 'react';

//COMPONENTS
import SkeletonLoader from 'components/SkeletonLoader';
import SkeletonMilestonesItem from './SkeletonMilestonesItem';

const SkeletonMilestones = () => {
  const [milestonesList] = useState([1, 2, 3]);

  return (
    <SkeletonLoader width="100%" height="auto" background="transparent">
      {milestonesList.map((milestone, index) => (
        <SkeletonMilestonesItem key={`${index}-milestone`} />
      ))}
    </SkeletonLoader>
  );
};

export default SkeletonMilestones;
