import styled from 'styled-components';
import { sm, lg } from 'theme/Theme.breackoints';

//COMPONENTS
import { Text } from 'components/Text';

export const OrganizationInfoContainer = styled.div`
  display: grid;
  align-items: start;
  grid-template: 1fr/ 24rem calc(100% - 26rem);
  gap: 0 2rem;
  padding: 0 0 2.5rem;
  margin: 0 0 2.5rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  ${sm`
    grid-template: 1fr / 1fr;
    border: none;
    margin: 0;
  `}
`;
export const OrganizationInfoImage = styled.img`
  width: 100%;
  ${sm`
    margin: 0 0 2rem;
  `}
`;
export const OrganizationInfoContent = styled.div``;
export const OrganizationInfoDetails = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 1rem 2rem;
`;
export const OrganizationInfoDetailsColumn = styled.div``;
export const OrganizationInfoLabel = styled(Text)`
  color: ${({ theme }) => theme.colors.fontSecondary};
  text-transform: uppercase;
  margin: 0 0 0.5rem;
  font-size: 0.875rem;
  line-height: 0.875rem;
  font-weight: 700;
`;
export const OrganizationInfoText = styled(Text)`
  color: ${({ theme }) => theme.colors.black};
  font-size: 0.875rem;
  line-height: 1.25rem;
`;
export const OrganizationInfoLink = styled.a`
  font-size: 1rem;
  line-height: 1rem;
  color: ${({ theme }) => theme.colors.brand};
  cursor: pointer;
  display: inline-block;
  background-color: transparent;
  font-weight: 700;
  word-break: break-all;
  &:hover {
    color: ${({ theme }) => theme.colors.active};
  }
`;

export const OrganizationInfoTelephones = styled.div`
  display: grid;
  grid-template: 1fr / 1fr 1fr;
  gap: 0.5rem;
  ${lg`
    grid-template: 1fr / 1fr;
  `}
`;
export const OrganizationInfoTelephonesItemLabel = styled(Text)`
  font-size: 0.625rem;
  line-height: 0.625rem;
  text-transform: uppercase;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.fontSecondary};
  margin: 0 0 0.25rem;
`;
export const OrganizationInfoTelephonesItemName = styled(Text)`
  font-size: 0.75rem;
  line-height: 0.75rem;
`;
export const OrganizationInfoTelephonesItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0 1rem;
  padding: 0.5rem 1rem;
  border: 1px solid ${({ theme }) => theme.colors.border};
  transition: all 0.3s ease-in-out;
  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.brand};
    ${OrganizationInfoTelephonesItemName} {
      color: ${({ theme }) => theme.colors.brand};
    }
  }
`;
