import styled, { css } from 'styled-components';

//PROPS TYPE
import { ButtonProps } from './Button.props';

//COMPONENT
import { Text } from 'components/Text';

const gradientBackgound = css`
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 0.25s ease-in;
  z-index: 1;
`;

export const ButtonText = styled(Text)`
  z-index: 2;
  color: #fcfcfc;
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 0.875rem;
`;

export const ButtonContainer = styled.button<ButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  transition: all 0.3s ease-in-out;
  min-width: max-content;
  padding: 1.125rem 1.5rem;
  font-size: 0.875rem;
  line-height: 0.875rem;
  position: relative;
  box-shadow: 0 4px 10px 0 rgb(16 3 22 / 10%);
  transition: all 0.25s ease-in-out;
  &::before {
    ${gradientBackgound}
    background-image: linear-gradient(90deg, #4e63a5 0%, #8298d5 100%);
    opacity: 1;
  }
  &::after {
    ${gradientBackgound}
    background-image: linear-gradient(90deg, #651366 0%, #4e63a5 100%);
    opacity: 0;
  }
  &:focus {
    outline: none;
  }
  &:hover {
    transform: translateY(-0.125rem);
    box-shadow: 0 4px 10px 0 rgb(16 3 22 / 20%);

    &::before {
      opacity: 0;
    }
    &::after {
      opacity: 1;
    }
  }

  ${({ disabled, theme }) =>
    disabled &&
    `
    background: ${theme.colors.border};
    cursor: not-allowed;
    box-shadow: none;
    &:hover {
      transform: translateY(0);
      box-shadow: none;
    }
    &::before {
      display: none;
    }
    &::after {
      display: none;
    }
    ${ButtonText} {
      color: ${theme.colors.placeholder};
    }
  `}

   ${({ wine, theme, outline, disabled }) => wine && `
      font-family: ${theme.fonts.wine};
      border-radius: 8px;
      box-shadow: none;
      background: ${outline ? "transparent" : theme.colors.wineBlack};
      border: ${outline ? `1px solid ${theme.colors.wineBlack}`  : "none"};
      &::before {display: none;}
      &::after {display: none;}
      &:hover {
        transform: none;
        box-shadow: none;
        background: ${outline ? theme.colors.wineBlack : theme.colors.wineGrayLigth};
        ${ButtonText} {
          color: ${outline ? theme.colors.white : theme.colors.wineGray};
        }
      }
      ${ButtonText} {
        color: ${outline ? theme.colors.wineBlack : theme.colors.white};
        font-size: 1rem;
      }

      ${disabled && `
        border-color: #c7c7c7;
        ${ButtonText} {
          color:  #c7c7c7;
        }
        &:hover {
          transform: none;
          box-shadow: none;
          background: transparent;
          ${ButtonText} {
            color: #c7c7c7;
          }
        }
      `}
   `}
`;
