import styled from "styled-components"
import { Text } from "components/Text"
import { breakpoints } from "theme/Theme.breackoints";

export const AppLayoutWineContaienr = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-family: 'Rozha One', serif;
  
`
export const AppLayoutWineContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;
export const AppLayoutWineWrapper = styled.div`
  width: 100%;
  max-width: 1230px;
  margin: 0 auto;
  @media screen and (max-width: ${breakpoints.lg}px) {
    padding: 0 2rem;
  }
`
export const AppLayoutWineHeader = styled.div`
  padding: 2rem 0;
  /* margin-bottom: 2rem; */
  display: flex;
  justify-content: space-between;
`
export const AppLayoutWineHeaderLogo = styled.img<{minSize?: boolean;}>`
  width: 120px;
  ${({ minSize }) => minSize && `
    width: 200px;
  `}
`
export const AppLayoutWineHeaderTranslate = styled.div`
  display: flex;
  gap: 0 2.5rem;
`
export const AppLayoutWineHeaderTranslateLang = styled(Text)<{active?: boolean;}>`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.winePrimary};
  cursor: pointer;
  ${({ active, theme }) => active && `
    color: ${theme.colors.wineBlack};
  `}
`
export const AppLayoutWineFooter = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.wineBlack};
  padding: 1.5rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  `
export const AppLayoutWineFooterText  = styled.a`
  font-family: ${({ theme }) => theme.fonts.main};
  font-size: 0.75rem;
  line-height: 0.75rem;
  color: ${({ theme }) => theme.colors.wineGray};
  cursor: pointer;
  text-decoration: none;
  &:hover {
    color: ${({ theme }) => theme.colors.winePrimary};
  }
`
export const AppLayoutWineFooterCopy = styled(AppLayoutWineFooterText)`
  display: flex;
  align-items: center;
`
export const AppLayoutWineFooterCopyLogo = styled.img`
  margin-right: 0.5rem;
`
export const AppLayoutWineContent = styled.div`
  flex: 1;
`