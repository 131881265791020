import styled from 'styled-components';

export const CustomerReviewContainer = styled.div`
  padding: 0 0 1.25rem;
  margin: 0 0 1.25rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  &:last-child {
    border: none;
  }
`;

export const CustomerReviewComment = styled.p`
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: ${({ theme }) => theme.colors.fontSecondary};
  strong {
    color: ${({ theme }) => theme.colors.black};
  }
`;

export const CustomerReviewReply = styled.div`
  margin: 1.25rem 0 0;
  padding: 0.5rem 0 0.5rem 2.5rem;
  position: relative;
  &::before {
    position: absolute;
    content: '';
    width: 3px;
    height: 100%;
    top: 0;
    left: 0.75rem;
    background-color: ${({ theme }) => theme.colors.border};
  }
`;
