import gql from 'graphql-tag';

export const reviewCreate = gql`
  mutation($reviewCreate: ProductReviewCreateInput!) {
    reviewCreate(input: $reviewCreate) {
      id
    }
  }
`;

export const reviewOrganizationCreate = gql`
  mutation($reviewCreate: OrganizationReviewCreateInput!) {
    organizationReviewCreate(input: $reviewCreate) {
      id
    }
  }
`;

export const reviewLotCreate = gql`
  mutation($reviewCreate: ProductLotReviewCreateInput!) {
    lotReviewCreate(input: $reviewCreate) {
      id
    }
  }
`;
