import {
  css,
  Interpolation,
  SimpleInterpolation,
  ThemedStyledProps,
  DefaultTheme,
} from 'styled-components';

export type Breakpoints = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

export const breakpoints: Record<Breakpoints, number> = {
  xs: 575,
  sm: 768,
  md: 920,
  lg: 1280,
  xl: 1920,
};

type Props = Record<string, unknown>;
type Interpolators<P extends Props> =
  | SimpleInterpolation[]
  | Array<Interpolation<ThemedStyledProps<Props, DefaultTheme>>>
  | Array<Interpolation<ThemedStyledProps<P, DefaultTheme>>>;

export const xs = <T extends Props>(
  strings: TemplateStringsArray,
  ...values: Interpolators<T>
) => css`
  @media screen and (max-width: ${breakpoints.xs}px) {
    ${css(strings, ...values)}
  }
`;

export const sm = <T extends Props>(
  strings: TemplateStringsArray,
  ...values: Interpolators<T>
) => css`
  @media screen and (max-width: ${breakpoints.sm}px) {
    ${css(strings, ...values)}
  }
`;

export const md = <T extends Props>(
  strings: TemplateStringsArray,
  ...values: Interpolators<T>
) => css`
  @media screen and (max-width: ${breakpoints.md}px) {
    ${css(strings, ...values)}
  }
`;

export const lg = <T extends Props>(
  strings: TemplateStringsArray,
  ...values: Interpolators<T>
) => css`
  @media screen and (max-width: ${breakpoints.lg}px) {
    ${css(strings, ...values)}
  }
`;

export const xl = <T extends Props>(
  strings: TemplateStringsArray,
  ...values: Interpolators<T>
) => css`
  @media screen and (min-width: ${breakpoints.xl}px) {
    ${css(strings, ...values)}
  }
`;
