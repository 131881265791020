import React, { useState } from 'react';
import { ProductTabs as ProductTabsList } from 'types/product';
import { useMediaQuery } from '@react-hook/media-query';
//COMPONENTS
import Tab from './Tab';
//STYLES
import { TabsContainer } from './Tabs.styled';

interface TabsProps {
  tabs: ProductTabsList[];
}

interface MapTemplateTabs {
  template: JSX.Element;
}

const Tabs = ({ tabs }: TabsProps) => {
  const matches = useMediaQuery('only screen and (max-width: 575px)');

  const [active, setActive] = useState(tabs[0].pk);

  return (
    <TabsContainer>
      {matches ? (
        <Tab tabs={tabs} active={active} setActive={setActive} mobile />
      ) : (
        <Tab tabs={tabs} active={active} setActive={setActive} />
      )}
    </TabsContainer>
  );
};

export default Tabs;
