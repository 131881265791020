import React from 'react';

//STYLES
import { HashPlaceholderColorContainer } from './HashPlaceholder.styled';

interface HashPlaceholderColorProps {
  color: string;
}

const HashPlaceholderColor = ({ color }: HashPlaceholderColorProps) => {
  let styles = { backgroundColor: color };

  return (
    <HashPlaceholderColorContainer
      style={styles}
    ></HashPlaceholderColorContainer>
  );
};

export default HashPlaceholderColor;
