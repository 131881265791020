import styled from 'styled-components';

import Button from 'components/Button';
import { Text } from 'components/Text';
import { Colors } from 'theme/Theme.styled';

interface TranslateStatus {
  status?: 'completed' | 'incomplete' | string;
}

type TranslateStatusColors = {
  color: Colors;
};

const translateStatusColorMap: Record<string, TranslateStatusColors> = {
  completed: {
    color: 'turquoise',
  },
  incomplete: {
    color: 'error',
  },
};

export const TranslateModalContainer = styled.div``;
export const TranslateModalLenguage = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 1rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.placeholder};
  padding: 0 0 1rem;
  &:last-child {
    margin: 0;
    padding: 0;
    border: none;
  }
`;
export const TranslateModalLenguageActions = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: space-between;
  height: 2.25rem;
`;
export const TranslateModalLenguageMeta = styled.div`
  display: grid;
  grid-template: 1fr / 2.25rem 1fr;
  gap: 0 1rem;
`;
export const TranslateModalLenguageAvatar = styled.img`
  max-width: 2.25rem;
  object-fit: cover;
  height: 2.25rem;
  border-radius: 50%;
`;
export const TranslateModalLenguageName = styled(Text)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 2.25rem;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.black};
`;
export const TranslateModalLenguageStatus = styled(Text)<TranslateStatus>`
  font-size: 0.75rem;
  line-height: 0.75rem;
  text-transform: capitalize;
  color: ${({ theme, status }) =>
    status
      ? theme.colors[translateStatusColorMap[status].color]
      : theme.colors.backgroundGray};
`;
export const TranslateModalLenguageAction = styled(Button)`
  height: 100%;
  padding: 0 1.5rem;
`;
