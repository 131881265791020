import * as React from 'react';

function ChevronDownIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={32}
      height={32}
      viewBox="0 0 448 512"
      aria-labelledby="chevronDown"
      stroke="#100316"
      strokeWidth={1.5}
      strokeLinecap="round"
      fill="none"
      {...props}
    >
      <title>{'Chevron Down'}</title>
      <path
        fill="currentColor"
        d="M441.9 167.3l-19.8-19.8c-4.7-4.7-12.3-4.7-17 0L224 328.2 42.9 147.5c-4.7-4.7-12.3-4.7-17 0L6.1 167.3c-4.7 4.7-4.7 12.3 0 17l209.4 209.4c4.7 4.7 12.3 4.7 17 0l209.4-209.4c4.7-4.7 4.7-12.3 0-17z"
      ></path>
    </svg>
  );
}

export default ChevronDownIcon;
