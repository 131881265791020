import React from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { LANGUAGE_MAP } from 'components/TranslateModal';

//UTILS
import { getInitials } from 'utils/get-initials';

//PROPS TYPE
import { CustomerReviewHeaderProps } from './CustomerReviewHeader.props';

//STORE
import { AppState } from 'store';

//COMPONENTS
import StarRating from 'components/StarRating';
import Avatar from 'components/Avatar';

//STYLES
import {
  CustomerReviewHeaderContainer,
  CustomerReviewUser,
  CustomerReviewInfo,
  CustomerReviewRating,
  CustomerReviewName,
  CustomerReviewDate,
} from './CustomerReviewHeader.styled';
import { compact } from 'lodash';

const CustomerReviewHeader = ({
  name,
  logo,
  date,
  rating,
  reply = false,
}: CustomerReviewHeaderProps) => {
  const { translateSelect } = useSelector(
    (state: AppState) => state.translateModal
  );
  const { product } = useSelector((state: AppState) => state.product);
  const langDate =
    product &&
    translateSelect &&
    translateSelect.length > 0 &&
    translateSelect.find((lang) => lang.idProduct === product.pk);

  return (
    <CustomerReviewHeaderContainer>
      <Avatar size={45} text={getInitials(name)} color="border" url={logo} />
      <CustomerReviewUser>
        <CustomerReviewInfo reply={reply}>
          <CustomerReviewName>{name}</CustomerReviewName>
          <CustomerReviewDate>
            {moment(date)
              .locale(
                !!langDate ? LANGUAGE_MAP[langDate.short_name].lang : 'es-ES'
              )
              .fromNow()}
          </CustomerReviewDate>
        </CustomerReviewInfo>
        {rating && (
          <CustomerReviewRating>
            <StarRating rating={(rating / 5) * 100} />
          </CustomerReviewRating>
        )}
      </CustomerReviewUser>
    </CustomerReviewHeaderContainer>
  );
};

export default CustomerReviewHeader;
