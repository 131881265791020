import React from 'react';

//PROPS TYPE
import { InputProps } from './Input.props';

//COMPONENTS
import Label from 'components/Label';

//STYLES
import { InputContainer, InputStyled } from './Input.styled';

export default function Input({
  name,
  label,
  error,
  placeholder,
  handleBlur,
  handleChange,
  type = 'text',
}: InputProps) {
  return (
    <InputContainer>
      <Label name={name}>{label}</Label>
      <InputStyled
        placeholder={placeholder}
        name={name}
        onBlur={handleBlur}
        onChange={handleChange}
        type={type}
      />
    </InputContainer>
  );
}
