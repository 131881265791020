import React, { useState } from 'react';

//STYLES
import {
  ReviewModalRatingContainer,
  ReviewModalRatingStar,
} from './ReviewModal.styled';

interface ReviewModalRatingProps {
  onClickRating: (rating: number) => void;
}

const ReviewModalRating = ({ onClickRating }: ReviewModalRatingProps) => {
  const [rating, setRating] = useState<number>(0);

  const handleSelectRating = (rating: number) => {
    onClickRating(rating);
    setRating(rating);
  };

  return (
    <ReviewModalRatingContainer>
      <ReviewModalRatingStar
        active={rating >= 1}
        onClick={() => handleSelectRating(1)}
      />
      <ReviewModalRatingStar
        active={rating >= 2}
        onClick={() => handleSelectRating(2)}
      />
      <ReviewModalRatingStar
        active={rating >= 3}
        onClick={() => handleSelectRating(3)}
      />
      <ReviewModalRatingStar
        active={rating >= 4}
        onClick={() => handleSelectRating(4)}
      />
      <ReviewModalRatingStar
        active={rating >= 5}
        onClick={() => handleSelectRating(5)}
      />
    </ReviewModalRatingContainer>
  );
};

export default ReviewModalRating;
