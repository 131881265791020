import React from 'react';

//COMPONENTS
import SkeletonLoader from 'components/SkeletonLoader';

const SkeletonSectionTitle = () => {
  return (
    <SkeletonLoader
      width="100%"
      height="5rem"
      display="flex"
      flex={{
        align: 'center',
      }}
      background="transparent"
    >
      <SkeletonLoader width="16.25rem" height="1rem" />
    </SkeletonLoader>
  );
};

export default SkeletonSectionTitle;
