import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
//PROPS TYPE
import { ProductProofPoint } from 'types/product';
//STORE
import { openModal } from 'store/proofPointModal';
//STYLES
import {
  TagsContainer,
  TagsItem,
  TagsItemIcon,
  TagsItemText,
} from './Tags.styled';

interface TagsProps {
  margin?: string;
  proofs: ProductProofPoint[];
  style?: React.CSSProperties;
}

const Tags = ({ style, margin, proofs }: TagsProps) => {
  const dispatch = useDispatch();

  const PROOFPOINTS_LIST = proofs.filter(
    (proof) =>
      proof && proof.icon && proof.icon.icon && !proof.icon.icon.includes('c_')
  );

  const handleOpenProofPointModal = useCallback(
    (proof: ProductProofPoint) => {
      dispatch(
        openModal({
          proof,
        })
      );
    },
    [dispatch]
  );

  return (
    <TagsContainer margin={margin} style={style}>
      {PROOFPOINTS_LIST.map((proof) => (
        <TagsItem
          key={proof.pk}
          onClick={() => {
            handleOpenProofPointModal(proof);
          }}
        >
          <TagsItemIcon src={proof.icon.icon} />
          <TagsItemText>{proof.name}</TagsItemText>
        </TagsItem>
      ))}
    </TagsContainer>
  );
};

export default Tags;
