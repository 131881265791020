import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

//ASSETS
import BlockchainIcon from 'assets/images/blockchain.png';

//STORE
import { AppState } from 'store';

//COMPONENTS
import CountUp from 'react-countup';

//STYLED
import {
  BlockchainDetailModalHeaderContainer,
  BlockchainDetailModalHeaderData,
  BlockchainDetailModalHeaderDataBlock,
  BlockchainDetailModalHeaderDataName,
  BlockchainDetailModalHeaderDataNumber,
  BlockchainDetailModalHeaderIcon,
  BlockchainDetailModalHeaderName,
} from './BlockchainDetailModal.styled';

const BlockchainDetailModalHeader = () => {
  const { t } = useTranslation('modalBlockChain');

  const { blockStats } = useSelector((state: AppState) => state.blockStats);
  const { proof, isStatic } = useSelector(
    (state: AppState) => state.blockchainDetailModal
  );

  return (
    <BlockchainDetailModalHeaderContainer>
      <BlockchainDetailModalHeaderName>
        <BlockchainDetailModalHeaderIcon src={BlockchainIcon} />
        {t('content.blockChainRecordingOf')}{' '}
        {isStatic ? 'CERTIFICADO CORPINNAT' : proof?.name}
      </BlockchainDetailModalHeaderName>
      <BlockchainDetailModalHeaderData>
        <BlockchainDetailModalHeaderDataBlock>
          <BlockchainDetailModalHeaderDataNumber>
            {blockStats.data &&
            blockStats.data.blocknitiveStats &&
            blockStats.data.blocknitiveStats.blockCount ? (
              <CountUp
                end={blockStats.data.blocknitiveStats.blockCount}
                duration={3}
              />
            ) : (
              0
            )}
          </BlockchainDetailModalHeaderDataNumber>
          <BlockchainDetailModalHeaderDataName>
            {t('content.blocks')}
          </BlockchainDetailModalHeaderDataName>
        </BlockchainDetailModalHeaderDataBlock>
        <BlockchainDetailModalHeaderDataBlock>
          <BlockchainDetailModalHeaderDataNumber>
            {blockStats.data &&
            blockStats.data.blocknitiveStats &&
            blockStats.data.blocknitiveStats.transactionsCount ? (
              <CountUp
                end={blockStats.data.blocknitiveStats.transactionsCount}
                duration={3}
              />
            ) : (
              0
            )}
          </BlockchainDetailModalHeaderDataNumber>
          <BlockchainDetailModalHeaderDataName>
            {t('content.transactions')}
          </BlockchainDetailModalHeaderDataName>
        </BlockchainDetailModalHeaderDataBlock>
      </BlockchainDetailModalHeaderData>
    </BlockchainDetailModalHeaderContainer>
  );
};

export default BlockchainDetailModalHeader;
