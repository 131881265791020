import styled from 'styled-components';

//COMPONENTS
import { Text } from 'components/Text';

export const SealItemContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.border};
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0 2px 10px 0 rgb(16 3 22 / 6%);
  padding: 1.25rem 1.5rem;
  max-width: 8.5rem;
  margin: 0 1rem 0 0;
  width: 100%;
  margin: 0 1rem 0 0;
  flex: 1 0 auto;
  cursor: pointer;
  transition: all 0.25s ease-in-out;
  &:last-child {
    margin: 0;
  }
  &:hover {
    transform: translateY(-0.25rem);
  }
`;
export const SealItemIcon = styled.img`
  width: 3.5rem;
  margin: 0 0 1rem -0.25rem;
`;
export const SealItemName = styled(Text)`
  font-size: 0.625rem;
  line-height: 0.95rem;
  text-transform: uppercase;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.fontSecondary};
`;
