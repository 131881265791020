import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

//PROPS TYPE
import { AppMobileNavMapProps } from './AppMobileNav.props';
import { tabType } from 'store/mobilenav';

//STORE
import { AppState } from 'store';
import { setMobileNav } from 'store/mobilenav';

//COMPONENTS
import SocialShare from 'components/SocialShare';
import Icon from 'components/Icon';
// import Milestones from 'components/icons/Milestones';
// import Reviews from 'components/icons/Reviews';
// import Information from 'components/icons/Information';
// import Share from 'components/icons/Share';
// import Close from 'components/icons/Close';

//STYLES
import {
  AppMobileNavContainer,
  AppMobileNavList,
  // AppMobileNavListItem,
  // AppMobileNavListLink,
  // AppMobileNavListText,
  AppMobileNavLinkProvisional,
  AppMobileNavShare,
  AppMobileNavShareIcon,
  AppMobileNavShareOverlay,
} from './AppMobileNav.styled';

const AppMobileNav = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const { tab } = useSelector((state: AppState) => state.mobileNav);
  const product = useSelector((state: AppState) => state.product.product);
  const organization = useSelector(
    (state: AppState) => state.organization.organization
  );

  const [shareActive, setShareActive] = useState<boolean>(false);

  const tabMenuMap: AppMobileNavMapProps[] = [
    {
      name: 'Info',
      visible: true,
      value: 'info',
      // icon: <Information />,
    },
    {
      name: 'Etapas',
      visible:
        location.pathname.includes('/products/') ||
        location.pathname.includes('/lot/')
          ? true
          : false,
      value: 'milestones',
      // icon: <Milestones />,
    },
    {
      name: 'Opiniones',
      visible: true,
      value: 'reviews',
      // icon: <Reviews />,
    },
  ];

  const selectTab = (tab: tabType) => {
    dispatch(setMobileNav(tab));
  };

  const handleToggleShare = () => {
    setShareActive(!shareActive);
  };

  return (
    <AppMobileNavContainer>
      {shareActive && <AppMobileNavShareOverlay onClick={handleToggleShare} />}
      <AppMobileNavShare>
        {shareActive && (
          <SocialShare
            type={
              location.pathname.includes('/products/') ||
              location.pathname.includes('/lot/')
                ? 'product'
                : 'organization'
            }
            mobile
            onClick={handleToggleShare}
            product={
              location.pathname.includes('/products/') ||
              location.pathname.includes('/lot/')
                ? product
                : undefined
            }
            organization={
              location.pathname.includes('/organizations/')
                ? organization
                : undefined
            }
          />
        )}

        <AppMobileNavShareIcon onClick={handleToggleShare}>
          {!shareActive ? <Icon iconName="share" /> : <Icon iconName="close" />}
        </AppMobileNavShareIcon>
      </AppMobileNavShare>
      <AppMobileNavList>
        {tabMenuMap
          .filter((tabItem) => tabItem.visible)
          .map((tabItem) => (
            <AppMobileNavLinkProvisional
              key={tabItem.value}
              onClick={() => selectTab(tabItem.value)}
              active={tabItem.value === tab}
            >
              {tabItem.name}
            </AppMobileNavLinkProvisional>
            // <AppMobileNavListItem
            //   key={tabItem.value}
            //   onClick={() => selectTab(tabItem.value)}
            // >
            //   <AppMobileNavListLink active={tabItem.value === tab}>
            //     {tabItem.icon}
            //     <AppMobileNavListText>{tabItem.name}</AppMobileNavListText>
            //   </AppMobileNavListLink>
            // </AppMobileNavListItem>
          ))}
        {/* <AppMobileNavListItem onClick={() => selectTab('share')}>
          <AppMobileNavListLink active={tab === 'share'}>
            <Share />
            <AppMobileNavListText>Compartir</AppMobileNavListText>
          </AppMobileNavListLink>
        </AppMobileNavListItem> */}
      </AppMobileNavList>
    </AppMobileNavContainer>
  );
};

export default AppMobileNav;
