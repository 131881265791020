import styled from 'styled-components';

import { Text } from 'components/Text';

export const ProofPointContainer = styled.button`
  display: flex;
  align-items: center;
  margin: 0.5rem 0.5rem 0.5rem 0;
  padding: 0.5rem 0.75rem;
  outline: none;
  background-color: ${({ theme }) => theme.colors.brand};
  transition: all 0.3s ease-in-out;
  border-radius: 9999px;
  box-shadow: 0 4px 10px 0 rgb(16 3 22 / 10%);
  gap: 0 0.5rem;
  flex: 0 0 auto;
  &:hover {
    background-color: ${({ theme }) => theme.colors.blueLigth};
    transform: translateY(-0.25rem);
    box-shadow: 0 4px 10px 0 rgb(16 3 22 / 20%);
  }
  &:focus,
  &:active {
    outline: none;
  }
`;
export const ProofPointIcon = styled.img`
  width: 100%;
  max-width: 20px;
  filter: invert(100%) sepia(15%) saturate(0%) hue-rotate(40deg)
    brightness(103%) contrast(102%);
`;
export const ProofPointName = styled(Text)`
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.white};
  flex: 1 0 auto;
`;
