import styled from 'styled-components';
import { DialogOverlay, DialogContent } from '@reach/dialog';
import { breakpoints } from 'theme/Theme.breackoints';
import { SizeModal } from './Modal.props';
import { Colors, isColor } from 'theme/Theme.styled';

export const sizesModalMap: Record<SizeModal, number> = {
  small: 575,
  medium: 768,
  big: 1200,
};

export const ModalDialogOverlay = styled(DialogOverlay)<{wine?: boolean;}>`
  z-index: 9999;
  background-color: rgba(78, 99, 165, 0.9);
  max-width: 100vw;
  ${({ wine }) => wine && `
    background-color: rgba(51, 51, 51, 0.9);
  `}
`;
export const ModalDialogContent = styled(DialogContent)<{
  size?: SizeModal;
  backcolor?: Colors | string;
  padding: string;
}>`
  position: relative;
  width: ${({ size = 'medium' }) => `${sizesModalMap[size]}px`};
  max-width: ${({ size = 'medium' }) => `${sizesModalMap[size]}px`};
  background-color: ${({ theme, backcolor = 'white' }) =>
    isColor(backcolor) ? theme.colors[backcolor] : backcolor};
  padding: ${({ padding }) => (padding ? padding : '0 2rem')};
  @media screen and (max-width: ${breakpoints.lg}px) {
    width: 70vw;
  }
  @media screen and (max-width: ${breakpoints.lg}px) {
    width: 80vw;
  }
  @media screen and (max-width: ${breakpoints.lg}px) {
    width: calc(100vw - 2rem);
  }
`;

export const ModalContainer = styled.div``;
export const ModalClose = styled.div`
  position: absolute;
  right: 0;
  top: -2.5rem;
  svg {
    stroke: ${({ theme }) => theme.colors.white};
    width: 1.5rem;
    margin: 0 -0.25rem 0 0;
    transition: all 0.25s ease-in-out;
    cursor: pointer;
    &:hover {
      transform: scale(1.1);
      stroke: ${({ theme }) => theme.colors.active};
    }
  }
`;
export const ModalWrapper = styled.div``;
export const ModalHeading = styled.h3<{ between?: boolean }>`
  font-size: 0.75rem;
  line-height: 0.75rem;
  font-weight: bold;
  letter-spacing: 1.13px;
  font-family: ${({ theme }) => theme.fonts.main};
  text-transform: uppercase;
  padding: 22px 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.placeholder};
  margin: 0;
  ${({ between }) => {
    if (between) {
      return `
        display: flex;
        align-items: center;
        justify-content: space-between;
      `;
    }
  }}
`;
export const ModalContent = styled.div`
  padding: 2rem 0 2.5rem;
`;
