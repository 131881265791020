import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import momment from 'moment';

//STORE
import { AppState } from 'store';

//PROPS TYPE
import { ProofBlock } from 'types/blocknitive';
import { AppHeaderType } from 'module/AppHeader/AppHeader.props';

//COMPONENTS
import HashPlaceholder from 'components/HashPlaceholder';

//STYLES
import {
  BlockchainDetailModalContentContainer,
  BlockchainDetailModalContentGridItem,
  BlockchainDetailModalContentGrid,
  BlockchainDetailModalContentWrapper,
  BlockchainDetailModalContentTitle,
  BlockchainDetailModalContentLabel,
  BlockchainDetailModalContentText,
  BlockchainDetailModalContentSeparator,
  BlockchainDetailModalCondition,
  BlockchainDetailModalTable,
  BlockchainDetailModalTableHead,
  BlockchainDetailModalBlock,
  BlockchainDetailModalBlockText,
  BlockchainDetailModalBlockDate,
  BlockchainDetailModalBlockTime,
  BlockchainDetailModalClickElement,
} from './BlockchainDetailModal.styled';

interface BlockchainDetailModalContentProps {
  proofBlockData: ProofBlock;
  type: AppHeaderType;
}

const BlockchainDetailModalContent = ({
  proofBlockData,
  type,
}: BlockchainDetailModalContentProps) => {
  const { t } = useTranslation('modalBlockChain');

  const location = useLocation();

  const { lotData } = useSelector(
    (state: AppState) => state.blockchainDetailModal
  );
  const { blockStats } = useSelector((state: AppState) => state.blockStats);
  const { product } = useSelector((state: AppState) => state.product);
  const { organization } = useSelector((state: AppState) => state.organization);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  // const [proofOne, proofTwo, ...conditions] = proof.conditions;

  const showMoreConditions = () => {
    setIsOpen(!isOpen);
  };

  return (
    <BlockchainDetailModalContentContainer>
      <BlockchainDetailModalContentGridItem gridArea="areaone">
        <BlockchainDetailModalContentWrapper>
          <BlockchainDetailModalContentTitle>
            {t('content.function')}
          </BlockchainDetailModalContentTitle>
          <BlockchainDetailModalContentLabel>
            {t('content.name')}
          </BlockchainDetailModalContentLabel>
          <BlockchainDetailModalContentText>
            getProof
          </BlockchainDetailModalContentText>
        </BlockchainDetailModalContentWrapper>
        <BlockchainDetailModalContentSeparator />
        <BlockchainDetailModalContentGrid>
          <BlockchainDetailModalContentWrapper>
            <BlockchainDetailModalContentTitle>
              {t('content.createAt')}
            </BlockchainDetailModalContentTitle>
            <BlockchainDetailModalContentLabel>
              {t('content.time')}
            </BlockchainDetailModalContentLabel>
            <BlockchainDetailModalContentText separate>
              {momment(lotData.createdAt).format('h:mm:ss a')}
            </BlockchainDetailModalContentText>
            <BlockchainDetailModalContentLabel>
              {t('content.date')}
            </BlockchainDetailModalContentLabel>
            <BlockchainDetailModalContentText>
              {momment(lotData.createdAt).format('DD/MM/YY')}
            </BlockchainDetailModalContentText>
          </BlockchainDetailModalContentWrapper>
        </BlockchainDetailModalContentGrid>
      </BlockchainDetailModalContentGridItem>
      <BlockchainDetailModalContentGridItem gridArea="areatwo">
        {lotData && (
          <BlockchainDetailModalContentWrapper separate>
            <BlockchainDetailModalContentLabel>
              {t('content.productName')}
            </BlockchainDetailModalContentLabel>
            <BlockchainDetailModalContentText>
              {lotData.pk}
            </BlockchainDetailModalContentText>
          </BlockchainDetailModalContentWrapper>
        )}
        {proofBlockData && proofBlockData.transactionId && (
          <BlockchainDetailModalContentWrapper separate>
            <BlockchainDetailModalContentLabel>
              {t('content.taxId')}
            </BlockchainDetailModalContentLabel>
            <BlockchainDetailModalContentText>
              {proofBlockData.transactionId}
            </BlockchainDetailModalContentText>
          </BlockchainDetailModalContentWrapper>
        )}

        {lotData && (
          <BlockchainDetailModalContentWrapper>
            <BlockchainDetailModalContentLabel>
              {t('content.productId')}
            </BlockchainDetailModalContentLabel>
            <BlockchainDetailModalContentText>
              {lotData.pk}
            </BlockchainDetailModalContentText>
          </BlockchainDetailModalContentWrapper>
        )}

        <BlockchainDetailModalContentSeparator />
      </BlockchainDetailModalContentGridItem>
      <BlockchainDetailModalContentGridItem gridArea="areathree">
        {proofBlockData &&
          proofBlockData.blocknitive &&
          proofBlockData.blocknitive.header &&
          proofBlockData.blocknitive.data.length > 0 &&
          proofBlockData.blocknitive.data[0].header && (
            <>
              <BlockchainDetailModalContentTitle>
                {t('content.hasgProofPoint')}
              </BlockchainDetailModalContentTitle>
              <BlockchainDetailModalTable>
                <BlockchainDetailModalTableHead>
                  {t('content.height')}
                </BlockchainDetailModalTableHead>
                <BlockchainDetailModalTableHead>
                  {t('content.hash')}
                </BlockchainDetailModalTableHead>
                <BlockchainDetailModalTableHead>
                  {t('content.minedOn')}
                </BlockchainDetailModalTableHead>
              </BlockchainDetailModalTable>
              <BlockchainDetailModalBlock>
                <BlockchainDetailModalBlockText>
                  {proofBlockData.blockId}
                </BlockchainDetailModalBlockText>
                <HashPlaceholder
                  taxId={proofBlockData.blocknitive.header.dataHash}
                />
                <BlockchainDetailModalBlockDate>
                  {momment(
                    proofBlockData.blocknitive.data[0].header.timestamp
                  ).format('YY/MM/DD')}
                  <BlockchainDetailModalBlockTime>
                    {momment(
                      proofBlockData.blocknitive.data[0].header.timestamp
                    ).format('h:mm a')}
                  </BlockchainDetailModalBlockTime>
                </BlockchainDetailModalBlockDate>
              </BlockchainDetailModalBlock>
              <BlockchainDetailModalContentSeparator />
            </>
          )}

        <BlockchainDetailModalContentTitle>
          {t('content.lastestBlocks')}
        </BlockchainDetailModalContentTitle>
        <BlockchainDetailModalTable>
          <BlockchainDetailModalTableHead>
            {t('content.height')}
          </BlockchainDetailModalTableHead>
          <BlockchainDetailModalTableHead>
            {t('content.hash')}
          </BlockchainDetailModalTableHead>
          <BlockchainDetailModalTableHead>
            {t('content.minedOn')}
          </BlockchainDetailModalTableHead>
        </BlockchainDetailModalTable>
        {blockStats &&
        blockStats.data &&
        blockStats.data.blocknitiveStats &&
        blockStats.data.blocknitiveStats.blocks &&
        blockStats.data.blocknitiveStats.blocks.length > 0 ? (
          <>
            {blockStats.data.blocknitiveStats.blocks.map(
              ({ header: { dataHash, number }, data }, index: any) => {
                let timestampValue = data[0].header.timestamp;
                return (
                  <BlockchainDetailModalBlock key={dataHash}>
                    <BlockchainDetailModalBlockText>
                      {number}
                    </BlockchainDetailModalBlockText>
                    <HashPlaceholder taxId={dataHash} />
                    <BlockchainDetailModalBlockDate>
                      {momment(timestampValue).format('YY/MM/DD')}
                      <BlockchainDetailModalBlockTime>
                        {momment(timestampValue).format('h:mm a')}
                      </BlockchainDetailModalBlockTime>
                    </BlockchainDetailModalBlockDate>
                  </BlockchainDetailModalBlock>
                );
              }
            )}
          </>
        ) : (
          <BlockchainDetailModalBlockText>
            {t('content.anyLastOperation')}
          </BlockchainDetailModalBlockText>
        )}
      </BlockchainDetailModalContentGridItem>
      <BlockchainDetailModalContentGridItem gridArea="areafour">
        <BlockchainDetailModalContentGrid>
          <BlockchainDetailModalContentWrapper>
            <BlockchainDetailModalContentTitle>
              {t('content.response')}
            </BlockchainDetailModalContentTitle>
            <BlockchainDetailModalContentLabel>
              {t('content.status')}
            </BlockchainDetailModalContentLabel>
            <BlockchainDetailModalContentText separate>
              200
            </BlockchainDetailModalContentText>
          </BlockchainDetailModalContentWrapper>
          <BlockchainDetailModalContentWrapper>
            <BlockchainDetailModalContentTitle>
              {t('content.results')}
            </BlockchainDetailModalContentTitle>
            <BlockchainDetailModalContentLabel>
              {t('content.verification')}
            </BlockchainDetailModalContentLabel>
            <BlockchainDetailModalContentText separate>
              {t('content.valid')}
            </BlockchainDetailModalContentText>
          </BlockchainDetailModalContentWrapper>
        </BlockchainDetailModalContentGrid>
      </BlockchainDetailModalContentGridItem>
    </BlockchainDetailModalContentContainer>
  );
};

export default BlockchainDetailModalContent;
