import styled from 'styled-components';

//COMPONENTS
import { Text } from 'components/Text';


export const ProofPointModalHeader = styled.div`
  position: relative;
  padding: 0 0 1.5rem;
  margin: 0 0 1.5rem;
  &::before {
    position: absolute;
    content: '';
    width: 11.25rem;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.placeholder};
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0 auto;
  }
`;
export const ProofPointModalName = styled(Text)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 1.125rem;
  line-height: 2rem;
  font-weight: bold;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.black};
`;
export const ProofPointModalIcon = styled.img`
  width: 2rem;
  margin: 0 0 0.5rem;
`;
export const ProofPointModalVerified = styled(Text)`
  text-align: center;
  font-size: 0.875rem;
  line-height: 0.875rem;
  color: ${({ theme }) => theme.colors.fontSecondary};
`;
export const ProofPointModalExpirationDate = styled(Text)`
  text-align: center;
  font-size: 0.875rem;
  line-height: 0.875rem;
  color: ${({ theme }) => theme.colors.fontSecondary};
  margin: 0 0 1rem;
`;
export const ProofPointModalContent = styled.div`
  text-align: center;
`;
export const ProofPointModalContentTitle = styled(Text)`
  font-size: 0.875rem;
  line-height: 1.5rem;
  font-weight: bold;
  margin: 0 0 1rem;
  color: ${({ theme }) => theme.colors.black};
  text-align: center;
`;
export const ProofPointModalContentAction = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 2rem;
`;
export const ProofPointModalContentLogo = styled.img`
  width: 6.25rem;
  margin: 0 auto 1.125rem;
`;

export const ProofPointModalContainer = styled.div<{wine?: boolean;}>`
  ${({ wine, theme }) => wine && `
    color: ${theme.colors.wineGray};
    ${ProofPointModalName} {
      font-family: Rozha One;
      text-transform: capitalize;
      font-size: 2.5rem;
      line-height: 3rem;
      margin: 0 0 0.5rem;
    }
    ${ProofPointModalIcon} {
      margin: 0 0 0.5rem;
    }
    ${ProofPointModalContentTitle} {
      font-weight: bold;
      font-size: 0.875rem;
    }
    ${ProofPointModalHeader} {
      padding: 0;
      &::before {
        display: none;
      }
    }
    ${ProofPointModalContentAction} {
      margin: 0 0 3.5rem;
    }
  `}
`;