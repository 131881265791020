import styled from 'styled-components';
import { sm } from 'theme/Theme.breackoints';

//COMPONENTS
import { Text } from 'components/Text';

export const CustomerReviewsContainer = styled.div`
  ${sm`
    padding: 2rem 0 0;
  `}
`;
export const CustomerReviewsTitle = styled(Text)`
  font-size: 1rem;
  line-height: 1rem;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.black};
  margin: 0 0 0.75rem;
  ${sm`
    text-transform: uppercase;
    margin: 0 0 1.5rem;
  `}
`;
export const CustomerReviewsTotal = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 1.25rem;
`;
export const CustomerReviewsTotalCount = styled(Text)`
  font-size: 1rem;
  line-height: 1rem;
  color: ${({ theme }) => theme.colors.black};
  margin: 0 0 1.25rem;
`;
export const CustomerReviewsValue = styled(Text)`
  font-size: 1rem;
  line-height: 1rem;
  color: ${({ theme }) => theme.colors.black};
`;
export const CustomerReviewsPercentages = styled.div`
  margin: 0 0 2.5rem;
`;
export const CustomerReviewsPercentage = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 0.75rem;
`;
export const CustomerReviewsBar = styled.div<{ percentage: number }>`
  width: 11.5rem;
  height: 0.25rem;
  background-color: ${({ theme }) => theme.colors.border};
  position: relative;
  margin: 0 0.75rem 0 0;
  &::before {
    content: '';
    position: absolute;
    width: ${({ percentage }) => percentage}%;
    left: 0;
    top: 0;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.error};
  }
  ${sm`
    width: 7.5rem;
  `}
`;
export const CustomerReviewsNumber = styled(Text)`
  color: ${({ theme }) => theme.colors.placeholder};
  font-size: 0.875rem;
  line-height: 0.875rem;
`;

export const CustomerReviewsAction = styled.div`
  margin: 0 0 2.5rem;
`;
export const CustomerReviewsList = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.border};
  padding: 2.5rem 0 0;
`;
export const CustomerReviewsEmpty = styled(Text)`
  margin: 0 0 2.5rem;
`;
