import React from 'react';

//COMPONENTS
import AppLayoutSkeleton from 'module/AppLayoutSkeleton';
import SkeletonLoader from 'components/SkeletonLoader';
import SkeletonProofs from 'components/SkeletonProofs';
import SkeletonSeals from 'components/SkeletonSeals';
import SkeletonSectionTitle from 'components/SkeletonSectionTitle';
import SkeletonDescription from 'components/SkeletonDescription';
import SkeletonShare from 'components/SkeletonShare';
import SkeletonReviews from 'components/SkeletonReviews';

interface OrganizationSkeletonProps {
  matches?: boolean;
}

const OrganizationSkeleton = ({ matches }: OrganizationSkeletonProps) => {
  return (
    <AppLayoutSkeleton matches={matches}>
      <SkeletonLoader width="100%" height="auto" background="transparent">
        <>
          <SkeletonLoader
            width="100%"
            height="auto"
            display="grid"
            gridTemplate="1fr / 1fr"
            flex={{
              gap: '0',
            }}
            background="transparent"
            overflow="hidden"
          >
            <>
              <SkeletonLoader
                width="100%"
                height="auto"
                background="transparent"
              >
                <SkeletonSectionTitle />
                <SkeletonProofs matches={matches} />
                <SkeletonSeals />
                <SkeletonLoader
                  width="100%"
                  height="auto"
                  display="grid"
                  gridTemplate={
                    matches ? '1fr/ 1fr' : '1fr/ 24rem calc(100% - 26rem)'
                  }
                  flex={{
                    align: 'start',
                    gap: matches ? '2rem 0' : '0 2rem',
                  }}
                  margin="0 0 2.5rem"
                  padding=" 0 0 2.5rem"
                  border={{
                    bottom: '1px solid #EDEDEE',
                  }}
                  background="transparent"
                >
                  <SkeletonLoader width="100%" height="24rem" />
                  <SkeletonLoader
                    width="100%"
                    height="auto"
                    background="transparent"
                  >
                    <SkeletonDescription />
                    <SkeletonLoader
                      width="100%"
                      height="auto"
                      background="transparent"
                      display="grid"
                      gridTemplate="1fr / repeat(2, minmax(0px, 1fr))"
                      flex={{
                        gap: '1rem 2rem',
                      }}
                    >
                      <div>
                        <SkeletonLoader
                          width="100%"
                          maxWidth="9rem"
                          height="0.875rem"
                          display="block"
                          margin="0 0 0.75rem"
                        />
                        <SkeletonLoader
                          width="100%"
                          maxWidth="16rem"
                          height="0.875rem"
                          display="block"
                        />
                      </div>
                      <div>
                        <SkeletonLoader
                          width="100%"
                          maxWidth="9rem"
                          height="0.875rem"
                          display="block"
                          margin="0 0 0.75rem"
                        />
                        <SkeletonLoader
                          width="100%"
                          maxWidth="16rem"
                          height="0.875rem"
                          display="block"
                        />
                      </div>
                      <div>
                        <SkeletonLoader
                          width="100%"
                          maxWidth="9rem"
                          height="0.875rem"
                          display="block"
                          margin="0 0 0.75rem"
                        />
                        <SkeletonLoader
                          width="100%"
                          maxWidth="16rem"
                          height="0.875rem"
                          display="block"
                        />
                      </div>
                      <div>
                        <SkeletonLoader
                          width="100%"
                          maxWidth="9rem"
                          height="0.875rem"
                          display="block"
                          margin="0 0 0.75rem"
                        />
                        <SkeletonLoader
                          width="100%"
                          maxWidth="16rem"
                          height="0.875rem"
                          display="block"
                        />
                      </div>
                      <div>
                        <SkeletonLoader
                          width="100%"
                          maxWidth="9rem"
                          height="0.875rem"
                          display="block"
                          margin="0 0 0.75rem"
                        />
                        <SkeletonLoader
                          width="100%"
                          maxWidth="16rem"
                          height="0.875rem"
                          display="block"
                        />
                      </div>
                      <div>
                        <SkeletonLoader
                          width="100%"
                          maxWidth="9rem"
                          height="0.875rem"
                          display="block"
                          margin="0 0 0.75rem"
                        />
                        <SkeletonLoader
                          width="100%"
                          height="auto"
                          display="flex"
                          flex={{
                            align: 'center',
                            gap: '0.5rem',
                            wrap: 'wrap',
                          }}
                          background="transparent"
                        >
                          <SkeletonLoader
                            width="1.75rem"
                            height="1.75rem"
                            margin="0px 0.75rem 1rem 0px"
                            radius="999px"
                          />
                          <SkeletonLoader
                            width="1.75rem"
                            height="1.75rem"
                            margin="0px 0.75rem 1rem 0px"
                            radius="999px"
                          />
                          <SkeletonLoader
                            width="1.75rem"
                            height="1.75rem"
                            margin="0px 0.75rem 1rem 0px"
                            radius="999px"
                          />
                          <SkeletonLoader
                            width="1.75rem"
                            height="1.75rem"
                            margin="0px 0.75rem 1rem 0px"
                            radius="999px"
                          />
                          <SkeletonLoader
                            width="1.75rem"
                            height="1.75rem"
                            margin="0px 0.75rem 1rem 0px"
                            radius="999px"
                          />
                        </SkeletonLoader>
                      </div>
                    </SkeletonLoader>
                  </SkeletonLoader>
                </SkeletonLoader>

                {!matches && <SkeletonShare />}
                {!matches && <SkeletonReviews />}
              </SkeletonLoader>
            </>
          </SkeletonLoader>
        </>
      </SkeletonLoader>
    </AppLayoutSkeleton>
  );
};

export default OrganizationSkeleton;
