import gql from 'graphql-tag';

export const organizationQuery = gql`
  query organization($pk: String!, $language: String) {
    organization(pk: $pk, language: $language) {
      data {
        name
        description
        telephones {
          id
          description
          telephone
          pk
        }
        email
        url
        pk
        socialChannels {
          handle
          url
          type
          pk
        }
        taxId
        logo(size: "250x250") {
          url
        }
        headImage(size: "720x720") {
          url
        }
        products {
          name
          pk
          thumbnail(size: "40x40") {
            url
          }
          images {
            pk
            url
          }
        }
        proofPoints {
          name
          pk
          createdAt
          description
          expirationDate
          icon {
            value
            icon
            label
          }
          mediaEvidences {
            name
            url
            type
          }
          evidences {
            file
            evidenceName
            expirationDate
            pk
          }
          conditions {
            value
            type
            typeDisplay
            unit
          }
          verifier {
            pk
            name
            description
            logo(size: "250x250") {
              url
            }
          }
        }
        reviews {
          edges {
            node {
              createdAt
              id
              title
              name
              email
              comment
              rating
              status
              reply {
                comment
              }
            }
          }
        }
        reviewStats {
          averageRating
          totalCount
          percentageCountFive
          percentageCountFour
          percentageCountThree
          percentageCountTwo
          percentageCountOne
        }
        translationStatus
      }
      error
    }
  }
`;
