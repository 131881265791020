import styled from 'styled-components';

export const LoadingContainer = styled.div<{ backgroundColor?: string }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : 'rgba(255, 255, 255, 0.8)'};
  z-index: 9;
`;
