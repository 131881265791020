import styled from 'styled-components';

//UTILS
import { isColor } from 'theme/Theme.styled';

//PROPS TYPE
import { IconContainerProps } from './Icon.props';

export const IconContainer = styled.div<IconContainerProps>`
  svg {
    ${({ size }) =>
      size &&
      `
      width: ${size}px;
      height: ${size}px;
    `}
    ${({ theme, fill }) =>
      fill &&
      `
      fill: ${isColor(fill) ? theme.colors[fill] : fill};
      `}
    ${({ theme, stroke }) =>
      stroke &&
      `
      stroke: ${isColor(stroke) ? theme.colors[stroke] : stroke};
    `}
    ${({ theme, color }) =>
      color &&
      `
      color: ${isColor(color) ? theme.colors[color] : color};
    `}
  }
`;
