import styled from 'styled-components';

//COMPONENTS
import { Text } from 'components/Text';

export const CustomerReviewHeaderContainer = styled.div`
  display: grid;
  grid-template: 1fr / 2.8125rem calc(100% - 4.0625rem);
  grid-gap: 1.25rem;
  margin: 0 0 0.75rem;
`;
export const CustomerReviewUser = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
`;
export const CustomerReviewName = styled(Text)`
  font-weight: bold;
  color: ${({ theme }) => theme.colors.black};
  margin: 0 0.5rem 0 0;
`;

export const CustomerReviewInfo = styled.div<{ reply: boolean }>`
  height: 100%;
  display: flex;
  align-items: center;
  ${({ reply }) =>
    reply &&
    `
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    ${CustomerReviewName} {
      margin: 0 0 0.5rem 0;
    }
  `}
`;

export const CustomerReviewDate = styled(Text)`
  font-size: 0.875rem;
  line-height: 0.875rem;
  color: ${({ theme }) => theme.colors.fontSecondary};
`;
export const CustomerReviewRating = styled.div`
  align-self: flex-start;
`;
