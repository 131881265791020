import styled from 'styled-components';

export const LabelContainer = styled.label`
  font-size: 0.65rem;
  line-height: 0.625rem;
  font-weight: 700;
  padding-left: 1rem;
  color: ${({ theme }) => theme.colors.fontSecondary};
  margin: 0 0 0.25rem;
  text-transform: uppercase;
`;
