import styled from 'styled-components';
import { sm } from 'theme/Theme.breackoints';

export const SealsContainer = styled.div`
  display: flex;
  overflow: auto;
  flex-wrap: nowrap;
  margin: 0 0 1rem;
  padding: 0 0 1rem;
  transition: all 0.25s ease-in-out;

  &::-webkit-scrollbar {
    height: 8px;
    background-color: transparent;
    transition: all 0.3s ease-in-out;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
    transition: all 0.3s ease-in-out;
  }
  &::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 6px;
    transition: all 0.3s ease-in-out;
  }

  &:hover::-webkit-scrollbar {
    background-color: #f5f5f5;
  }
  &:hover::-webkit-scrollbar-track {
    background-color: ${({ theme }) => theme.colors.backgroundGrayLight};
  }
  &:hover::-webkit-scrollbar-thumb {
    background-image: ${({ theme }) => theme.colors.buttonGradient};
    border-radius: 6px;
  }

  ${sm`
     padding: 0 0 1rem;
   `}
`;
