import styled, { keyframes } from 'styled-components';

import { Text } from 'components/Text';

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const EvidenceModalContainer = styled.div`
  position: relative;
  background-color: #fff;
  width: 100%;
  min-height: 20rem;
`;

export const EvidenceModalContent = styled.div<{ show: boolean }>`
  opacity: 0;
  visibility: hidden;
  ${({ show }) =>
    show &&
    `
    opacity: 1;
    visibility: visible;
  `}
`;

export const EvidenceModalViewerContainer = styled.div`
  height: 750px;
`;

export const EvidenceModalLoading = styled(Text)`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  background: ${({ theme }) => theme.colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  animation-duration: 0.5s;
  transition-timing-function: ease-in;
  animation-fill-mode: both;
  animation-name: ${fadeIn};
  z-index: 9;
`;
export const EvidenceModalImage = styled.img`
  width: 100%;
`;
export const EvidenceModalVideo = styled.video`
  width: 100%;
`;
export const EvidenceModalNotFile = styled.div``;
export const EvidenceModalNotFileTitle = styled(Text)`
  text-align: center;
  font-size: 0.875rem;
  line-height: 0.875rem;
  font-weight: bold;
  text-transform: uppercase;
  margin: 0 0 1rem;
  color: ${({ theme }) => theme.colors.black};
`;
export const EvidenceModalNotFileLink = styled.a`
  font-size: 0.875rem;
  line-height: 0.875rem;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.brand};
  transition: all 0.25s ease-in-out;
  &:hover {
    color: ${({ theme }) => theme.colors.active};
  }
`;
export const EvicenceModalError = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  background: ${({ theme }) => theme.colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  animation-duration: 0.5s;
  transition-timing-function: ease-in;
  animation-fill-mode: both;
  animation-name: ${fadeIn};
  z-index: 9;
  flex-direction: column;
`;
export const EvicenceModalErrorImage = styled.img`
  width: 9.375rem;
  margin: 0 0 0.5rem;
`;
export const EvicenceModalErrorText = styled(Text)`
  text-align: center;
  font-size: 1.125rem;
  line-height: 2rem;
  font-weight: bold;
  color: #100316;
  margin: 0 0 0.5rem;
`;
export const EvicenceModalErrorSubText = styled(Text)`
  text-align: center;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #a89fac;
  max-width: 350px;
  margin: 0 auto 1.5rem;
`;
