import { createSlice } from '@reduxjs/toolkit';

import { Product } from 'types/product';

interface productStateProps {
  product: Product;
  lotId: string;
  lotInternalId: string;
}

const initialState: productStateProps = {
  product: {} as Product,
  lotId: '',
  lotInternalId: '',
};

const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    setProduct: (state: typeof initialState, action) => {
      return {
        ...state,
        product: action.payload,
      };
    },
    setLotId: (state: typeof initialState, action) => {
      return {
        ...state,
        lotId: action.payload,
      };
    },
    setInternalId: (state: typeof initialState, action) => {
      return {
        ...state,
        lotInternalId: action.payload,
      };
    },
  },
});

export const { setProduct, setLotId, setInternalId } = productSlice.actions;

export default productSlice;
