import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import ReactGA from 'react-ga4';
import get from 'lodash/get';
import Product from 'screens/Product';
import Wine from 'screens/Wine';
import WineBottle from 'screens/Wine/WineBottle';
import Winery from 'screens/Winery';
import Certificate from 'screens/Certificate';
import CertificateBottle from 'screens/Certificate/CertificateBottle';
import CheckIt from 'screens/CheckIt';
import Lot from 'screens/Lot';
import Error404 from 'screens/Error404';
import Organization from 'screens/Organization';
import SpreadWidget from 'widgets/SpreadWidget';
import AppContainer from 'module/App';

const TRACKING_ID = get(process.env, 'REACT_APP_GOOGLE_ANALYTICS_ID', '');
ReactGA.initialize(TRACKING_ID);

function App() {
  return (
    <AppContainer>
      <Router>
        <Routes>
          <Route path="/wine/:wineId" element={<Wine />} />
          <Route path="/wine-bottle/:wineId" element={<WineBottle />} />
          <Route path="/winery/:wineryId" element={<Winery />} />
          <Route path="/certificate/:certificateId" element={<Certificate />} />
          <Route
            path="/certificate-bottle/:certificateId"
            element={<CertificateBottle />}
          />
          <Route path="/products/:productId" element={<Product />} />
          <Route path="/check/:checkId" element={<CheckIt />} />
          <Route path="/lot/:lotId" element={<Lot />} />
          <Route path="/spread-widget/:proofId" element={<SpreadWidget />} />
          <Route
            path="/organizations/:organizationId"
            element={<Organization />}
          />
          <Route path="*" element={<Error404 />} />
        </Routes>
      </Router>
    </AppContainer>
  );
}

export default App;
