import React, { useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Markup } from 'interweave';
import { useTranslation } from 'react-i18next';
//ASSETS
import placeholderImage from 'assets/images/placeholder255x255.png';
//PROPS TYPE
import { ProductMilestone } from 'types/product';
//STORE
import { openModal } from 'store/cycleLifeModal';
//STYLES
import {
  CycleLifeContaier,
  CycleLifeTitle,
  CycleLifeList,
  CycleLifeItem,
  CycleLifeItemDate,
  CycleLifeItemTitle,
  CycleLifeItemParagraph,
  CycleLifeItemImage,
  CycleLifeItemColumn,
  CycleLifeItemParagraphSeeMore,
  CycleLifeAction,
  CycleLifeHeader,
  CycleLifeListOverlay,
  CycleLifeListBox,
} from './CycleLife.styled';
import moment from 'moment';
import VideoPreview from 'components/VideoPreview';
import { WineContentAction } from 'screens/Wine/Wine.styled';
import { CertificatePrintIcon } from 'screens/Certificate/Certificate.styled';

interface CycleLifeProps {
  milestones: ProductMilestone[];
}
const CycleLife = ({ milestones }: CycleLifeProps) => {
  const scrollList = useRef<HTMLDivElement>(null);

  const { t } = useTranslation('general');
  const dispatch = useDispatch();

  const $milestoneParent = useRef<HTMLDivElement>(null);

  const removeTags = (str: string) => {
    if (str === null || str === '') return false;
    else str = str.toString();

    // Regular expression to identify HTML tags in
    // the input string. Replacing the identified
    // HTML tag with a null string.
    return str.replace(/(<([^>]+)>)/gi, '');
  };

  const handleOpenCycleLifeModal = useCallback(
    (milestone: ProductMilestone) => {
      dispatch(
        openModal({
          milestone,
        })
      );
    },
    [dispatch]
  );

  const handleScrollLeft = () => {
    if (scrollList && scrollList.current) {
      scrollList.current.scrollLeft -= 352;
    }
  };
  const handleScrollRight = () => {
    if (scrollList && scrollList.current) {
      scrollList.current.scrollLeft += 352;
    }
  };

  return (
    <CycleLifeContaier>
      <CycleLifeHeader>
        <WineContentAction justify="space-between" margin="0 0 2rem">
          <CycleLifeTitle style={{ margin: '0' }}>Ciclo de vida</CycleLifeTitle>
          <CycleLifeAction>
            <CertificatePrintIcon
              iconName="arrowDoubleLeft"
              onClick={() => {
                handleScrollLeft();
              }}
            />
            <CertificatePrintIcon
              iconName="arrowDoubleRight"
              onClick={() => {
                handleScrollRight();
              }}
            />
          </CycleLifeAction>
        </WineContentAction>
      </CycleLifeHeader>
      <CycleLifeListBox>
        <CycleLifeListOverlay />
        <CycleLifeList ref={scrollList}>
          {milestones.map((milestone) => {
            const des = removeTags(
              milestone.description.split(' ').slice(0, 26).join(' ')
            );

            return (
              <CycleLifeItem key={milestone.id}>
                {/* <CycleLifeItemDate>
                {moment(milestone.createdAt).format('DD/MM/YYYY')}
              </CycleLifeItemDate> */}
                <CycleLifeItemTitle>{milestone.name}</CycleLifeItemTitle>
                <CycleLifeItemColumn>
                  <CycleLifeItemParagraph>
                    {des && <Markup content={`${des}...`} />}{' '}
                    <CycleLifeItemParagraphSeeMore
                      onClick={() => {
                        handleOpenCycleLifeModal(milestone);
                      }}
                    >
                      {t('readMore')}
                    </CycleLifeItemParagraphSeeMore>
                  </CycleLifeItemParagraph>

                  {milestone.video && (
                    <VideoPreview
                      url={milestone.video.url}
                      name={milestone.name}
                      parent={$milestoneParent}
                      id={milestone.pk as string}
                      margin="0"
                      poster={
                        milestone && milestone.image && milestone.image.url
                          ? milestone.image.url
                          : undefined
                      }
                    />
                  )}
                  {!milestone.video && (
                    <CycleLifeItemImage
                      src={
                        milestone.image ? milestone.image.url : placeholderImage
                      }
                      alt={milestone.name}
                    />
                  )}
                </CycleLifeItemColumn>
              </CycleLifeItem>
            );
          })}
        </CycleLifeList>
      </CycleLifeListBox>
    </CycleLifeContaier>
  );
};

export default CycleLife;
