import styled from 'styled-components';

//COMPONENTS
import { Text } from 'components/Text';

export const Error404Container = styled.div`
  padding: 5rem 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

export const Error404Content = styled.div`
  padding: 2rem 2.5rem 2.5rem;
  align-items: center;
  flex-direction: column;
  display: flex;
  background-color: #fcfcfc;
`;

export const Error404Title = styled(Text)`
  text-align: center;
  margin-bottom: 1rem;
  font-size: 1.75rem;
  line-height: 2rem;
  font-weight: 700;
`;

export const Error404Desctiption = styled(Text)`
  text-align: center;
  margin-bottom: 1.5rem;
  font-size: 0.875rem;
  line-height: 1.5rem;
  font-weight: 500;
`;

export const Error404Link = styled.a`
  text-align: center;
  font-size: 1.125rem;
  line-height: 1.125rem;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.brand};
`;
