import React from 'react';
//PROPS TYPE
import { ProductExtraField, ProductStep } from 'types/product';
//ASSETS
import placeholderImage from 'assets/images/placeholder255x255.png';
//STYLES
import {
  TransitionsContaier,
  TransitionsList,
  TransitionsItem,
  TransitionsItemTitle,
  TransitionsItemParagraph,
  TransitionsItemImage,
  TransitionsItemColumn,
} from './Transitions.styled';

interface TransitionsProps {
  steps: ProductStep[];
}

const Transitions = ({ steps }: TransitionsProps) => {
  const removeTags = (str: string) => {
    if (str === null || str === '') return false;
    else str = str.toString();
    return str.replace(/(<([^>]+)>)/gi, '');
  };

  return (
    <TransitionsContaier>
      <TransitionsList>
        {steps.map((step) => {
          const extraFields =
            !!step.extraFields && JSON.parse(step.extraFields).length > 0
              ? JSON.parse(step.extraFields)
              : [];

          return (
            <TransitionsItem key={`${step.pk}-${step.name}`}>
              <TransitionsItemTitle>{step.name}</TransitionsItemTitle>
              <TransitionsItemColumn>
                {extraFields.map((extrafield: ProductExtraField) => (
                  <TransitionsItemParagraph key={extrafield.internal_id}>
                    {extrafield.name}{' '}
                    {extrafield.internal_id === 'varieties'
                      ? JSON.parse(
                          extrafield.value
                        ).map(
                          (
                            item: { label: string; value: number },
                            index: number
                          ) => (
                            <span
                              key={index}
                            >{`${item.label} ${item.value}%,`}</span>
                          )
                        )
                      : extrafield.value}
                    {!!extrafield.unit && extrafield.unit}
                  </TransitionsItemParagraph>
                ))}

                <TransitionsItemImage
                  src={step.image ? step.image.url : placeholderImage}
                />
              </TransitionsItemColumn>
            </TransitionsItem>
          );
        })}
      </TransitionsList>
    </TransitionsContaier>
  );
};

export default Transitions;
