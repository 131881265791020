import { createSlice } from '@reduxjs/toolkit';

interface Media {
  name: string;
  url: string;
}

const initialState = {
  isOpen: false,
  media: {} as Media,
};

const mediaEvidenceModalSlice = createSlice({
  name: 'mediaEvidenceModal',
  initialState,
  reducers: {
    openModal: (state: typeof initialState, action) => {
      return {
        ...action.payload,
        isOpen: true,
      };
    },
    closeModal: () => initialState,
  },
});

export const { openModal, closeModal } = mediaEvidenceModalSlice.actions;

export default mediaEvidenceModalSlice;
