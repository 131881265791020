import * as React from 'react';

function ArrowDoubleLeftIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={32}
      height={32}
      strokeWidth={1.5}
      strokeLinecap="round"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path d="M8.5 5H13L8 12L13 19H8.5L3.5 12L8.5 5Z" fill="currentColor" />
      <path
        d="M15.5 5H20L15 12L20 19H15.5L10.5 12L15.5 5Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default ArrowDoubleLeftIcon;
