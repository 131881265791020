import {
  LANGUAGE_MAP,
  LenguageMapProp,
} from 'components/ProductTabs/templates/ProductLanguage';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store';
import { ProductTabsNutrients, TabsTechDetail } from 'types/product';
//STYLES
import SelectLanguage from 'components/SelectLanguage/SelectLanguage';
import { setWineProductLanguage } from 'store/proofPointDetailModal';
import { TabsDetailLanguageTypes } from 'types/product';
import {
  TabNutrientContainer,
  TabNutrientHead,
  TabNutrientHeader,
  TabNutrientItem,
  TabNutrientItemBody,
  TabNutrientList,
  TabNutrientText,
} from './Tabs.styled';

interface TabNutrientProp {
  nutrient: ProductTabsNutrients;
  maxWidth?: string;
}

interface FilteredIngredientsChild {
  disable: boolean;
  value: boolean;
  id: string;
  internal_id: string;
  name: string;
  translated_name: TabsDetailLanguageTypes;
  show_ean: boolean;
  ean_code: boolean;
  category?: 'Allergin' | 'Ecological' | 'Other';
}

interface FilteredIngredients {
  id: string;
  name: string;
  childs: FilteredIngredientsChild[];
  text: string;
  translated_name: TabsDetailLanguageTypes;
  show_ean: boolean;
  ean_code: boolean;
}

const TabNutrient = ({ nutrient, maxWidth = '600px' }: TabNutrientProp) => {
  const { t } = useTranslation('product');

  const dispatch = useDispatch();

  const { translateSelect } = useSelector(
    (state: AppState) => state.translateModal
  );

  const { pk, translationStatus } = useSelector(
    (state: AppState) => state.product.product
  );

  const [selectedLanguage, setSelectedLanguage] = useState<
    LenguageMapProp | undefined
  >(LANGUAGE_MAP.find((lang) => lang.shortName === 'en'));

  const percentDiary: TabsTechDetail[] = JSON.parse(nutrient.details);
  const ingredients: FilteredIngredients[] = !!nutrient.ingredientsFiltered
    ? JSON.parse(nutrient.ingredientsFiltered)
    : null;
  const calories =
    Object.entries(JSON.parse(nutrient.calories)).length === 0
      ? null
      : JSON.parse(nutrient.calories);

  const handleLanguage = (language: LenguageMapProp) => {
    setSelectedLanguage(language);
    dispatch(setWineProductLanguage(language));
  };

  useEffect(() => {
    const languagueSelected = translateSelect.filter(
      (select) => select.idProduct === pk
    );

    if (pk && languagueSelected.length > 0) {
      const languageFound = LANGUAGE_MAP.find(
        (lang) => lang.shortName === languagueSelected[0].short_name
      );

      if (languageFound) {
        setSelectedLanguage(languageFound);
        dispatch(setWineProductLanguage(languageFound));
      }
    }
  }, [translateSelect, pk]);

  useEffect(() => {
    if (
      translationStatus &&
      Object.keys(JSON.parse(translationStatus)).length === 1
    ) {
      setSelectedLanguage(
        LANGUAGE_MAP.find(
          (lang) =>
            lang.shortName === Object.keys(JSON.parse(translationStatus))[0]
        )
      );
      dispatch(setWineProductLanguage(selectedLanguage));
    }
  }, [translationStatus]);

  return (
    <TabNutrientContainer maxWidth={maxWidth}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
      >
        <SelectLanguage
          placeholder="Selecciona un lenguaje"
          onSelect={handleLanguage}
        />
      </div>
      {!!ingredients && ingredients.length > 0 && selectedLanguage && (
        <TabNutrientText
          style={{
            margin: '1.25rem 0',
          }}
        >
          {/* {`${selectedLanguage && selectedLanguage.ingredient}: ${ingredients
            .filter((item, index) => item.translated_name)
            .map((item) =>
              !!item.childs && item.childs.length > 0
                ? `${
                    item.translated_name[selectedLanguage.shortName]
                  }(${item.childs
                    .map((item) =>
                      item.show_ean
                        ? item.ean_code
                        : item.translated_name
                        ? item.translated_name[selectedLanguage.shortName]
                        : ' '
                    )
                    .join(', ')})`
                : item.show_ean
                ? item.ean_code
                : item.translated_name[selectedLanguage.shortName]
            )
            .join(', ')}`} */}
          {/* TODO: not all items are showing, if it show_ean is false */}
          <>
            {selectedLanguage && selectedLanguage.ingredient}:{' '}
            {ingredients
              .filter((item) => item.translated_name)
              .map((item, index, array) => (
                <React.Fragment key={index}>
                  {!!item.childs && item.childs.length > 0 ? (
                    <>
                      {item.translated_name[selectedLanguage.shortName]}(
                      {item.childs.map((child, childIndex) => (
                        <React.Fragment key={childIndex}>
                          {child.show_ean ? (
                            child.ean_code
                          ) : child.translated_name ? (
                            <span
                              style={{
                                color:
                                  child?.category === 'Allergin'
                                    ? 'black'
                                    : 'gray',
                                fontWeight:
                                  child?.category === 'Allergin'
                                    ? 'bold'
                                    : 'normal',
                              }}
                            >
                              {
                                child.translated_name[
                                  selectedLanguage.shortName
                                ]
                              }
                            </span>
                          ) : (
                            ' '
                          )}
                          {childIndex < item.childs.length - 1 ? ', ' : ''}
                        </React.Fragment>
                      ))}
                      )
                    </>
                  ) : item.show_ean ? (
                    item.ean_code
                  ) : (
                    item.translated_name[selectedLanguage.shortName]
                  )}
                  {index < array.length - 1 ? ', ' : ''}

                  {console.log(ingredients, 'ingredients')}
                </React.Fragment>
              ))}
          </>
        </TabNutrientText>
      )}

      <TabNutrientHeader>
        <TabNutrientHead hiddenTop></TabNutrientHead>
        <TabNutrientHead hiddenRight hiddenTop align="right">
          {selectedLanguage && selectedLanguage.usage}
        </TabNutrientHead>
      </TabNutrientHeader>
      <TabNutrientList>
        {!!calories && (
          <TabNutrientItem>
            <TabNutrientItemBody>
              {selectedLanguage && selectedLanguage.value}
            </TabNutrientItemBody>
            <TabNutrientItemBody hiddenRight align="right">
              {Math.round(
                parseFloat(calories[0].value) +
                  (!!nutrient.discountCalories
                    ? parseFloat(nutrient.discountCalories) *
                      (!!nutrient.scaleFactor
                        ? parseFloat(nutrient.scaleFactor)
                        : 4.184)
                    : 0)
              )}
              {calories[0].label}
              {` - `}
              {Math.round(
                parseFloat(calories[1].value) +
                  (!!nutrient.discountCalories
                    ? parseFloat(nutrient.discountCalories)
                    : 0)
              )}
              {calories[1].label}
            </TabNutrientItemBody>
          </TabNutrientItem>
        )}

        {percentDiary
          .filter(
            (percent) =>
              !!percent.visible && percent.title != 'alcoholicStrength'
          )
          .map((percent, index) => (
            <TabNutrientItem key={`${percent.id}-${index}`}>
              <TabNutrientItemBody
                hiddenTop={!!percent.child}
                child={!!percent.child}
              >
                {selectedLanguage &&
                  percent.translated_title &&
                  percent.translated_title[selectedLanguage.shortName]}
              </TabNutrientItemBody>
              <TabNutrientItemBody
                hiddenRight
                hiddenTop={!!percent.child}
                align="right"
              >
                {percent.description}
                {percent.unit}
              </TabNutrientItemBody>
            </TabNutrientItem>
          ))}
      </TabNutrientList>
    </TabNutrientContainer>
  );
};

export default TabNutrient;
