import styled from 'styled-components';
import { xs, sm, md } from 'theme/Theme.breackoints';

export const HashPlaceholderContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  cursor: help;
  text-transform: uppercase;
  font-size: 1rem;
  line-height: 1rem;
  color: ${({ theme }) => theme.colors.white};
  font-family: 'Roboto Mono', monospace;
`;

export const HashPlaceholderShow = styled.span`
  ${xs`
    font-size: 0.75rem;
    line-height: 0.75rem;
    word-break: initial;
  `}
`;
export const HashPlaceholderColorList = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  cursor: help;
  text-transform: uppercase;
`;

export const HashPlaceholderColorContainer = styled.span`
  width: 3px;
  height: 1.25rem;
  display: inline-block;
  ${md`
width: 5px;
  `}
  ${sm`
    width: 3px;
  `}
  ${xs`
    width: 2px;
  `}
`;

export const HashPlaceholderToolTipContainer = styled.div<{
  isVisible: boolean;
}>`
  position: absolute;
  border-radius: 5px;
  background: hsla(0, 0%, 100%, 0.8);
  color: #100316;
  padding: 10px;
  max-width: 200px;
  font-size: 12px;
  opacity: 0;
  top: -80px;
  left: 0;
  right: 0;
  margin: 0 auto;
  cursor: help;
  visibility: hidden;

  ${({ isVisible }) =>
    isVisible &&
    `
    opacity: 1;
    visibility: visible;
    z-index: 20;
  `}

  &::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    height: 0;
    width: 0;
    margin-left: -6px;
    border: solid transparent;
    border-top: solid hsla(0, 0%, 100%, 0.8);
    border-width: 8px 6px;
  }
`;
