import styled from 'styled-components';
import { sm } from 'theme/Theme.breackoints';

//PROPS TYPE
import { VersionDevice } from './TranslateSelector.props';

//COMPONENTS
import { Text } from 'components/Text';

export const TranslateSelectorLanguage = styled(Text)`
  font-size: 0.75rem;
  line-height: 0.75rem;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.brand};
  ${sm`
     color: ${({ theme }) => theme.colors.white};
  `}
`;
export const TranslateSelectorAvatar = styled.img`
  max-width: 1.25rem;
  object-fit: cover;
  height: 1.25rem;
  border-radius: 50%;
`;
export const TranslateSelectorIcon = styled.div`
  svg {
    width: 0.625rem;
  }
  ${sm`
    svg { 
      path {
        fill: ${({ theme }) => theme.colors.white};
      }
    }
  `}
`;
export const TranslateSelectorContainer = styled.div<{ type: VersionDevice }>`
  padding: 0.25rem 0.75rem;
  border: 1px solid ${({ theme }) => theme.colors.border};
  display: flex;
  align-items: center;
  gap: 0 0.25rem;
  cursor: pointer;
  height: 36px;
  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.brand};
    ${sm`
      border: 1px solid ${({ theme }) => theme.colors.white};
    `}
  }

  ${sm`
    ${({ type }) =>
      type === 'desktop' &&
      `
      display: none;
    `}

    ${({ type, theme }) =>
      type === 'mobile' &&
      `
      gap: 0 0.5rem;
      border-color: ${theme.colors.white};
      
    `}

    ${({ type, theme}) => type === 'both' && `
      display: flex;
      ${TranslateSelectorLanguage} {
        color: ${theme.colors.brand};
      }
      ${TranslateSelectorIcon} {
        svg { 
          path {
            fill: ${theme.colors.brand};
          }
        }
      }
    `}

  `}
`;
