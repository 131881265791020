import React from 'react';
import { ProductTabsRecomendations } from 'types/product';
//COMPONENTS
import RenderHtml from 'components/RenderHtml/RenderHtml';
//STYLES
import {
  TemplateIngredient,
  TemplateIngredientItem,
  TemplateIngredientImage,
  TemplateTechDetail,
  TemplateLabel,
  TemplateText,
} from './Template.styled';

interface ProductTabsRecommendationProp {
  recommendations: ProductTabsRecomendations;
}

const ProductTabsRecommendation = ({
  recommendations,
}: ProductTabsRecommendationProp) => {
  return (
    <TemplateIngredient>
      {recommendations.recommendations.map((recommendation, index) => (
        <TemplateTechDetail key={index}>
          <TemplateLabel>{recommendation.title}</TemplateLabel>
          <TemplateText>
            <RenderHtml text={recommendation.description} noOverlay />
          </TemplateText>
        </TemplateTechDetail>
      ))}
    </TemplateIngredient>
  );
};

export default ProductTabsRecommendation;
