import styled from 'styled-components';
import { sm } from 'theme/Theme.breackoints';

//COMPONENTS
import { Text } from 'components/Text';

export const AppHeaderContainer = styled.div`
  height: 5rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  ${sm`
    position: fixed;
    width: 100%;
    z-index: 99;
    background-color: #fff;
  `}
  @media only screen and (max-width: 768px) and (orientation: landscape) {
    visibility: hidden;
  }
`;
export const AppHeaderWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: calc(100% - 5rem);
  margin: 0 auto;
  ${sm`
    max-width: calc(100% - 2rem);
  `}
`;

export const AppHeaderLInformation = styled.div`
  display: flex;
  align-items: center;
`;
export const AppHeaderName = styled(Text)`
  font-size: 1rem;
  line-height: 1rem;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.black};
  font-weight: bold;
  margin: 0 0 0 1rem;
  display: flex;
  align-items: center;
  ${sm`
    flex-direction: column;
    align-items: flex-start;
    a {
      margin: 0.5rem 0 0;
    }
  `}
`;
export const AppHeaderSeparator = styled.div`
  font-size: 1rem;
  line-height: 1.125rem;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.border};
  margin: 0 0.5rem;
  ${sm`
    display: none;
  `}
`;

export const AppHeaderMenuButton = styled.div`
  display: none;
  width: 2rem;
  height: 2rem;
  ${sm`
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      color: ${({ theme }) => theme.colors.brand};
      font-size: 1.5rem;
    }
  `}
`;
