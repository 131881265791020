import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: string[] = JSON.parse(
  localStorage.getItem('review') || '[]'
) as string[];

const reviewsSlice = createSlice({
  name: 'reviews',
  initialState,
  reducers: {
    setHaveAnswered: (state: string[], action: PayloadAction<string>) => {
      const newState = [...state, action.payload];
      localStorage.setItem('review', JSON.stringify(newState));
      return newState;
    },
  },
});

export const { setHaveAnswered } = reviewsSlice.actions;

export default reviewsSlice;
