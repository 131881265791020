import styled from 'styled-components';

//COMPONENTS
import { Text } from 'components/Text';

export const SectionHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  height: 5rem;
`;
export const SectionHeaderText = styled(Text)`
  font-size: 1rem;
  line-height: 1rem;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.black};
  text-transform: uppercase;
`;
