import styled, { keyframes } from 'styled-components';
import { Text } from 'components/Text';

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const MediaEvicenceModalContainer = styled.div`
  position: relative;
  padding: 0 0 58.25%;
`;
export const MediaEvicenceModalMedia = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`;
export const MediaEvicenceModalLoading = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  background: ${({ theme }) => theme.colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  animation-duration: 0.5s;
  transition-timing-function: ease-in;
  animation-fill-mode: both;
  animation-name: ${fadeIn};
  z-index: 9;
`;
export const MediaEvicenceModalError = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  background: ${({ theme }) => theme.colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  animation-duration: 0.5s;
  transition-timing-function: ease-in;
  animation-fill-mode: both;
  animation-name: ${fadeIn};
  z-index: 9;
  flex-direction: column;
`;
export const MediaEvicenceModalErrorImage = styled.img`
  width: 9.375rem;
  margin: 0 0 0.5rem;
`;
export const MediaEvicenceModalErrorText = styled(Text)`
  text-align: center;
  font-size: 1.125rem;
  line-height: 2rem;
  font-weight: bold;
  color: #100316;
  margin: 0 0 0.5rem;
`;
export const MediaEvicenceModalErrorSubText = styled(Text)`
  text-align: center;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #a89fac;
  max-width: 350px;
  margin: 0 auto 1.5rem;
`;
