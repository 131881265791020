import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

//STORE
import { openModal } from 'store/proofPointModal';

//PROPS TYPE
import { ConditionsProps } from './Conditions.props';

//STYLES
import {
  ConditionsContainer,
  ConditionsProofName,
  CoditionsList,
  CoditionsItem,
  CoditionsData,
} from './Conditions.styled';

const Conditions = ({ proofpoint }: ConditionsProps) => {
  const dispatch = useDispatch();

  const handleOpenProofPointModal = useCallback(() => {
    dispatch(
      openModal({
        proof: proofpoint,
      })
    );
  }, [dispatch, proofpoint]);

  return (
    <ConditionsContainer>
      <ConditionsProofName onClick={handleOpenProofPointModal}>
        {proofpoint.name}
      </ConditionsProofName>
      <CoditionsList>
        {proofpoint.conditions.map((condition, index) => (
          <CoditionsItem key={`${condition.type}-${index}`}>
            <CoditionsData>
              {condition.type} {condition.value} {condition.unit}
            </CoditionsData>
          </CoditionsItem>
        ))}
      </CoditionsList>
    </ConditionsContainer>
  );
};

export default Conditions;
