import React from 'react';
import { ProductTabs as ProductTabsList } from 'types/product';
//COMPONENTS
import ProductTabsTech from './templates/ProductTabs.tech';
import ProductTabsRecommendation from './templates/ProductTabs.recommendation';
import ProductTabsNutrient from './templates/ProductTabs.nutrient';
import ProductTabsPdf from './templates/ProductTabs.pdf';
import ProductTabsIngredient from './templates/ProductTabs.ingredient';
//STYLES
import {
  ProductTabsHeader,
  ProductTabsHead,
  ProductTabsBody,
  ProductTabsItem,
} from './ProductTabs.styled';
import TabNutrient from 'screens/Wine/components/Tabs/TabNutrient';

interface ProductTabDesktopProps {
  tabs: ProductTabsList[];
  active: string;
  setActive: (pk: string) => void;
}

const ProductTabDesktop = ({
  tabs,
  active,
  setActive,
}: ProductTabDesktopProps) => {
  return (
    <>
      <ProductTabsHeader size={tabs.length}>
        {tabs.map((tab) => (
          <ProductTabsHead
            key={tab.pk}
            active={active === tab.pk}
            onClick={() => {
              setActive(tab.pk);
            }}
          >
            {tab.title}
          </ProductTabsHead>
        ))}
      </ProductTabsHeader>
      <ProductTabsBody>
        {tabs.map((tab) => (
          <ProductTabsItem key={tab.pk} active={active === tab.pk}>
            {tab.technicalDetail && (
              <ProductTabsTech tech={tab.technicalDetail} />
            )}
            {tab.ingredientList && (
              <ProductTabsIngredient ingredients={tab.ingredientList} />
            )}
            {tab.fileList && <ProductTabsPdf documents={tab.fileList} />}
            {tab.nutritionFact && (
              <TabNutrient maxWidth="100%" nutrient={tab.nutritionFact} />
            )}
            {tab.recommendationList && (
              <ProductTabsRecommendation
                recommendations={tab.recommendationList}
              />
            )}
          </ProductTabsItem>
        ))}
      </ProductTabsBody>
    </>
  );
};

export default ProductTabDesktop;
