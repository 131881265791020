import React from 'react';
// Modules
import { TextPresets, textPresets } from './Text.presets';
import { Colors, Font } from 'theme/Theme.styled';

export interface TextProps extends React.HTMLAttributes<HTMLDivElement> {
  tx?: string;
  children?: React.ReactNode;
  preset?: TextPresets;
  align?: 'center' | 'right' | 'left';
  weight?: '200' | '300' | '400' | '600' | '700' | '900' | 'normal' | 'bold';
  className?: string;
  color?: Colors | string;
  gradient?: boolean;
  italic?: boolean;
  inline?: boolean;
  textTransform?: 'capitalize' | 'uppercase' | 'lowercase';
  fontFamily?: Font | string;
}

export const Text = ({
  tx,
  children,
  preset = 'h6',
  align = 'left',
  weight = 'normal',
  className,
  color = 'black',
  gradient,
  italic,
  inline,
  textTransform,
  fontFamily = 'Montserrat, sans-serif',
  ...rest
}: TextProps) => {
  const content = children ? children : tx;
  const Text = textPresets[preset];

  return (
    <Text
      className={className}
      align={align}
      weight={weight}
      fontFamily={fontFamily}
      color={color}
      gradient={gradient}
      italic={italic}
      inline={inline}
      textTransform={textTransform}
      preset={preset}
      {...rest}
    >
      {content}
    </Text>
  );
};
