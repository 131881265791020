import styled from "styled-components";
import { Text } from "components/Text";
import { breakpoints } from "theme/Theme.breackoints";
import { Link } from 'react-router-dom';

export const CatalogContaier = styled.div`
  width: 100%;
  max-width: calc(calc(1230px + calc(calc(100% - 1230px) / 2)) - calc(2rem + 4.5vw));
  margin: 0 0 0 auto;
  @media screen and (max-width: ${breakpoints.lg}px) {
    max-width: 100%;
    padding-left: calc(2rem + 4.5vw);
  }
  @media screen and (max-width: ${breakpoints.xs}px) {
    padding: 0;
    margin: 0 0 1.5rem;
  }

`
export const CatalogTitle = styled.div`
  font-size: 32px;
  line-height: 32px;
  margin: 0 0 2.5rem;
`
export const CatalogList = styled.div`
  display: grid;
  grid-template: 1fr / repeat(5, 1fr);
  gap: 2rem 1rem;
  max-width: 100%;
  overflow: auto;
  padding-bottom: 1.125rem;
  @media screen and (max-width: ${breakpoints.lg}px) {
    grid-template: 1fr / repeat(4, 1fr);
  }
  @media screen and (max-width: ${breakpoints.sm}px) {
    grid-template: 1fr / repeat(2, 1fr);
  }
  @media screen and (max-width: ${breakpoints.xs}px) {
    grid-template: 1fr / 1fr;
    padding: 0;
    overflow: hidden;
  }
`
export const CatalogItem = styled.div`
  width: 100%;
  max-width: 100%;
  position: relative;
  flex: 1 0 auto;
`
export const CatalogItemDate = styled(Text)`
  font-family: ${({ theme }) => theme.fonts.main};
  font-size: 14px;
  line-height: 14px;
  color: ${({ theme }) => theme.colors.wineGray};
  margin: 0 0 1.125rem;
`
export const CatalogItemTitle = styled(Link)`
  font-size: 1.5rem;
  line-height: 2rem;
  color: ${({ theme }) => theme.colors.wineBlack};
  margin: 0 0 0.75rem;
  max-width: 78%;
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.colors.winePrimary};
  }
`
export const CatalogItemImage = styled.img`
  max-width: 100%;
  margin: 0 0 1.5rem;
  min-height: 355px;
  width: 100%;
  object-fit: cover;
  @media screen and (max-width: ${breakpoints.xs}px) {
    min-height: 56.25vh;
  }
`