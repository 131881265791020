import * as React from 'react';

function DotMenu(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={32}
      height={32}
      aria-labelledby="DotMenu"
      strokeWidth={1.5}
      strokeLinecap="round"
      fill="none"
      viewBox="0 0 128 512"
      {...props}
    >
      <title>{'DotMenu'}</title>
      <path
        fill="currentColor"
        d="M64 208c26.5 0 48 21.5 48 48s-21.5 48-48 48-48-21.5-48-48 21.5-48 48-48zM16 104c0 26.5 21.5 48 48 48s48-21.5 48-48-21.5-48-48-48-48 21.5-48 48zm0 304c0 26.5 21.5 48 48 48s48-21.5 48-48-21.5-48-48-48-48 21.5-48 48z"
      ></path>
    </svg>
  );
}

export default DotMenu;
