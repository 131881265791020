import { createSlice } from '@reduxjs/toolkit';
import { LANGUAGE_MAP, LenguageMapProp } from 'components/ProductTabs/templates/ProductLanguage';
import { ProductProofPoint } from 'types/product';

const initialState = {
  isOpen: false,
  proof: {} as ProductProofPoint,
  wineProductLanguage: LANGUAGE_MAP.find((lang) => lang.shortName === 'en') as
    | LenguageMapProp
    | undefined,
};

const proofPointDetailModalSlice = createSlice({
  name: 'proofPointDetailModal',
  initialState,
  reducers: {
    openModal: (state: typeof initialState, action) => {
      return {
        ...action.payload,
        isOpen: true,
      };
    },
    closeModal: () => {
      return initialState;
    },
    setWineProductLanguage: (state: typeof initialState, action) => {
      return {
        ...state,
        wineProductLanguage: action.payload,
      };
    },
  },
});

export const {
  openModal,
  closeModal,
  setWineProductLanguage,
} = proofPointDetailModalSlice.actions;

export default proofPointDetailModalSlice;
