import * as React from 'react';

function RotatePhone(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={32}
      height={32}
      viewBox="0 0 392.657 392.656"
      aria-labelledby="rotatePhoneTitle"
      stroke="#100316"
      strokeWidth={1.5}
      strokeLinecap="round"
      fill="none"
      {...props}
    >
      <title>{'Rotate Phone'}</title>
      <g>
        <path
          d="M266.182,62.711c15.014,21.124,21.545,46.847,18.255,73.22c-0.688,5.546,6.11,6.426,9.037,6.799
		c1.338,0.172,2.581,0.239,3.576,0.239c5.44,0,6.607-2.888,6.942-5.441c3.786-30.418-3.969-61.028-21.841-86.196
		c-9.477-13.32-21.296-24.403-35.104-32.943C227.693,6.359,205.413,0,182.654,0c-30.342,0-59.412,11.15-81.855,31.375
		c-1.042,0.928-3.031,1.062-4.035,0.306l-8.291-6.417c-3.768-2.897-7.994-2.744-10.433,0.019c-1.023,1.176-2.161,3.347-1.683,6.923
		l3.414,26.564c0.727,5.719,5.613,10.031,11.36,10.031l28.018-3.509c5.364-0.65,6.809-3.911,7.181-5.279
		c0.373-1.377,0.775-4.905-3.48-8.157l-7.488-5.814l-0.191-1.262c18.656-16.247,42.62-25.197,67.473-25.197
		c19.115,0,37.82,5.336,54.076,15.415C248.338,42.228,258.255,51.551,266.182,62.711z"
        />
        <path
          d="M374.544,351.105V218.158c0-10.93-8.854-19.756-19.775-19.756H202.372v26.67h134.104c3.013,0,5.432,2.439,5.432,5.441
		v108.229c0,3.012-2.429,5.441-5.432,5.441H202.372v26.689h152.388C365.69,370.873,374.544,362.018,374.544,351.105z"
        />
        <path
          d="M37.877,392.656h132.948c10.911,0,19.765-8.855,19.765-19.775V119.283c0-10.92-8.864-19.775-19.765-19.775H37.877
		c-10.93,0-19.766,8.855-19.766,19.775v253.598C18.122,383.801,26.948,392.656,37.877,392.656z M104.021,380.578
		c-6.885,0-12.45-5.566-12.45-12.441s5.565-12.449,12.45-12.449c6.866,0,12.441,5.574,12.441,12.449
		S110.887,380.578,104.021,380.578z M44.792,137.566c0-3.012,2.438-5.432,5.441-5.432h108.229c3.012,0,5.441,2.429,5.441,5.432
		v195.246c0,3.004-2.438,5.432-5.441,5.432H50.232c-3.002,0-5.441-2.428-5.441-5.432V137.566z"
        />
      </g>
    </svg>
  );
}

export default RotatePhone;
