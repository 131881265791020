import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from '@react-hook/media-query';
import { useInViewport } from 'react-in-viewport';
//ASSETS
import placeholderImage from 'assets/images/placeholder255x255.png';
//STORE
import { openModal as openMediaEvidenceModal } from 'store/mediaEvidenceModal';
//PROPS TYPE
import { VideoPreviewProps } from './VideoPreview.props';
//STYLES
import {
  VideoPreviewContent,
  VideoPreviewMedia,
  VideoPreviewMediaOverlay,
  VideoPreviewMediaIcons,
  VideoPreviewMediaIconPlay,
} from './VideoPreview.styled';

let TIME_PREVIEW: ReturnType<typeof setTimeout> | null = null;

const VideoPreview = ({
  url,
  name,
  id,
  parent,
  margin,
  poster = placeholderImage,
}: VideoPreviewProps) => {
  const dispatch = useDispatch();

  const $video = useRef<HTMLVideoElement>(null);

  const matches = useMediaQuery('only screen and (max-width: 768px)');

  const [play, setPlay] = useState<boolean>(false);

  const startPreview = () => {
    if ($video && $video.current && !play) {
      $video.current.currentTime = 1;
      $video.current.playbackRate = 0.5;
      $video.current.play();
    }
  };
  const stopPreview = () => {
    if ($video && $video.current && !play) {
      $video.current.currentTime = 0;
      $video.current.playbackRate = 1;
      $video.current.pause();
    }
  };

  const handleMouseEnter = () => {
    if (!matches) {
      startPreview();
      TIME_PREVIEW = setTimeout(() => {
        stopPreview();
      }, 4000);
    }
  };

  const handleMouseLeave = () => {
    if (!matches && !play) {
      if (TIME_PREVIEW) clearTimeout(TIME_PREVIEW);
      TIME_PREVIEW = null;
      stopPreview();
    }
  };

  const { inViewport, enterCount, leaveCount } = useInViewport(
    $video,
    {
      rootMargin: '100px',
      threshold: 1.0,
    },
    { disconnectOnLeave: false },
    {
      onEnterViewport: () => {
        if ($video.current && !play) {
          const video = $video.current;
          video.currentTime = 1;
          video.playbackRate = 0.5;
          video.play();
          TIME_PREVIEW = setTimeout(() => {
            video.currentTime = 0;
            video.playbackRate = 1;
            video.pause();
          }, 4000);
        }
      },
      onLeaveViewport: () => {
        if ($video.current && !play) {
          const video = $video.current;
          if (TIME_PREVIEW) clearTimeout(TIME_PREVIEW);
          TIME_PREVIEW = null;
          video.currentTime = 0;
          video.playbackRate = 1;
          video.pause();
        }
      },
    }
  );

  const handleOpenMediaEvidenceModal = useCallback(
    (media: { name: string; url: string }) => {
      dispatch(
        openMediaEvidenceModal({
          media: media,
        })
      );
    },
    [dispatch]
  );

  const handleTogglePlay = () => {
    if ($video && $video.current) {
      if (TIME_PREVIEW) clearTimeout(TIME_PREVIEW);
      $video.current.currentTime = 0;
      $video.current.playbackRate = 1;
      if (play) {
        $video.current.pause();
      } else {
        $video.current.play();
      }
      setPlay(!play);
    }
  };

  return (
    <VideoPreviewContent
      margin={margin}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={() => {
        handleTogglePlay();
      }}
    >
      {!play && <VideoPreviewMediaOverlay />}
      {!play ? (
        <VideoPreviewMediaIcons>
          <VideoPreviewMediaIconPlay />
        </VideoPreviewMediaIcons>
      ) : (
        <></>
      )}

      {url && (
        <VideoPreviewMedia
          src={url}
          ref={$video}
          muted={play}
          controls={play}
          poster={poster}
        />
      )}
    </VideoPreviewContent>
  );
};

export default VideoPreview;
