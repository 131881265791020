import styled from 'styled-components';
import { sm } from 'theme/Theme.breackoints';

export const AppMobileNavContainer = styled.nav`
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  display: none;
  ${sm`
    display: block;
  `}
`;
export const AppMobileNavList = styled.ul`
  background-color: ${({ theme }) => theme.colors.white};
  padding: 0.625rem 0.75rem;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  box-shadow: 0 -2px 10px 0 rgb(16 3 22 / 6%);
  border-top: 1px solid ${({ theme }) => theme.colors.border};
`;
export const AppMobileNavListItem = styled.li``;
export const AppMobileNavListIcon = styled.i``;
export const AppMobileNavListText = styled.span`
  color: ${({ theme }) => theme.colors.white};
  opacity: 0;
  height: 0;
  overflow: hidden;
  transform: translateY(-5px);
  transition: all 0.1s ease-in-out 0.1s;
`;
export const AppMobileNavListLink = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 8px;
  line-height: 8px;
  padding: 10px;
  color: ${({ theme }) => theme.colors.black};
  width: 55px;
  height: 55px;
  box-sizing: border-box;
  border-radius: 7.5px;
  transition: background-color 0.1s ease-in-out, color 0.1s ease-in-out;

  &:hover {
    color: ${({ theme }) => theme.colors.white};
    background-image: linear-gradient(90deg, #4e63a5 0%, #8298d5 100%);
  }

  svg {
    width: 1.5rem;
    height: 1.5rem;
    transform: translateY(3px);
    transition: all 0.1s ease-in-out;
    margin: 0 0 3px;
  }

  ${({ active, theme }) =>
    active &&
    `
    color: ${theme.colors.white};
    background-image: linear-gradient(90deg, #4e63a5 0%, #8298d5 100%);
    ${AppMobileNavListText} {
      opacity: 1;
      height: auto;
      transform: translateY(1px)
    }
    svg {
      transform: translateY(-1px);
    }
  `}
`;

//LINK IDEA
export const AppMobileNavLinkProvisional = styled.li<{ active: boolean }>`
  font-size: 1rem;
  line-height: 1rem;
  color: ${({ theme }) => theme.colors.brand};
  cursor: pointer;
  display: inline-block;
  background-color: transparent;
  font-weight: 700;
  word-break: break-all;
  padding: 1rem 0;
  min-width: 6rem;
  text-align: center;
  &:hover {
    color: ${({ theme }) => theme.colors.active};
  }
  ${({ active, theme }) =>
    active &&
    `
    color: ${theme.colors.active};
  `}
`;
export const AppMobileNavShare = styled.div`
  position: absolute;
  right: 0.875rem;
  bottom: 5.25rem;
  border-radius: 1.625rem;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
`;
export const AppMobileNavShareIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.25rem;
  height: 3.25rem;
  svg {
    width: 1.25rem;
    color: ${({ theme }) => theme.colors.brand};
    stroke: ${({ theme }) => theme.colors.brand};
  }
  &:hover {
    svg {
      color: ${({ theme }) => theme.colors.active};
      stroke: ${({ theme }) => theme.colors.active};
    }
  }
`;

export const AppMobileNavShareOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: ${({ theme }) => theme.colors.brand};
  opacity: 0.75;
`;
