import { createSlice } from '@reduxjs/toolkit';
import { Lot } from 'types/lot';
import { ProductProofPoint } from 'types/product';

const initialState = {
  isOpen: false,
  proof: {} as ProductProofPoint,
  lotId: '',
  lotData: {} as Lot,
  isStatic: false,
};

const blockchainDetailModalSlice = createSlice({
  name: 'blockchainDetailModal',
  initialState,
  reducers: {
    openModal: (state: typeof initialState, action) => {
      return {
        ...state,
        ...action.payload,
        isOpen: true,
      };
    },
    openModalLot: (state: typeof initialState, action) => {
      return {
        ...state,
        ...action.payload,
        isOpen: true,
      };
    },
    closeModal: () => {
      return initialState;
    },
  },
});

export const { openModal, closeModal } = blockchainDetailModalSlice.actions;

export default blockchainDetailModalSlice;
