import React, { useState } from 'react';

//COMPONENTS
import SkeletonLoader from 'components/SkeletonLoader';

interface SkeletonProofsProps {
  matches?: boolean;
}

const SkeletonProofs = ({ matches }: SkeletonProofsProps) => {
  const [proofList] = useState([1, 2, 3, 4, 5]);

  return (
    <SkeletonLoader
      width="100%"
      height="auto"
      padding="0 1.5rem 0.25rem 0.5rem"
      margin="0 0 1.5rem -0.5rem"
      display="flex"
      flex={{
        align: 'center',
        wrap: 'nowrap',
      }}
      background="transparent"
      overflow="hidden"
      maxWidth={matches ? 'calc(100vw - 2rem)' : 'calc(100vw - 5rem)'}
    >
      {proofList.map((poorf, index) => (
        <SkeletonLoader
          key={`${index}-proof`}
          width="15rem"
          height="2rem"
          margin="0.5rem 0.5rem 0.5rem 0"
          radius="9999px"
          flex={{
            flexGroup: '1 0 auto;',
          }}
        />
      ))}
    </SkeletonLoader>
  );
};

export default SkeletonProofs;
