import styled from "styled-components";

export const SealsContainer = styled.div<{margin?: string;}>`
  display: flex;
  gap: 1.5rem;
  margin: ${({ margin }) => margin ? margin : "0"};
  @media (max-width: 768px) {
    margin: 0 0 1.5rem;
  }
`
export const SealsItemImage = styled.img`
  width: 3.5rem;
  left: 0;
  top: 0;
  position: absolute;
  transition: all 0.25s ease-in-out;
`
export const SealsItem = styled.div`
  width: 3.5rem;
  height: 3.5rem;
  cursor: pointer;
  position: relative;
  &:hover {
    ${SealsItemImage} {
      transform: scale(1.1);
    }
  }
`