import gql from 'graphql-tag';

export const productQuery = gql`
  query product($pk: String!, $language: String) {
    product(pk: $pk, language: $language) {
      data {
        name
        description
        internalId
        pk
        brand
        hideFromOrgMirror
        tabs {
          pk,
          title,
          order,
          type,
          fileList {
            pk
            documents {
              pk
              document
              title
              description
            }
          }
          ingredientList {
            ingredients {
              description,
              title,
              image
              link
            }
          }
          recommendationList {
            recommendations {
              title
              description
            }
          }
          nutritionFact {
            description
            amountPerServing
            discountCalories
            scaleFactor
            details 
            calories 
            ingredients
            ingredientsFiltered
          }
          technicalDetail {
            title
            subtitle
            footer
            composition
            principalDetails {
              id
              title
              description
            }
            otherDetails {
              id
              title
              description
            }
            image
          }
        }
        lots {
          totalCount
        }
        thumbnail(size: "40x40") {
          url
        }
        organization {
          name
          pk
          logo(size: "250x250") {
            url
          }
          url
          socialChannels {
            handle
            url
            type
            pk
          }
        }
        images {
          id
          url(size: "768x768")
          order
        }
        featuredProofPoints {
          name
          pk
          description
          createdAt
          mediaEvidences {
            name
            url
            type
          }
          shouldShowWidget
          icon {
            value
            icon
            label
          }
          evidences {
            file
            evidenceName
            expirationDate
            pk
          }
          conditions {
            value
            type
            typeDisplay
            unit
          }
          verifier {
            name
            description
            logo(size: "250x250") {
              url
            }
          }
        }
        journey {
          name
          milestones {
            id
            pk
            name
            startDate
            endDate
            video {
              url
            }
            internalId
            description
            icon
            location {
              name
            }
            createdAt
            image(size: "700x700") {
              url
            }
            proofPoints {
              name
              pk
              shouldShowWidget
              description
              icon {
                value
                icon
                label
              }
              mediaEvidences {
                name
                url
                type
              }
              createdAt
              conditions {
                value
                type
                typeDisplay
                unit
              }
              evidences {
                file
                evidenceName
                expirationDate
                pk
              }
              verifier {
                name
                description
                logo(size: "250x250") {
                  url
                }
              }
            }
          }
        }
        reviews {
          edges {
            node {
              createdAt
              id
              title
              name
              email
              comment
              rating
              status
              reply {
                comment
                createdAt
              }
            }
          }
        }
        reviewStats {
          averageRating
          totalCount
          percentageCountFive
          percentageCountFour
          percentageCountThree
          percentageCountTwo
          percentageCountOne
        }
        translationStatus
      }
      error
      url
    }
  }
`;

export const relatedProductQuery = gql`
  query relatedProducts($id: String, $language: String) {
    relatedProducts(id: $id, language: $language) {
      products {
        pk
        name
        thumbnail(size: "700x700") {
          url
        }
      }
    }
  }
`;

export const stepsProductQuery = gql`
  query product($pk: String!, $language: String) {
    product(pk: $pk, language: $language) {
      data {
        name
        description
        pk
        brand
        hideFromOrgMirror
        workflow {
          name
          description
        }
        steps {
          id
          name
          stepDate
          extraFields
          createdAt
          image(size: "700x700") {
            url
          }
          internalId
          description
          icon
        }
        images {
          id
          url(size: "768x768")
          order
        }
        organization {
          name
          pk
          logo(size: "250x250") {
            url
          }
          url
          socialChannels {
            handle
            url
            type
            pk
          }
        }
      }
      error
      url
    }
  }
`;
