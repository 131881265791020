import React from 'react';
import { Product } from 'types/product';
//ASSETS
import placeholderImage from 'assets/images/placeholder255x255.png';
//STYLES
import {
  CatalogContaier,
  CatalogTitle,
  CatalogList,
  CatalogItem,
  CatalogItemDate,
  CatalogItemTitle,
  CatalogItemImage,
} from './Catalog.styled';

interface CatalogProps {
  products: Product[];
  organizationName: string;
}

const Catalog = ({ products, organizationName }: CatalogProps) => {
  return (
    <CatalogContaier>
      <CatalogTitle>Catálogo</CatalogTitle>
      <CatalogList>
        {products.map((product) => (
          <CatalogItem key={product.pk}>
            <CatalogItemImage
              src={
                product.images && product.images.length === 0
                  ? placeholderImage
                  : product.images[0].url
              }
            />
            <CatalogItemDate>{organizationName}</CatalogItemDate>
            <CatalogItemTitle
              to={{
                pathname: `/wine/${product.pk}/`,
              }}
            >
              {product.name}
            </CatalogItemTitle>
            {/* <CatalogItemDate>Denominación de origen</CatalogItemDate> */}
          </CatalogItem>
        ))}
      </CatalogList>
    </CatalogContaier>
  );
};

export default Catalog;
