import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

//STORE
import { openModal } from 'store/proofPointModal';

//PROPS TYPE
import { SealProps } from './Seals.props';

//STYLED
import {
  SealItemContainer,
  SealItemIcon,
  SealItemName,
} from './SealItem.styled';

const SealItem = ({ proof }: SealProps) => {
  const dispatch = useDispatch();

  const handleOpenProofPointModal = useCallback(() => {
    dispatch(
      openModal({
        proof,
      })
    );
  }, [dispatch, proof]);

  return (
    <SealItemContainer onClick={handleOpenProofPointModal}>
      {proof && proof.icon && proof.icon.icon && (
        <SealItemIcon src={proof.icon.icon} alt={proof.name} />
      )}
      {proof && proof.icon && <SealItemName>{proof.icon.label}</SealItemName>}
    </SealItemContainer>
  );
};

export default SealItem;
