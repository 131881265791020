import { useQuery } from '@apollo/react-hooks';
import { useMediaQuery } from '@react-hook/media-query';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
//import ReactGA from 'react-ga4';
//QUERIES
import { lotQuery } from 'queries/lot';
//STORE
import { AppState } from 'store';
import { setMobileNav } from 'store/mobilenav';
import { setOrganizationInfo } from 'store/organization';
import { setProduct } from 'store/product';
import {
  openModal as langOpenModal,
  setTranslations,
} from 'store/tramslateModal';
//PROPS TYPE
import { MetaHeadProps } from 'components/MetaHead/MetaHead';
import { LotData } from 'types/lot';
//PROPS TYPE
//COMPONENTS
import BlockchainDetailModal from 'components/BlockchainDetailModal';
import EvidenceModal from 'components/EvidenceModal';
import MediaEvicenceModal from 'components/MediaEvidenceModal/MediaEvicenceModal';
import ProofPointDetailModal from 'components/ProofPointDetailModal';
import ProofPointModal from 'components/ProofPointModal';
import RenderHtml from 'components/RenderHtml';
import AppLayoutWine from 'module/AppLayoutWine';
import ProductSkeleton from 'screens/Product/components/ProductSkeleton';
import CycleLife from './components/CycleLife';
import Pictures from './components/Pictures';
import Seals from './components/Seals';
import Share from './components/Share';
import Tabs from './components/Tabs';
import Tags from './components/Tags';
//STYLES
import Button from 'components/Button';
import Modal from 'components/Modal';
import {
  LANGUAGE_MAP,
  LenguageMapProp,
  _LANGUAGES,
} from 'components/ProductTabs/templates/ProductLanguage';
import SelectLanguageComponent from 'components/SelectLanguage/SelectLanguageComponent';
import TranslateModal from 'components/TranslateModal/TranslateModal';
import { AppLayoutWineWrapper } from 'module/AppLayoutWine/AppLayoutWine.styled';
import { LotVars } from 'screens/Lot/Lot.props';
import { openModal as openBlockchainDetailModal } from 'store/blockchainDetailModal';
import {
  WineContent,
  WineContentBody,
  WineContentBodyContainer,
  WineContentBodyImages,
  WineContentBodyInformation,
  WineContentFooter,
  WineContentLimkWeb,
  WineContentOrganization,
  WineContentOrganizationImage,
  WineContentShare,
  WineContentShareItem,
  WineContentShareList,
  WineContentTitle,
  WineContentiLink,
  WineContentiParagraph,
  WineContentiSeparator,
} from './Wine.styled';
import CycleLifeModal from './components/CycleLifeModal';

const SOCIALS: Record<string, string> = {
  facebook: 'fb',
  twitter: 'tw',
  instagram: 'in',
  youtube: 'yb',
  linkedin: 'ln',
};

const Wine = () => {
  const { wineId } = useParams();
  const dispatch = useDispatch();

  const matches = useMediaQuery('only screen and (max-width: 920px)');
  const matchesMd = useMediaQuery('only screen and (max-width: 768px)');
  const matchesXs = useMediaQuery('only screen and (max-width: 575px)');
  const [certIsOpen, setCertIsOpen] = useState(false);

  const { isOpen, proof, wineProductLanguage } = useSelector(
    (state: AppState) => state.proofPointDetailModal
  );

  const { translateSelect } = useSelector(
    (state: AppState) => state.translateModal
  );

  const { blockStats } = useSelector((state: AppState) => state.blockStats);

  const [metaInformation, setMetaInformation] = useState<MetaHeadProps>({});
  const [languageSelect, setLanguageSelect] = useState<
    LenguageMapProp | undefined
  >(LANGUAGE_MAP.find((lang) => lang.shortName === 'es'));
  const [selectedLanguageText, setSelectedLanguageText] = useState<
    string | null
  >(null);
  const [selectedTitle, setSelectedTitle] = useState<string | null>(null);

  const { data: resProduct, loading, refetch } = useQuery<LotData, LotVars>(
    lotQuery,
    {
      variables: {
        pk: wineId,
        language:
          translateSelect && translateSelect.length > 0
            ? translateSelect.find((lang) => lang.idProduct === wineId)
                ?.short_name
            : '',
      },
    }
  );

  const lotData = resProduct?.productLot.data;
  const lotResult = resProduct?.productLot;

  useEffect(() => {
    const selectLanguage = translateSelect.find(
      (lang) => lang.idProduct === wineId
    );
    const translationStatus = lotData?.product?.translationStatus;
    const parsedTranslationStatus = translationStatus
      ? JSON.parse(translationStatus)
      : {};

    // console.log(selectLanguage, 'select', parsedTranslationStatus);
    if (Object.keys(parsedTranslationStatus).length > 1) {
      if (selectLanguage) {
        dispatch(
          setTranslations({
            translations: parsedTranslationStatus,
          })
        );
      } else {
        dispatch(
          langOpenModal({
            isOpen: true,
            translations: parsedTranslationStatus,
            isHiddenClose: true,
          })
        );
      }
    }
  }, [translateSelect, lotData, dispatch, wineId]);

  /**useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname,
      title: 'wine-bottle',
    });
  }, []);**/
  const isEcological = lotData
    ? JSON.parse(lotData?.steps[3]?.extraFields!)?.find((field: any) => {
        return field?.internal_id === 'ecological';
      })?.value === 'yes'
    : false;

  useEffect(() => {
    if (lotData && lotData.product) {
      dispatch(setMobileNav('info'));
      setMetaInformation({
        title: lotData.product.name,
        description: lotData.product.description,
        image:
          lotData.product.images.length > 0
            ? lotData.product.images[0].url
            : '',
        url: window.location.href,
      });

      dispatch(
        setOrganizationInfo({
          name: lotData.product.organization.name,
          logo: lotData.product.organization.logo.url,
        })
      );
      dispatch(setProduct(lotData.product));
    }
  }, [lotData, dispatch]);

  const handleRefetchQuery = useCallback(() => {
    refetch();
  }, [refetch]);

  const handleOpenBlochainDetailModal = useCallback(() => {
    if (lotData) {
      dispatch(
        openBlockchainDetailModal({
          proof: lotData?.featuredProofPoints[0],
          lotId: lotData.pk,
          lotData: lotData,
          isStatic: true,
        })
      );
    }
  }, [dispatch, lotData]);

  const [alcoholicStrengthTitle, setAlcoholicStrengthTitle] = useState();
  const [alcoholicStrength, setAlcoholicStrength] = useState();
  const [deguelleDateState, setDeguelleDate] = useState<string | null>(null);
  const details = lotData?.product?.tabs[0]?.nutritionFact?.details!;
  //  const details =
  //     lotData?.product?.tabs && lotData.product.tabs.length > 0
  //       ? lotData.product.tabs[0]?.nutritionFact?.details
  //       : undefined;

  useEffect(() => {
    if (details) {
      const detailsList = JSON.parse(details);
      detailsList.map((detail: any, index: number) => {
        if (detail.title === 'alcoholicStrength') {
          setAlcoholicStrengthTitle(
            detail.translated_title[wineProductLanguage?.shortName!]
          );
          setAlcoholicStrength(detail.description);
        }
      });
    }
  }, [details, wineProductLanguage]);

  type LanguageKeys = 'ca' | 'es' | 'en';
  const viewedThankYou: Record<LanguageKeys, string> = {
    ca: 'Vist, gràcies!',
    es: 'Visto, Igracias!',
    en: 'Seen, Thank You!',
  };

  const viewOnBlockchain: Record<LanguageKeys, string> = {
    ca: 'Veure a la cadena de blocs',
    es: 'Ver en la cadena de bloques',
    en: 'View on Blockchain',
  };

  const selectedTopicTitle: Record<LanguageKeys, string> = {
    ca: "Identificador únic de l'ampolla",
    es: 'ID de botella única',
    en: 'Unique Bottle ID',
  };

  const _queryCertificateId =
    URLSearchParams &&
    new URLSearchParams(window.location.search).get('certificateId');

  const generateTranslatedText = ({
    product_name,
    product_number,
    arg2,
    arg3,
    deguelle_date,
  }: {
    [key: string]: string;
  }) => {
    const dataBank = [
      {
        id: 'ca',
        title: 'COMPROMIS DE CORPINNAT',
        text: `CORPINNAT CERTIFICA que aquesta ampolla de {product_name}, número {product_number}, ha estat elaborada a partir de raïm {arg2} collit a mà, premsat i vinificat íntegrament en el mateix celler. Després d’una llarga criança a la cava, aquest {arg3} ha estat degorjat i etiquetat el {deguelle_date}. Tot aquest procés s’audita anualment per una empresa externa qualificada.`,
      },
      {
        id: 'es',
        title: 'CERTIFICADO CORPINNAT',
        text: `CORPINNAT CERTIFICA que esta botella de {product_name} número {product_number}, ha sido elaborada a partir de uva {arg2} cultivada exclusivamente en el corazón del Penedès, vendimiada a mano, prensada y vinificada íntegramente en la propia bodega. Después de una larga crianza en la cava, este {arg3} ha sido degollado y etiquetado el {deguelle_date}. Todo este proceso se audita anualmente por una empresa externa cualificada`,
      },
      {
        id: 'en',
        title: 'CORPINNAT CERTIFICATE',
        text: `CORPINNAT CERTIFIES that this bottle of {product_name} number {product_number}, has been elaborated from {arg2} grapes, grown exclusively in the heart of Penedes, hand harvested, pressed and vinified entirely in the same winery. After a long ageing in the cellar, this {arg3} has been disgorged and labelled the {deguelle_date}. All this process is audited annually by a qualified external Enterprise`,
      },
    ];

    // find the object filtered by the language selected
    const found = dataBank.find(
      (data) => data.id === languageSelect?.shortName
    );

    if (!found) return;

    const placeholders: { [key: string]: string } = {
      product_name,
      product_number,
      arg2,
      arg3,
      deguelle_date,
    };
    // Replace placeholders with actual values
    const text = found.text.replace(/{(.*?)}/g, (_, key) => placeholders[key]);
    const _title = found.title;

    setSelectedLanguageText(text);
    setSelectedTitle(_title);
  };

  useEffect(() => {
    if (languageSelect && lotData && lotData?.product) {
      let arg3 = 'Dosage';
      let deguelleDate = 'deguelle date';
      const allExtraFields = lotData.steps.map((step, index) => {
        return JSON.parse(step.extraFields!);
      });
      allExtraFields.flat(1).map((extraField, index) => {
        if (
          extraField.name === 'Fecha de Deguelle' &&
          extraField.type === 'date'
        ) {
          deguelleDate = extraField.value;
          setDeguelleDate(extraField.value);
        }
      });
      allExtraFields.flat(1).map((extraField, index) => {
        if (extraField.name === 'Dosage') {
          arg3 = extraField.value;
        }
      });

      const ecologicalTranslations = {
        en: 'Ecologic',
        es: 'ecológica',
        ca: 'ecològic',
      };

      generateTranslatedText({
        product_name: lotData?.product?.name,
        product_number: _queryCertificateId ?? '',
        arg2: isEcological
          ? ecologicalTranslations[
              languageSelect?.shortName as keyof typeof ecologicalTranslations
            ] || ''
          : '',
        arg3,
        deguelle_date: deguelleDate,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [languageSelect, lotData?.product]);

  return (
    <AppLayoutWine
      loading={loading}
      skeletonLoader={<ProductSkeleton matches={matchesMd} />}
      metas={metaInformation}
      errorType={lotResult && lotResult.error}
      errorUrl={lotResult && lotResult.url}
      logo={
        lotData &&
        lotData.product &&
        lotData.product.organization &&
        lotData.product.organization.logo &&
        lotData.product.organization.logo.url
      }
      type="wine"
      id={wineId}
      hideOrgData={
        lotData && lotData.product && lotData.product.hideFromOrgMirror
      }
    >
      <WineContent>
        <AppLayoutWineWrapper>
          <WineContentBody>
            {!matchesXs &&
              lotData &&
              lotData.product &&
              lotData.product.organization &&
              lotData.product.organization.socialChannels &&
              lotData.product.organization.socialChannels.length > 0 &&
              !lotData.product.hideFromOrgMirror && (
                <WineContentShare>
                  <WineContentShareList>
                    {lotData.product.organization.socialChannels.map(
                      (social) => (
                        <WineContentShareItem
                          key={social.pk}
                          href={social.url}
                          target="_blank"
                        >
                          {SOCIALS[social.type.toLowerCase()]}
                        </WineContentShareItem>
                      )
                    )}
                  </WineContentShareList>
                </WineContentShare>
              )}
            {lotData && lotData.product && (
              <WineContentBodyContainer>
                {!matches &&
                  lotData.product &&
                  lotData.product.images &&
                  lotData.product.images.length > 0 && (
                    <WineContentBodyImages>
                      <Pictures
                        images={lotData.product.images.map(
                          (image) => image.url
                        )}
                      />
                    </WineContentBodyImages>
                  )}

                <WineContentBodyInformation>
                  {lotData.product &&
                    lotData.product.organization &&
                    !lotData.product.hideFromOrgMirror && (
                      <WineContentiLink
                        to={{
                          pathname: `/winery/${lotData.product.organization.pk}/`,
                          search: `?wineId=${lotData.product.pk}`,
                        }}
                      >
                        {lotData.product.organization.name}
                      </WineContentiLink>
                    )}
                  <div style={{ display: 'flex' }}>
                    {lotData.product.organization &&
                      !lotData.product.hideFromOrgMirror && (
                        <WineContentLimkWeb
                          href={lotData.product.organization.url}
                          target="_blank"
                        >
                          {' '}
                          {lotData.product.organization.url
                            .replace('https://', '')
                            .replace('http://', '')
                            .replace('www.', '')}
                        </WineContentLimkWeb>
                      )}
                  </div>
                  <WineContentTitle>
                    {lotData.product && lotData.product.name}
                  </WineContentTitle>
                  {lotData.workflow &&
                    lotData.workflow.verifier &&
                    (lotData.workflow.verifier.name.toLowerCase() === '' ||
                      lotData.workflow.verifier.name.toLowerCase() ===
                        'davecast') &&
                    lotData.steps &&
                    lotData.steps.length > 0 &&
                    !lotData.product.hideFromOrgMirror && (
                      <WineContentOrganization>
                        <WineContentOrganizationImage
                          src={lotData.workflow.verifier.logo.url}
                        />

                        <WineContentiLink
                          to={{
                            pathname: `/certificate-bottle/${lotData.pk}/`,
                          }}
                        ></WineContentiLink>
                      </WineContentOrganization>
                    )}
                  <img
                    src="/corpinnat_logo.svg"
                    alt="corpinnat logo"
                    onClick={() => setCertIsOpen(true)}
                    className="cursor-pointer"
                  />
                  <WineContentiSeparator />
                  {lotData.product && lotData.product.description && (
                    <WineContentiParagraph maxWidth="500px" margin="0 0 1rem">
                      <RenderHtml text={lotData.product.description} wine />
                    </WineContentiParagraph>
                  )}
                  {lotData.featuredProofPoints &&
                    lotData.featuredProofPoints.length > 0 && (
                      <Tags
                        style={{ display: 'none' }}
                        margin="0 0 1.5rem"
                        proofs={lotData.featuredProofPoints}
                      />
                    )}
                  <div>
                    {alcoholicStrengthTitle && `${alcoholicStrengthTitle} :`}{' '}
                    {alcoholicStrength && ` ${alcoholicStrength}`}
                  </div>
                  {lotData.featuredProofPoints &&
                    lotData.featuredProofPoints.length > 0 && (
                      <Seals
                        margin="0 0 1.5rem"
                        proofs={lotData.featuredProofPoints}
                      />
                    )}

                  {matches &&
                    lotData.product.images &&
                    lotData.product.images.length > 0 && (
                      <WineContentBodyImages>
                        <Pictures
                          images={lotData.product.images.map(
                            (image) => image.url
                          )}
                        />
                      </WineContentBodyImages>
                    )}
                  {lotData.product.tabs && lotData.product.tabs.length > 0 && (
                    <Tabs tabs={lotData.product.tabs} />
                  )}
                  <Share type="product" product={lotData.product} />
                </WineContentBodyInformation>
              </WineContentBodyContainer>
            )}
          </WineContentBody>
        </AppLayoutWineWrapper>
        {lotData &&
          lotData.product &&
          lotData.journey &&
          lotData.journey.milestones &&
          lotData.journey.milestones.length > 0 && (
            <CycleLife milestones={lotData.journey.milestones} />
          )}
        <AppLayoutWineWrapper>
          {lotData && lotData.product && !lotData.product.hideFromOrgMirror && (
            <WineContentFooter>
              {lotData.product.organization && (
                <WineContentiLink
                  to={{
                    pathname: `/winery/${lotData.product.organization.pk}/`,
                    search: `?wineId=${lotData.product.pk}`,
                  }}
                  margin="0 0 2rem;"
                ></WineContentiLink>
              )}
              <img
                src="/corpinnat_logo.svg"
                alt="corpinnat logo"
                onClick={() => setCertIsOpen(true)}
                className="cursor-pointer"
              />
            </WineContentFooter>
          )}
        </AppLayoutWineWrapper>
        <ProofPointModal wine />
        <ProofPointDetailModal wine />
        <MediaEvicenceModal wine />
        <EvidenceModal wine />
        <CycleLifeModal />
        <TranslateModal
          refetch={handleRefetchQuery}
          type="product"
          id={wineId}
        />
        <Modal
          isOpen={certIsOpen}
          close={() => setCertIsOpen(false)}
          backcolor="white"
          size="big"
          hiddeClose
        >
          <div className="flex flex-col items-center lg:p-20 md:p-14 p-2 py-4 h-[70dvw]">
            <div className="md:absolute top-[2rem] ml-6 md:flex hidden justify-start w-full">
              {!matchesXs &&
                lotData &&
                lotData.product &&
                lotData.product.organization &&
                lotData.product.organization.socialChannels &&
                lotData.product.organization.socialChannels.length > 0 &&
                !lotData.product.hideFromOrgMirror && (
                  <WineContentShare>
                    <WineContentShareList>
                      {lotData.product.organization.socialChannels.map(
                        (social) => (
                          <WineContentShareItem
                            key={social.pk}
                            href={social.url}
                            target="_blank"
                          >
                            {SOCIALS[social.type.toLowerCase()]}
                          </WineContentShareItem>
                        )
                      )}
                    </WineContentShareList>
                  </WineContentShare>
                )}
            </div>

            <div className="md:absolute top-[2rem] right-[5rem] flex md:justify-end justify-center w-full mb-6">
              <SelectLanguageComponent
                placeholder="Selecciona un lenguaje"
                onSelect={(val) => setLanguageSelect(val)}
                data={_LANGUAGES}
                defaultLang="es"
              />
            </div>

            <img
              src="/corpinnat_logo.svg"
              alt="corpinnat logo"
              width={150}
              onClick={() => setCertIsOpen(true)}
              className="cursor-pointer"
            />

            <h2 className="p1 md:text-4xl text-2xl mb-8 mt-6">
              {/* Corpinnat Certificate */}
              {selectedTitle}
            </h2>
            <h3 className="text-2xl text-center mb-4">
              {lotData?.product.name}
            </h3>
            <h3 className="text-xl mb-10 text-center">
              <span className="p1 font-thin">
                {/* Unique Bottled ID:  */}
                {selectedTopicTitle[languageSelect?.shortName as LanguageKeys]}
                :&nbsp;
              </span>
              {_queryCertificateId ?? ''}
            </h3>
            <div className="w-full h-1 border-t-2 mb-12" />
            <p
              className="mb-16 text-lg text-center leading-9"
              style={{ color: '#555' }}
            >
              {selectedLanguageText}
            </p>
            <div
              className="border rounded-md border-black h-12 p-3 px-5 mb-5 cursor-pointer"
              onClick={() => {
                if (!blockStats.errors) {
                  handleOpenBlochainDetailModal();
                }
              }}
            >
              {viewOnBlockchain[languageSelect?.shortName as LanguageKeys]}
            </div>

            <div className="mb-2" />

            <Button
              text={viewedThankYou[languageSelect?.shortName as LanguageKeys]}
              onClick={() => setCertIsOpen(false)}
            />
          </div>
        </Modal>
        <BlockchainDetailModal deguelleDate={deguelleDateState} type="lot" />
      </WineContent>
    </AppLayoutWine>
  );
};

export default Wine;
