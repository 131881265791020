import gql from 'graphql-tag';

export const qrQuery = gql`
  query qrData($pk: String!) {
    qrData(pk: $pk) {
      data {
        objectId
        type
        model
        certificateId
      }
      error
    }
  }
`;
