import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

//STORE
import { openModal } from 'store/proofPointModal';

//COMPONENTS
import { ProofPointProps } from './ProofPoint.props';

//STYLES
import {
  ProofPointContainer,
  ProofPointIcon,
  ProofPointName,
} from './ProofPoint.styled';

const ProofPoint = ({ proof }: ProofPointProps) => {
  const dispatch = useDispatch();

  const handleOpenProofPointModal = useCallback(() => {
    dispatch(
      openModal({
        proof,
      })
    );
  }, [dispatch, proof]);

  return (
    <ProofPointContainer onClick={handleOpenProofPointModal}>
      <ProofPointIcon src={proof.icon.icon} />
      <ProofPointName>
        {proof.name.charAt(0).toUpperCase() + proof.name.slice(1)}
      </ProofPointName>
    </ProofPointContainer>
  );
};

export default ProofPoint;
