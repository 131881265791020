import React, { useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

//ASSETS
import ModalReviewGraphic from 'assets/images/modal-graphic-small.png';

//STORE
import { AppState } from 'store';
import { closeModal } from 'store/reviewModal';

//PROPS TYPE
import { ReviewModalProps } from './ReviewModal.props';

//COMPONENT
import Modal from 'components/Modal';
import ReviewFormProduct from './ReviewForm.product';
import ReviewFormOrganization from './ReviewForm.organization';
import ReviewFormLot from './ReviewForm.lot';
import Button from 'components/Button';

//STYLES
import { ModalContent } from 'components/Modal/Modal.styled';
import {
  ReviewModalContainer,
  ReviewModalActions,
  ReviewModalImage,
  ReviewModalTitle,
  ReviewModalSubtitle,
} from './ReviewModal.styled';

const ReviewModal = ({ type }: ReviewModalProps) => {
  const { t } = useTranslation('modalReview');

  const dispatch = useDispatch();
  const location = useLocation();

  const { isOpen } = useSelector((state: AppState) => state.reviewModal);

  const [submited, setSubmited] = useState<boolean>(false);

  const handleCloseModal = useCallback(() => {
    dispatch(closeModal());
  }, [dispatch]);

  return (
    <Modal isOpen={isOpen} close={handleCloseModal}>
      <ModalContent>
        {submited ? (
          <ReviewModalContainer>
            <ReviewModalImage
              src={ModalReviewGraphic}
              alt="review modal graphic"
            />
            <ReviewModalTitle>{t('content.doneReviewTitle')}</ReviewModalTitle>
            <ReviewModalSubtitle>
              {t('content.doneReviewSubTitle')}
            </ReviewModalSubtitle>
            <ReviewModalActions>
              <Button
                onClick={handleCloseModal}
                text={t('content.doneButton')}
              />
            </ReviewModalActions>
          </ReviewModalContainer>
        ) : (
          <ReviewModalContainer>
            <ReviewModalImage
              src={ModalReviewGraphic}
              alt="review modal graphic"
            />
            <ReviewModalTitle>{t('content.textReviewTitle')}</ReviewModalTitle>
            <ReviewModalSubtitle>
              {t('content.textReviewSubTitleUp')}
              <br /> {t('content.textReviewSubTitleDown')}
            </ReviewModalSubtitle>
            {type === 'lot' && <ReviewFormLot setSubmited={setSubmited} />}
            {type === 'product' && (
              <ReviewFormProduct setSubmited={setSubmited} />
            )}
            {type === 'organization' && (
              <ReviewFormOrganization setSubmited={setSubmited} />
            )}
          </ReviewModalContainer>
        )}
      </ModalContent>
    </Modal>
  );
};

export default ReviewModal;
