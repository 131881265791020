import React from 'react';
import { useTranslation } from 'react-i18next';

//ASSETS
import LogoImage from 'assets/images/logo.png';

//PROPS-TYPE
import { AppMobileMenuProps, SocialLinksProps } from './AppMobileMenu.props';

//COMPONENTS
import TranslateSelector from 'components/TranslateSelector';
import Icon from 'components/Icon';

//STYLED
import {
  AppMobileMenuContainer,
  AppMobileMenuHeader,
  AppMobileMenuBrand,
  AppMobileMenuLogo,
  AppMobileMenuClose,
  AppMobileMenuContent,
  AppMobileMenuLink,
  AppMobileMenuFooter,
  AppMobileMenuSocial,
  AppMobileMenuNetwork,
} from './AppMobileMenu.styled';

const SOCIAL_LINKS: SocialLinksProps[] = [
  {
    name: 'facebook',
    link: 'https://www.facebook.com/swearitsoftware/',
  },
  {
    name: 'instagram',
    link: 'https://www.instagram.com/swearit_/',
  },
  {
    name: 'youtube',
    link: 'https://www.youtube.com/channel/UC-smPk49x3o90Di00egGQPg',
  },
  {
    name: 'linkedin',
    link: 'https://www.linkedin.com/in/swearit/',
  },
  {
    name: 'twitter',
    link: 'https://twitter.com/swearit_',
  },
];

const AppMobileMenu = ({
  active,
  toggleMenu,
  type,
  id,
}: AppMobileMenuProps) => {
  const { t } = useTranslation('header');

  return (
    <AppMobileMenuContainer active={active}>
      <AppMobileMenuHeader>
        <AppMobileMenuBrand>
          <AppMobileMenuLogo src={LogoImage} />
        </AppMobileMenuBrand>
        <TranslateSelector device="mobile" type={type} id={id} />
        <AppMobileMenuClose onClick={() => toggleMenu()}>
          <Icon iconName="close" />
        </AppMobileMenuClose>
      </AppMobileMenuHeader>

      <AppMobileMenuContent>
        <AppMobileMenuLink href="https://www.swearit.io/" margin="0 0 1rem">
          {t('content.aboutSwearit')}
        </AppMobileMenuLink>
        <AppMobileMenuLink href="https://www.swearit.io/contact">
          {t('content.helpCenter')}
        </AppMobileMenuLink>
      </AppMobileMenuContent>

      <AppMobileMenuFooter>
        <AppMobileMenuSocial>
          {SOCIAL_LINKS.map((network) => (
            <AppMobileMenuNetwork href={network.link} key={network.link}>
              <Icon iconName={network.name} />
            </AppMobileMenuNetwork>
          ))}
        </AppMobileMenuSocial>
        <AppMobileMenuLink href="mailto:hello@swearit.io" margin="0 0 2rem">
          hello@swearit.io
        </AppMobileMenuLink>
        <AppMobileMenuLink href="https://www.swearit.io/legal">
          {t('content.legal')}
        </AppMobileMenuLink>
      </AppMobileMenuFooter>
    </AppMobileMenuContainer>
  );
};

export default AppMobileMenu;
