import React from 'react';
import { ProductTabs as ProductTabsList } from 'types/product';
//COMPONENTS
import ProductTabsTech from './templates/ProductTabs.tech';
import ProductTabsRecommendation from './templates/ProductTabs.recommendation';
import ProductTabsNutrient from './templates/ProductTabs.nutrient';
import ProductTabsIngredient from './templates/ProductTabs.ingredient';
import ProductTabsPdf from './templates/ProductTabs.pdf';
//STYLES
import {
  ProductTabsMobileContainer,
  ProductTabsMobileItem,
  ProductTabsHeader,
  ProductTabsHead,
  ProductTabsBody,
  ProductTabsItem,
} from './ProductTabs.styled';
import TabNutrient from 'screens/Wine/components/Tabs/TabNutrient';

interface ProductTabMobileProps {
  tabs: ProductTabsList[];
  active: string;
  setActive: (pk: string) => void;
}

const ProductTabMobile = ({
  tabs,
  active,
  setActive,
}: ProductTabMobileProps) => {
  return (
    <ProductTabsMobileContainer>
      {tabs.map((tab) => (
        <ProductTabsMobileItem key={tab.pk}>
          <ProductTabsHeader size={tabs.length}>
            <ProductTabsHead active={false} fullWidth>
              {tab.title}
            </ProductTabsHead>
          </ProductTabsHeader>
          <ProductTabsBody>
            <ProductTabsItem active={true}>
              {tab.technicalDetail && (
                <ProductTabsTech tech={tab.technicalDetail} />
              )}
              {tab.ingredientList && (
                <ProductTabsIngredient ingredients={tab.ingredientList} />
              )}
              {tab.fileList && <ProductTabsPdf documents={tab.fileList} />}
              {tab.nutritionFact && (
                <TabNutrient maxWidth="100%" nutrient={tab.nutritionFact} />
              )}
              {tab.recommendationList && (
                <ProductTabsRecommendation
                  recommendations={tab.recommendationList}
                />
              )}
            </ProductTabsItem>
          </ProductTabsBody>
        </ProductTabsMobileItem>
      ))}
    </ProductTabsMobileContainer>
  );
};

export default ProductTabMobile;
