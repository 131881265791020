import styled from 'styled-components';
import { sm } from 'theme/Theme.breackoints';

export const ContainerContent = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  max-width: 76.25rem;
  padding: 0 2.5rem;
  margin: 0 auto;
  box-sizing: border-box;
  ${sm`
    padding: 0 1rem;
  `}
`;
