import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from '@react-hook/media-query';
import ReactGA from 'react-ga4';
import useGetQuery from 'hooks/useGetQuery';
//ASSETS
import placeholderImage from 'assets/images/placeholder255x255.png';
//PROPS TYPE
import { MetaHeadProps } from 'components/MetaHead/MetaHead';
import { OrganizationVars } from 'screens/Organization/Organization.props';
import { OrganizationData } from 'types/organization';
//QUERIES
import { organizationQuery } from 'queries/organization';
//STORE
import { AppState } from 'store';
import { openModal, setTranslations } from 'store/tramslateModal';
import { setOrganizationInfo, setOrganization } from 'store/organization';
import { setMobileNav } from 'store/mobilenav';
//COMPONENTS
import AppLayoutWine from 'module/AppLayoutWine';
import Tags from 'screens/Wine/components/Tags';
import Seals from 'screens/Wine/components/Seals';
import Share from 'screens/Wine/components/Share';
import Pictures from 'screens/Wine/components/Pictures';
import Catalog from './components/Catalog';
import OrganizationSkeleton from 'screens/Organization/components/OrganizationSkeleton';
import RenderHtml from 'components/RenderHtml';
import ProofPointModal from 'components/ProofPointModal';
import ProofPointDetailModal from 'components/ProofPointDetailModal';
import MediaEvicenceModal from 'components/MediaEvidenceModal/MediaEvicenceModal';
import EvidenceModal from 'components/EvidenceModal';
import BlockchainDetailModal from 'components/BlockchainDetailModal';
import ReviewModal from 'components/ReviewModal';
//STYLES
import { AppLayoutWineWrapper } from 'module/AppLayoutWine/AppLayoutWine.styled';
import {
  WineContent,
  WineContentBody,
  WineContentShare,
  WineContentAction,
  WineContentActionBtn,
  WineContentiLink,
  WineContentBodyImages,
  WineContentBodyContainer,
  WineContentBodyInformation,
  WineContentTitle,
  WineContentiSeparator,
  WineContentiParagraph,
  WineContentShareList,
  WineContentShareItem,
  WineContentLimkWeb,
} from 'screens/Wine/Wine.styled';
import TranslateModal from 'components/TranslateModal/TranslateModal';
import Icon from 'components/Icon/Icon';

const SOCIALS: Record<string, string> = {
  facebook: 'fb',
  twitter: 'tw',
  instagram: 'in',
  youtube: 'yb',
  linkedin: 'ln',
};

const Winery = () => {
  const dispatch = useDispatch();
  const query = useGetQuery();
  const { wineryId } = useParams();

  const matches = useMediaQuery('only screen and (max-width: 920px)');
  const matchesMd = useMediaQuery('only screen and (max-width: 768px)');
  const matchesXs = useMediaQuery('only screen and (max-width: 575px)');

  const { translateSelect } = useSelector(
    (state: AppState) => state.translateModal
  );

  const [isPrivate, setIsPrivate] = useState<boolean>(false);
  const [metaInformation, setMetaInformation] = useState<MetaHeadProps>({});

  const { data: resOrganization, loading, error, refetch } = useQuery<
    OrganizationData,
    OrganizationVars
  >(organizationQuery, {
    variables: {
      pk: wineryId,
      language:
        translateSelect && translateSelect.length > 0
          ? translateSelect.find((lang) => lang.idOrganization === wineryId)
              ?.short_name
          : '',
    },
  });

  useEffect(() => {
    if (resOrganization && resOrganization.organization.data === null) {
      setIsPrivate(true);
    }
  }, [resOrganization]);

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname,
      title: 'winery',
    });
  }, []);

  useEffect(() => {
    if (
      resOrganization &&
      resOrganization.organization &&
      resOrganization.organization.data
    ) {
      dispatch(setMobileNav('info'));
      setMetaInformation({
        title: resOrganization.organization.data.name,
        description: resOrganization.organization.data.description,
        image: resOrganization.organization.data.logo.url
          ? resOrganization.organization.data.logo.url
          : '',
        url: window.location.href,
      });
    }
  }, [resOrganization, dispatch]);

  useEffect(() => {
    if (
      resOrganization &&
      resOrganization.organization &&
      resOrganization.organization.data
    ) {
      dispatch(
        setOrganizationInfo({
          name: resOrganization.organization.data.name,
          logo: resOrganization.organization.data.logo.url,
        })
      );
      dispatch(setOrganization(resOrganization.organization.data));
    }
  }, [dispatch, resOrganization]);

  useEffect(() => {
    const selectLanguage = translateSelect.find(
      (lang) => lang.idOrganization === wineryId
    );

    if (
      !selectLanguage &&
      resOrganization &&
      resOrganization.organization &&
      resOrganization.organization.data &&
      resOrganization.organization.data.translationStatus &&
      Object.keys(
        JSON.parse(resOrganization.organization.data.translationStatus)
      ).length > 1
    ) {
      dispatch(
        openModal({
          isOpen: true,
          translations: JSON.parse(
            resOrganization.organization.data.translationStatus
          ),
          isHiddenClose: true,
        })
      );
    } else if (
      selectLanguage &&
      resOrganization &&
      resOrganization.organization &&
      resOrganization.organization.data &&
      resOrganization.organization.data.translationStatus &&
      Object.keys(
        JSON.parse(resOrganization.organization.data.translationStatus)
      ).length > 1
    ) {
      dispatch(
        setTranslations({
          translations: JSON.parse(
            resOrganization.organization.data.translationStatus
          ),
        })
      );
    }
  }, [translateSelect, wineryId, resOrganization, dispatch]);

  const handleRefetchQuery = useCallback(() => {
    refetch();
  }, [refetch]);

  return (
    <AppLayoutWine
      loading={loading}
      skeletonLoader={<OrganizationSkeleton matches={matchesMd} />}
      metas={metaInformation}
      errorType={
        resOrganization &&
        resOrganization.organization &&
        resOrganization.organization.errors
          ? resOrganization.organization.errors.message
          : resOrganization &&
            resOrganization.organization &&
            resOrganization.organization.error
          ? resOrganization.organization.error
          : undefined
      }
      logo={
        resOrganization &&
        resOrganization.organization &&
        resOrganization.organization.data &&
        resOrganization.organization.data.logo &&
        resOrganization.organization.data.logo.url
      }
      type="wine"
      id={wineryId}
    >
      <WineContent>
        <AppLayoutWineWrapper>
          <WineContentBody>
            {!matchesXs &&
              resOrganization &&
              resOrganization.organization &&
              resOrganization.organization.data &&
              resOrganization.organization.data.socialChannels &&
              resOrganization.organization.data.socialChannels.length > 0 && (
                <WineContentShare>
                  <WineContentShareList>
                    {resOrganization.organization.data.socialChannels.map(
                      (social) => (
                        <WineContentShareItem
                          key={social.pk}
                          href={social.url}
                          target="_blank"
                        >
                          {SOCIALS[social.type.toLowerCase()]}
                        </WineContentShareItem>
                      )
                    )}
                  </WineContentShareList>
                </WineContentShare>
              )}

            {resOrganization &&
              resOrganization.organization &&
              resOrganization.organization.data && (
                <WineContentBodyContainer>
                  {!matches && resOrganization.organization.data.headImage && (
                    <WineContentBodyImages>
                      <Pictures
                        images={[
                          resOrganization.organization.data.headImage.url,
                        ]}
                      />
                    </WineContentBodyImages>
                  )}

                  <WineContentBodyInformation>
                    {query.get('wineId') && (
                      <WineContentAction>
                        <WineContentiLink
                          to={{
                            pathname: `/wine/${query.get('wineId')}/`,
                          }}
                          margin="0 0 2rem;"
                        >
                          Volver
                        </WineContentiLink>
                      </WineContentAction>
                    )}

                    <WineContentTitle style={{ margin: '0 0 0.75rem' }}>
                      {resOrganization.organization.data.name}
                    </WineContentTitle>

                    <WineContentiSeparator />

                    {resOrganization.organization &&
                      resOrganization.organization.data && (
                        <div style={{ display: 'flex' }}>
                          <WineContentLimkWeb
                            style={{
                              fontSize: '0.85rem',
                              lineHeight: '0.85rem',
                            }}
                            href={resOrganization.organization.data.url}
                            target="_blank"
                          >
                            <Icon iconName="link" />{' '}
                            {resOrganization.organization.data.url
                              .replace('https://', '')
                              .replace('http://', '')
                              .replace('www.', '')}
                          </WineContentLimkWeb>
                        </div>
                      )}

                    {resOrganization.organization.data.description && (
                      <WineContentiParagraph
                        maxWidth="500px"
                        margin="0 0 1.5rem"
                      >
                        <RenderHtml
                          text={resOrganization.organization.data.description}
                          wine
                        />
                      </WineContentiParagraph>
                    )}

                    {resOrganization.organization.data.proofPoints &&
                      resOrganization.organization.data.proofPoints.length >
                        0 && (
                        <Tags
                          margin="0 0 1.5rem"
                          proofs={resOrganization.organization.data.proofPoints}
                        />
                      )}
                    {resOrganization.organization.data.proofPoints &&
                      resOrganization.organization.data.proofPoints.length >
                        0 && (
                        <Seals
                          margin="0 0 3rem"
                          proofs={resOrganization.organization.data.proofPoints}
                        />
                      )}
                    {matches && resOrganization.organization.data.headImage && (
                      <WineContentBodyImages>
                        <Pictures
                          images={[
                            resOrganization.organization.data.headImage.url,
                          ]}
                        />
                      </WineContentBodyImages>
                    )}

                    <Share
                      type="organization"
                      organization={resOrganization.organization.data}
                    />
                  </WineContentBodyInformation>
                </WineContentBodyContainer>
              )}
          </WineContentBody>
        </AppLayoutWineWrapper>

        <AppLayoutWineWrapper>
          {resOrganization &&
            resOrganization.organization &&
            resOrganization.organization.data &&
            resOrganization.organization.data.products.length > 0 && (
              <Catalog
                products={resOrganization.organization.data.products}
                organizationName={resOrganization.organization.data.name}
              />
            )}
        </AppLayoutWineWrapper>
        <ProofPointModal wine />
        <ProofPointDetailModal wine />
        <MediaEvicenceModal wine />
        <EvidenceModal wine />
        <BlockchainDetailModal type="organization" wine />
        <TranslateModal
          refetch={handleRefetchQuery}
          type="organization"
          id={wineryId}
        />
      </WineContent>
    </AppLayoutWine>
  );
};

export default Winery;
