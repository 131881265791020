import React from 'react';

//ASSETS
import LogoImage from 'assets/images/brand-icon-white.png';

//STYLES
import {
  BlockchainDetailModalLoadingContainer,
  BlockchainDetailModalLoadingImageSee,
  BlockchainDetailModalLoadingImagePulse,
} from './BlockchainDetailModal.styled';

const BlockchainDetailModalLoading = () => {
  return (
    <BlockchainDetailModalLoadingContainer>
      <BlockchainDetailModalLoadingImageSee src={LogoImage} />
      <BlockchainDetailModalLoadingImagePulse src={LogoImage} />
    </BlockchainDetailModalLoadingContainer>
  );
};

export default BlockchainDetailModalLoading;
