import React from 'react';
import { ProductTabs as TabsList } from 'types/product';
//COMPONENTS
import ProductTabsTech from 'components/ProductTabs/templates/ProductTabs.tech';
import ProductTabsRecommendation from 'components/ProductTabs/templates/ProductTabs.recommendation';
import TabNutrient from './TabNutrient';
import ProductTabsIngredient from 'components/ProductTabs/templates/ProductTabs.ingredient';
import ProductTabsPdfs from 'components/ProductTabs/templates/ProductTabs.pdf';
import Icon from 'components/Icon';
//STYLES
import {
  TabsHeader,
  TabsHead,
  TabsHeadIcon,
  TabsBody,
  TabsItem,
  TabsMobileContainer,
  TabsMobileItem,
} from './Tabs.styled';

interface ProductTabDesktopProps {
  tabs: TabsList[];
  active: string;
  setActive: (pk: string) => void;
  mobile?: boolean;
}

const ProductTabDesktop = ({
  tabs,
  active,
  setActive,
  mobile,
}: ProductTabDesktopProps) => {
  return mobile ? (
    <TabsMobileContainer>
      {tabs.map((tab) => (
        <TabsMobileItem key={tab.pk}>
          <TabsHeader size={tabs.length}>
            <TabsHead
              active={active === tab.pk}
              onClick={() => {
                if (!active) {
                  setActive(tab.pk);
                } else {
                  setActive('');
                }
              }}
            >
              {tab.title.toLowerCase()}{' '}
              <TabsHeadIcon>
                <Icon iconName="chevronDown" />
              </TabsHeadIcon>
            </TabsHead>
          </TabsHeader>
          <TabsBody>
            <TabsItem active={active === tab.pk}>
              {tab.technicalDetail && (
                <ProductTabsTech tech={tab.technicalDetail} wine />
              )}
              {tab.ingredientList && (
                <ProductTabsIngredient ingredients={tab.ingredientList} wine />
              )}
              {tab.fileList && (
                <ProductTabsPdfs documents={tab.fileList} wine />
              )}
              {tab.nutritionFact && (
                <TabNutrient nutrient={tab.nutritionFact} />
              )}
              {tab.recommendationList && (
                <ProductTabsRecommendation
                  recommendations={tab.recommendationList}
                />
              )}
            </TabsItem>
          </TabsBody>
        </TabsMobileItem>
      ))}
    </TabsMobileContainer>
  ) : (
    <>
      <TabsHeader size={tabs.length}>
        {tabs.map((tab, index) => (
          <TabsHead
            key={`${tab.pk}-${index}`}
            active={active === tab.pk}
            onClick={() => {
              setActive(tab.pk);
            }}
          >
            {tab.title.toLowerCase()}
          </TabsHead>
        ))}
      </TabsHeader>
      <TabsBody>
        {tabs.map((tab) => (
          <TabsItem key={tab.pk} active={active === tab.pk}>
            {tab.technicalDetail && (
              <ProductTabsTech tech={tab.technicalDetail} wine />
            )}
            {tab.ingredientList && (
              <ProductTabsIngredient ingredients={tab.ingredientList} wine />
            )}
            {tab.fileList && <ProductTabsPdfs documents={tab.fileList} wine />}
            {tab.nutritionFact && <TabNutrient nutrient={tab.nutritionFact} />}
            {tab.recommendationList && (
              <ProductTabsRecommendation
                recommendations={tab.recommendationList}
              />
            )}
          </TabsItem>
        ))}
      </TabsBody>
    </>
  );
};

export default ProductTabDesktop;
