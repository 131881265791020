import React from 'react';

//PROPS TYPE
import { ContainerProps } from './Container.props';

//STYLES
import { ContainerContent } from './Container.styled';

const Container = ({ children }: ContainerProps) => {
  return <ContainerContent>{children}</ContainerContent>;
};

export default Container;
