import React from 'react';
import { useSelector } from 'react-redux';

//PROPS TYPE
import { CustomerReviewProps } from './CustomerReview.props';

//COMPONENTS
import CustomerReviewHeader from './CustomerReviewHeader';

//STORE
import { AppState } from 'store';

//STYLES
import {
  CustomerReviewContainer,
  CustomerReviewComment,
  CustomerReviewReply,
} from './CustomerReview.styled';

const CustomerReview = ({ review }: CustomerReviewProps) => {
  const { organizationName, organizationLogo } = useSelector(
    (state: AppState) => state.organization
  );

  return (
    <CustomerReviewContainer>
      <CustomerReviewHeader
        name={review.node.name}
        date={review.node.createdAt}
        rating={review.node.rating}
      />

      <CustomerReviewComment>
        <strong>
          {review.node.title}
          {' -'}
        </strong>{' '}
        {review.node.comment}
      </CustomerReviewComment>

      {review.node.reply && (
        <CustomerReviewReply>
          <CustomerReviewHeader
            name={organizationName}
            logo={organizationLogo}
            date={review.node.reply.createdAt}
            reply={true}
          />
          <CustomerReviewComment>
            {review.node.reply.comment}
          </CustomerReviewComment>
        </CustomerReviewReply>
      )}
    </CustomerReviewContainer>
  );
};

export default CustomerReview;
