import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isOpen: false,
};

const reviewModalSlice = createSlice({
  name: 'reviewModal',
  initialState,
  reducers: {
    openModal: () => {
      return {
        isOpen: true,
      };
    },
    closeModal: () => {
      return initialState;
    },
  },
});

export const { openModal, closeModal } = reviewModalSlice.actions;

export default reviewModalSlice;
