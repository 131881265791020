import styled from 'styled-components';
import { sm } from 'theme/Theme.breackoints';

export const AppLayoutSkeletonNav = styled.div`
  display: none;
  position: fixed;
  bottom: 0;
  background: #fff;
  width: 100%;
  left: 0;
  right: 0;

  ${sm`
    display: block;
  `}
`;
