import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from '@react-hook/media-query';
import ReactGA from 'react-ga4';

//QUERIES
import { AppState } from 'store';
import { lotQuery } from 'queries/lot';

//STORE
import { setProduct, setLotId, setInternalId } from 'store/product';
import { setOrganizationInfo } from 'store/organization';

//PROPS TYPE
import { MetaHeadProps } from 'components/MetaHead/MetaHead';
import { LotVars } from './Lot.props';
import { LotData } from 'types/lot';

//COMPONENTS
import AppLayout from 'module/AppLayout';
import Container from 'components/Container';
import ProductDesktopView from '../Product/components/ProductDesktopView';
import ProductMobileView from '../Product/components/ProductMobileView';
import ProductSkeleton from '../Product/components/ProductSkeleton';
import ProofPointModal from 'components/ProofPointModal';
import ProofPointDetailModal from 'components/ProofPointDetailModal';
import MediaEvicenceModal from 'components/MediaEvidenceModal';
import EvidenceModal from 'components/EvidenceModal';
import BlockchainDetailModal from 'components/BlockchainDetailModal';
import ReviewModal from 'components/ReviewModal';

const Lot = () => {
  const { lotId } = useParams();

  const dispatch = useDispatch();
  const matches = useMediaQuery('only screen and (max-width: 768px)');

  const [isPrivate, setIsPrivate] = useState<boolean>(false);
  const [metaInformation, setMetaInformation] = useState<MetaHeadProps>({});

  const product = useSelector((state: AppState) => state.product.product);

  const { data: resPorductLot, loading, error } = useQuery<LotData, LotVars>(
    lotQuery,
    {
      variables: { pk: lotId },
    }
  );

  useEffect(() => {
    if (resPorductLot && resPorductLot.productLot.data === null) {
      setIsPrivate(true);
    }
  }, [resPorductLot]);

  useEffect(() => {
    if (
      resPorductLot &&
      resPorductLot.productLot &&
      resPorductLot.productLot.data
    ) {
      setMetaInformation({
        title: resPorductLot.productLot.data.product.name,
        description: resPorductLot.productLot.data.product.description,
        image:
          resPorductLot.productLot.data.product.images.length > 0
            ? resPorductLot.productLot.data.product.images[0].url
            : '',
        url: window.location.href,
      });
    }
  }, [resPorductLot]);

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname,
      title: 'lots',
    });
  }, []);

  useEffect(() => {
    if (
      resPorductLot &&
      resPorductLot.productLot &&
      resPorductLot.productLot.data
    ) {
      dispatch(
        setOrganizationInfo({
          name: resPorductLot.productLot.data.product.organization.name,
          logo: resPorductLot.productLot.data.product.organization.logo.url,
        })
      );
      dispatch(
        setProduct({
          ...resPorductLot.productLot.data.product,
          journey: {
            ...resPorductLot.productLot.data.journey,
          },
          featuredProofPoints: [
            ...resPorductLot.productLot.data.featuredProofPoints,
          ],
          reviewStats: {
            ...resPorductLot.productLot.data.reviewStats,
          },
          reviews: {
            ...resPorductLot.productLot.data.reviews,
          },
        })
      );
      dispatch(setLotId(resPorductLot.productLot.data.pk));
      dispatch(setInternalId(resPorductLot.productLot.data.internalId));
    }
  }, [dispatch, resPorductLot]);

  return (
    <AppLayout
      loading={loading}
      error={error}
      errorType={
        resPorductLot &&
        resPorductLot.productLot &&
        resPorductLot.productLot.errors
          ? resPorductLot.productLot.errors.message
          : resPorductLot &&
            resPorductLot.productLot &&
            resPorductLot.productLot.error
          ? resPorductLot.productLot.error
          : undefined
      }
      metas={metaInformation}
      type="lot"
      id={lotId}
      skeletonLoader={<ProductSkeleton />}
    >
      <>
        <Container>
          <>
            {product && (
              <>
                {matches ? (
                  <ProductMobileView product={product} />
                ) : (
                  <ProductDesktopView product={product} />
                )}
              </>
            )}
          </>
        </Container>
        <MediaEvicenceModal />
        <EvidenceModal />
        <BlockchainDetailModal type="lot" />
        <ProofPointModal />
        <ProofPointDetailModal />
        <ReviewModal type="lot" />
      </>
    </AppLayout>
  );
};

export default Lot;
