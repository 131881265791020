import { createSlice } from '@reduxjs/toolkit';

type Link = {
  secureShortURL: string;
  shortURL: string;
};

interface productStateProps {
  link: Link;
}

const initialState: productStateProps = {
  link: {} as Link,
};

const linkSlice = createSlice({
  name: 'link',
  initialState,
  reducers: {
    setLink: (state: typeof initialState, action) => {
      return {
        ...state,
        link: action.payload,
      };
    },
  },
});

export const { setLink } = linkSlice.actions;

export default linkSlice;
