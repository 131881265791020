import styled from "styled-components";
import Icon from 'components/Icon';
import { Text } from "components/Text";
import { breakpoints } from "theme/Theme.breackoints";

export const ShareContainer = styled.div``

export const ShareShowIcon = styled(Icon)`
  margin-right: 1.5rem;
  svg {
    width: 24px;
  }
`
export const ShareShowText = styled(Text)`
  font-size: 1rem;
  line-height: 1rem;
  @media screen and (max-width: ${breakpoints.lg}px) {
    font-size: 0.875rem;
    line-height: 0.875rem;
  }
`

export const ShareShowSocials = styled.div<{visible?: boolean;}>`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0 1.5rem;
  position: relative;
  left: -2rem;
  width: 0;
  height: 0;
  visibility: hidden;
  overflow: hidden;
  opacity: 0;
  transition: opacity 0.25s ease-in-out, left 0.25s ease-in-out;
  ${({ visible }) => visible && `
    opacity: 1;
    visibility: visible;
    left: 0;
    width: auto;
    height: auto;
  `}
  
  @media screen and (max-width: ${breakpoints.lg}px) {
    gap: 1.5rem;
  }
`
export const ShareShowSocial = styled.a`
  font-size: 1rem;
  line-height: 1rem;
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.colors.winePrimary};
  }
  @media screen and (max-width: ${breakpoints.lg}px) {
    font-size: 0.875rem;
    line-height: 0.875rem;
  }
`

export const ShareShowSocialClose = styled(ShareShowSocial)``

export const ShareShow = styled.div<{visible?: boolean;}>`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    ${ShareShowIcon} {
      color: ${({ theme }) => theme.colors.winePrimary};
    }
    ${ShareShowText} {
      color: ${({ theme }) => theme.colors.winePrimary};
    }
  }

  ${({ visible, theme}) => visible && `
    ${ShareShowIcon} {
      color: ${theme.colors.winePrimary};
    }
    ${ShareShowText} {
      display: none;
    }
  `}
`