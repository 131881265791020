import React from 'react';

//PROPS TYPE
import { SectionHeaderProps } from './SectionHeader.props';

//STYLES
import {
  SectionHeaderContainer,
  SectionHeaderText,
} from './SectionHeader.styled';

const SectionHeader = ({ text }: SectionHeaderProps) => {
  return (
    <SectionHeaderContainer>
      <SectionHeaderText>{text}</SectionHeaderText>
    </SectionHeaderContainer>
  );
};

export default SectionHeader;
