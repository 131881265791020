import styled from "styled-components";
import { Text } from "components/Text";
import { breakpoints } from "theme/Theme.breackoints";

export const TransitionsContaier = styled.div`
  width: 100%;
  max-width: 1230px;
  margin: 0 auto;
`
export const TransitionsTitle = styled.div`
  font-size: 32px;
  line-height: 32px;
  margin: 0 0 2.5rem;
`
export const TransitionsList = styled.div`
  gap: 0 1.5rem;
  max-width: 100%;
  overflow: auto;
  padding-bottom: 1.125rem;
  display: grid;
  grid-template: 1fr / repeat(3, 1fr);
  @media screen and (max-width: ${breakpoints.md}px) {
    grid-template: 1fr / repeat(2, 1fr);
  }
  @media screen and (max-width: ${breakpoints.xs}px) {
    grid-template: 1fr / 1fr;
  }
`
export const TransitionsItemColumn = styled.div``
export const TransitionsItem = styled.div`
  width: 100%;
  max-width: 540px;
  position: relative;
  flex: 1 0 auto;
  margin: 0 0 2.5rem;
`
export const TransitionsItemDate = styled(Text)`
  font-family: ${({ theme }) => theme.fonts.main};
  font-size: 14px;
  line-height: 14px;
  color: ${({ theme }) => theme.colors.wineGray};
  margin: 0 0 1.125rem;
`
export const TransitionsItemTitle = styled(Text)`
  font-size: 1.5rem;
  line-height: 2rem;
  color: ${({ theme }) => theme.colors.wineBlack};
  margin: 0 0 0.75rem;
  max-width: 78%;
`
export const TransitionsItemParagraph = styled(Text)`
  font-family: ${({ theme }) => theme.fonts.main};
  font-size: 0.875rem;
  line-height: 21px;
  color: ${({ theme }) => theme.colors.wineGray};
  margin: 0 0 0.875rem;
`
export const TransitionsItemImage = styled.img`
  max-width: 250px;
  width: 100%;
  object-fit: cover;
  object-position: center;
  max-height: 140px;
  @media screen and (max-width: ${breakpoints.md}px) {
    height: 140px;
  }
  @media screen and (max-width: ${breakpoints.xs}px) {
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }
`