import styled from 'styled-components';

export const ProductImagesContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin: 0 0 2rem;
  overflow: auto;
  padding: 1rem 0 1rem;

  &::-webkit-scrollbar {
    height: 8px;
    background-color: transparent;
    transition: all 0.3s ease-in-out;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
    transition: all 0.3s ease-in-out;
  }
  &::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 6px;
    transition: all 0.3s ease-in-out;
  }

  &:hover::-webkit-scrollbar {
    background-color: #f5f5f5;
  }
  &:hover::-webkit-scrollbar-track {
    background-color: ${({ theme }) => theme.colors.backgroundGrayLight};
  }
  &:hover::-webkit-scrollbar-thumb {
    background-image: ${({ theme }) => theme.colors.buttonGradient};
    border-radius: 6px;
  }
`;
export const ProductImagesPicture = styled.img`
  height: 17rem;
  margin: 0 1rem 0 0;
  object-fit: contain;
`;
