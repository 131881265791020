import React from 'react';

//COMPONENTS
import SkeletonLoader from 'components/SkeletonLoader';

const SkeletonSealsItem = () => {
  return (
    <SkeletonLoader
      width="100%"
      height="auto"
      border={{
        full: '1px solid #EDEDEE',
      }}
      padding="1.25rem 1.5rem"
      maxWidth="8.5rem"
      margin="0 1rem 0 0"
      flex={{
        flexGroup: '1 0 auto',
      }}
      background="transparent"
    >
      <SkeletonLoader width="3.5rem" height="3.5rem" margin="0 0 1rem " />
      <SkeletonLoader width="100%" height="0.75rem" margin="0 0 0.125rem" />
      <SkeletonLoader width="100%" height="0.75rem" margin="0 0 0.125rem" />
      <SkeletonLoader width="100%" height="0.75rem" />
    </SkeletonLoader>
  );
};

export default SkeletonSealsItem;
