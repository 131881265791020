import React from 'react';
import { LinkProps } from 'react-router-dom';

//PROPS TYPE
import { LinkOwnProps } from './Link.props';

//STYLES
import { LinkStyled } from './Link.styled';

const Link = ({ to, children, ...rest }: LinkProps & LinkOwnProps) => {
  return (
    <LinkStyled to={to} {...rest}>
      {children}
    </LinkStyled>
  );
};

export default Link;
