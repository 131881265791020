import momment from 'moment';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

//STORE
import { AppState } from 'store';

//PROPS TYPE
import { AppHeaderType } from 'module/AppHeader/AppHeader.props';
import { ProofBlock } from 'types/blocknitive';

//COMPONENTS
import HashPlaceholder from 'components/HashPlaceholder';

//STYLES
import {
  BlockchainDetailModalBlock,
  BlockchainDetailModalBlockDate,
  BlockchainDetailModalBlockText,
  BlockchainDetailModalBlockTime,
  BlockchainDetailModalClickElement,
  BlockchainDetailModalCondition,
  BlockchainDetailModalContentContainer,
  BlockchainDetailModalContentGrid,
  BlockchainDetailModalContentGridItem,
  BlockchainDetailModalContentLabel,
  BlockchainDetailModalContentSeparator,
  BlockchainDetailModalContentText,
  BlockchainDetailModalContentTitle,
  BlockchainDetailModalContentWrapper,
  BlockchainDetailModalTable,
  BlockchainDetailModalTableHead,
} from './BlockchainDetailModal.styled';

interface BlockchainDetailModalContentProps {
  proofBlockData: ProofBlock;
  type: AppHeaderType;
  deguelleDate?: string | null;
}

const BlockchainDetailModalContent = ({
  proofBlockData,
  type,
  deguelleDate,
}: BlockchainDetailModalContentProps) => {
  const { t } = useTranslation('modalBlockChain');

  const location = useLocation();

  const { proof, isStatic } = useSelector(
    (state: AppState) => state.blockchainDetailModal
  );
  const { blockStats } = useSelector((state: AppState) => state.blockStats);
  const { product } = useSelector((state: AppState) => state.product);
  const { organization } = useSelector((state: AppState) => state.organization);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [proofOne, proofTwo, ...conditions] = proof?.conditions ?? [];

  const showMoreConditions = () => {
    setIsOpen(!isOpen);
  };

  // get certificateId from url query params
  const query = new URLSearchParams(location.search);
  const certificateId = query.get('certificateId');

  return (
    <BlockchainDetailModalContentContainer>
      <BlockchainDetailModalContentGridItem gridArea="areaone">
        <BlockchainDetailModalContentWrapper>
          <BlockchainDetailModalContentTitle>
            {t('content.function')}
          </BlockchainDetailModalContentTitle>
          <BlockchainDetailModalContentLabel>
            {t('content.name')}
          </BlockchainDetailModalContentLabel>
          <BlockchainDetailModalContentText>
            getProof
          </BlockchainDetailModalContentText>
        </BlockchainDetailModalContentWrapper>
        <BlockchainDetailModalContentSeparator />
        <BlockchainDetailModalContentGrid>
          <BlockchainDetailModalContentWrapper>
            <BlockchainDetailModalContentTitle>
              {t('content.createAt')}
            </BlockchainDetailModalContentTitle>
            {!isStatic && (
              <>
                <BlockchainDetailModalContentLabel>
                  {t('content.time')}
                </BlockchainDetailModalContentLabel>
                <BlockchainDetailModalContentText separate>
                  {momment(proof?.createdAt).format('h:mm:ss a')}
                </BlockchainDetailModalContentText>
              </>
            )}
            <BlockchainDetailModalContentLabel>
              {t('content.date')}
            </BlockchainDetailModalContentLabel>
            <BlockchainDetailModalContentText>
              {momment(proof?.createdAt)?.format('DD/MM/YY') || '23/04/2892'}
            </BlockchainDetailModalContentText>
            {isStatic && (
              <>
                <br />
                <BlockchainDetailModalContentLabel>
                  {t('content.etiDate')}
                </BlockchainDetailModalContentLabel>
                <BlockchainDetailModalContentText>
                  {momment(deguelleDate)?.format('DD/MM/YY') || '23/04/2892'}
                </BlockchainDetailModalContentText>
              </>
            )}
          </BlockchainDetailModalContentWrapper>
          {proof?.conditions.length > 0 && (
            <BlockchainDetailModalContentWrapper>
              <BlockchainDetailModalContentTitle>
                {t('content.conditions')}
              </BlockchainDetailModalContentTitle>
              {proofOne && (
                <BlockchainDetailModalCondition>
                  <BlockchainDetailModalContentLabel>
                    {proofOne?.typeDisplay}
                  </BlockchainDetailModalContentLabel>
                  <BlockchainDetailModalContentText>
                    {proofOne?.value}
                    {proofOne?.unit ? ` ${proofOne?.unit}` : ''}
                  </BlockchainDetailModalContentText>
                </BlockchainDetailModalCondition>
              )}
              {proofTwo && (
                <BlockchainDetailModalCondition>
                  <BlockchainDetailModalContentLabel>
                    {proofTwo?.typeDisplay}
                  </BlockchainDetailModalContentLabel>
                  <BlockchainDetailModalContentText>
                    {proofTwo?.value}
                    {proofTwo?.unit ? ` ${proofTwo?.unit}` : ''}
                  </BlockchainDetailModalContentText>
                </BlockchainDetailModalCondition>
              )}
              {isOpen &&
                conditions.map(
                  ({ typeDisplay, unit, value }: any, index: any) => {
                    return (
                      <BlockchainDetailModalCondition key={index}>
                        <BlockchainDetailModalContentLabel>
                          {typeDisplay}
                        </BlockchainDetailModalContentLabel>
                        <BlockchainDetailModalContentText>
                          {value}
                          {unit ? ` ${unit}` : ''}
                        </BlockchainDetailModalContentText>
                      </BlockchainDetailModalCondition>
                    );
                  }
                )}
              {conditions.length > 0 && (
                <BlockchainDetailModalClickElement onClick={showMoreConditions}>
                  {!isOpen
                    ? t('content.seeMoreConditions')
                    : t('content.seeLessConditions')}
                </BlockchainDetailModalClickElement>
              )}
            </BlockchainDetailModalContentWrapper>
          )}
        </BlockchainDetailModalContentGrid>
      </BlockchainDetailModalContentGridItem>
      <BlockchainDetailModalContentGridItem gridArea="areatwo">
        {(type === 'product' || type === 'lot') && (
          <BlockchainDetailModalContentWrapper separate>
            <BlockchainDetailModalContentLabel>
              {t('content.productName')}
            </BlockchainDetailModalContentLabel>
            <BlockchainDetailModalContentText>
              {product.name}
            </BlockchainDetailModalContentText>
          </BlockchainDetailModalContentWrapper>
        )}
        {!isStatic && type === 'organization' && (
          <BlockchainDetailModalContentWrapper separate>
            <BlockchainDetailModalContentLabel>
              {t('content.organizationName')}
            </BlockchainDetailModalContentLabel>
            <BlockchainDetailModalContentText>
              {organization.name}
            </BlockchainDetailModalContentText>
          </BlockchainDetailModalContentWrapper>
        )}
        {!isStatic && proofBlockData && proofBlockData?.transactionId && (
          <BlockchainDetailModalContentWrapper separate>
            <BlockchainDetailModalContentLabel>
              {t('content.taxId')}
            </BlockchainDetailModalContentLabel>
            <BlockchainDetailModalContentText>
              {proofBlockData?.transactionId}
            </BlockchainDetailModalContentText>
          </BlockchainDetailModalContentWrapper>
        )}
        {/* {!isStatic && (
          <> */}
        {(type === 'product' || type === 'lot') && (
          <BlockchainDetailModalContentWrapper>
            <BlockchainDetailModalContentLabel>
              {t('content.productId')}
            </BlockchainDetailModalContentLabel>
            <BlockchainDetailModalContentText>
              {product?.pk}
            </BlockchainDetailModalContentText>
          </BlockchainDetailModalContentWrapper>
        )}
        {/* </>
        )} */}
        {isStatic && (
          <>
            <br />
            {(type === 'product' || type === 'lot') && (
              <BlockchainDetailModalContentWrapper>
                <BlockchainDetailModalContentLabel>
                  {t('content.bottleId')}
                </BlockchainDetailModalContentLabel>
                <BlockchainDetailModalContentText>
                  {certificateId || ''}
                </BlockchainDetailModalContentText>
              </BlockchainDetailModalContentWrapper>
            )}
          </>
        )}
        {type === 'organization' && (
          <BlockchainDetailModalContentWrapper>
            <BlockchainDetailModalContentLabel>
              {t('content.organizationId')}
            </BlockchainDetailModalContentLabel>
            <BlockchainDetailModalContentText>
              {organization?.pk}
            </BlockchainDetailModalContentText>
          </BlockchainDetailModalContentWrapper>
        )}
        {!isStatic && (
          <>
            <BlockchainDetailModalContentSeparator />
            <BlockchainDetailModalContentWrapper separate>
              <BlockchainDetailModalContentLabel>
                {t('content.proofName')}
              </BlockchainDetailModalContentLabel>
              <BlockchainDetailModalContentText>
                {proof?.name}
              </BlockchainDetailModalContentText>
            </BlockchainDetailModalContentWrapper>
            {/* {(type === 'product' || type === 'lot') && (
          <BlockchainDetailModalContentWrapper>
            <BlockchainDetailModalContentLabel>
              {t('content.productId')}
              </BlockchainDetailModalContentLabel>
              <BlockchainDetailModalContentText>
                {product?.pk}
              </BlockchainDetailModalContentText>
            </BlockchainDetailModalContentWrapper>
            )}{' '} */}
            <BlockchainDetailModalContentWrapper separate>
              <BlockchainDetailModalContentLabel>
                {t('content.proofId')}
              </BlockchainDetailModalContentLabel>
              <BlockchainDetailModalContentText>
                {proof?.pk}
              </BlockchainDetailModalContentText>
            </BlockchainDetailModalContentWrapper>

            {proof?.evidences.length > 0 && (
              <BlockchainDetailModalContentWrapper>
                <BlockchainDetailModalContentLabel>
                  {t('content.evidences')}
                </BlockchainDetailModalContentLabel>
                {proof?.evidences?.map((evidence, index) => {
                  return (
                    <BlockchainDetailModalContentText
                      key={index}
                      separate={index + 1 !== proof?.evidences?.length}
                    >
                      {evidence.pk}
                    </BlockchainDetailModalContentText>
                  );
                })}
              </BlockchainDetailModalContentWrapper>
            )}
          </>
        )}
      </BlockchainDetailModalContentGridItem>
      <BlockchainDetailModalContentGridItem gridArea="areathree">
        {!isStatic &&
          proofBlockData &&
          proofBlockData.blocknitive &&
          proofBlockData.blocknitive.header &&
          proofBlockData.blocknitive.data.length > 0 &&
          proofBlockData.blocknitive.data[0].header && (
            <>
              <BlockchainDetailModalContentTitle>
                {t('content.hasgProofPoint')}
              </BlockchainDetailModalContentTitle>
              <BlockchainDetailModalTable>
                <BlockchainDetailModalTableHead>
                  {t('content.height')}
                </BlockchainDetailModalTableHead>
                <BlockchainDetailModalTableHead>
                  {t('content.hash')}
                </BlockchainDetailModalTableHead>
                <BlockchainDetailModalTableHead>
                  {t('content.minedOn')}
                </BlockchainDetailModalTableHead>
              </BlockchainDetailModalTable>
              <BlockchainDetailModalBlock>
                <BlockchainDetailModalBlockText>
                  {proofBlockData.blockId}
                </BlockchainDetailModalBlockText>
                <HashPlaceholder
                  taxId={proofBlockData.blocknitive.header.dataHash}
                />
                <BlockchainDetailModalBlockDate>
                  {momment(
                    proofBlockData.blocknitive.data[0].header.timestamp
                  ).format('YY/MM/DD')}
                  <BlockchainDetailModalBlockTime>
                    {momment(
                      proofBlockData.blocknitive.data[0].header.timestamp
                    ).format('h:mm a')}
                  </BlockchainDetailModalBlockTime>
                </BlockchainDetailModalBlockDate>
              </BlockchainDetailModalBlock>
              <BlockchainDetailModalContentSeparator />
            </>
          )}

        <BlockchainDetailModalContentTitle>
          {t('content.lastestBlocks')}
        </BlockchainDetailModalContentTitle>
        <BlockchainDetailModalTable>
          <BlockchainDetailModalTableHead>
            {t('content.height')}
          </BlockchainDetailModalTableHead>
          <BlockchainDetailModalTableHead>
            {t('content.hash')}
          </BlockchainDetailModalTableHead>
          <BlockchainDetailModalTableHead>
            {t('content.minedOn')}
          </BlockchainDetailModalTableHead>
        </BlockchainDetailModalTable>
        {blockStats &&
        blockStats.data &&
        blockStats.data.blocknitiveStats &&
        blockStats.data.blocknitiveStats.blocks &&
        blockStats.data.blocknitiveStats.blocks.length > 0 ? (
          <>
            {blockStats.data.blocknitiveStats.blocks.map(
              ({ header: { dataHash, number }, data }, index: any) => {
                let timestampValue = data[0].header.timestamp;
                return (
                  <BlockchainDetailModalBlock key={dataHash}>
                    <BlockchainDetailModalBlockText>
                      {number}
                    </BlockchainDetailModalBlockText>
                    <HashPlaceholder taxId={dataHash} />
                    <BlockchainDetailModalBlockDate>
                      {momment(timestampValue).format('YY/MM/DD')}
                      <BlockchainDetailModalBlockTime>
                        {momment(timestampValue).format('h:mm a')}
                      </BlockchainDetailModalBlockTime>
                    </BlockchainDetailModalBlockDate>
                  </BlockchainDetailModalBlock>
                );
              }
            )}
          </>
        ) : (
          <BlockchainDetailModalBlockText>
            {t('content.anyLastOperation')}
          </BlockchainDetailModalBlockText>
        )}
      </BlockchainDetailModalContentGridItem>
      <BlockchainDetailModalContentGridItem gridArea="areafour">
        <BlockchainDetailModalContentGrid>
          <BlockchainDetailModalContentWrapper>
            <BlockchainDetailModalContentTitle>
              {t('content.response')}
            </BlockchainDetailModalContentTitle>
            <BlockchainDetailModalContentLabel>
              {t('content.status')}
            </BlockchainDetailModalContentLabel>
            <BlockchainDetailModalContentText separate>
              200
            </BlockchainDetailModalContentText>
          </BlockchainDetailModalContentWrapper>
          <BlockchainDetailModalContentWrapper>
            <BlockchainDetailModalContentTitle>
              {t('content.results')}
            </BlockchainDetailModalContentTitle>
            <BlockchainDetailModalContentLabel>
              {t('content.verification')}
            </BlockchainDetailModalContentLabel>
            <BlockchainDetailModalContentText separate>
              {t('content.valid')}
            </BlockchainDetailModalContentText>
          </BlockchainDetailModalContentWrapper>
        </BlockchainDetailModalContentGrid>
      </BlockchainDetailModalContentGridItem>
    </BlockchainDetailModalContentContainer>
  );
};

export default BlockchainDetailModalContent;
