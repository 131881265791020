import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { useDispatch } from 'react-redux';
import ReactGA from 'react-ga4';
//QUERIES
import { widgetsSpreadQuery } from 'queries/widgets';
//STORE
import { setProduct } from 'store/product';
import { setOrganizationInfo } from 'store/organization';
import { openModal } from 'store/proofPointModal';
//PROPS TYPE
import { WidgetSpreadVars } from './SpreadWidget.props';
import { WidgetSpreadData } from 'types/widgetsSpread';
//COMPONENTS
import BlockchainDetailModal from 'components/BlockchainDetailModal';
import ProofPointDetailModal from 'components/ProofPointDetailModal';
import ProofPointModal from 'components/ProofPointModal';
import Loader from 'components/Loader';
import MediaEvicenceModal from 'components/MediaEvidenceModal';
import EvidenceModal from 'components/EvidenceModal';
//SCREENS
import Error404 from 'screens/Error404';

//STYLES
import { SpreadWidgetContainer } from './SpreadWidget.styled';

const SpreadWidget = () => {
  const { proofId } = useParams();

  const dispatch = useDispatch();

  const [isSendEvent, setIsSendEvent] = useState<boolean>(true);

  const { data, loading, error } = useQuery<WidgetSpreadData, WidgetSpreadVars>(
    widgetsSpreadQuery,
    {
      variables: { id: proofId },
    }
  );

  useEffect(() => {
    if (data && data.widget) {
      if (data.widget.product) {
        dispatch(
          setOrganizationInfo({
            name: data.widget.product.organization.name,
            logo: data.widget.product.organization.logo.url,
          })
        );
        dispatch(setProduct(data.widget.product));
      }

      if (data.widget.organization) {
        dispatch(
          setOrganizationInfo({
            name: data.widget.organization.name,
            logo: data.widget.organization.logo.url,
          })
        );
      }

      dispatch(
        openModal({
          proof: data.widget,
        })
      );
    }
  }, [dispatch, data]);

  useEffect(() => {
    if (isSendEvent && data && data.widget) {
      setIsSendEvent(false);
      handleEvent(data.widget.pk);
    }
  }, [isSendEvent, data]);

  const handleEvent = async (id: string) => {
    await ReactGA.event({
      category: 'Page Click',
      action: 'Shared Widget Click',
      label: `proof_point/${id}`,
    });
  };

  if (error) {
    return <Error404 />;
  }

  return (
    <SpreadWidgetContainer>
      {loading ? (
        <Loader />
      ) : (
        <>
          <MediaEvicenceModal />
          <EvidenceModal />
          <ProofPointModal hiddeClose />
          <ProofPointDetailModal />
          {data && data.widget && data.widget.organization && (
            <BlockchainDetailModal type="organization" />
          )}
          {data && data.widget && data.widget.product && (
            <BlockchainDetailModal type="product" />
          )}
        </>
      )}
    </SpreadWidgetContainer>
  );
};

export default SpreadWidget;
