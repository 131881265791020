import React from 'react';
import { useTranslation } from 'react-i18next';

//PROPS TYPE
import { MilestoneListProps } from './MilestoneList.props';

//COMPONENTS
import Milestone from './Milestone';

//STYLED
import {
  MilestoneListContainer,
  MilestoneLisEmpty,
} from './MilestoneList.styled';

const MilestoneList = ({ milestones }: MilestoneListProps) => {
  const { t: productTranslation } = useTranslation('product');

  return (
    <MilestoneListContainer>
      {milestones.length > 0 ? (
        milestones.map((milestone) => (
          <Milestone key={milestone.id} milestone={milestone} />
        ))
      ) : (
        <MilestoneLisEmpty>
          {productTranslation('content.productEmptyMilestones')}
        </MilestoneLisEmpty>
      )}
    </MilestoneListContainer>
  );
};

export default MilestoneList;
