import styled, { css } from 'styled-components';

import { Text } from 'components/Text';

import { AvatarSize } from './Avatar.props';
import { Colors, isColor } from 'theme/Theme.styled';
function fontSize(size: AvatarSize) {
  switch (size) {
    case 'small':
      return 14;
    case 'tiny':
      return 10;
    case 'medium':
      return 16;
    case 'large':
      return 18;
    default:
      return size / 3;
  }
}

function getSize(size: AvatarSize) {
  switch (size) {
    case 'tiny':
      return 27;
    case 'small':
      return 56;
    case 'medium':
      return 81;
    case 'large':
      return 100;
  }
}

const AVATAR_STYLE = css<{ size: AvatarSize; color?: Colors | string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  ${({ size }) => `
		width: ${typeof size === 'string' ? getSize(size) : size}px;
		height: ${typeof size === 'string' ? getSize(size) : size}px;
	`}
`;

export const AvatarText = styled(Text)`
  font-weight: bold;
  text-transform: uppercase;
`;

export const AvatarImage = styled.img`
  ${AVATAR_STYLE}
  object-fit: cover;
  object-position: center;
`;

export const AvatarIcon = styled.div<{
  size: AvatarSize;
  color: Colors | string;
}>`
  background-color: ${({ theme, color }) =>
    isColor(color) ? theme.colors[color] : color};
  color: ${({ theme }) => theme.colors.white};
  ${AVATAR_STYLE}

  ${AvatarText} {
    font-size: ${({ size }) =>
      `${typeof size === 'string' ? fontSize(size) : size / 3}px`};
  }
`;
