import React, { useRef, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
//STORE
import { AppState } from 'store';
//UTILS
import {
  LANGUAGE_MAP,
  LenguageMapProp,
} from 'components/ProductTabs/templates/ProductLanguage';
//PROPS TYPE
import { SelectLanguageProps } from './SelectLanguage.props';
//STYLES
import {
  SelectLanguageContainer,
  SelectLanguageLabel,
  SelectLanguageInputBox,
  SelectLanguageInput,
  SelectLanguageInputSelect,
  SelectLanguageInputChevron,
  SelectLanguageOptions,
  SelectLanguageOption,
  SelectLanguageOptionName,
  SelectLanguageOptionFlag,
} from './SelectLanguage.styled';
import Icon from 'components/Icon/Icon';

interface IProps extends SelectLanguageProps {
  data: LenguageMapProp[];
  defaultLang: string | null;
}

const SelectLanguageComponent = ({
  label,
  placeholder,
  onSelect,
  data,
  defaultLang = 'en',
}: IProps) => {
  const inputField = useRef<HTMLDivElement>(null);

  const { translateSelect } = useSelector(
    (state: AppState) => state.translateModal
  );

  const { pk, translationStatus } = useSelector(
    (state: AppState) => state.product.product
  );

  const [languageSelect, setLanguageSelect] = useState<
    LenguageMapProp | undefined
  >(LANGUAGE_MAP.find((lang) => lang.shortName === defaultLang));
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleOnBlur = () => {
    setIsOpen(false);
    if (inputField.current) inputField.current.blur();
  };

  const handleOnClick = () => {
    if (isOpen) {
      handleOnBlur();
    } else {
      setIsOpen(true);
      if (inputField.current) inputField.current.focus();
    }
  };

  const handleClickOption = (
    event: React.MouseEvent<HTMLDivElement>,
    language: LenguageMapProp
  ) => {
    event.stopPropagation();
    setLanguageSelect(language);
    handleOnBlur();
    if (onSelect) onSelect(language);
  };

  useEffect(() => {
    const languagueSelected = translateSelect.filter(
      (select) => select.idProduct === pk
    );

    if (pk && languagueSelected.length > 0) {
      const languageFound = LANGUAGE_MAP.find(
        (lang) => lang.shortName === languagueSelected[0].short_name
      );

      if (languageFound) {
        setLanguageSelect(languageFound);
      }
    }
  }, [translateSelect, pk]);

  const _LANGUAGE_MAP: LenguageMapProp[] = data || LANGUAGE_MAP;

  return (
    <div className="border-2 border-gray-100 p-1 rounded-md mr-2">
      <SelectLanguageContainer>
        {label && (
          <SelectLanguageLabel htmlFor="searchInput">
            {label}
          </SelectLanguageLabel>
        )}
        <SelectLanguageInputBox
          tabIndex={1}
          onClick={handleOnClick}
          onBlur={handleOnBlur}
          ref={inputField}
          isOpen={isOpen}
        >
          <SelectLanguageInput>
            {languageSelect ? (
              <SelectLanguageInputSelect>
                <SelectLanguageOptionFlag src={languageSelect.flag} />
                <SelectLanguageOptionName>
                  {languageSelect.name}
                </SelectLanguageOptionName>
              </SelectLanguageInputSelect>
            ) : (
              placeholder
            )}
          </SelectLanguageInput>
          <SelectLanguageInputChevron>
            <Icon iconName="chevronDown" />
          </SelectLanguageInputChevron>
          {isOpen && (
            <SelectLanguageOptions>
              {_LANGUAGE_MAP?.map((language) => (
                <SelectLanguageOption
                  key={language.shortName}
                  onClick={(e) => {
                    handleClickOption(e, language);
                  }}
                  active={
                    language && language.shortName === languageSelect?.shortName
                  }
                >
                  <SelectLanguageOptionFlag src={language.flag} />
                  <SelectLanguageOptionName>
                    {language.name}
                  </SelectLanguageOptionName>
                </SelectLanguageOption>
              ))}
            </SelectLanguageOptions>
          )}
        </SelectLanguageInputBox>
      </SelectLanguageContainer>
    </div>
  );
};

export default SelectLanguageComponent;
