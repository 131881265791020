import React from 'react';

//PROPS TYPE
import { AppLayoutSkeletonProps } from './AppLayoutSkeleton.props';

//COMPONENTS
import SkeletonLoader from 'components/SkeletonLoader';
import Container from 'components/Container';

//SSTYLES
import { AppLayoutSkeletonNav } from './AppLayoutSkeleton.styles';

const AppLayoutSkeleton = ({ children, matches }: AppLayoutSkeletonProps) => {
  return (
    <SkeletonLoader
      width="100%"
      height="auto"
      minHeight="100vh"
      display="flex"
      flex={{
        direction: 'column',
      }}
      background="transparent"
    >
      <SkeletonLoader
        width="100%"
        height="5rem"
        border={{
          bottom: '1px solid #EDEDEE',
        }}
        background="transparent"
      >
        <SkeletonLoader
          width="100%"
          maxWidth={matches ? 'calc(100% - 2rem)' : 'calc(100% - 5rem)'}
          height="100%"
          margin="0 auto"
          display="flex"
          flex={{
            justify: 'space-between',
            align: 'center',
          }}
          background="transparent"
          overflow="hidden"
        >
          <SkeletonLoader
            width="auto"
            height="auto"
            display="flex"
            flex={{
              align: 'center',
            }}
            background="transparent"
          >
            <SkeletonLoader
              width="2.8125rem"
              height="2.8125rem"
              radius="2.8125rem"
            />
            <SkeletonLoader
              width="18.75rem"
              height="1.125rem"
              margin="0 0 0 1rem"
            />
          </SkeletonLoader>
        </SkeletonLoader>
      </SkeletonLoader>
      <SkeletonLoader
        width="100%"
        height="auto"
        flex={{
          flexGroup: '1',
        }}
        background="transparent"
      >
        <Container>{children}</Container>
      </SkeletonLoader>
      <AppLayoutSkeletonNav>
        <SkeletonLoader
          width="100%"
          height="auto"
          padding="1.625rem 0.75rem"
          display="flex"
          flex={{
            align: 'center',
            justify: 'space-evenly',
          }}
          border={{
            top: '1px solid #EDEDEE',
          }}
          background="transparent"
        >
          <SkeletonLoader width="4.5rem" height="1rem" display="block" />
          <SkeletonLoader width="5rem" height="1rem" display="block" />
          <SkeletonLoader width="6rem" height="1rem" display="block" />
        </SkeletonLoader>
      </AppLayoutSkeletonNav>
    </SkeletonLoader>
  );
};

export default AppLayoutSkeleton;
