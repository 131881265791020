import styled from "styled-components";
import { Text } from "components/Text";
import { breakpoints } from "theme/Theme.breackoints";

export const CycleLifeContaier = styled.div`
  width: 100%;
  max-width: calc(calc(1230px + calc(calc(100% - 1230px) / 2)) - calc(2rem + 4.5vw));
  margin: 0 0 0 auto;
  @media screen and (max-width: ${breakpoints.lg}px) {
    max-width: 100%;
    padding-left: calc(4rem + 4.5vw);
  }
  @media screen and (max-width: ${breakpoints.xs}px) {
    padding: 0 2rem;
  }

`
export const CycleLifeTitle = styled.div`
  font-size: 32px;
  line-height: 32px;
  margin: 0 0 2.5rem;
`
export const CycleLifeListBox = styled.div`
  width: 100%;
  position: relative;
`
export const CycleLifeList = styled.div`
  display: flex;
  gap: 0 2.5rem;
  max-width: 100%;
  overflow: auto;
  padding-right: 5rem;
  padding-bottom: 1.125rem;
  scroll-behavior: smooth;
  &::-webkit-scrollbar {
    height: 8px;
    background-color: transparent;
    transition: all 0.3s ease-in-out;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
    transition: all 0.3s ease-in-out;
  }
  &::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 6px;
    transition: all 0.3s ease-in-out;
  }

  &:hover::-webkit-scrollbar {
    background-color: #f5f5f5;
  }
  &:hover::-webkit-scrollbar-track {
    background-color: ${({ theme }) => theme.colors.backgroundGrayLight};
  }
  &:hover::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.wineGray};
    cursor: pointer;
    border-radius: 6px;
  }
  @media screen and (max-width: ${breakpoints.xs}px) {
    flex-direction: column;
    padding: 0;
  }
`
export const CycleLifeListOverlay = styled.div`
  position: absolute;
  height: 100%;
  width: 140px;
  z-index: 9;
  top: 0;
  bottom: 0;
  right: 0;
  background: linear-gradient(270deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 15%, rgba(255,255,255,0) 90%);
  @media screen and (max-width: ${breakpoints.xs}px) {
    display: none;
  }
`
export const CycleLifeItemColumn = styled.div`
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media screen and (max-width: ${breakpoints.xs}px) {
    padding: 0.5rem 0 0.5rem 1.5rem; 
    border-left: 1px solid ${({ theme }) => theme.colors.wineBlack};
  }
`

export const CycleLifeItemDate = styled(Text)`
  font-family: ${({ theme }) => theme.fonts.main};
  font-size: 14px;
  line-height: 14px;
  color: ${({ theme }) => theme.colors.wineGray};
  margin: 0 0 1.125rem;
`
export const CycleLifeItemTitle = styled(Text)`
  font-family: ${({ theme }) => theme.fonts.wine};
  font-size: 1.5rem;
  line-height: 2rem;
  color: ${({ theme }) => theme.colors.wineBlack};
  margin: 0 0 0.75rem;
  max-width: 78%;
`
export const CycleLifeItemParagraph = styled(Text)`
  font-family: ${({ theme }) => theme.fonts.main};
  font-size: 0.875rem;
  line-height: 21px;
  color: ${({ theme }) => theme.colors.wineGray};
  margin: 0 0 0.75rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`
export const CycleLifeItemParagraphSeeMore = styled.span`
  cursor: pointer;
  font-family: ${({ theme }) => theme.fonts.wine};
  font-size: 0.875rem;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.winePrimary};
  transition: all 0.25s ease-in-out;
  &:hover {
    color: ${({ theme }) => theme.colors.wineBlack};
  }
  margin: 0 0 1rem;
`
export const CycleLifeItemImage = styled.img`
  width: 100%;
  max-width: 250px;
  height: 100%;
  max-height: 250px;
  object-fit: cover;
  @media screen and (max-width: ${breakpoints.xs}px) {
    max-width: 100%;
  }
`
export const CycleLifeItem = styled.div<{modal?: boolean}>`
  width: 312px;
  max-width: 100%;
  position: relative;
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  &::before {
    position: absolute;
    content: "";
    height: 1px;
    width: 90px;
    right: -25px;
    background-color: ${({ theme }) => theme.colors.wineBlack};
    top: 18px;
    @media screen and (max-width: ${breakpoints.xs}px) {
      display: none;
    }
  }
  ${({ modal }) => modal && `
    width: 100%;
    ${CycleLifeItemImage} {
      max-width: 100%;
    }
  `}
  @media screen and (max-width: ${breakpoints.xs}px) {
    width: 100%;
    margin: 0 0 1.5rem;
  }
`

export const CycleLifeAction = styled.div`
  display: flex;
  gap: 0 2rem;
  align-items: center;
  @media screen and (max-width: ${breakpoints.xs}px) {
    display: none;
  }
`
export const CycleLifeHeader = styled.div`
  width: 100%;
  max-width: calc(1230px - calc(2rem + 4.5vw));
`