import styled from 'styled-components';
import { sm } from 'theme/Theme.breackoints';

export const OrganizationSection = styled.section`
  width: 100%;
  display: grid;
  grid-template: 1fr / 1fr;
  grid-gap: 0 1.5rem;
  min-height: calc(100vh - 15.375rem);
  ${sm`
    grid-template: 1fr / 1fr;
    overflow: hidden;
    padding: 5rem 0 0;
  `}
`;
export const OrganizationColumns = styled.div`
  ${sm`
    width: calc(100vw - 2rem);
  `}
`;
export const OrganizationContent = styled.div`
  padding: 0 0 3rem;
  ${sm`
    width: calc(100vw - 2rem);
  `}
`;
