import React from 'react';
import { Helmet } from 'react-helmet-async';

export type MetaHeadProps = {
  title?: string;
  description?: string;
  image?: string;
  url?: string;
};

const MetaHead = ({ title, description, image, url }: MetaHeadProps) => {
  return (
    <Helmet>
      <title>{title || ''} | SwearIt</title>
      <meta name="description" content={description} />
      <meta
        name="robots"
        content="max-snippet:-1, max-image-preview:large, max-video-preview:-1"
      />
      {url && <link rel="canonical" href={url} />}
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={`${title} | SwearIt`} />
      <meta property="og:description" content={description} />
      {url && <meta property="og:url" content={url} />}
      <meta property="og:site_name" content="Swear It" />
      <meta property="og:image" content={image} />
      <meta property="og:image:secure_url" content={image} />
      <meta property="og:image:width" content="1280" />
      <meta property="og:image:height" content="720" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:title" content={`${title} | SwearIt`} />
      <meta name="twitter:image" content={image} />
      {url && <meta name="twitter:url" content={url} />}
    </Helmet>
  );
};

export default MetaHead;
