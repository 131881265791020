import React, { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
//ASSETS
import placeholderImage from 'assets/images/placeholder255x255.png';
//PROPS TYPE
import { CycleLifeModalProps } from './CycleLifeModal.props';
//STORE
import { AppState } from 'store';
import { closeModal } from 'store/cycleLifeModal';
//COMPONETNS
import Modal from 'components/Modal';
import { Markup } from 'interweave';
import VideoPreview from 'components/VideoPreview';
//STYLES
import { ModalContent } from 'components/Modal/Modal.styled';
import {
  CycleLifeItem,
  CycleLifeItemDate,
  CycleLifeItemTitle,
  CycleLifeItemImage,
  CycleLifeItemColumn,
} from 'screens/Wine/components/CycleLife/CycleLife.styled';
import { CycleLifeModalParagraph } from './CycleLifeModal.styled';

const CycleLifeModal = ({ hiddeClose, wine }: CycleLifeModalProps) => {
  const dispatch = useDispatch();

  const $milestoneParent = useRef<HTMLDivElement>(null);

  const { isOpen, milestone } = useSelector(
    (state: AppState) => state.cycleLifeModal
  );

  const handleCloseModal = useCallback(() => {
    if (!hiddeClose) {
      dispatch(closeModal());
    }
  }, [dispatch, hiddeClose]);

  return (
    <Modal isOpen={isOpen} close={handleCloseModal} wine={wine} size="small">
      <ModalContent>
        {milestone && (
          <CycleLifeItem key={milestone.id} modal>
            <CycleLifeItemDate>
              {moment(milestone.createdAt).format('DD/MM/YYYY')}
            </CycleLifeItemDate>
            <CycleLifeItemTitle>{milestone.name}</CycleLifeItemTitle>
            <CycleLifeItemColumn>
              <CycleLifeModalParagraph>
                {milestone.description && (
                  <Markup content={milestone.description} />
                )}
              </CycleLifeModalParagraph>
              {milestone.video && (
                <VideoPreview
                  url={milestone.video.url}
                  name={milestone.name}
                  parent={$milestoneParent}
                  id={milestone.pk as string}
                  margin="0"
                  poster={
                    milestone && milestone.image && milestone.image.url
                      ? milestone.image.url
                      : undefined
                  }
                />
              )}
              {!milestone.video && (
                <CycleLifeItemImage
                  src={milestone.image ? milestone.image.url : placeholderImage}
                  alt={milestone.name}
                />
              )}
            </CycleLifeItemColumn>
          </CycleLifeItem>
        )}
      </ModalContent>
    </Modal>
  );
};

export default CycleLifeModal;
