import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

//PROPS TYPE
import { RenderHtmlProps } from './RenderHtml.props';

//STYLES
import {
  RenderHtmlContainer,
  RenderHtmlAction,
  RenderHtmlContent,
  RenderHtmlWrapper,
  RenderHtmlOverlay,
  RenderHtmlText,
} from './RenderHtml.styled';

const RenderHtml = ({
  text,
  align = 'left',
  margin = '0 0 1.25rem',
  wine,
  noOverlay,
}: RenderHtmlProps) => {
  const { t } = useTranslation('commons');

  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [textLength, setTextLength] = useState<number | null>(null);

  const createMarkup = (text: string) => {
    return { __html: text };
  };

  let className = 'RenderHtml';

  const toggleText = () => {
    setIsVisible(!isVisible);
  };

  useEffect(() => {
    if (!textLength) {
      setTextLength(text.split(' ').length);
    }
  }, [textLength, text]);

  return (
    <RenderHtmlContainer
      className={className}
      align={align}
      margin={margin}
      wine={wine}
    >
      <RenderHtmlWrapper
        active={!noOverlay && !isVisible && !!(textLength && textLength > 20)}
      >
        {/* {!noOverlay && !isVisible && textLength && textLength > 20 && (
          <RenderHtmlOverlay />
        )} */}

        <RenderHtmlContent dangerouslySetInnerHTML={createMarkup(text)} />
      </RenderHtmlWrapper>

      {!noOverlay && textLength && textLength > 20 && (
        <RenderHtmlAction onClick={toggleText}>
          {!isVisible ? t('content.seeMore') : t('content.seeLess')}
        </RenderHtmlAction>
      )}
    </RenderHtmlContainer>
  );
};

export default RenderHtml;
