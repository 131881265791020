import React, { useState } from 'react';

//COMPONENTS
import SkeletonLoader from 'components/SkeletonLoader';

const SkeletonMilestonesItem = () => {
  const [proofList] = useState([10, 7]);

  return (
    <SkeletonLoader
      width="100%"
      height="auto"
      background="transparent"
      padding="1.125rem 0px 1.125rem 3rem"
      position="relative"
      after={`
        content: "";
        position: absolute;
        width: 3px;
        height: 100%;
        top: 0px;
        left: 1.15625rem;
        background: linear-gradient(-90deg,#f0f0f0 0%,#f8f8f8 50%,#f0f0f0 100%);
        z-index: 1;
      `}
    >
      <SkeletonLoader
        width="100%"
        height="1rem"
        margin="0px 0px 1rem"
        position="relative"
        background="transparent"
      >
        <SkeletonLoader width="100%" height="1rem" display="block" />
        <SkeletonLoader
          width="1.875rem"
          height="1.875rem"
          position="absolute"
          top="calc(50% - 0.9375rem)"
          left="-2.6875rem"
          radius="50%"
        />
      </SkeletonLoader>
      <SkeletonLoader
        width="100%"
        height="auto"
        margin="0px 0px 1.25rem"
        background="transparent"
      >
        {proofList.map((proof, index) => (
          <SkeletonLoader
            key={`${index}-proof`}
            width={`${proof}rem`}
            height="2rem"
            margin="0.5rem 0.5rem 0.5rem 0"
            radius="9999px"
            flex={{
              flexGroup: '1 0 auto;',
            }}
          />
        ))}
      </SkeletonLoader>
      <SkeletonLoader
        width="100%"
        height="10rem"
        margin="0px 0px 1rem"
        display="block"
      />
      <SkeletonLoader
        width="100%"
        height="auto"
        margin="0px 0px 1rem"
        background="transparent"
      >
        <SkeletonLoader
          width="100%"
          height="0.75rem"
          margin="0px 0px 0.5rem"
          display="block"
        />
        <SkeletonLoader
          width="100%"
          height="0.75rem"
          margin="0px 0px 0.5rem"
          display="block"
        />
        <SkeletonLoader
          width="100%"
          height="0.75rem"
          margin="0px 0px 0.5rem"
          display="block"
        />
        <SkeletonLoader
          width="10rem"
          height="0.75rem"
          margin="0px 0px 1rem"
          display="block"
        />
        <SkeletonLoader
          width="4.5rem"
          height="0.625rem"
          margin="0px 0px 0.5rem"
          display="block"
        />
      </SkeletonLoader>
    </SkeletonLoader>
  );
};

export default SkeletonMilestonesItem;
