import styled from 'styled-components';

//ASSETS
import VideoTrimmerIcon from 'assets/images/videoTrimmerIcon.png';

export const VideoPreviewMedia = styled.video`
  max-width: 100%;
`;
export const VideoPreviewMediaOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  background-color: rgba(0, 0, 0, 0.25);
  transition: all 0.25s ease-in;
`;
export const VideoPreviewMediaIcons = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9;
`;
export const VideoPreviewMediaIconPlay = styled.div`
  width: 4.4375rem;
  height: 4.4375rem;
  background-repeat: no-repeat;
  background-position: 0 0;
  background-image: url(${VideoTrimmerIcon});
  transition: opacity 0.2s ease-out;
  background-size: 8.875rem;
`;
export const VideoPreviewContent = styled.div<{margin?: string;}>`
  width: 100%;
  margin: ${({ margin }) => margin ? margin : "0 0 1rem"};
  position: relative;
  cursor: pointer;
  &:hover {
    ${VideoPreviewMediaOverlay} {
      opacity: 0;
    }
  }
`;
