import styled from 'styled-components';

//COMPONENTS
import { Text } from 'components/Text';

export const ReviewModalContainer = styled.div`
  position: relative;
`;
export const ReviewModalImage = styled.img`
  width: 9.375rem;
  margin: -5.375rem auto 0.5rem;
`;
export const ReviewModalTitle = styled(Text)`
  text-align: center;
  font-size: 1.125rem;
  line-height: 2rem;
  font-weight: bold;
  color: #100316;
  margin: 0 0 0.5rem;
`;
export const ReviewModalSubtitle = styled(Text)`
  text-align: center;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #a89fac;
  max-width: 350px;
  margin: 0 auto 1.5rem;
`;
export const ReviewModalActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const ReviewModalForm = styled.div`
  display: grid;
  grid-template: 1fr / 1fr 1fr;
  grid-template-areas: 'title name' 'commment email' 'submit submit';
  grid-gap: 1rem 1.5rem;
`;
export const ReviewModalFormRating = styled.div`
  margin: 1rem 0 0;
`;
export const ReviewModalFormControl = styled.div<{ gridArea?: string }>`
  ${({ gridArea }) =>
    gridArea &&
    `
    grid-area: ${gridArea};
  `}
`;

export const ReviewModalRatingContainer = styled.div``;
export const ReviewModalRatingStar = styled.div<{ active: boolean }>`
  display: inline-block;
  font-size: 2rem;
  font-family: Times;
  line-height: 2rem;
  cursor: pointer;
  margin: 0 0.25rem 0 0;
  &:hover {
    opacity: 0.8;
  }
  &::before {
    content: '❤';
    background: ${({ theme, active }) =>
      active ? theme.colors.error : theme.colors.placeholder};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;
