import React from 'react';
import { useMediaQuery } from '@react-hook/media-query';

//PROPS TYPE
import { AppLayoutProps } from './AppLayout.props';

//COMPONENTS
import AppHeader from 'module/AppHeader';
import AppFooter from 'module/AppFooter';
import Loader from 'components/Loader';
import MetaHead from 'components/MetaHead';

//SCREENS
import Error404 from 'screens/Error404';

//COMPONENTS
import Icon from 'components/Icon';

//STYLES
import { ThemeGlobalStyles } from 'theme/Theme.globalstyles';
import {
  AppLayoutContainer,
  AppLayoutContent,
  AppBlocker,
  AppBlockerText,
} from './AppLayout.styled';
import { ERRORS } from 'constants/errors';

const AppLayout = ({
  children,
  loading,
  error,
  errorType,
  errorUrl,
  metas,
  type,
  id,
  skeletonLoader,
}: AppLayoutProps) => {
  const matches = useMediaQuery('only screen and (max-width: 768px)');

  if (errorType === ERRORS.private) {
    return <Error404 isPrivate errorUrl={errorUrl} />;
  }

  if (errorType === ERRORS.pending_payment) {
    return <Error404 issetProduct />;
  }

  if (
    errorType === ERRORS.not_registered ||
    errorType === ERRORS.invalid_uuid
  ) {
    return <Error404 />;
  }
  if (
    !!errorType &&
    errorType !== ERRORS.not_registered &&
    errorType !== ERRORS.invalid_uuid &&
    errorType !== ERRORS.private &&
    errorType !== ERRORS.pending_payment
  ) {
    return <Error404 />;
  }

  return (
    <>
      {loading ? (
        <>{skeletonLoader ? skeletonLoader : <Loader />}</>
      ) : (
        <AppLayoutContainer>
          <ThemeGlobalStyles />
          {matches && (
            <AppBlocker>
              <AppBlockerText>
                <Icon iconName="rotatePhone" />
                Por favor rote el telefono
              </AppBlockerText>
            </AppBlocker>
          )}

          {metas && (
            <MetaHead
              title={metas.title}
              description={metas.description}
              image={metas.image}
              url={metas.url}
            />
          )}
          <AppHeader type={type} id={id} />
          <AppLayoutContent>{children}</AppLayoutContent>
          <AppFooter />
        </AppLayoutContainer>
      )}
    </>
  );
};

export default AppLayout;
