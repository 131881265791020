import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
//STORE
import { AppState } from 'store';
//PROPS TYPE
import { ProductDesktopViewProps } from './ProductDesktopView.props';
//COMPONENTS
import ProductTabs from 'components/ProductTabs';
import SectionHeader from 'components/SectionHeader';
import FeatureProofPoints from 'components/FeatureProofPoints';
import SocialShare from 'components/SocialShare';
import Seals from 'components/Seals';
import ProductImages from 'components/ProductImages';
import CustomerReviews from 'components/CustomerReviews';
import RenderHtml from 'components/RenderHtml';
import MilestoneList from 'components/MilestoneList';
import RelatedProducts from 'components/RelatedProducts';
//STYLES
import {
  ProductSection,
  ProductGrid,
  ProductColumns,
  ProductContent,
  ProductSideBar,
  ProductInfoTitle,
  ProductInfoData,
} from '../../Product.styled';

const ProductDesktopView = ({
  product,
  relatedProducts,
}: ProductDesktopViewProps) => {
  const { t } = useTranslation('product');

  const location = useLocation();

  const lotInternalId = useSelector(
    (state: AppState) => state.product.lotInternalId
  );

  return (
    <ProductSection>
      <ProductGrid>
        <ProductColumns>
          <ProductContent>
            <SectionHeader text={t('header.productInfo')} />
            {product &&
              product.featuredProofPoints &&
              product.featuredProofPoints.length > 0 && (
                <FeatureProofPoints
                  featuredProofs={product.featuredProofPoints}
                />
              )}

            {product &&
              product.featuredProofPoints &&
              product.featuredProofPoints.length > 0 && (
                <Seals featuredProofs={product.featuredProofPoints} />
              )}

            {location.pathname.includes('/lot/') && (
              <>
                <ProductInfoTitle>{t('content.productLots')}</ProductInfoTitle>
                <ProductInfoData>{lotInternalId}</ProductInfoData>
              </>
            )}
            {product && product.description && (
              <>
                <ProductInfoTitle>
                  {t('content.productDescription')}
                </ProductInfoTitle>
                <RenderHtml text={product.description} />
              </>
            )}

            {product && product.images && product.images.length > 0 && (
              <>
                <ProductInfoTitle>
                  {t('content.productImages')}
                </ProductInfoTitle>
                <ProductImages images={product.images} />
              </>
            )}

            {product && product.tabs && product.tabs.length > 0 && (
              <ProductTabs tabs={product.tabs} />
            )}

            {product && product.thumbnail && (
              <SocialShare type="product" product={product} />
            )}

            {product &&
              product.reviewStats &&
              product.reviews &&
              product.reviews.edges && (
                <CustomerReviews
                  reviewStats={product.reviewStats}
                  reviews={product.reviews.edges}
                />
              )}

            {relatedProducts &&
              relatedProducts.products &&
              relatedProducts.products.length > 0 && (
                <>
                  <ProductInfoTitle>
                    {t('content.productRelated')}
                  </ProductInfoTitle>
                  <RelatedProducts products={relatedProducts.products} />
                </>
              )}
          </ProductContent>
        </ProductColumns>
        <ProductColumns>
          <ProductSideBar>
            <SectionHeader text={t('header.productJourney')} />
            {product && product.journey && (
              <MilestoneList milestones={product.journey.milestones} />
            )}
          </ProductSideBar>
        </ProductColumns>
      </ProductGrid>
    </ProductSection>
  );
};

export default ProductDesktopView;
