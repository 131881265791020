import styled from 'styled-components';

//COMPONENTS
import { Text } from 'components/Text';

export const MilestoneContainer = styled.div`
  padding: 1.125rem 0 1.125rem 3rem;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    width: 2px;
    height: 100%;
    top: 0;
    left: 1.1875rem;
    background-color: ${({ theme }) => theme.colors.blueLigth};
    z-index: 1;
  }
`;
export const MilestoneTitle = styled(Text)`
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.black};
  margin: 0 0 1.25rem;
  position: relative;
  z-index: 2;
`;
export const MilestoneIconBox = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.875rem;
  height: 1.875rem;
  background-color: ${({ theme }) => theme.colors.brand};
  top: calc(50% - 0.9375rem);
  left: -2.6875rem;
  border-radius: 50%;
`;
export const MilestoneIcon = styled.img`
  max-width: 1rem;
  filter: invert(93%) sepia(100%) saturate(0) hue-rotate(186deg)
    brightness(104%) contrast(101%);
`;

export const MilestoneProofPoints = styled.div`
  margin: 0 0 1.25rem;
`;

export const MilestoneLocation = styled(Text)`
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin: 0 0 1.25rem;
  color: ${({ theme }) => theme.colors.fontSecondary};
  display: flex;
  align-items: center;
  gap: 0 0.5rem;
  svg {
    width: 12px;
  }
`;

export const MilestoneImage = styled.img`
  max-width: 100%;
  margin: 0 0 1rem;
`;
