import React, { useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

//ASSETS
import ErrorHandlerGraphic from 'assets/images/error-handler-graphic.png';

//STORE
import { AppState } from 'store';
import { closeModal } from 'store/mediaEvidenceModal';

//COMPONENTS
import ReactPlayer from 'react-player';
import Modal from 'components/Modal';
import Loading from 'components/Loading';

//STYLES
import {
  MediaEvicenceModalContainer,
  MediaEvicenceModalMedia,
  MediaEvicenceModalLoading,
  MediaEvicenceModalError,
  MediaEvicenceModalErrorImage,
  MediaEvicenceModalErrorText,
  MediaEvicenceModalErrorSubText,
} from './MediaEvicenceModal.styled';

interface MediaEvicenceModalProps {
  wine?: boolean;
}
const MediaEvicenceModal = ({ wine }: MediaEvicenceModalProps) => {
  const { t } = useTranslation('modalMediaEvidence');

  const dispatch = useDispatch();

  const { media, isOpen } = useSelector(
    (state: AppState) => state.mediaEvidenceModal
  );

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [hasError, setHasError] = useState<boolean>(false);

  const handleCloseModal = useCallback(() => {
    dispatch(closeModal());
  }, [dispatch]);

  return (
    <Modal isOpen={isOpen} close={handleCloseModal} padding="0" wine={wine}>
      <MediaEvicenceModalContainer>
        {isLoading && (
          <MediaEvicenceModalLoading>
            <Loading isLoading={isLoading} />
          </MediaEvicenceModalLoading>
        )}
        {hasError && (
          <MediaEvicenceModalError>
            <MediaEvicenceModalErrorImage src={ErrorHandlerGraphic} />
            <MediaEvicenceModalErrorText>
              {t('content.textError')}
            </MediaEvicenceModalErrorText>
            <MediaEvicenceModalErrorSubText>
              {t('content.subTextError')}
            </MediaEvicenceModalErrorSubText>
          </MediaEvicenceModalError>
        )}
        <MediaEvicenceModalMedia>
          <ReactPlayer
            url={media.url}
            controls={true}
            width="100%"
            playing={true}
            height="100%"
            loop={true}
            style={{
              margin: '0 auto',
              width: '100%',
              maxWidth: '800px',
              height: '100%',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            }}
            onReady={() => {
              setIsLoading(false);
            }}
            onError={() => {
              setHasError(true);
              setIsLoading(false);
            }}
          />
        </MediaEvicenceModalMedia>
      </MediaEvicenceModalContainer>
    </Modal>
  );
};

export default MediaEvicenceModal;
