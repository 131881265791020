import styled from 'styled-components';

//COMPONENTS
import { Text } from 'components/Text';

export const CycleLifeModalParagraph= styled(Text)`
  font-family: ${({ theme }) => theme.fonts.main};
  font-size: 0.875rem;
  line-height: 21px;
  color: ${({ theme }) => theme.colors.wineGray};
  margin: 0 0 1rem;
  p {
    margin: 0 0 1rem;
  }
  a {
    font-weight: bold;
    color: ${({ theme }) => theme.colors.winePrimary};
    transition: all 0.25s ease-in-out;
    margin: 0 0 0.5rem;
    display: inline-block;
    outline: none;
    &:focus {
      outline: none;
    }
    &:hover {
      color: ${({ theme }) => theme.colors.wineBlack};
    }
  }
  em {
    font-style: normal;
  }
`;