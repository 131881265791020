import React from 'react';

//PROPS TYPE
import { TextAreaProps } from './TextArea.props';

//COMPONENTS
import Label from 'components/Label';

//STYLES
import { TextAreaContainer, TextAreaStyled } from './TextArea.styled';

const TextArea = ({
  name,
  label,
  error,
  placeholder,
  handleBlur,
  handleChange,
}: TextAreaProps) => {
  return (
    <TextAreaContainer>
      <Label name={name}>{label}</Label>
      <TextAreaStyled
        placeholder={placeholder}
        name={name}
        onBlur={handleBlur}
        onChange={handleChange}
        rows={6}
      />
    </TextAreaContainer>
  );
};

export default TextArea;
