import { useQuery } from '@apollo/react-hooks';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

//QUERIES
import { blockProofPointQuery } from 'queries/blocknitive';

//STORE
import { AppState } from 'store';
import { closeModal } from 'store/blockchainDetailModal';

//PROPS TYPE
import { BlockchainDetailModalProps } from './BlockchainDetailModal.props';

//COMPONETNS
import Modal from 'components/Modal';
import BlockchainDetailModalContent from './BlockchainDetailModalContent';
import BlockchainDetailModalHeader from './BlockchainDetailModalHeader';
import BlockchainDetailModalLoading from './BlockchainDetailModalLoading';

//STYLED
import { ModalContent } from 'components/Modal/Modal.styled';
import {
  BlockchainDetailModalButton,
  BlockchainDetailModalContainer,
} from './BlockchainDetailModal.styled';

const BlockchainDetailModal = ({
  deguelleDate,
  type,
  wine,
}: BlockchainDetailModalProps) => {
  const { t } = useTranslation('modalBlockChain');

  const dispatch = useDispatch();

  const { isOpen, proof } = useSelector(
    (state: AppState) => state.blockchainDetailModal
  );

  const { data: proofBlockData, loading } = useQuery(blockProofPointQuery, {
    variables: { pk: proof?.pk },
  });

  //console.log(proofBlockData, 'proofBlockData', { pk: proof && proof.pk });
  //   {
  //     "blockId": "86208",
  //     "transactionId": "54152fb38b9ddc93a469614a2c6a96dc6b91a544a16ef1aa7c6435977a31fec1",
  //     "blocknitive": {
  //         "header": {
  //             "dataHash": "11473c44545aafd06b918eacf8b0fd8d8832a67acf0a44c52c3920fcb69d05d1",
  //             "__typename": "BlocknitiveHeader"
  //         },
  //         "data": [
  //             {
  //                 "header": {
  //                     "timestamp": "2024-03-06T10:01:53.233Z",
  //                     "__typename": "BlocknitiveDataHeader"
  //                 },
  //                 "__typename": "BlocknitiveData"
  //             }
  //         ],
  //         "__typename": "Blocknitive"
  //     },
  //     "__typename": "ProofPoint"
  // }
  const handleCloseModal = useCallback(() => {
    dispatch(closeModal());
  }, [dispatch]);

  return (
    <Modal
      isOpen={isOpen}
      close={handleCloseModal}
      size="big"
      backcolor="black"
      wine={wine}
    >
      <ModalContent>
        <BlockchainDetailModalContainer>
          {loading ? (
            <BlockchainDetailModalLoading />
          ) : (
            <>
              <BlockchainDetailModalHeader />
              {proofBlockData && (
                <BlockchainDetailModalContent
                  proofBlockData={proofBlockData.proofPoint}
                  type={type}
                  deguelleDate={deguelleDate}
                />
              )}

              <BlockchainDetailModalButton onClick={handleCloseModal}>
                {t('content.back')}
              </BlockchainDetailModalButton>
            </>
          )}
        </BlockchainDetailModalContainer>
      </ModalContent>
    </Modal>
  );
};

export default BlockchainDetailModal;
