import styled from 'styled-components';

export const OrganizationLinksContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
export const OrganizationLinksAction = styled.a`
  width: 1.75rem;
  height: 1.75rem;
  background-color: ${({ theme }) => theme.colors.brand};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 9999px;
  margin: 0 0.75rem 1rem 0;
  transition: all 0.25s ease-in-out;
  box-shadow: 0 4px 10px 0 rgb(16 3 22 / 10%);
  &:last-child {
    margin: 0 0 1rem;
  }
  &:hover {
    background-color: ${({ theme }) => theme.colors.active};
    transform: translateY(-0.25rem);
    box-shadow: 0 4px 10px 0 rgb(16 3 22 / 20%);
  }
  svg {
    width: 1rem;
    color: ${({ theme }) => theme.colors.white};
  }
`;
