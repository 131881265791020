import React from 'react';

//PROPS TYPE
import { ProductImagesProps } from './ProductImages.props';

//STYLED
import {
  ProductImagesContainer,
  ProductImagesPicture,
} from './ProductImages.styled';

const ProductImages = ({ images }: ProductImagesProps) => {
  return (
    <ProductImagesContainer>
      {images.map((image) => (
        <ProductImagesPicture src={image.url} key={image.id} />
      ))}
    </ProductImagesContainer>
  );
};

export default ProductImages;
