import React from 'react';

//PROPS TYPE
import { SkeletonLineProps } from './SkeletonLoader.props';

//STYLES
import { SkeletonLoaderContainer } from './SkeletonLoader.styled';

const SkeletonLoader = ({ children, ...props }: SkeletonLineProps) => {
  return children ? (
    <SkeletonLoaderContainer {...props}>{children}</SkeletonLoaderContainer>
  ) : (
    <SkeletonLoaderContainer {...props} />
  );
};

export default SkeletonLoader;
