import React, { useState, useMemo, useCallback } from 'react';
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useMutation } from '@apollo/react-hooks';
import { useParams } from 'react-router-dom';
import { Formik, FormikHelpers, Form } from 'formik';
import * as yup from 'yup';

//QUERIES
import { reviewLotCreate } from 'queries/reviews';

//STORE
import { setHaveAnswered } from 'store/reviews';

//COMPONENTS
import Input from 'components/Input';
import TextArea from 'components/TextArea';
import Button from 'components/Button';
import Label from 'components/Label';
import ReviewModalRating from './ReviewModal.rating';
import Loading from 'components/Loading';

//STYLES
import {
  ReviewModalContainer,
  ReviewModalForm,
  ReviewModalFormControl,
  ReviewModalFormRating,
} from './ReviewModal.styled';

const validationSchema = yup.object().shape({
  title: yup.string().required(),
  name: yup.string().required(),
  email: yup.string().email().required(),
  comment: yup.string().required(),
  rating: yup.number().integer().min(1).required(),
});

interface ReviewFormLotProps {
  setSubmited: (submited: boolean) => void;
}

const ReviewFormLot = ({ setSubmited }: ReviewFormLotProps) => {
  const { t } = useTranslation('modalReview');

  const dispatch = useDispatch();
  const { lotId } = useParams();

  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);

  const INITIAL_VALUES = useMemo(
    () => ({
      title: '',
      name: '',
      email: '',
      comment: '',
      rating: 0,
      productLot: lotId,
    }),
    [lotId]
  );

  const [reviewCreateMutation] = useMutation(reviewLotCreate, {
    awaitRefetchQueries: true,
    refetchQueries: ['organization'],
  });

  const onSubmit = useCallback(
    async (
      values: typeof INITIAL_VALUES,
      helpers: FormikHelpers<typeof INITIAL_VALUES>
    ) => {
      try {
        setLoadingSubmit(true);
        await reviewCreateMutation({ variables: { reviewCreate: values } });
        dispatch(setHaveAnswered(lotId));
        setSubmited(true);
        setLoadingSubmit(false);
        helpers.resetForm();

        await ReactGA.event({
          category: 'Reviewing',
          action: 'Lot Review',
          label: values.productLot,
          value: values.rating,
        });
      } catch (error) {}
    },
    [reviewCreateMutation, INITIAL_VALUES, dispatch, lotId, setSubmited]
  );

  return (
    <ReviewModalContainer>
      <Loading isLoading={loadingSubmit} />
      <Formik
        initialValues={INITIAL_VALUES}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        validateOnMount={true}
      >
        {({
          handleBlur,
          handleChange,
          errors,
          isSubmitting,
          isValid,
          values,
          setFieldValue,
        }) => (
          <Form>
            <ReviewModalForm>
              <ReviewModalFormControl gridArea="title">
                <Input
                  label={t('content.reviewTitle')}
                  placeholder={t('content.reviewTitleHolder')}
                  name="title"
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  error={errors.title}
                />
              </ReviewModalFormControl>
              <ReviewModalFormControl gridArea="name">
                <Input
                  name="name"
                  label={t('content.name')}
                  placeholder={t('content.nameHolder')}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  error={errors.name}
                />
              </ReviewModalFormControl>
              <ReviewModalFormControl gridArea="commment">
                <TextArea
                  label={t('content.yourComment')}
                  placeholder={t('content.yourCommentHolder')}
                  name="comment"
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  error={errors.comment}
                />
              </ReviewModalFormControl>
              <ReviewModalFormControl gridArea="email">
                <Input
                  name="email"
                  label={t('content.email')}
                  placeholder={t('content.emailHolder')}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  error={errors.name}
                  type="email"
                />
                <ReviewModalFormRating>
                  <Label>{t('content.touchCountHearth')}</Label>
                  <ReviewModalRating
                    onClickRating={(rating: number) => {
                      setFieldValue('rating', rating);
                    }}
                  />
                </ReviewModalFormRating>
              </ReviewModalFormControl>
              <ReviewModalFormControl gridArea="submit">
                <Button
                  disabled={!isValid || isSubmitting}
                  text={t('content.postButton')}
                />
              </ReviewModalFormControl>
            </ReviewModalForm>
          </Form>
        )}
      </Formik>
    </ReviewModalContainer>
  );
};

export default ReviewFormLot;
