import styled from "styled-components"
import { Text } from "components/Text"
import { sm } from 'theme/Theme.breackoints';

export const TemplateContainer = styled.div``
export const TemplateItem = styled.div``
export const TemplateSeparator = styled.div`
  margin: 1rem 0;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.border};
`
export const TemplateImage = styled.img<{margin?: string;}>`
  max-width: 100%;
  margin: ${({ margin }) => margin ? margin : 0};
`
export const TemplateTitle = styled(Text)`
  font-size: 16px;
  line-height: 16px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.fontSecondary};
  `
export const TemplateSubtitle = styled.span`
  font-size: 12px;
  line-height: 12px;
  color: ${({ theme }) => theme.colors.fontMain};
  font-weight: normal;
`
export const TemplateLabel = styled(Text)`
  font-size: 12px;
  line-height: 12px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.fontSecondary};
  margin-bottom: 10px;
  text-transform: uppercase;
`
export const TemplateLabelPercents = styled(TemplateLabel)`
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
`
export const TemplateText = styled(Text)`
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.fontMain};
`
export const TemplateLink = styled(Text)`
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.winePrimary};
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.colors.wineBlack};
  }
`
export const TemplateTechDetail = styled.div<{margin?: string;}>`
  margin: ${({ margin }) => margin ? margin : 0};
`
export const TemplateTechHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem 0 0;
`
export const TemplateTechFooter = styled(Text)`
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.fontMain};
  font-weight: 600;
`
export const TemplateTechPrincipal = styled.div`
  display: grid;
  grid-template: 1fr / 1fr 1fr;
  gap: 20px;
  margin-bottom: 1rem;
  @media (max-width: 1080px) {
    grid-template: 1fr / 1fr;
  }
`
export const TemplateTechOther = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  gap: 1rem;
  flex-wrap: wrap;
`

export const TemplateTechComposition = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 1rem;

`

export const TemplateNutrient = styled.div``
export const TemplateNutrientLoop = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
`
export const TemplateNutrientItem = styled.div`
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid ${({ theme }) => theme.colors.fontSecondary};
`
export const TemplateNutrientItemNumber =  styled(Text)`
  font-size: 14px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.fontSecondary};
  margin: 0 0 0.25rem;
`
export const TemplateNutrientItemName =  styled(Text)`
  font-size: 0.625em;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.fontMain};
`
export const TemplateIngredientItem = styled.div<{href?: string; target?: string; isLink?: boolean;}>`
  display: flex;
  align-items: center;
  gap: 0 1rem;
  transition: all 0.25s ease-in-out;
  border: 1px solid transparent;
  padding: 0.25rem;
  border-radius: 0.5rem;
  ${({ isLink, theme }) => isLink && `
    background-color: #f7f7f7;
    &:hover {
      border: 1px solid ${theme.colors.wineGrayLigth};
    }
  `}
`
export const TemplateIngredientImage = styled.img`
  width: 70px;
  height: 70px;
  object-fit: cover;
`

export const TemplateTech = styled.div<{wine?: boolean;}>`
  display: grid;
  grid-template: 1fr / 230px 1fr;
  grid-gap: 0 20px;
  @media (max-width: 768px) {
    grid-template: 1fr / 140px calc(100% - 160px);
  }

  ${({ wine, theme }) => wine && `
    font-family: ${theme.fonts.main};
    grid-template: 1fr / 1fr;
    @media (max-width: 768px) {
      grid-template: 1fr / 1fr;
      margin: 0 0 1.5rem;
    }
  `}
`

export const TemplateIngredient = styled.div<{wine?: boolean;}>`
  display: grid;
  grid-template: 1fr / 1fr 1fr;
  gap: 1rem;
  @media (max-width: 1080px) {
    grid-template: 1fr / 1fr;
  }
  ${({ wine, theme }) => wine && `
    font-family: ${theme.fonts.main};
  `}
`