import { useQuery } from '@apollo/react-hooks';
import { useMediaQuery } from '@react-hook/media-query';
import React, { useCallback, useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

//QUERIES
import { productQuery, relatedProductQuery } from 'queries/product';

//STORE
import { AppState } from 'store';
import { setMobileNav } from 'store/mobilenav';
import { setOrganizationInfo } from 'store/organization';
import { setProduct } from 'store/product';
import { openModal, setTranslations } from 'store/tramslateModal';

//PROPS TYPE
import { MetaHeadProps } from 'components/MetaHead/MetaHead';
import { ProductData, ProductRelatedData } from 'types/product';
import { ProductVars, ProductsRelatedVars } from './Product.props';

//COMPONENTS
import BlockchainDetailModal from 'components/BlockchainDetailModal';
import Container from 'components/Container';
import EvidenceModal from 'components/EvidenceModal';
import MediaEvicenceModal from 'components/MediaEvidenceModal';
import ProofPointDetailModal from 'components/ProofPointDetailModal';
import ProofPointModal from 'components/ProofPointModal';
import ReviewModal from 'components/ReviewModal';
import TranslateModal from 'components/TranslateModal';
import AppLayout from 'module/AppLayout';
import ProductDesktopView from './components/ProductDesktopView';
import ProductMobileView from './components/ProductMobileView';
import ProductSkeleton from './components/ProductSkeleton';

const Product = () => {
  const { productId } = useParams();

  const { translateSelect } = useSelector(
    (state: AppState) => state.translateModal
  );

  const dispatch = useDispatch();
  const matches = useMediaQuery('only screen and (max-width: 768px)');

  const [metaInformation, setMetaInformation] = useState<MetaHeadProps>({});

  const { data: resProduct, loading, error, refetch } = useQuery<
    ProductData,
    ProductVars
  >(productQuery, {
    variables: {
      pk: productId,
      language:
        translateSelect && translateSelect.length > 0
          ? translateSelect.find((lang) => lang.idProduct === productId)
              ?.short_name
          : '',
    },
  });
  const {
    data: resRelatedProduct,
    loading: loadingRelated,
    error: errorRelated,
    refetch: refetchRelated,
  } = useQuery<ProductRelatedData, ProductsRelatedVars>(relatedProductQuery, {
    variables: {
      id: productId,
      language:
        translateSelect && translateSelect.length > 0
          ? translateSelect.find((lang) => lang.idProduct === productId)
              ?.short_name
          : '',
    },
  });

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname,
      title: 'product',
    });
  }, []);

  useEffect(() => {
    if (resProduct && resProduct.product && resProduct.product.data) {
      dispatch(setMobileNav('info'));
      setMetaInformation({
        title: resProduct.product.data.name,
        description: resProduct.product.data.description,
        image:
          resProduct.product.data.images.length > 0
            ? resProduct.product.data.images[0].url
            : '',
        url: window.location.href,
      });
    }
  }, [resProduct, dispatch]);

  useEffect(() => {
    if (resProduct && resProduct.product && resProduct.product.data) {
      dispatch(
        setOrganizationInfo({
          name: resProduct.product.data.organization.name,
          logo: resProduct.product.data.organization.logo.url,
        })
      );
      dispatch(setProduct(resProduct.product.data));
    }
  }, [dispatch, resProduct]);

  useEffect(() => {
    const selectLanguage = translateSelect.find(
      (lang) => lang.idProduct === productId
    );

    if (
      !selectLanguage &&
      resProduct &&
      resProduct.product &&
      resProduct.product.data &&
      resProduct.product.data.translationStatus &&
      Object.keys(JSON.parse(resProduct.product.data.translationStatus))
        .length > 1
    ) {
      dispatch(
        openModal({
          isOpen: true,
          translations: JSON.parse(resProduct.product.data.translationStatus),
          isHiddenClose: true,
        })
      );
    } else if (
      selectLanguage &&
      resProduct &&
      resProduct.product &&
      resProduct.product.data &&
      resProduct.product.data.translationStatus &&
      Object.keys(JSON.parse(resProduct.product.data.translationStatus))
        .length > 1
    ) {
      dispatch(
        setTranslations({
          translations: JSON.parse(resProduct.product.data.translationStatus),
        })
      );
    }
  }, [translateSelect, resProduct, dispatch, productId]);

  const handleRefetchQuery = useCallback(() => {
    refetch();
    refetchRelated();
  }, [refetch, refetchRelated]);

  return (
    <AppLayout
      loading={loading || loadingRelated}
      error={error}
      errorType={
        resProduct && resProduct.product && resProduct.product.errors
          ? resProduct.product.errors.message
          : resProduct && resProduct.product && resProduct.product.error
          ? resProduct.product.error
          : undefined
      }
      errorUrl={resProduct && resProduct.product && resProduct.product.url}
      metas={metaInformation}
      type="product"
      id={productId}
      skeletonLoader={<ProductSkeleton matches={matches} />}
    >
      <>
        <Container>
          <>
            {matches ? (
              <ProductMobileView
                product={
                  resProduct && resProduct.product && resProduct.product.data
                }
                relatedProducts={
                  resRelatedProduct && resRelatedProduct.relatedProducts
                }
              />
            ) : (
              <ProductDesktopView
                product={
                  resProduct && resProduct.product && resProduct.product.data
                }
                relatedProducts={
                  resRelatedProduct && resRelatedProduct.relatedProducts
                }
              />
            )}
          </>
        </Container>
        <ProofPointModal />
        <ProofPointDetailModal />
        <MediaEvicenceModal />
        <EvidenceModal />
        <BlockchainDetailModal type="product" />
        <ReviewModal type="product" />
        <TranslateModal
          refetch={handleRefetchQuery}
          type="product"
          id={productId}
        />
      </>
    </AppLayout>
  );
};

export default Product;
