import React, { useState, useEffect, useRef } from 'react';

//PROPS TYPE
import { HashPlaceholderProps } from './HashPlaceholder.props';

//COMPONENTS
import HashPlaceholderColor from './HashPlaceholderColor';
import HashPlaceholderToolTip from './HashPlaceholderToolTip';

//STYLES
import {
  HashPlaceholderContainer,
  HashPlaceholderShow,
  HashPlaceholderColorList,
} from './HashPlaceholder.styled';

const HashPlaceholder = ({ taxId }: HashPlaceholderProps) => {
  const [toolTipVisible, setToolTipVisible] = useState<boolean>(false);

  let $colorList = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if ($colorList.current) {
      $colorList.current.addEventListener('mouseenter', () => {
        setToolTipVisible(true);
      });
      $colorList.current.addEventListener('mouseleave', () => {
        setToolTipVisible(false);
      });
    }
  }, []);

  const COLORS: any = {
    1: '#CEDDF4',
    2: '#8298D5',
    3: '#4E63A5',
    4: '#5A3B86',
    5: '#651366',
    6: '#861761',
    7: '#A71A5B',
    8: '#C71D55',
    9: '#E7204E',
    0: '#E33B73',
    a: '#B55C92',
    b: '#EF6B8A',
    c: '#F76E2A',
    d: '#F49A18',
    e: '#F0C505',
    f: '#FCDF5F',
    g: '#FDEFAF',
    h: '#DCE097',
    i: '#BBD07F',
    j: '#9AC067',
    k: '#78B04E',
    l: '#3CA673',
    m: '#1EA185',
    n: '#009B97',
    o: '#67bcc6',
    p: '#39dedf',
    q: '#0AFFF7',
    r: '#ADFFFC',
    s: '#D6FFFE',
    t: '#7FEAF0',
    u: '#1BD8E3',
    v: '#2CB1CE',
    w: '#3D8ABA',
    x: '#3C6193',
    y: '#3B376C',
    z: '#312150',
  };

  let numbersInternal = taxId.substring(2, taxId.length - 2).split('');

  return (
    <HashPlaceholderContainer>
      <HashPlaceholderShow>{taxId.substring(0, 2)}</HashPlaceholderShow>
      <HashPlaceholderColorList ref={$colorList}>
        <HashPlaceholderToolTip taxId={taxId} isVisible={toolTipVisible} />
        {numbersInternal.length > 0 &&
          numbersInternal.map((taxId, index) => {
            return <HashPlaceholderColor color={COLORS[taxId]} key={index} />;
          })}
      </HashPlaceholderColorList>
      <HashPlaceholderShow>
        {taxId.substring(taxId.length - 2, taxId.length)}
      </HashPlaceholderShow>
    </HashPlaceholderContainer>
  );
};

export default HashPlaceholder;
