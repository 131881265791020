import styled from 'styled-components';
import { sm } from 'theme/Theme.breackoints';

export const AppFooterContainer = styled.div`
  border-top: 1px solid #ededee;
  ${sm`
    display: none;
  
  `}
  @media only screen and (max-width: 768px) and (orientation: landscape) {
    visibility: hidden;
  }
`;
export const AppFooterWrapper = styled.div`
  max-width: calc(100% - 5rem);
  margin: 0 auto;
`;
export const AppFooterTop = styled.div`
  padding-top: 2.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const AppFooterBottom = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
`;
export const AppFooterSocial = styled.div`
  width: 12rem;
  margin-top: 1.25rem;
  display: flex;
  justify-content: space-between;
`;

export const AppFooterLink = styled.a<{ uppercase?: boolean }>`
  font-size: 0.875rem;
  line-height: 0.875rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.brand};
  transition: all 0.25s ease-in-out;
  &:hover {
    color: ${({ theme }) => theme.colors.active};
  }
  ${({ uppercase }) =>
    uppercase &&
    `
    text-transform: uppercase;
  `}
`;

export const AppFooterLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-width: 200px;
`;
export const AppFooterLogoImage = styled.img`
  width: 2.5rem;
  margin: 0 0.5rem 0 0;
`;
export const AppFooterLogoText = styled.span`
  font-size: 0.875rem;
  line-height: 0.875rem;
  font-weight: 700;
`;
export const AppFooterLegal = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-width: 200px;
`;

export const AppFooterMail = styled.div`
  display: flex;
  justify-content: center;
`;

export const AppFooterSocialLink = styled(AppFooterLink)`
  svg {
    width: 1.25rem;
    color: ${({ theme }) => theme.colors.brand};
  }
  &:hover {
    svg {
      color: ${({ theme }) => theme.colors.active};
    }
  }
`;
