import React from 'react';
import { IconMapProps } from './Icon.props';

import ChevronRight from './components/ChevronRight';
import ChevronDown from './components/ChevronDown';
import Close from './components/Close';
import RotatePhone from './components/RotatePhone';
import Share from './components/Share';
import Copy from './components/Copy';
import Whatsapp from './components/Whatsapp';
import Telegram from './components/Telegram';
import Twitter from './components/Twitter';
import Facebook from './components/Facebook';
import Linkedin from './components/Linkedin';
import Pinterest from './components/Pinterest';
import DotMenu from './components/DotMenu';
import Instagram from './components/Instagram';
import Youtube from './components/Youtube';
import Point from './components/Point';
import ShareWine from './components/ShareWine';
import Print from './components/Print';
import Tiktok from './components/Tiktok';
import ArrowDoubleLeft from './components/ArrowDoubleLeft';
import ArrowDoubleRight from './components/ArrowDoubleRight';
import Link from './components/Link';

const ICON_MAP: Record<string, IconMapProps> = {
  chevronRight: {
    icon: <ChevronRight />,
    iconName: 'ChevronRight',
  },
  chevronDown: {
    icon: <ChevronDown />,
    iconName: 'ChevronDown',
  },
  close: {
    icon: <Close />,
    iconName: 'Close',
  },
  rotatePhone: {
    icon: <RotatePhone />,
    iconName: 'RotatePhone',
  },
  share: {
    icon: <Share />,
    iconName: 'Share',
  },
  copy: {
    icon: <Copy />,
    iconName: 'Copy',
  },
  whatsapp: {
    icon: <Whatsapp />,
    iconName: 'Whatsapp',
  },
  telegram: {
    icon: <Telegram />,
    iconName: 'Telegram',
  },
  twitter: {
    icon: <Twitter />,
    iconName: 'Twitter',
  },
  facebook: {
    icon: <Facebook />,
    iconName: 'Facebook',
  },
  linkedin: {
    icon: <Linkedin />,
    iconName: 'Linkedin',
  },
  pinterest: {
    icon: <Pinterest />,
    iconName: 'Pinterest',
  },
  dotMenu: {
    icon: <DotMenu />,
    iconName: 'DotMenu',
  },
  instagram: {
    icon: <Instagram />,
    iconName: 'Instagram',
  },
  youtube: {
    icon: <Youtube />,
    iconName: 'Youtube',
  },
  point: {
    icon: <Point />,
    iconName: 'Point',
  },
  shareWine: {
    icon: <ShareWine />,
    iconName: 'ShareWine',
  },
  print: {
    icon: <Print />,
    iconName: 'Print',
  },
  tiktok: {
    icon: <Tiktok />,
    iconName: 'Tiktok',
  },
  arrowDoubleRight: {
    icon: <ArrowDoubleRight />,
    iconName: 'ArrowDoubleRight',
  },
  arrowDoubleLeft: {
    icon: <ArrowDoubleLeft />,
    iconName: 'ArrowDoubleLeft',
  },
  link: {
    icon: <Link />,
    iconName: 'Link',
  },
};

export default ICON_MAP;
