import * as React from 'react';

function ShareWineIcons(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={32}
      height={32}
      strokeWidth={1.5}
      strokeLinecap="round"
      fill="none"
      viewBox="0 0 24 15"
      {...props}
    >
      <path
        d="M13 4.8V5.7L11.3 5.9C8.7 6.3 6.8 7.3 5.4 8.6C7.1 8.1 8.9 7.8 11 7.8H13V9.1L15.2 7L13 4.8ZM11 0L18 7L11 14V9.9C6 9.9 2.5 11.5 0 15C1 10 4 5 11 4M17 3V0L24 7L17 14V11L21 7"
        fill="currentColor"
      />
    </svg>
  );
}

export default ShareWineIcons;
