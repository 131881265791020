import React, { useState } from 'react';

//COMPONENTS
import SkeletonLoader from 'components/SkeletonLoader';

const SkeletonShare = () => {
  const [shareList] = useState([1, 2, 3, 4, 5, 6, 7]);

  return (
    <SkeletonLoader
      width="100%"
      height="auto"
      margin="0 0 1rem"
      display="flex"
      flex={{
        wrap: 'wrap',
      }}
      background="transparent"
    >
      {shareList.map((share, index) => (
        <SkeletonLoader
          key={`${index}-share`}
          width="2rem"
          height="2rem"
          margin="0 0.75rem 1rem 0"
          radius="2rem"
        />
      ))}
    </SkeletonLoader>
  );
};

export default SkeletonShare;
