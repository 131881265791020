import styled from 'styled-components';
import { sm, md, lg, xs } from 'theme/Theme.breackoints';

//COMPONENTS
import { Text } from 'components/Text';

export const RelatedProductsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin: 0 0 2rem;
  overflow: auto;
  padding: 1rem 0 1rem;
  gap: 0 1rem;

  &::-webkit-scrollbar {
    height: 8px;
    background-color: transparent;
    transition: all 0.3s ease-in-out;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
    transition: all 0.3s ease-in-out;
  }
  &::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 6px;
    transition: all 0.3s ease-in-out;
  }

  &:hover::-webkit-scrollbar {
    background-color: #f5f5f5;
  }
  &:hover::-webkit-scrollbar-track {
    background-color: ${({ theme }) => theme.colors.backgroundGrayLight};
  }
  &:hover::-webkit-scrollbar-thumb {
    background-image: ${({ theme }) => theme.colors.buttonGradient};
    border-radius: 6px;
  }
  ${xs`
    padding: 1rem 0 2rem;
  `}
`;

export const RelatedProductsItemImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 0.5s ease-in-out;
`;
export const RelatedProductsItemAction = styled(Text)`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.875rem;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.white};
  left: 0;
  z-index: 9;
  padding: 1rem 0.875rem;
  bottom: 20px;
  opacity: 0;
  transition: all 0.25s ease-in-out;
  svg {
    width: 0.75rem;
    height: 0.875rem;
    margin: 0 0 0 0.5rem;
  }
  ${xs`
    padding: 0.75rem;
  `}
`;
export const RelatedProductsItemOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    360deg,
    rgba(0, 0, 0, 0.4) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  z-index: 5;
  transition: all 0.25s ease-in-out;
`;
export const RelatedProductsItemName = styled(Text)`
  font-size: 0.875rem;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.white};
  transition: all 0.25s ease-in-out;
  padding: 1rem 0.875rem;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 9;
  max-width: 100%;
  ${xs`
    padding: 0.75rem;
  `}
`;
export const RelatedProductsItem = styled.div`
  flex: 0 0 auto;
  cursor: pointer;
  user-select: none;
  background: #fbfbfb;
  width: calc(calc(100% / 3) - 12px);
  border: 1px solid ${({ theme }) => theme.colors.border};
  position: relative;
  height: 15rem;
  max-height: 16rem;
  overflow: hidden;
  box-shadow: 0 2px 10px 0 rgb(16 3 22 / 6%);
  &:hover {
    ${RelatedProductsItemOverlay} {
      opacity: 0.7;
    }
    ${RelatedProductsItemImage} {
      transform: scale(1.05);
    }
    ${RelatedProductsItemName} {
      opacity: 0;
      bottom: -20px;
    }
    ${RelatedProductsItemAction} {
      opacity: 1;
      bottom: 0;
      text-shadow: 0 2px 4px rgb(16 3 22 / 25%);
    }
  }
  a {
    height: 100%;
  }
  ${lg`
    width: calc(calc(100% / 2) - 12px);
  `}
  ${sm`
    width: calc(calc(100% / 2) - 12px);
  `}
  ${xs`
    height: 10rem;
  `}
`;
