import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

//UTILS
import { LANGUAGE_MAP } from 'components/TranslateModal';

//PROPS TYPE
import { TranslationSelect } from 'store/tramslateModal';
import { TranslateSelectorProps } from './TranslateSelector.props';

//STORE
import { AppState } from 'store';
import { openSelectModal } from 'store/tramslateModal';

//COMPONENTS
import Icon from 'components/Icon';

//STYLES
import {
  TranslateSelectorContainer,
  TranslateSelectorLanguage,
  TranslateSelectorAvatar,
  TranslateSelectorIcon,
} from './TranslateSelector.styled';

const TranslateSelector = ({ device, type, id }: TranslateSelectorProps) => {
  const { i18n } = useTranslation('translate');

  const dispatch = useDispatch();

  const { translateSelect } = useSelector(
    (state: AppState) => state.translateModal
  );

  const [language, setLanguage] = useState<TranslationSelect | undefined>(
    undefined
  );

  useEffect(() => {
    if (
      translateSelect.find(
        (lang) => lang.idProduct === id || lang.idOrganization === id
      )
    ) {
      let language = translateSelect.find(
        (lang) => lang.idProduct === id || lang.idOrganization === id
      );
      setLanguage(language);
      if (language) i18n.changeLanguage(LANGUAGE_MAP[language.short_name].lang);
    } else {
      i18n.changeLanguage('es-ES');
    }
  }, [id, translateSelect, i18n]);

  const handleSelectLanguage = () => {
    dispatch(
      openSelectModal({
        isOpen: true,
        isHiddenClose: false,
      })
    );
  };

  return (
    <>
      {language ? (
        <TranslateSelectorContainer
          onClick={handleSelectLanguage}
          type={device}
        >
          <TranslateSelectorAvatar
            src={LANGUAGE_MAP[language.short_name].icon}
          />

          <TranslateSelectorLanguage>
            {language.long_name}
          </TranslateSelectorLanguage>

          <TranslateSelectorIcon>
            <Icon iconName="chevronDown" />
          </TranslateSelectorIcon>
        </TranslateSelectorContainer>
      ) : (
        <></>
      )}
    </>
  );
};

export default TranslateSelector;
