import merge from 'lodash/merge';

import { THEME } from './Theme.props';

const COLORS = [
  'border',
  'backgroundWhite',
  'backgroundGray',
  'fontMain',
  'fontSecondary',
  'backgroundGrayLight',
  'black',
  'brand',
  'active',
  'blueLigth',
  'brandHover',
  'turquoise',
  'white',
  'placeholder',
  'buttonGradient',
  'buttonGradientHover',
  'info',
  'error',
  'success',
  'warning',
  'blueGray',
  'yellow',
  'green',
  'winePrimary',
  'wineBlack',
  'wineGray',
  'wineGrayLigth',
  'wineSecondary',
  'wineTertiary'
] as const;

export type Colors = typeof COLORS[number];

export function isColor(key: string): key is Colors {
  return COLORS.includes(key as Colors);
}

const colors: Record<Colors, string> = {
  border: '#EDEDEE',
  backgroundWhite: '#FCFCFC',
  backgroundGray: '#f9f9f9',
  backgroundGrayLight: '#f5f5f5',
  fontMain: '#210018',
  fontSecondary: '#A89FAC',
  black: '#100316',
  brand: '#4E63A5',
  active: '#651366',
  blueLigth: '#8298D5',
  brandHover: '#8695c6',
  turquoise: '#009B97',
  white: '#fff',
  placeholder: '#D0CED1',
  buttonGradient: 'linear-gradient(90deg, #4E63A5 0%, #8298D5 100%)',
  buttonGradientHover: 'linear-gradient(90deg, #651366 0%, #4E63A5 100%)',
  info: '#4E63A5',
  error: '#E7204E',
  success: '#009B97',
  warning: '#F0C505',
  blueGray: '#2E384D',
  yellow: '#F0C505',
  green: '#17a4a0',
  winePrimary: '#FF2E5D',
  wineSecondary: '#B51C45',
  wineTertiary: '#FF9196',
  wineBlack: '#333333',
  wineGray: '#666666',
  wineGrayLigth: '#D9D9D9'
};

//FONTS
export const FONTS = ['main', 'wine'] as const;

export type Font = typeof FONTS[number];

//Fonts for Text Properties
export const fontsMapped: Record<Font, string> = {
  main: 'Montserrat',
  wine: 'Rozha One',
};

export const fonts = {
  main: 'Montserrat',
  wine: 'Rozha One',
};

export const baseTheme = {
  colors,
  fonts,
};

const lightTheme = { ...baseTheme };

const darkTheme = merge({}, baseTheme, {
  // TODO: implement dark theme
});

export const themes = {
  [THEME.Light]: lightTheme,
  [THEME.Dark]: darkTheme,
};

export interface BaseTheme extends Readonly<typeof baseTheme> {}

declare module 'styled-components' {
  export interface DefaultTheme extends BaseTheme {}
}
