import styled, { css } from 'styled-components';

import { Text } from 'components/Text';

const optionSelect = css`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin: 0 0 0.5rem;
  &:last-child {
    margin: 0;
  }
`;

export const SelectLanguageContainer = styled.div`
  max-width: 21.875rem;
  margin-left: 20px;
`;
export const SelectLanguageLabel = styled.label`
  padding-left: 16px;
  color: ${({ theme }) => theme.colors.placeholder};
  font-weight: bold;
  font-size: 8px;
  display: inline-block;
  line-height: 10px;
  margin-bottom: 6px;
  text-transform: uppercase;
  letter-spacing: 0.75px;
`;
export const SelectLanguageInput = styled.div<{ background?: string }>`
  box-sizing: border-box;
  font-size: inherit;
  font-family: inherit;
  -webkit-appearance: none;
  outline: none;
  background: transparent;
  color: inherit;
  font-weight: 600;
  margin: 0;
  border: 1px solid #ededee;
  border-radius: 0;
  width: 100%;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  border: none;
`;
export const SelectLanguageInputChevron = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 14px;
    height: 14px;
    justify-self: center;
    align-self: center;
    cursor: pointer;
    &.active_close {
      display: none;
      visibility: hidden;
    }
    &.active_arrow {
      transform: rotate(180deg);
    }
  }
`;

export const SelectLanguageOptions = styled.div`
  position: absolute;
  top: 1.75rem;
  background: #fff;
  right: 0;
  padding: 0.75rem 1rem;
  z-index: 99999;
  box-shadow: rgba(16, 3, 22, 0.06) 0px 2px 10px 0px;
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
  border: 1px solid #f1f1f1;
  width: 130px;
`;
export const SelectLanguageOptionFlag = styled.img`
  /* max-width: 1.5rem; */
  width: 30px;
  height: 30px;
  object-fit: cover;
  object-position: center;
  border-radius: 30px;
`;
export const SelectLanguageOptionName = styled(Text)`
  font-size: 0.75rem;
  line-height: 0.9375rem;
  color: ${({ theme }) => theme.colors.black};
`;
export const SelectLanguageOption = styled.div<{ active: boolean }>`
  ${optionSelect};
  &:hover {
    ${SelectLanguageOptionName} {
      font-weight: bold;
    }
  }

  ${({ active }) =>
    active &&
    `
    ${SelectLanguageOptionName} {
      font-weight: bold;
    }
  `}
`;
export const SelectLanguageInputSelect = styled.div`
  ${optionSelect};
  ${SelectLanguageOptionFlag} {
    /* max-height: 15px; */
  }
`;

export const SelectLanguageInputBox = styled.div<{ isOpen: boolean }>`
  display: grid;
  grid-template: 1fr / 1fr 40px;
  /* border: 1px solid ${({ theme }) => theme.colors.placeholder}; */
  position: relative;
  cursor: pointer;
  /* width: 180px; */
  &:focus {
    outline: none;
  }

  ${({ isOpen }) =>
    isOpen &&
    `
    ${SelectLanguageInputChevron} {
      transform: rotate(-180deg);
    }
  `}
`;
