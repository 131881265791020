import React from 'react';

//STYLES
import { HashPlaceholderToolTipContainer } from './HashPlaceholder.styled';

interface HashPlaceholderToolTipProps {
  taxId: string;
  isVisible: boolean;
}

const HashPlaceholderToolTip = ({
  taxId,
  isVisible,
}: HashPlaceholderToolTipProps) => {
  return (
    <HashPlaceholderToolTipContainer isVisible={isVisible}>
      {taxId}
    </HashPlaceholderToolTipContainer>
  );
};

export default HashPlaceholderToolTip;
