import { defaultTextStyles } from './Text.styled';

const TEXT_PRESETS = [
  'huge',
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'small',
  'tiny',
  'xstiny',
  'little',
  'link',
] as const;
export type TextPresets = typeof TEXT_PRESETS[number];
export function isTextPreset(key: string): key is TextPresets {
  return TEXT_PRESETS.includes(key as TextPresets);
}

const huge = defaultTextStyles('5.5rem', '0px', `line-height: 5.5rem;`);
const h1 = defaultTextStyles('4.938rem', '0px', `line-height: 4.938rem;`);
const h2 = defaultTextStyles('3.375rem', '0px;', `line-height: 3.375rem;`);
const h3 = defaultTextStyles('2.25rem', '0px', `line-height: 2.25rem;`);
const h4 = defaultTextStyles('1.4rem', '0px', `line-height: 1.4rem;`);
const h5 = defaultTextStyles('1.125rem', '0px', `line-height: 1.125rem;`);
const h6 = defaultTextStyles('1rem', '0px', `line-height: 1rem;`);
const small = defaultTextStyles('0.875rem', '0px', `line-height: 0.875rem;`);
const tiny = defaultTextStyles('0.75rem', '0px', `line-height: 0.75rem;`);
const xstiny = defaultTextStyles('0.625rem', '0px', `line-height: 0.625rem;`);
const little = defaultTextStyles('0.5rem', '0px', `line-height: 0.5rem;`);
const link = defaultTextStyles(
  '1rem',
  '0px',
  `
  display: inline-block;
  line-height: 1rem;
  transition: all 0.25s ease-in;
  cursor: pointer;
`
);

export const textPresets: Record<TextPresets, any> = {
  huge,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  small,
  tiny,
  xstiny,
  little,
  link,
};
