import styled from "styled-components";

export const PicturesContainer = styled.div<{sizeArray: number}>`
  .swiper {
    overflow: visible;
  }
  .swiper-slide {
    height: 600px;
  }
  .swiper-pagination {
    display: grid;
    width: 2.5rem;
    height: 0.5rem;
    grid-template: 1fr / repeat(${({ sizeArray }) => sizeArray}, 0.5rem);
    gap: 0 2rem;
    left: 0;
    bottom: -1.5rem;
    .swiper-pagination-bullet {
      margin: 0;
      width: 0.5rem;
      height: 0.5rem;
      opacity: 1;
      border-radius: 0.5rem;
      background-color: ${({ theme }) => theme.colors.wineGrayLigth};
      &.swiper-pagination-bullet-active {
        background-color: ${({ theme }) => theme.colors.wineBlack};
      }
    }
  }
`
export const PicturesItem = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`