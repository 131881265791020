import gql from 'graphql-tag';

export const blockNitiveStatsQuery = gql`
  query blocknitiveStats {
    blocknitiveStats {
      blocks {
        header {
          dataHash
          number
          previousHash
        }
        data {
          header {
            type
            version
            timestamp
            channelId
            txId
            epoch
          }
          creator {
            mspid
          }
          txValidation
        }
      }
      blockCount
      transactionsCount
    }
  }
`;

export const blockProofPointQuery = gql`
  query proofPoint($pk: String) {
    proofPoint(id: $pk) {
      blockId
      transactionId
      blocknitive {
        header {
          dataHash
        }
        data {
          header {
            timestamp
          }
        }
      }
    }
  }
`;
export const blockProofPointQueryLot = gql`
  query blocknitiveLot($pk: String) {
    blocknitiveLot(id: $pk) {
      blockId
      transactionId
      blocknitive {
        header {
          dataHash
        }
        data {
          header {
            timestamp
          }
        }
      }
    }
  }
`;
