import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type TranslationSelect = {
  short_name: string;
  long_name: string;
  idProduct: string;
  idOrganization: string;
};

interface TanslateStoreProps {
  isOpen: boolean;
  translations: Record<string, string> | null;
  translateSelect: TranslationSelect[];
  isHiddenClose: boolean;
}

const initialState: TanslateStoreProps = {
  isOpen: false,
  translations: null,
  translateSelect: JSON.parse(
    localStorage.getItem('translateSelect') || '[]'
  ) as TranslationSelect[],
  isHiddenClose: true,
};

type initialStateType = typeof initialState;

const translateModalSlice = createSlice({
  name: 'translateModal',
  initialState,
  reducers: {
    openModal(
      state: initialStateType,
      {
        payload,
      }: PayloadAction<{
        isOpen: boolean;
        translations: Record<string, string> | null;
        isHiddenClose: boolean;
      }>
    ) {
      return {
        ...state,
        isOpen: payload.isOpen,
        translations: payload.translations,
        isHiddenClose: payload.isHiddenClose,
      };
    },
    openSelectModal(
      state: initialStateType,
      {
        payload,
      }: PayloadAction<{
        isOpen: boolean;
        isHiddenClose: boolean;
      }>
    ) {
      return {
        ...state,
        isOpen: payload.isOpen,
        isHiddenClose: payload.isHiddenClose,
      };
    },
    setTranslations(state: initialStateType, action) {
      return {
        ...state,
        translations: action.payload.translations,
      };
    },
    setTranslateSelect(state: initialStateType, action) {
      localStorage.setItem(
        'translateSelect',
        JSON.stringify(action.payload.translateSelect)
      );

      return {
        ...state,
        translateSelect: action.payload.translateSelect,
      };
    },
    closeModal(state: initialStateType) {
      return {
        ...state,
        isOpen: false,
        isHiddenClose: true,
      };
    },
  },
});

export const {
  openModal,
  openSelectModal,
  setTranslateSelect,
  setTranslations,
  closeModal,
} = translateModalSlice.actions;

export default translateModalSlice;
