import React from 'react';
import { useMediaQuery } from '@react-hook/media-query';
import { useTranslation } from 'react-i18next';
import { ProductTabsTechDetail } from 'types/product';
//STYLES
import {
  TemplateTech,
  TemplateImage,
  TemplateItem,
  TemplateTechHeader,
  TemplateTechFooter,
  TemplateTitle,
  TemplateSubtitle,
  TemplateSeparator,
  TemplateTechDetail,
  TemplateTechPrincipal,
  TemplateTechComposition,
  TemplateTechOther,
  TemplateLabel,
  TemplateText,
} from './Template.styled';
import RenderHtml from 'components/RenderHtml/RenderHtml';

interface ProductTabsTechProp {
  tech: ProductTabsTechDetail;
  wine?: boolean;
}

const ProductTabsTech = ({ tech, wine }: ProductTabsTechProp) => {
  const matches = useMediaQuery('only screen and (max-width: 768px)');

  const { t } = useTranslation('product');

  return (
    <TemplateTech wine={wine}>
      {!wine && (
        <TemplateItem>
          {tech.image && (
            <TemplateImage
              src={tech.image}
              margin={matches ? '0 0 20px' : '0'}
            />
          )}
          {matches && (
            <>
              <TemplateTechOther>
                {tech.otherDetails.map((other) => (
                  <TemplateTechDetail key={other.id}>
                    <TemplateLabel>{other.title}</TemplateLabel>
                    <TemplateText>
                      <RenderHtml text={other.description} noOverlay />
                    </TemplateText>
                  </TemplateTechDetail>
                ))}
              </TemplateTechOther>
            </>
          )}
        </TemplateItem>
      )}

      <TemplateItem>
        <TemplateTechHeader>
          <TemplateTitle>
            {tech.title}{' '}
            <TemplateSubtitle>{`- ${tech.subtitle}`}</TemplateSubtitle>
          </TemplateTitle>
        </TemplateTechHeader>
        <TemplateSeparator />
        <TemplateTechPrincipal>
          {tech.principalDetails.map((principal) => (
            <TemplateTechDetail key={principal.id}>
              <TemplateLabel>{principal.title}</TemplateLabel>
              <TemplateText>
                <RenderHtml text={principal.description} noOverlay />
              </TemplateText>
            </TemplateTechDetail>
          ))}
        </TemplateTechPrincipal>
        {!matches && (
          <>
            <TemplateTechOther>
              {tech.otherDetails.map((other) => (
                <TemplateTechDetail key={other.id}>
                  <TemplateLabel>{other.title}</TemplateLabel>
                  <TemplateText>
                    <RenderHtml text={other.description} noOverlay />{' '}
                  </TemplateText>
                </TemplateTechDetail>
              ))}
            </TemplateTechOther>
          </>
        )}

        <TemplateTechFooter>{tech.footer}</TemplateTechFooter>
      </TemplateItem>
    </TemplateTech>
  );
};

export default ProductTabsTech;
