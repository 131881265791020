import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ProductTabsPdfs, ProofPointEvidence } from 'types/product';
//STORE
import { openModal as openEvidenceModal } from 'store/evidenceModal';
//COMPONENTS
import RenderHtml from 'components/RenderHtml/RenderHtml';
//STYLES
import {
  TemplateIngredient,
  TemplateIngredientItem,
  TemplateTechDetail,
  TemplateLabel,
  TemplateText,
  TemplateLink,
} from './Template.styled';

interface ProductTabsIngredientProp {
  documents: ProductTabsPdfs;
  wine?: boolean;
}

const ProductTabsPdf = ({ documents, wine }: ProductTabsIngredientProp) => {
  const { t } = useTranslation('product');

  const dispatch = useDispatch();

  const handleOpenEvidenceModal = useCallback(
    (evidence: ProofPointEvidence) => {
      dispatch(
        openEvidenceModal({
          evidence,
        })
      );
    },
    [dispatch]
  );

  return (
    <TemplateIngredient wine={wine}>
      {documents.documents.map((document, index) => (
        <TemplateIngredientItem key={index}>
          <TemplateTechDetail>
            <TemplateLabel>{document.title}</TemplateLabel>
            <TemplateText style={{ margin: '0 0 0.5rem' }}>
              <RenderHtml text={document.description} noOverlay />
            </TemplateText>
            <TemplateLink
              onClick={() => {
                handleOpenEvidenceModal({
                  evidenceName: document.title,
                  file: document.document as string,
                  pk: '',
                  expirationDate: '',
                });
              }}
            >
              {t('content.clickToOpen')}
            </TemplateLink>
          </TemplateTechDetail>
        </TemplateIngredientItem>
      ))}
    </TemplateIngredient>
  );
};

export default ProductTabsPdf;
