import styled from 'styled-components';


export const RenderHtmlOverlay = styled.div`
  position: absolute;
  z-index: 999;
  background: linear-gradient(180deg,rgba(255,255,255,0) 0%,rgba(255,255,255,0.5) 10%,rgba(255,255,255,1) 100%);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`
export const RenderHtmlText = styled.div``
export const RenderHtmlWrapper = styled.div<{active: boolean;}>`
  position: relative;
  overflow: hidden;

  ${({ active }) => active ? `
    max-height: 150px;
  ` : `
    margin: 0 0 1rem;
  `}
`

export const RenderHtmlContent = styled.div<{
  isVisible?: boolean;
}>`
  margin-bottom: 0.5rem;

  /* ${({ isVisible }) =>
    !isVisible &&
    `
    max-height: 100px;
    box-sizing: content-box;
    overflow: hidden;
  `} */
  ul {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
`;

export const RenderHtmlAction = styled.span`
  font-size: 0.75rem;
  line-height: 0.75rem;
  color: ${({ theme }) => theme.colors.brand};
  cursor: pointer;
  display: inline-block;
  transition: all 0.25s ease-in-out;
  font-weight: bold;
  &:hover {
    color: ${({ theme }) => theme.colors.active};
  }
`;

export const RenderHtmlContainer = styled.div<{
  align: string;
  margin: string;
  wine?: boolean;
}>`
  font-size: 14px;
  line-height: 27px;
  margin: ${({ margin }) => margin};
  width: 100%;
  text-align: ${({ align }) => align};
  color: ${({ theme }) => theme.colors.black};
  ${({ wine, theme, margin }) => wine && `
    color: ${theme.colors.wineGray};
    max-width: 475px;
    margin: ${margin};
    ${RenderHtmlAction} {
      color: ${theme.colors.winePrimary};
      &:hover {
        color: ${theme.colors.wineBlack};
      }
    }
  `}
`;

