import React from 'react';
import { useTranslation } from 'react-i18next';

//PROPS TYPE
import { OrganizationDesktopViewProps } from './OrganizationDesktopView.props';

//COMPONENTS
import SectionHeader from 'components/SectionHeader';
import FeatureProofPoints from 'components/FeatureProofPoints';
import Seals from 'components/Seals';
import SocialShare from 'components/SocialShare';
import CustomerReviews from 'components/CustomerReviews';
import OrganizationInfo from '../OrganizationInfo';

//STYLES
import {
  OrganizationSection,
  OrganizationColumns,
  OrganizationContent,
} from '../../Organization.styled';

const OrganizationDesktopView = ({
  organization,
}: OrganizationDesktopViewProps) => {
  const { t } = useTranslation('organization');

  return (
    <OrganizationSection>
      <OrganizationColumns>
        <OrganizationContent>
          <SectionHeader text={t('header.organizationInfo')} />
          {organization &&
            organization.proofPoints &&
            organization.proofPoints.length > 0 && (
              <FeatureProofPoints featuredProofs={organization.proofPoints} />
            )}
          {organization &&
            organization.proofPoints &&
            organization.proofPoints.length > 0 && (
              <Seals featuredProofs={organization.proofPoints} />
            )}
          {organization && <OrganizationInfo organization={organization} />}
          {organization && organization.logo && (
            <SocialShare type="organization" organization={organization} />
          )}

          {organization &&
            organization.reviewStats &&
            organization.reviews &&
            organization.reviews.edges && (
              <CustomerReviews
                reviewStats={organization.reviewStats}
                reviews={organization.reviews.edges}
              />
            )}

          {/* 
     

      {organization && organization.description && (
        <RenderHtml text={organization.description} />
      )}

      {organization && organization.images && organization.images.length > 0 && (
        <ProductImages images={organization.images} />
      )}

      {organization && organization.thumbnail && <SocialShare organization={organization} />}

      {organization &&
        organization.reviewStats &&
        product.reviews &&
        product.reviews.edges && (
          <CustomerReviews
            reviewStats={product.reviewStats}
            reviews={product.reviews.edges}
          />
        )} */}
        </OrganizationContent>
      </OrganizationColumns>
    </OrganizationSection>
  );
};

export default OrganizationDesktopView;
