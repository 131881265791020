import { ProductProofPoint } from 'types/product';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isOpen: false,
  proof: {} as ProductProofPoint,
};

const proofPointModalSlice = createSlice({
  name: 'proofPointModal',
  initialState,
  reducers: {
    openModal: (state: typeof initialState, action) => {
      return {
        ...action.payload,
        isOpen: true,
      };
    },
    closeModal: () => {
      return initialState;
    },
  },
});

export const { openModal, closeModal } = proofPointModalSlice.actions;

export default proofPointModalSlice;
