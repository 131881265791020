import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useQuery } from '@apollo/react-hooks';

//QUERIES
import { blockProofPointQueryLot } from 'queries/blocknitive';

//STORE
import { AppState } from 'store';
import { closeModal } from 'store/blockchainDetailModal';

//PROPS TYPE
import { BlockchainDetailModalProps } from './BlockchainDetailModal.props';

//COMPONETNS
import Modal from 'components/Modal';
import BlockchainDetailModalHeader from './BlockchainDetailModalHeader';
import BlockchainDetailModalContentLot from './BlockchainDetailModalContentLot';
import BlockchainDetailModalLoading from './BlockchainDetailModalLoading';

//STYLED
import { ModalContent } from 'components/Modal/Modal.styled';
import {
  BlockchainDetailModalContainer,
  BlockchainDetailModalButton,
} from './BlockchainDetailModal.styled';

const BlockchainDetailModal = ({ type, wine }: BlockchainDetailModalProps) => {
  const { t } = useTranslation('modalBlockChain');

  const dispatch = useDispatch();

  const { isOpen, proof } = useSelector(
    (state: AppState) => state.blockchainDetailModal
  );

  const { data: proofBlockData, loading } = useQuery(blockProofPointQueryLot, {
    variables: { pk: proof && proof.pk },
  });

  const handleCloseModal = useCallback(() => {
    dispatch(closeModal());
  }, [dispatch]);

  return (
    <Modal
      isOpen={isOpen}
      close={handleCloseModal}
      size="big"
      backcolor="black"
      wine={wine}
    >
      <ModalContent>
        <BlockchainDetailModalContainer>
          {loading ? (
            <BlockchainDetailModalLoading />
          ) : (
            <>
              <BlockchainDetailModalHeader />
              {proofBlockData && (
                <BlockchainDetailModalContentLot
                  proofBlockData={proofBlockData.blocknitiveLot}
                  type={type}
                />
              )}

              <BlockchainDetailModalButton onClick={handleCloseModal}>
                {t('content.back')}
              </BlockchainDetailModalButton>
            </>
          )}
        </BlockchainDetailModalContainer>
      </ModalContent>
    </Modal>
  );
};

export default BlockchainDetailModal;
