import styled, { keyframes } from 'styled-components';

const ldsRing = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const LoadingInner = styled.div`
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 22px;
  height: 22px;
  margin: 0;
  border: 3px solid ${({ theme }) => theme.colors.brand};
  border-radius: 50%;
  animation: ${ldsRing} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: ${({ theme }) => theme.colors.brand} transparent transparent
    transparent;
  &:nth-child(1) {
    animation-delay: -0.45s;
  }
  &:nth-child(2) {
    animation-delay: -0.3s;
  }
  &:nth-child(3) {
    animation-delay: -0.15s;
  }
`;
export const Loading = styled.div`
  display: inline-block;
  position: relative;
  width: 22px;
  height: 22px;
  margin-right: 10px;
`;

interface LoadingSpinnerContent {
  margin?: string;
}

export const LoadingSpinnerContent = styled.div<LoadingSpinnerContent>`
  display: flex;
  align-items: center;
  margin: ${({ margin }) => (margin ? margin : '20px 0 0')};
`;
export const LoadingMessage = styled.p`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.brand};
`;
