import React from 'react';
import { useSelector } from 'react-redux';

//ASSETS
import Background from 'assets/images/background.png';
import BackgroundPrivate from 'assets/images/errorPrivate.jpg';

//PROPS TYPE
import { Error404Props } from './Error404.props';

//STORE
import { AppState } from 'store';

//STYLES
import {
  Error404Container,
  Error404Content,
  Error404Title,
  Error404Desctiption,
  Error404Link,
} from './Error404.styled';

const Error404 = ({
  issetProduct,
  issetLot,
  isPrivate,
  isNotQrUsed,
  errorUrl,
}: Error404Props) => {
  const organization = useSelector(
    (state: AppState) => state.organization.organization
  );

  return (
    <Error404Container
      style={{
        backgroundImage: `url(${isPrivate ? BackgroundPrivate : Background})`,
      }}
    >
      <>
        {isNotQrUsed && (
          <Error404Content>
            <Error404Title>En proceso</Error404Title>
            <Error404Desctiption>
              Estamos trabajando en ello
              <br />
              ¡casi lo tenemos!
            </Error404Desctiption>
            <Error404Link href="https://swearit.io/">
              Ir a Swearit.io
            </Error404Link>
          </Error404Content>
        )}
        {isPrivate && (
          <Error404Content>
            <Error404Title>En proceso</Error404Title>
            <Error404Desctiption>
              Estamos trabajando en ello
              <br />
              ¡casi lo tenemos!
            </Error404Desctiption>
            {errorUrl !== '' && (
              <Error404Link href={`${errorUrl}`}>Ir a {errorUrl}</Error404Link>
            )}
          </Error404Content>
        )}
        {(issetProduct || issetLot) && (
          <Error404Content>
            <Error404Title>Es privado</Error404Title>
            <Error404Desctiption>
              No se puede mostrar este {issetProduct ? 'producto' : 'lote'}
            </Error404Desctiption>
            <Error404Link href="https://swearit.io/">
              Ir a Swearit.io
            </Error404Link>
          </Error404Content>
        )}
        {!issetProduct && !issetLot && !isPrivate && !isNotQrUsed && (
          <Error404Content>
            <Error404Title>404</Error404Title>
            <Error404Desctiption>
              ¡Vaya! No podemos encontrar
              <br />
              lo que estás buscando.
            </Error404Desctiption>
            <Error404Link href="https://swearit.io/">
              Ir a Swearit.io
            </Error404Link>
          </Error404Content>
        )}
      </>

      {/* <div className="bg-white px-6 pt-8 pb-10 flex flex-col items-center">
       


        {!issetProduct && !issetLot && (
          <h1 className="text-big-title text-center font-bold mb-4">404</h1>
        )}

        {issetProduct && (
          <p className="text-body text-center font-medium mb-6">
            This product is private
          </p>
        )}
        {issetLot && (
          <p className="text-body text-center font-medium mb-6">
            This lot is private
          </p>
        )}
        {!issetProduct && !issetLot && (
          <p className="text-body text-center font-medium mb-6">
            ¡Vaya! No podemos encontrar lo que estás buscando
          </p>
        )}

        <a
          className="bg-gradient-button p-2 min-w-button text-white text-center"
          href="https://swearit.io/"
        >
          Volver
        </a>
      </div> */}
    </Error404Container>
  );
};
export default Error404;
