import { createGlobalStyle } from 'styled-components';

export const ThemeGlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
  }
  body {
    font-family: "Montserrat", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 16px;

    @media only screen and (max-width: 768px) and (orientation: landscape) {
      overflow: hidden;
    }

  }
  input, select { font-size: 100%; }
  
  @media screen and (device-aspect-ratio: 2/3) {
      select, textarea, input[type="text"], input[type="password"],
      input[type="datetime"], input[type="datetime-local"],
      input[type="date"], input[type="month"], input[type="time"],
      input[type="week"], input[type="number"], input[type="email"],
      input[type="url"]{ font-size: 16px !important; font-size: max(1.1rem, 16px) !important; }
  }

  @media screen and (device-aspect-ratio: 40/71) {
      select, textarea, input[type="text"], input[type="password"],
      input[type="datetime"], input[type="datetime-local"],
      input[type="date"], input[type="month"], input[type="time"],
      input[type="week"], input[type="number"], input[type="email"],
      input[type="url"]{ font-size: 16px !important; font-size: max(1.1rem, 16px) !important; }
  }

  @media screen and (device-aspect-ratio: 375/667) {
      select, textarea, input[type="text"], input[type="password"],
      input[type="datetime"], input[type="datetime-local"],
      input[type="date"], input[type="month"], input[type="time"],
      input[type="week"], input[type="number"], input[type="email"], 
      input[type="tel"], input[type="url"]{ font-size: 16px !important; font-size: max(1.1rem, 16px) !important;}
  }

  @media screen and (device-aspect-ratio: 9/16) {
      select, textarea, input[type="text"], input[type="password"],
      input[type="datetime"], input[type="datetime-local"],
      input[type="date"], input[type="month"], input[type="time"],
      input[type="week"], input[type="number"], input[type="email"],
      input[type="tel"], input[type="url"]{ font-size: 16px !important; font-size: max(1.1rem, 16px) !important; }
  }
  .hidden-overflow {
    overflow: hidden;
  }
`;
