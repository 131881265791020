import React from 'react';

//COMPONENTS
import SkeletonLoader from 'components/SkeletonLoader';

const SkeletonDescription = () => {
  return (
    <SkeletonLoader width="100%" height="auto" background="transparent">
      <SkeletonLoader
        width="9rem"
        height="0.875rem"
        display="block"
        margin="0 0 1.25rem"
      />
      <SkeletonLoader
        width="100%"
        height="0.875rem"
        display="block"
        margin="0 0 1rem"
      />
      <SkeletonLoader
        width="45%"
        height="0.875rem"
        display="block"
        margin="0 0 1.5rem"
      />
      <SkeletonLoader
        width="7rem"
        height="0.625rem"
        display="block"
        margin="0 0 2rem"
      />
    </SkeletonLoader>
  );
};

export default SkeletonDescription;
