import gql from 'graphql-tag';

export const widgetsSpreadQuery = gql`
  query widget($id: String!) {
    widget(id: $id) {
      name
      pk
      description
      icon {
        value
        icon
        label
      }
      mediaEvidences {
        name
        url
        type
      }
      evidences {
        file
        evidenceName
        pk
      }
      conditions {
        value
        type
        typeDisplay
        unit
      }
      verifier {
        pk
        name
        description
        logo(size: "250x250") {
          url
        }
      }
      organization {
        name
        pk
        logo(size: "45x45") {
          url
        }
      }
      product {
        name
        description
        internalId
        pk
        brand
        thumbnail(size: "40x40") {
          url
        }
        organization {
          name
          pk
          logo(size: "45x45") {
            url
          }
        }
        images {
          id
          url(size: "266x266")
          order
        }
        featuredProofPoints {
          name
          pk
          description
          createdAt
          mediaEvidences {
            name
            url
            type
          }
          shouldShowWidget
          icon {
            value
            icon
            label
          }
          evidences {
            file
            evidenceName
            pk
          }
          conditions {
            value
            type
            typeDisplay
            unit
          }
          verifier {
            name
            description
            logo(size: "250x250") {
              url
            }
          }
        }
        journey {
          name
          milestones {
            id
            name
            startDate
            endDate
            internalId
            description
            icon
            location {
              name
            }
            createdAt
            image(size: "700x700") {
              url
            }
            proofPoints {
              name
              pk
              shouldShowWidget
              description
              icon {
                value
                icon
                label
              }
              mediaEvidences {
                name
                url
                type
              }
              createdAt
              conditions {
                value
                type
                typeDisplay
                unit
              }
              evidences {
                file
                evidenceName
                pk
              }
              verifier {
                name
                description
                logo(size: "250x250") {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`;
