import React from 'react';

//PROPS TYPE
import { ButtonProps } from './Button.props';

//COMPONENTS
import { ButtonContainer, ButtonText } from './Button.styled';

const Button = ({
  text,
  children,
  onClick,
  wine,
  outline,
  disabled,
  ...props
}: ButtonProps) => {
  const content = children ? children : text;

  return (
    <ButtonContainer
      {...props}
      onClick={onClick}
      wine={wine}
      outline={outline}
      disabled={disabled}
    >
      <ButtonText>{content}</ButtonText>
    </ButtonContainer>
  );
};

export default Button;
