import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

//PROPS TYPE
import { OrganizationMobileViewProps } from './OrganizationMobileView.props';

//STORE
import { AppState } from 'store';

//COMPONENTS
import SectionHeader from 'components/SectionHeader';
import FeatureProofPoints from 'components/FeatureProofPoints';
import Seals from 'components/Seals';
import CustomerReviews from 'components/CustomerReviews';
import OrganizationInfo from '../OrganizationInfo';

//STYLES
import {
  OrganizationSection,
  OrganizationContent,
} from '../../Organization.styled';

const OrganizationMobileView = ({
  organization,
}: OrganizationMobileViewProps) => {
  const { t } = useTranslation('organization');
  const { tab } = useSelector((state: AppState) => state.mobileNav);

  return (
    <OrganizationSection>
      <OrganizationContent>
        {(() => {
          switch (tab) {
            case 'info':
              return (
                <>
                  <SectionHeader text={t('header.organizationInfo')} />
                  {organization && organization.proofPoints.length > 0 && (
                    <FeatureProofPoints
                      featuredProofs={organization.proofPoints}
                    />
                  )}

                  {organization && organization.proofPoints.length > 0 && (
                    <Seals featuredProofs={organization.proofPoints} />
                  )}

                  {organization && (
                    <OrganizationInfo organization={organization} />
                  )}
                </>
              );
            default:
              return (
                <>
                  {organization && (
                    <CustomerReviews
                      reviewStats={organization.reviewStats}
                      reviews={organization.reviews.edges}
                    />
                  )}
                </>
              );
          }
        })()}
      </OrganizationContent>
    </OrganizationSection>
  );
};

export default OrganizationMobileView;
