import React, { useMemo, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ReactGA from 'react-ga4';
import useClipboard from 'react-use-clipboard';

//PROPS TYPE
import { OrganizationSocial } from 'types/organization';
import { SocialShareProps } from './SocialShare.props';
import { Icons } from 'components/Icon/Icon.props';

//STORE
import { AppState } from 'store';

//COMPONENTS
import Icon from 'components/Icon';

//STYLED
import {
  SocialShareContainer,
  SocialShareLink,
  SocialShareCopiedtext,
} from './SocialShare.styled';

const SocialShare = ({
  product,
  organization,
  onClick,
  mobile,
  type,
}: SocialShareProps) => {
  const { t } = useTranslation('general');

  const shortUrl = useSelector(
    (state: AppState) => state.link.link.secureShortURL
  );

  const [isCopied, setCopied] = useClipboard(shortUrl, {
    successDuration: 3000,
  });

  const getHandler = (array: OrganizationSocial[], type: string) => {
    const handler = array.find((social) => social.type === type);

    return handler && handler.handle ? handler.handle : false;
  };

  const SOCIAL_ICONS = useMemo(() => {
    const shareImage: string = encodeURIComponent(
      type === 'product'
        ? product && product.images && product.images.length > 0
          ? (product.images[0].url as string)
          : ('https://engine-static.s3.amazonaws.com/img/placeholders/placeholder540x540.png' as string)
        : organization
        ? organization.logo.url
        : 'https://engine-static.s3.amazonaws.com/img/placeholders/placeholder540x540.png'
    );
    const socials = product
      ? product && product.organization.socialChannels
      : organization
      ? organization.socialChannels
      : null;

    const tags =
      product &&
      product.featuredProofPoints &&
      product.featuredProofPoints.length > 0
        ? product.featuredProofPoints.slice(0, 3)
        : organization &&
          organization.proofPoints &&
          organization.proofPoints.length > 0
        ? organization.proofPoints.slice(0, 3)
        : [];
    const [tag1, tag2, tag3] = tags.map((proof: any, index: any) => {
      let nameProof = proof.name ? proof.name : proof.node.name;
      let number = index + 1;
      return tags.length === number ? nameProof : `${nameProof}, `;
    });
    const tagsText =
      tags.length > 0 ? `${tag1}${tag2 ? tag2 : ''}${tag3 ? tag3 : ''}...` : '';

    return [
      {
        name: 'copy' as Icons,
        onClick: () => {
          setCopied();
          if (mobile && onClick) onClick();
        },
        href: (summary: string) => '#',
        summary: null,
      },
      {
        name: 'whatsapp' as Icons,
        href: (summary: string) => `https://wa.me/?text=${summary}${shortUrl}`,
        onClick: onClick,
        summary: encodeURIComponent(
          `${tagsText ? `*${tagsText}* ` : ''}${
            product ? product.name : organization ? organization.name : ''
          }!\nMíralo en Swear IT, te va a gustar\n`
        ),
      },
      {
        name: 'telegram' as Icons,
        href: (summary: string) =>
          `https://telegram.me/share/url?url=${shortUrl}&text=${summary}`,
        onClick: onClick,
        summary:
          (product || organization) &&
          encodeURIComponent(
            `${tagsText ? `${tagsText} ` : ''}${
              product ? product.name : organization ? organization.name : ''
            }!\nMíralo en Swear IT, te va a gustar\n@swearit_\n`
          ),
      },
      {
        name: 'twitter' as Icons,
        href: (summary: string) =>
          `https://twitter.com/intent/tweet?text=${summary}&url=${shortUrl}`,
        onClick: onClick,
        summary:
          (product || organization) &&
          encodeURIComponent(
            `${tagsText ? `${tagsText} ` : ''}${
              product ? product.name : organization ? organization.name : ''
            }!\nMíralo en Swear IT, te va a gustar\n@swearit_ ${
              getHandler(socials as OrganizationSocial[], 'TWITTER')
                ? `${getHandler(socials as OrganizationSocial[], 'TWITTER')}\n`
                : '\n'
            }`
          ),
      },
      {
        name: 'facebook' as Icons,
        href: (summary: string) =>
          `https://www.facebook.com/sharer/sharer.php?u=${shortUrl}`,
        onClick: onClick,
        summary:
          (product || organization) &&
          encodeURIComponent(
            `${tagsText ? `${tagsText} ` : ''}${
              product ? product.name : organization ? organization.name : ''
            }!\nMíralo en Swear IT, te va a gustar\n@swearit_\n`
          ),
      },
      {
        name: 'linkedin' as Icons,
        href: (summary: string) =>
          `https://www.linkedin.com/sharing/share-offsite/?url=${shortUrl}`,
        onClick: onClick,
        summary:
          (product || organization) &&
          encodeURIComponent(
            `${tagsText ? `${tagsText} ` : ''}${
              product ? product.name : organization ? organization.name : ''
            }!\nMíralo en Swear IT, te va a gustar\n@swearit_\n`
          ),
      },
      {
        name: 'pinterest' as Icons,
        href: (summary: string) =>
          `http://pinterest.com/pin/create/button/?url=${encodeURIComponent(
            shortUrl
          )}&media=${shareImage}&image_url=${shareImage}&description=${summary}`,
        onClick: onClick,
        summary:
          (product || organization) &&
          encodeURIComponent(
            `${tagsText ? `${tagsText} ` : ''}${
              product ? product.name : organization ? organization.name : ''
            }!\nMíralo en Swear IT, te va a gustar\n@swearit_\n`
          ),
      },
    ];
  }, [product, organization, shortUrl, onClick, setCopied, mobile, type]);

  const analyticalEvent = (social: string) => {
    ReactGA.event({
      category: organization ? 'Page Click' : 'Social Sharing',
      action: `${social} Share`,
      label: organization
        ? `${organization.pk}`
        : product
        ? `${product.organization.pk}/${product.pk}`
        : '',
    });
  };

  return (
    <SocialShareContainer mobile={mobile}>
      {SOCIAL_ICONS.map((icon) => (
        <SocialShareLink
          key={icon.name}
          onClick={(e: MouseEvent) => {
            analyticalEvent(icon.name);
            if (icon.onClick && !mobile) {
              e.preventDefault();
              icon.onClick();
            }

            if (icon.onClick && mobile) {
              icon.onClick();
            }
          }}
          href={icon.summary ? icon.href(icon.summary) : '#'}
          target={'_blank'}
          rel="noopener noreferrer"
        >
          <Icon iconName={icon.name} />
        </SocialShareLink>
      ))}
      {isCopied && <SocialShareCopiedtext>{t('copied')}</SocialShareCopiedtext>}
    </SocialShareContainer>
  );
};

export default SocialShare;
