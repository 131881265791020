import styled from "styled-components";
import { Text } from "components/Text";
import { breakpoints } from "theme/Theme.breackoints";
import { Link } from 'react-router-dom';

export const WineContent = styled.div``
export const WineContentBody = styled.div`
  display: flex;
  justify-content: center;
  gap: 0 4.5vw;
  margin: 0 0 5rem;
  width: 100%;
  max-width: 100%;
  @media screen and (max-width: ${breakpoints.xs}px) {
    margin: 0 0 2.5rem;
  }
`
export const WineContentShare = styled.div`
  width: 2rem;
  flex: 0 0 auto;
  position: relative;
  padding: 8rem 0 0;
`

export const WineContentAction = styled.div<{justify?: string; margin?: string;}>`
  display: flex;
  align-items: center;
  justify-content: ${({ justify }) => justify ? justify : "flex-end"};
  ${({ margin }) => margin && `margin: ${margin};`}
`
export const WineContentActionBtn = styled(Text)`
  font-size: 1rem;
  line-height: 1rem;
  color: ${({ theme }) => theme.colors.wineGray};
  margin: 0 0 2rem;
  display: inline-block;
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.colors.winePrimary};
  }
`

export const WineContentShareList = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  position: fixed;
`
export const WineContentShareItem = styled.a`
  font-size: 0.875rem;
  line-height: 0.875rem;
  color: ${({ theme }) => theme.colors.wineBlack};
  margin: 0 0 2.5rem;
  cursor: pointer;
  &:last-child {
    margin: 0;
  }
  &:hover {
    color: ${({ theme }) => theme.colors.winePrimary};
  }
`
export const WineContentBodyContainer = styled.div`
  display: grid;
  max-width: 100%;
  width: 100%;
  grid-template: 1fr / 400px 1fr;  
  gap: 0 6.25vw;
  @media screen and (max-width: ${breakpoints.lg}px) {
    gap: 0 3.25vw;
    grid-template: 1fr / 320px 1fr;  
  }
  @media screen and (max-width: ${breakpoints.md}px) {
    grid-template: 1fr / 1fr;  
    display: flex;
    flex-direction: column;
  }
`
export const WineContentBodyImages = styled.div`
  overflow: hidden;
  padding-bottom: 2.5rem;
  img {
    width: 100%;
  }
  @media screen and (max-width: ${breakpoints.md}px) {
    width: 400px;
    padding-bottom: 2rem;
    margin-bottom: 3rem;
  }
  @media screen and (max-width: ${breakpoints.xs}px) {
    width: auto;
    max-width: 100%;
    padding-bottom: 2rem;
    margin-bottom: 3rem;
  }
`
export const WineContentBodyInformation = styled.div<{certificate?: boolean;}>`
  ${({ certificate }) => certificate && `
    display: grid;
    grid-template: 1fr / 1fr 230px;
    gap: 0 1.5rem;
    @media screen and (max-width: ${breakpoints.md}px) {
      grid-template: 1fr / 1fr;
    }
  `}
`

export const WineContentTitle = styled(Text)`
  font-size: 3.5rem;
  line-height: 4rem;
  color: ${({ theme }) => theme.colors.wineBlack};
  margin: 0 0 1rem;
  @media screen and (max-width: ${breakpoints.lg}px) {
    font-size: 3rem;
    line-height: 3.5rem;
  }
  @media print {
    font-size: 2.5rem;
    line-height: 3rem;
  }
`
export const WineContentOrganization = styled.div`
  display: flex;
  align-items: center;
  gap: 0 1.5rem;
`
export const WineContentOrganizationImage = styled.img`
  max-height: 2rem;
`
export const WineContentiParagraph = styled(Text)<{maxWidth?: string; margin?: string;}>`
  font-family: ${({ theme }) => theme.fonts.main};
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: ${({ theme }) => theme.colors.wineGray};
  max-width: ${({ maxWidth }) => maxWidth ? maxWidth : "auto"};
  margin: ${({ margin }) => margin ? margin : "0"};
  
`
export const WineContentiLink = styled(Link)<{margin?: string;}>`
  font-family: ${({ theme }) => theme.fonts.main};
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.125rem;
  color: ${({ theme }) => theme.colors.wineGray};
  display: inline-block;
  cursor: pointer;
  margin: 0 0 0.25rem;
  ${({ margin }) => margin && `margin: ${margin};`}
  &:hover {
    color: ${({ theme }) => theme.colors.winePrimary};
  }
`
export const WineContentiUnLink = styled.span<{margin?: string;}>`
  font-family: ${({ theme }) => theme.fonts.main};
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.125rem;
  color: ${({ theme }) => theme.colors.wineGray};
  display: inline-block;
  margin: 0 0 0.25rem;
  ${({ margin }) => margin && `margin: ${margin};`}
 
`

export const WineContentLimkWeb = styled.a`
  font-size: 0.875rem;
  line-height: 0.875rem;
  font-weight: bold;
  margin: 0 0 1rem;
  font-family: ${({ theme }) => theme.fonts.main};
  color: ${({ theme }) => theme.colors.winePrimary};
  display: inline-flex;
  align-items: center;
  gap: 0.4rem;
  &:hover {
    color: ${({ theme }) => theme.colors.wineSecondary};
  }
  svg {
    width: 12px;
  }
`

export const WineContentiSeparator = styled.div`
  width: 100%;
  max-width: 450px;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.wineBlack};
  margin: 1.5rem 0;
`
export const WineContentFooter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 90px 0;
`