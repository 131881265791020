import React from 'react';
import { useTranslation } from 'react-i18next';

//PROPS TYPE
import { RelatedProductsProps } from './RelatedProducts.props';

//COMPONENTS
import Link from 'components/Link';
import Icon from 'components/Icon';

//STYLES
import {
  RelatedProductsContainer,
  RelatedProductsItem,
  RelatedProductsItemImage,
  RelatedProductsItemName,
  RelatedProductsItemOverlay,
  RelatedProductsItemAction,
} from './RelatedProducts.styles';

const RelatedProducts = ({ products }: RelatedProductsProps) => {
  const { t } = useTranslation('commons');

  return (
    <RelatedProductsContainer>
      {products.map((product) => (
        <RelatedProductsItem key={product.pk}>
          <Link
            to={{
              pathname: `/products/${product.pk}/`,
            }}
            display="block"
            width="100%"
          >
            <RelatedProductsItemAction>
              {t('content.seeMore')} <Icon iconName="chevronRight" />
            </RelatedProductsItemAction>
            <RelatedProductsItemOverlay />
            <RelatedProductsItemImage src={product.thumbnail.url} />
            <RelatedProductsItemName>{product.name}</RelatedProductsItemName>
          </Link>
        </RelatedProductsItem>
      ))}
    </RelatedProductsContainer>
  );
};

export default RelatedProducts;
