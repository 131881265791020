import styled from 'styled-components';

//COMPONENTS
import { Text } from 'components/Text';

export const ConditionsContainer = styled.div`
  margin: 0 0 1.25rem;
`;
export const ConditionsProofName = styled(Text)`
  font-size: 0.875rem;
  line-height: 1rem;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.brand};
  cursor: pointer;
  display: inline-block;
  margin: 0 0 0.5rem;
  &:hover {
    color: ${({ theme }) => theme.colors.active};
  }
`;
export const CoditionsList = styled.ul`
  list-style: square;
  padding: 0 0 0 1.1rem;
`;
export const CoditionsItem = styled.li``;
export const CoditionsData = styled(Text)`
  font-size: 0.75rem;
  line-height: 1rem;
  color: ${({ theme }) => theme.colors.fontSecondary};
  text-transform: capitalize;
`;
