import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from '@react-hook/media-query';
import ReactGA from 'react-ga4';

//QUERIES
import { organizationQuery } from 'queries/organization';

//STORE
import { AppState } from 'store';
import { openModal, setTranslations } from 'store/tramslateModal';
import { setOrganizationInfo, setOrganization } from 'store/organization';
import { setMobileNav } from 'store/mobilenav';

//PROPS TYPE
import { MetaHeadProps } from 'components/MetaHead/MetaHead';
import { OrganizationVars } from './Organization.props';
import { OrganizationData } from 'types/organization';

//COMPONENTS
import AppLayout from 'module/AppLayout';
import Container from 'components/Container';
import OrganizationDesktopView from './components/OrganizationDesktopView';
import OrganizationMobileView from './components/OrganizationMobileView';
import ProofPointModal from 'components/ProofPointModal';
import ProofPointDetailModal from 'components/ProofPointDetailModal';
import MediaEvicenceModal from 'components/MediaEvidenceModal';
import EvidenceModal from 'components/EvidenceModal';
import BlockchainDetailModal from 'components/BlockchainDetailModal';
import ReviewModal from 'components/ReviewModal';
import TranslateModal from 'components/TranslateModal';
import OrganizationSkeleton from './components/OrganizationSkeleton';

const Organization = () => {
  const { organizationId } = useParams();

  const { translateSelect } = useSelector(
    (state: AppState) => state.translateModal
  );

  const dispatch = useDispatch();
  const matches = useMediaQuery('only screen and (max-width: 768px)');

  const [isPrivate, setIsPrivate] = useState<boolean>(false);
  const [metaInformation, setMetaInformation] = useState<MetaHeadProps>({});

  const { data: resOrganization, loading, error, refetch } = useQuery<
    OrganizationData,
    OrganizationVars
  >(organizationQuery, {
    variables: {
      pk: organizationId,
      language:
        translateSelect && translateSelect.length > 0
          ? translateSelect.find(
              (lang) => lang.idOrganization === organizationId
            )?.short_name
          : '',
    },
  });

  useEffect(() => {
    if (resOrganization && resOrganization.organization.data === null) {
      setIsPrivate(true);
    }
  }, [resOrganization]);

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname,
      title: 'organization',
    });
  }, []);

  useEffect(() => {
    if (
      resOrganization &&
      resOrganization.organization &&
      resOrganization.organization.data
    ) {
      dispatch(setMobileNav('info'));
      setMetaInformation({
        title: resOrganization.organization.data.name,
        description: resOrganization.organization.data.description,
        image: resOrganization.organization.data.logo.url
          ? resOrganization.organization.data.logo.url
          : '',
        url: window.location.href,
      });
    }
  }, [resOrganization, dispatch]);

  useEffect(() => {
    if (
      resOrganization &&
      resOrganization.organization &&
      resOrganization.organization.data
    ) {
      dispatch(
        setOrganizationInfo({
          name: resOrganization.organization.data.name,
          logo: resOrganization.organization.data.logo.url,
        })
      );
      dispatch(setOrganization(resOrganization.organization.data));
    }
  }, [dispatch, resOrganization]);

  useEffect(() => {
    const selectLanguage = translateSelect.find(
      (lang) => lang.idOrganization === organizationId
    );

    if (
      !selectLanguage &&
      resOrganization &&
      resOrganization.organization &&
      resOrganization.organization.data &&
      resOrganization.organization.data.translationStatus &&
      Object.keys(
        JSON.parse(resOrganization.organization.data.translationStatus)
      ).length > 1
    ) {
      dispatch(
        openModal({
          isOpen: true,
          translations: JSON.parse(
            resOrganization.organization.data.translationStatus
          ),
          isHiddenClose: true,
        })
      );
    } else if (
      selectLanguage &&
      resOrganization &&
      resOrganization.organization &&
      resOrganization.organization.data &&
      resOrganization.organization.data.translationStatus &&
      Object.keys(
        JSON.parse(resOrganization.organization.data.translationStatus)
      ).length > 1
    ) {
      dispatch(
        setTranslations({
          translations: JSON.parse(
            resOrganization.organization.data.translationStatus
          ),
        })
      );
    }
  }, [translateSelect, organizationId, resOrganization, dispatch]);

  const handleRefetchQuery = useCallback(() => {
    refetch();
  }, [refetch]);

  return (
    <AppLayout
      loading={loading}
      error={error}
      errorType={
        resOrganization &&
        resOrganization.organization &&
        resOrganization.organization.errors
          ? resOrganization.organization.errors.message
          : resOrganization &&
            resOrganization.organization &&
            resOrganization.organization.error
          ? resOrganization.organization.error
          : undefined
      }
      metas={metaInformation}
      type="organization"
      id={organizationId}
      skeletonLoader={<OrganizationSkeleton matches={matches} />}
    >
      <>
        <Container>
          <>
            {matches ? (
              <OrganizationMobileView
                organization={
                  resOrganization &&
                  resOrganization.organization &&
                  resOrganization.organization.data
                }
              />
            ) : (
              <OrganizationDesktopView
                organization={
                  resOrganization &&
                  resOrganization.organization &&
                  resOrganization.organization.data
                }
              />
            )}
          </>
        </Container>
        <ProofPointModal />
        <ProofPointDetailModal />
        <MediaEvicenceModal />
        <EvidenceModal />
        <BlockchainDetailModal type="organization" />
        <ReviewModal type="organization" />
        <TranslateModal
          refetch={handleRefetchQuery}
          type="organization"
          id={organizationId}
        />
      </>
    </AppLayout>
  );
};

export default Organization;
