import gql from 'graphql-tag';

export const lotQuery = gql`
  query lot($pk: String!, $language: String) {
    productLot(id: $pk, language: $language) {
      data {
        id
        pk
        internalId
        isPublic
        createdAt
        reviews {
          edges {
            node {
              createdAt
              id
              title
              name
              email
              comment
              rating
            }
          }
        }
        featuredProofPoints {
          name
          pk
          description
          createdAt
          mediaEvidences {
            name
            url
            type
          }
          shouldShowWidget
          icon {
            value
            icon
            label
          }
          evidences {
            file
            evidenceName
            pk
          }
          conditions {
            value
            type
            typeDisplay
            unit
          }
          verifier {
            name
            description
            logo(size: "250x250") {
              url
            }
          }
        }
        workflow {
          name
          verifier {
            name
            pk
            logo(size: "250x250") {
              url
            }
            url
          }
        }
        steps {
          id
          name
          stepDate
          extraFields
          createdAt
          image(size: "700x700") {
            url
          }
          internalId
          description
          icon
        }
        reviewStats {
          averageRating
          totalCount
          percentageCountFive
          percentageCountFour
          percentageCountThree
          percentageCountTwo
          percentageCountOne
        }
        journey {
          name
          milestones {
            id
            name
            startDate
            endDate
            internalId
            description
            icon
            location {
              name
            }
            createdAt
            image(size: "700x700") {
              url
            }
            proofPoints {
              name
              pk
              shouldShowWidget
              description
              icon {
                value
                icon
                label
              }
              mediaEvidences {
                name
                url
                type
              }
              createdAt
              conditions {
                value
                type
                typeDisplay
                unit
              }
              evidences {
                file
                evidenceName
                pk
              }
              verifier {
                name
                description
                logo(size: "250x250") {
                  url
                }
              }
            }
          }
        }
        product(language: $language) {
          name
          description
          internalId
          translationStatus
          pk
          brand
          hideFromOrgMirror
          tabs {
            pk
            title
            order
            type
            fileList {
              pk
              documents {
                pk
                document
                title
                description
              }
            }
            ingredientList {
              ingredients {
                description
                title
                image
                link
              }
            }
            recommendationList {
              recommendations {
                title
                description
              }
            }
            nutritionFact {
              description
              amountPerServing
              calories
              details
              ingredients
            }
            technicalDetail {
              title
              subtitle
              footer
              composition
              principalDetails {
                id
                title
                description
              }
              otherDetails {
                id
                title
                description
              }
              image
            }
          }
          thumbnail(size: "40x40") {
            url
          }
          organization {
            name
            pk
            logo(size: "250x250") {
              url
            }
            url
            socialChannels {
              handle
              url
              type
              pk
            }
          }
          images {
            id
            url(size: "266x266")
            order
          }
          reviews {
            edges {
              node {
                createdAt
                id
                title
                name
                email
                comment
                rating
              }
            }
          }
          reviewStats {
            averageRating
            totalCount
            percentageCountFive
            percentageCountFour
            percentageCountThree
            percentageCountTwo
            percentageCountOne
          }
        }
      }
      error
      url
    }
  }
`;

export const stepsLotQuery = gql`
  query lot($pk: String) {
    productLot(id: $pk) {
      data {
        id
        pk
        internalId
        isPublic
        createdAt
        workflow {
          name
          description
          verifier {
            name
            pk
            logo(size: "250x250") {
              url
            }
            url
          }
        }
        steps {
          id
          name
          stepDate
          extraFields
          createdAt
          image(size: "700x700") {
            url
          }
          internalId
          description
          icon
        }
        product {
          name
          description
          internalId
          pk
          brand
          hideFromOrgMirror
          thumbnail(size: "40x40") {
            url
          }
          organization {
            name
            pk
            logo(size: "250x250") {
              url
            }
            url
            socialChannels {
              handle
              url
              type
              pk
            }
          }
          images {
            id
            url(size: "768x768")
            order
          }
        }
      }
      error
      url
    }
  }
`;
