import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

//STORE
import { AppState } from 'store';
import { closeModal } from 'store/proofPointModal';
import { openModal } from 'store/proofPointDetailModal';

//PROPS TYPE
import { ProofPointModalProps } from './ProofPointModal.props';

//COMPONETNS
import Modal from 'components/Modal';
import RenderHtml from 'components/RenderHtml';
import Button from 'components/Button';

//STYLES
import { ModalContent } from 'components/Modal/Modal.styled';
import {
  ProofPointModalContainer,
  ProofPointModalHeader,
  ProofPointModalName,
  ProofPointModalVerified,
  ProofPointModalExpirationDate,
  ProofPointModalIcon,
  ProofPointModalContent,
  ProofPointModalContentTitle,
  ProofPointModalContentAction,
  ProofPointModalContentLogo,
} from './ProofPointModal.styled';

const ProofPointModal = ({ hiddeClose, wine }: ProofPointModalProps) => {
  const { t } = useTranslation('modalProofPoints');

  const dispatch = useDispatch();

  const { isOpen, proof } = useSelector(
    (state: AppState) => state.proofPointModal
  );

  const proofWithExpirationDates =
    proof &&
    proof.evidences &&
    proof.evidences.length > 0 &&
    proof.evidences.filter((evidence) => !!evidence.expirationDate);

  const handleCloseModal = useCallback(() => {
    if (!hiddeClose) {
      dispatch(closeModal());
    }
  }, [dispatch, hiddeClose]);

  const handleOpenProofPointDetailModal = useCallback(() => {
    dispatch(openModal({ proof }));
  }, [dispatch, proof]);

  return (
    <Modal
      isOpen={isOpen}
      close={handleCloseModal}
      hiddeClose={hiddeClose}
      wine={wine}
    >
      <ModalContent>
        <ProofPointModalContainer wine={wine}>
          <ProofPointModalHeader>
            <ProofPointModalName>
              {proof && proof.icon && proof.icon.icon && (
                <ProofPointModalIcon src={proof.icon.icon} />
              )}
              {proof && proof.name && proof.name.toLowerCase()}
            </ProofPointModalName>
            <ProofPointModalVerified>
              {proof &&
              proof.verifier &&
              proof.verifier.name &&
              proof.verifier.name.toLowerCase().includes('swearit')
                ? t('content.verifiedBySwearit')
                : t('content.verified')}
            </ProofPointModalVerified>
          </ProofPointModalHeader>
          <ProofPointModalContent>
            <ProofPointModalContentTitle>
              {t('content.whatDoesThisMean')}
            </ProofPointModalContentTitle>
            {proof && proof.description && (
              <RenderHtml
                text={proof.description}
                align="center"
                wine
                margin="0 auto 1.125rem"
              />
            )}
            <ProofPointModalContentAction>
              <Button
                text={
                  wine
                    ? t('content.viewProofInfoEvidence')
                    : t('content.viewProofInfo')
                }
                onClick={() => handleOpenProofPointDetailModal()}
                wine={wine}
                outline
              />
            </ProofPointModalContentAction>
            {proofWithExpirationDates &&
              proofWithExpirationDates.length > 0 && (
                <ProofPointModalContentTitle>
                  {t('content.expirationDate')}
                </ProofPointModalContentTitle>
              )}
            {proofWithExpirationDates &&
              proofWithExpirationDates.length > 0 &&
              proofWithExpirationDates.map((evidence) => (
                <>
                  <ProofPointModalExpirationDate>
                    {`${moment(evidence.expirationDate).format(
                      'DD/MM/YYYY'
                    )} - ${evidence.evidenceName}`}
                  </ProofPointModalExpirationDate>
                </>
              ))}
            {/* {proof && proof.expirationDate && (
              
            )} */}
            {wine ? (
              <ProofPointModalVerified>
                {t('content.verifiedBy')}
              </ProofPointModalVerified>
            ) : (
              <ProofPointModalContentTitle>
                {t('content.verifiedBy')}
              </ProofPointModalContentTitle>
            )}

            {proof && proof.verifier && proof.verifier.logo && (
              <ProofPointModalContentLogo
                src={proof.verifier.logo.url}
                width={110}
                alt={`logo-${proof.verifier.name}`}
              />
            )}

            {proof && proof.verifier && proof.verifier.description && (
              <RenderHtml
                text={proof.verifier.description}
                align="center"
                wine
                margin="0 auto 1.125rem"
              />
            )}
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Button
                text={t('content.closeProofModal')}
                onClick={() => handleCloseModal()}
                wine={wine}
              />
            </div>
          </ProofPointModalContent>
        </ProofPointModalContainer>
      </ModalContent>
    </Modal>
  );
};

export default ProofPointModal;
