import { createSlice } from '@reduxjs/toolkit';

export type tabType = 'info' | 'milestones' | 'reviews' | 'share';

interface mobileNavStateProps {
  tab: tabType;
}

const initialState: mobileNavStateProps = {
  tab: 'info',
};

const mobileNavSlice = createSlice({
  name: 'mobilenav',
  initialState,
  reducers: {
    setMobileNav: (state: typeof initialState, action) => {
      return {
        ...state,
        tab: action.payload,
      };
    },
  },
});

export const { setMobileNav } = mobileNavSlice.actions;

export default mobileNavSlice;
