import styled from 'styled-components';
import { sm } from 'theme/Theme.breackoints';

export const StarRatingContainer = styled.div<{ rating: number }>`
  display: inline-block;
  font-size: 1rem;
  font-family: Times;
  line-height: 1rem;
  margin: 0 0.75rem 0 0;
  ${sm`
    font-size: 0.9375rem;
  `}

  &:before {
    content: '❤ ❤ ❤ ❤ ❤';
    letter-spacing: 3px;
    background: linear-gradient(
      90deg,
      ${({ theme }) => theme.colors.error} ${({ rating }) => `${rating}%`},
      #ededee ${({ rating }) => `${rating}%`}
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    ${sm`
     letter-spacing: 0;
    `}
  }
`;
