import styled from 'styled-components';
import { sm } from 'theme/Theme.breackoints';
import { Text } from 'components/Text';

export const ProductSection = styled.section`
  width: 100%;
  ${sm`
   
  `}
`;

export const ProductGrid = styled.div`
  width: 100%;
  display: grid;
  grid-template: 1fr / calc(100% - 24.5rem) 23rem;
  grid-gap: 0 1.5rem;
  min-height: calc(100vh - 15.375rem);
  margin: 0 0 2rem;
  ${sm`
    grid-template: 1fr / 1fr;
    overflow: hidden;
    padding: 5rem 0 0;
  `}
`;
export const ProductColumns = styled.div`
  ${sm`
    width: calc(100vw - 2rem);
  `}
`;
export const ProductContent = styled.div`
  padding: 0 0 3rem;
  ${sm`
    width: 100%;
    max-width: calc(100vw - 2rem);
  `}
`;
export const ProductSideBar = styled.aside`
  border-left: 1px solid ${({ theme }) => theme.colors.border};
  flex: 1;
  height: 100%;
  padding: 0 0 2.5rem 2.5rem;
  ${sm`
    padding: 0 0 2.5rem 0;
    border: none;
  `}
`;
export const ProductInfoTitle = styled(Text)`
  color: ${({ theme }) => theme.colors.fontSecondary};
  text-transform: uppercase;
  margin: 0 0 0.5rem;
  font-size: 0.875rem;
  line-height: 0.875rem;
  font-weight: 700;
`;
export const ProductInfoData = styled(Text)`
  font-size: 0.75rem;
  line-height: 1.25rem;
  margin: 0 0 1rem;
  color: ${({ theme }) => theme.colors.black};
`;
