import React, { useState } from 'react';
import { ProductTabs as ProductTabsList } from 'types/product';
import { useMediaQuery } from '@react-hook/media-query';
//COMPONENTS
import ProductTabsMobile from './ProductTab.mobile';
import ProductTabsDesktop from './ProductTab.desktop';
//STYLES
import { ProductTabsContainer } from './ProductTabs.styled';

interface ProductTabsProps {
  tabs: ProductTabsList[];
}

interface MapTemplateTabs {
  template: JSX.Element;
}

const ProductTabs = ({ tabs }: ProductTabsProps) => {
  const matches = useMediaQuery('only screen and (max-width: 980px)');

  const [active, setActive] = useState(tabs[0].pk);

  return (
    <ProductTabsContainer>
      {matches ? (
        <ProductTabsMobile tabs={tabs} active={active} setActive={setActive} />
      ) : (
        <ProductTabsDesktop tabs={tabs} active={active} setActive={setActive} />
      )}
    </ProductTabsContainer>
  );
};

export default ProductTabs;
