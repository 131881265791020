import React from 'react';

//PROPST TYPE
import { ModalProps } from './Modal.props';

//COMPONENT
import Icon from 'components/Icon';

//STYLES
import {
  ModalDialogOverlay,
  ModalDialogContent,
  ModalContainer,
  ModalClose,
} from './Modal.styled';

export default function Modal({
  isOpen,
  children,
  close,
  size,
  backcolor,
  hiddeClose,
  padding = '0 2rem',
  wine,
}: ModalProps) {
  if (isOpen) {
    return (
      <ModalDialogOverlay
        isOpen={isOpen}
        onDismiss={!hiddeClose ? close : () => {}}
        wine={wine}
      >
        {/* <ModalStyles /> */}
        <ModalDialogContent
          aria-labelledby="modal"
          size={size}
          backcolor={backcolor}
          padding={padding}
        >
          {!hiddeClose && (
            <ModalClose onClick={close}>
              <Icon iconName="close" />
            </ModalClose>
          )}
          <ModalContainer>{children}</ModalContainer>
        </ModalDialogContent>
      </ModalDialogOverlay>
    );
  }
  return <></>;
}
