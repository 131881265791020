import styled from 'styled-components';

//PROPS TYPE
import { SkeletonLineProps } from './SkeletonLoader.props';

export const SkeletonPulse = styled.div`
  display: inline-block;
  height: 100%;
  width: 100%;
  vertical-align: middle;
  background: linear-gradient(-90deg, #f0f0f0 0%, #f8f8f8 50%, #f0f0f0 100%);
  background-size: 400% 400%;
  animation: pulse 1.2s ease-in-out infinite;
  @keyframes pulse {
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: -135% 0%;
    }
  }
`;
export const SkeletonLoaderContainer = styled(SkeletonPulse)<SkeletonLineProps>`
  width: ${({ width }) => (width ? width : '10rem')};
  height: ${({ height }) => (height ? height : '1.25rem')};
  border-radius: ${({ radius }) => (radius ? radius : '0')};
  margin: ${({ margin }) => (margin ? margin : '0')};
  padding: ${({ padding }) => (padding ? padding : '0')};
  display: ${({ display }) => (display ? display : 'inline-block')};
  ${({ gridArea }) =>
    gridArea &&
    `
    grid-area: ${gridArea};
  `};
  ${({ background }) =>
    background &&
    `
    background: ${background};
  `};
  ${({ border }) => {
    if (border) {
      return `
      border: ${border.full ? border.full : 'none'};
      border-bottom: ${border.bottom ? border.bottom : 'none'};
      border-top: ${border.top ? border.top : 'none'};
      border-left: ${border.left ? border.left : 'none'};
      border-right: ${border.right ? border.right : 'none'};
      ${border.full && `border: ${border.full};`};
      `;
    }
  }};
  ${({ flex }) =>
    flex &&
    flex.align &&
    `
    align-items: ${flex.align};
  `};
  ${({ gridTemplate }) =>
    gridTemplate &&
    `
    grid-template: ${gridTemplate};
  `};
  ${({ flex }) =>
    flex &&
    flex.justify &&
    `
    justify-content: ${flex.justify};
  `};
  ${({ flex }) =>
    flex &&
    flex.gap &&
    `
    gap: ${flex.gap};
  `};

  ${({ flex }) =>
    flex &&
    flex.grow &&
    `
    flex-grow: ${flex.grow};
  `};
  ${({ flex }) =>
    flex &&
    flex.wrap &&
    `
    flex-wrap: ${flex.wrap};
  `};
  ${({ flex }) =>
    flex &&
    flex.flexGroup &&
    `
    flex: ${flex.flexGroup};
  `};
  ${({ flex }) =>
    flex &&
    flex.direction &&
    `
    flex-direction: ${flex.direction};
  `};
  ${({ backgroundPosition }) =>
    backgroundPosition &&
    `
    background-position: ${backgroundPosition};
  `};

  ${({ backgroundRepeat }) =>
    backgroundRepeat &&
    `
    background-repeat: ${backgroundRepeat};
  `};

  ${({ backgroundSize }) =>
    backgroundSize &&
    `
    background-size: ${backgroundSize};
  `};
  ${({ overflow }) =>
    overflow &&
    `
    overflow: ${overflow};
  `};
  ${({ minHeight }) =>
    minHeight &&
    `
    min-height: ${minHeight};
  `};
  ${({ maxWidth }) =>
    maxWidth &&
    `
    max-width: ${maxWidth};
  `};
  ${({ animation }) =>
    animation &&
    `
    animation: ${animation};
  `};
  ${({ position }) =>
    position &&
    `
    position: ${position};
  `};
  ${({ right }) =>
    right &&
    `
    right: ${right};
  `};
  ${({ left }) =>
    left &&
    `
    left: ${left};
  `};
  ${({ top }) =>
    top &&
    `
    top: ${top};
  `};
  ${({ bottom }) =>
    bottom &&
    `
    bottom: ${bottom};
  `};

  &::before {
    content: '\00a0';
  }
  ${({ after }) =>
    after &&
    `
    &::after {
      ${after}
    }
  `};
`;
