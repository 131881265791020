import { useQuery } from '@apollo/react-hooks';
import React from 'react';
import { Navigate, useParams } from 'react-router-dom';
//PROPS TYPE
import { QrData } from 'types/qr';
import { QrVars } from './CheckIt.props';
//QUERIES
import Loader from 'components/Loader';
import { qrQuery } from 'queries/qr';
import Error404 from 'screens/Error404';

const CheckIt = () => {
  const { checkId } = useParams();

  const { data: resQr, loading } = useQuery<QrData, QrVars>(qrQuery, {
    variables: {
      pk: checkId,
    },
  });

  if (
    resQr &&
    resQr.qrData &&
    resQr.qrData.data &&
    resQr.qrData.data.type === 'wine' &&
    resQr.qrData.data.model === 'product'
  ) {
    return (
      <Navigate
        to={`/wine/${resQr.qrData.data.objectId}?certificateId=${resQr.qrData.data.certificateId}`}
      />
    );
  }
  if (
    resQr &&
    resQr.qrData &&
    resQr.qrData.data &&
    resQr.qrData.data.type === 'wine' &&
    resQr.qrData.data.model === 'lot'
  ) {
    return (
      <Navigate
        to={`/wine-bottle/${resQr.qrData.data.objectId}?certificateId=${resQr.qrData.data.certificateId}`}
      />
    );
  }
  if (
    resQr &&
    resQr.qrData &&
    resQr.qrData.data &&
    resQr.qrData.data.type === 'product' &&
    resQr.qrData.data.model === 'product'
  ) {
    return <Navigate to={`/products/${resQr.qrData.data.objectId}`} />;
  }
  if (
    resQr &&
    resQr.qrData &&
    resQr.qrData.data &&
    resQr.qrData.data.type === 'product' &&
    resQr.qrData.data.model === 'lot'
  ) {
    return <Navigate to={`/lot/${resQr.qrData.data.objectId}`} />;
  }
  if (resQr && resQr.qrData && resQr.qrData.error === 'qr_not_used') {
    return <Error404 isNotQrUsed />;
  }
  if (resQr && resQr.qrData && resQr.qrData.error === 'invalid_uuid') {
    return <Error404 />;
  }

  return <>{loading ? <Loader /> : <Error404 />}</>;
};

export default CheckIt;
