import styled, { css, keyframes } from 'styled-components';
import { xs, sm, md } from 'theme/Theme.breackoints';

//COMPONENTS
import { Text } from 'components/Text';

const TEXT_COMMON_CSS_WHITE = css`
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.white};
  font-family: 'Roboto Mono', monospace;
`;

const pulse = keyframes`
  0% {
     transform: scale(0.95);
     opacity: 0.7;
   }

   70% {
     transform: scale(1.85);
     opacity: 0;
   }

   100% {
     transform: scale(0.95);
     opacity: 0;
   }
`;

const pulseSee = keyframes`
   0% {
     transform: scale(0.95);
   }

   70% {
     transform: scale(1.15);
   }

   100% {
     transform: scale(0.95);
   }
`;

export const BlockchainDetailModalContainer = styled.div`
  padding: 0 0 1.5rem;
`;
export const BlockchainDetailModalHeaderContainer = styled.div`
  width: 100%;
  border-bottom: 1px solid #e4e4e4;
  padding-bottom: 2rem;
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${sm`
    flex-direction: column;
    align-items: flex-start;
  `}
`;
export const BlockchainDetailModalHeaderName = styled(Text)`
  ${TEXT_COMMON_CSS_WHITE};
  text-transform: uppercase;
  font-weight: bold;
  display: flex;
  align-items: center;
  ${sm`
    width: 100%;
    padding-bottom: 25px;
    margin-bottom: 25px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.white};
  `}
`;
export const BlockchainDetailModalHeaderIcon = styled.img`
  width: 2rem;
  height: 2rem;
  margin: 0 1rem 0 0;
`;
export const BlockchainDetailModalHeaderData = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  ${sm`
    width: 100%;
    display: grid;
    grid-template: 1fr/1fr 1fr;
    grid-gap: 20px;
    justify-content: flex-start;
  `}
`;
export const BlockchainDetailModalHeaderDataBlock = styled.div`
  margin: 0 3rem 0 0;
  &:last-child {
    margin: 0;
  }
`;
export const BlockchainDetailModalHeaderDataName = styled(Text)`
  ${TEXT_COMMON_CSS_WHITE};
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.fontSecondary};
  text-transform: uppercase;
  font-weight: bold;
`;
export const BlockchainDetailModalHeaderDataNumber = styled(Text)`
  ${TEXT_COMMON_CSS_WHITE};
  font-size: 2rem;
  line-height: 2rem;
  font-weight: bold;
  margin: 0 0 0.5rem;
`;

export const BlockchainDetailModalContentContainer = styled.div`
  display: grid;
  grid-template: 1fr 1fr / 1fr 1fr 1fr;
  grid-template-areas: 'areaone areatwo areathree' ' areafour areatwo areathree';
  grid-gap: 1.5rem;
  margin: 0 0 2rem;
  ${md`
    grid-template: 1fr / 1fr 1fr;
    grid-template-areas: 'areaone areatwo' 'areafour areatwo' 'areathree areathree';
  `}
  ${sm`
    grid-template: 1fr / 1fr;
    grid-template-areas: 'areaone' 'areathree' 'areatwo' 'areafour';
  `}
`;
export const BlockchainDetailModalContentGridItem = styled.div<{
  gridArea?: string;
}>`
  ${({ gridArea }) =>
    gridArea &&
    `
    grid-area: ${gridArea};
  `}
`;
export const BlockchainDetailModalContentGrid = styled.div<{
  separate?: boolean;
}>`
  display: grid;
  grid-template: 1fr / 1fr 1fr;
  grid-gap: 0 1rem;
  ${({ separate }) =>
    separate &&
    `
    margin: 0 0 1.125rem;
  `}
`;
export const BlockchainDetailModalContentWrapper = styled.div<{
  separate?: boolean;
}>`
  ${({ separate }) =>
    separate &&
    `
    margin: 0 0 1.125rem;
  `}
`;
export const BlockchainDetailModalContentTitle = styled(Text)`
  ${TEXT_COMMON_CSS_WHITE};
  font-weight: bold;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.fontSecondary};
  font-size: 0.875rem;
  line-height: 0.875rem;
  margin: 0 0 0.75rem;
`;
export const BlockchainDetailModalContentLabel = styled(Text)`
  ${TEXT_COMMON_CSS_WHITE};
  font-weight: bold;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.fontSecondary};
  font-size: 0.75rem;
  line-height: 0.75rem;
  margin: 0 0 0.5rem;
  ${xs`
    font-size: 0.625rem;
    line-height: 0.625rem;
  `}
`;
export const BlockchainDetailModalContentText = styled(Text)<{
  separate?: boolean;
}>`
  ${TEXT_COMMON_CSS_WHITE};
  line-height: 1rem;
  text-transform: capitalize;
  word-break: break-all;
  ${({ separate }) =>
    separate &&
    `
    margin: 0 0 0.75rem;
  `}
`;
export const BlockchainDetailModalContentSeparator = styled.div`
  margin-bottom: 1.5rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.white};
`;
export const BlockchainDetailModalCondition = styled.div`
  margin: 0 0 0.75rem;
`;
export const BlockchainDetailModalClickElement = styled.div`
  color: ${({ theme }) => theme.colors.backgroundGrayLight};
  cursor: pointer;
  display: inline-block;
  font-size: 0.75rem;
`;

export const BlockchainDetailModalLoadingContainer = styled.div`
  width: 4.7rem;
  height: 4.7rem;
  position: relative;
  margin: 18.75rem auto;
`;
export const BlockchainDetailModalLoadingImageSee = styled.img`
  position: absolute;
  width: 150px;
  animation: ${pulseSee} 2s infinite;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;
export const BlockchainDetailModalLoadingImagePulse = styled.img`
  width: 150px;
  position: absolute;
  animation: ${pulse} 2s infinite;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
`;

export const BlockchainDetailModalTable = styled.ul`
  display: grid;
  grid-template: 1fr / 90px 230px 85px;
  grid-gap: 15px;
  align-items: center;
  margin: 0 0 0.25rem;
  ${md`
    grid-template: 1fr / 90px 1fr 85px;
  `}
  ${xs`
    grid-template: 1fr/50px minmax(106px,1fr) 65px;
    grid-gap: 10px;
  `}
`;
export const BlockchainDetailModalTableHead = styled.li`
  ${TEXT_COMMON_CSS_WHITE};
  font-weight: bold;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.fontSecondary};
  font-size: 0.75rem;
  line-height: 0.75rem;
  margin: 0 0 0.5rem;
  ${xs`
    font-size: 0.625rem;
    line-height: 0.625rem;
  `}
`;

export const BlockchainDetailModalBlock = styled.div`
  display: grid;
  grid-template: 1fr / 90px 230px 85px;
  grid-gap: 15px;
  align-items: center;
  padding-bottom: 12px;
  margin-bottom: 12px;
  border-bottom: 2px solid #fcfcfc30;
  word-break: break-all;
  ${xs`
    grid-template: 1fr/50px minmax(106px,1fr) 65px;
    grid-gap: 10px;
    padding-bottom: 8px;
    margin-bottom: 8px;
  `}
`;

export const BlockchainDetailModalBlockText = styled.p`
  ${TEXT_COMMON_CSS_WHITE};
  word-break: break-all;
  line-height: 1rem;
  text-transform: capitalize;
  margin: 0;
  ${xs`
    font-size: 0.75rem;
    line-height: 0.75rem;
  `}
`;
export const BlockchainDetailModalBlockDate = styled.div`
  ${TEXT_COMMON_CSS_WHITE};
  position: relative;
  text-transform: uppercase;
  line-height: 1rem;
  ${xs`
    font-size: 0.75rem;
    line-height: 0.75rem;
  `}
`;
export const BlockchainDetailModalBlockTime = styled.div`
  display: block;
  position: absolute;
  font-size: 12px;
  z-index: 2;
  line-height: 12px;
  background: ${({ theme }) => theme.colors.black};
  bottom: -18px;
  padding: 0 10px;
  left: -10px;
  ${xs`
    font-size: 0.5rem;
    line-height: 0.5rem;
  `}
`;
export const BlockchainDetailModalButton = styled.button`
  outline: none;
  transition: all 0.3s ease-in-out;
  border: 2px solid ${({ theme }) => theme.colors.white};
  background-color: transparent;
  color: ${({ theme }) => theme.colors.white};
  padding: 1rem 2rem;
  min-width: 200px;
  font-size: 1rem;
  line-height: 1.125rem;
  font-weight: bold;
  &:hover {
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.black};
  }
`;
