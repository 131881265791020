import styled from 'styled-components';

//ASSETS
import background from 'assets/images/background.png';

//COMPONENTS
import { Text } from 'components/Text';

export const AppLayoutContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;
export const AppLayoutContent = styled.div`
  flex: 1;
  /* @media only screen and (max-width: 950px) and (orientation: landscape) {
    visibility: hidden;
  } */
`;
export const AppBlocker = styled.div`
  position: fixed;
  background-color: ${({ theme }) => theme.colors.white};
  background-image: url(${background});
  background-size: cover;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: hidden;
  height: 0;
  overflow: hidden;
  padding: 0;
  @media only screen and (orientation: landscape) {
    visibility: visible;
    padding: 0 2rem;
    height: auto;
  }
`;
export const AppBlockerText = styled(Text)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.white};
  padding: 2.5rem;
  box-shadow: 0px 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 0.875rem;
  color: ${({ theme }) => theme.colors.black};
  svg {
    width: 7rem;
    height: 4rem;
    fill: ${({ theme }) => theme.colors.brand};
    margin: 0 0 1.25rem;
  }
`;
