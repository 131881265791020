import React, { useState } from 'react';

//COMPONENTS
import SkeletonLoader from 'components/SkeletonLoader';
import SkeletonSealsItem from './SkeletonSealsItem';

const SkeletonSeals = () => {
  const [sealsList] = useState([1, 2]);

  return (
    <SkeletonLoader
      width="100%"
      height="auto"
      padding="0 0 1rem"
      margin="0 0 1rem"
      display="flex"
      flex={{
        wrap: 'nowrap',
      }}
      background="transparent"
      overflow="hidden"
    >
      {sealsList.map((seal, index) => (
        <SkeletonSealsItem key={`${index}-seal`} />
      ))}
    </SkeletonLoader>
  );
};

export default SkeletonSeals;
