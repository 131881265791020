import styled from "styled-components"
import Icon from 'components/Icon';
import { Text } from "components/Text";

export const WineContentBodyCertificate = styled.div`
  width: 100%;
  max-width: 100%;
`
export const CertificateInformation = styled.div``
export const CertificateLogo = styled.img`
  width: 100%;
  object-fit: cover;
  object-position: center;
`
export const CertificatePrintIcon = styled(Icon)`
  svg {
    width: 24px;
    cursor: pointer;
    &:hover {
      color: ${({ theme }) => theme.colors.winePrimary};
    }
  }
`
export const CertificateBottle = styled(Text)`
  font-size: 26px;
  color: ${({ theme }) => theme.colors.wineBlack};
  margin: 0 0 2.5rem;  
`
export const CertificateBottleDates = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0 0.5rem;
`
export const CertificateBottleDate = styled(Text)<{withBorder?: boolean;}>`
  font-family: ${({ theme }) => theme.fonts.main};
  font-size: 0.875rem;
  line-height: 0.875rem;
  color: ${({ theme }) => theme.colors.wineGray};
  ${({ withBorder, theme }) => withBorder && `
    border-left: 1px solid ${theme.colors.wineGray};
    padding: 0 0 0 0.5rem;
  `}
  span {
    display: inline-block;
    text-transform: uppercase;
    margin: 0 0 0 0.5rem;
  }
`

export const CertificatePrintArea = styled.div`
  @media print {
    padding: 1cm;
    font-family: ${({ theme }) => theme.fonts.wine};
    @page { size: landscape; }
  }
`

export const CertificateBottleDescriptionTitle = styled(Text)`
  font-family: ${({ theme }) => theme.fonts.wine};
  font-size: 16px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.wineBlack};
  margin: 0 0 1rem;
`
export const CertificateBottleDescription = styled(Text)`
  font-family: ${({ theme }) => theme.fonts.main};
  font-size: 14px;
  line-height: 18px;
  color: ${({ theme }) => theme.colors.wineBlack};
  margin: 0 0 1rem;
  `
export const CertificateBottleDescriptionLink = styled(Text)<{disabled: boolean}>`
  font-family: ${({ theme }) => theme.fonts.wine};
  font-size: 16px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.winePrimary};
  cursor: pointer;
  display: inline-block;
  &:hover {
    color: ${({ theme }) => theme.colors.wineBlack};
  }
  ${({ disabled, theme}) => disabled && `
    color: ${theme.colors.wineGrayLigth};
    cursor: not-allowed;
    &:hover {
      color: ${theme.colors.wineGrayLigth};
    }
  `}
`