import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { LinkOwnProps } from './Link.props';

export const LinkStyled = styled(Link)<LinkOwnProps>`
  width: ${({ width }) => (width ? width : 'auto')};
  display: ${({ display }) => (display ? display : 'inline')};
  font-size: 1rem;
  line-height: 1rem;
  color: ${({ theme }) => theme.colors.brand};
  cursor: pointer;
  display: inline-block;
  background-color: transparent;
  font-weight: 700;
  &:hover {
    color: ${({ theme }) => theme.colors.active};
  }
`;
