import styled from 'styled-components';

//COMPONENTS
import { Text } from 'components/Text';


export const ProofPointDetailModalHeader = styled.div`
  position: relative;
  padding: 0 0 1.25rem;
  margin: 0 0 1.5rem;
  &::before {
    position: absolute;
    content: '';
    width: 11.25rem;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.placeholder};
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0 auto;
  }
`;
export const ProofPointDetailModalName = styled(Text)`
  text-align: center;
  font-size: 1.125rem;
  line-height: 2rem;
  font-weight: bold;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.black};
`;

export const ProofPointDetailModalText = styled(Text)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 0.875rem;
  line-height: 0.875rem;
  color: ${({ theme }) => theme.colors.fontSecondary};
  span {
    margin: 0 0.5rem 0 0;
  }
  strong {
    margin: 0 0 0 0.5rem;
  }
`;

export const ProofPointDetailModalIcon = styled.img`
  width: 2rem;
  margin: 0 auto 0.5rem;
`;
export const ProofPointDetailModalVerified = styled(Text)`
  font-size: 0.875rem;
  line-height: 0.875rem;
  font-weight: bold;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.green};
  text-align: center;
`;
export const ProofPointDetailModalContent = styled.div``;
export const ProofPointDetailModalContentTitle = styled(Text)`
  text-align: center;
  font-size: 0.875rem;
  line-height: 0.875rem;
  font-weight: bold;
  text-transform: uppercase;
  margin: 0 0 1rem;
  color: ${({ theme }) => theme.colors.black};
`;
export const ProofPointDetailModalContentText = styled(Text)`
  text-align: center;
  font-size: 0.875rem;
  line-height: 0.875rem;
  margin: 0 0 2.5rem;
  color: ${({ theme }) => theme.colors.fontSecondary};
`;
export const ProofPointDetailModalEvidences = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 0 2.5rem;
`;
export const ProofPointDetailModalAction = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const ProofPointDetailModalEvidencesLink = styled.a`
  font-size: 0.875rem;
  line-height: 0.875rem;
  font-weight: bold;
  margin: 0 0 0.5rem;
  color: ${({ theme }) => theme.colors.brand};
  transition: all 0.25s ease-in-out;
  &:hover {
    color: ${({ theme }) => theme.colors.active};
  }
  &:focus {
    outline: none;
  }
`;
export const ProofPointDetailModalEvidencesButton = styled.button`
  font-size: 0.875rem;
  line-height: 0.875rem;
  font-weight: bold;
  margin: 0 0 0.5rem;
  color: ${({ theme }) => theme.colors.brand};
  transition: all 0.25s ease-in-out;
  outline: none;
  &:hover {
    color: ${({ theme }) => theme.colors.active};
  }
  &:focus {
    outline: none;
  }
`;

export const ProofPointDetailModalContainer = styled.div<{wine?: boolean;}>`
  ${({ wine, theme }) => wine && `
     ${ProofPointDetailModalName} {
      font-family: ${theme.fonts.wine};
      text-transform: capitalize;
      color: ${theme.colors.wineBlack};
      font-size: 2.5rem;
      line-height: 3rem;
     }
     ${ProofPointDetailModalText} {
      color: ${theme.colors.wineGray};
     }
     ${ProofPointDetailModalVerified} {
      font-weight: 400;
      margin: 0 0 0.5rem;
     }
     ${ProofPointDetailModalContentTitle} {
      font-size: 1.125rem;
      font-family: ${theme.fonts.wine};
      text-transform: capitalize;
     }
     ${ProofPointDetailModalEvidencesLink} {
      color: ${theme.colors.wineBlack};
      font-size: 0.875rem;
      &:hover {
        color: ${theme.colors.winePrimary};
      }
     }
     ${ProofPointDetailModalEvidencesButton} {
      color: ${theme.colors.wineBlack};
      font-size: 0.875rem;
      &:hover {
        color: ${theme.colors.winePrimary};
      }
     }
  `}

`;