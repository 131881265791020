import React, { useState } from 'react';

//COMPONENTS
import AppLayoutSkeleton from 'module/AppLayoutSkeleton';
import SkeletonLoader from 'components/SkeletonLoader';
import SkeletonProofs from 'components/SkeletonProofs';
import SkeletonSeals from 'components/SkeletonSeals';
import SkeletonSectionTitle from 'components/SkeletonSectionTitle';
import SkeletonDescription from 'components/SkeletonDescription';
import SkeletonShare from 'components/SkeletonShare';
import SkeletonReviews from 'components/SkeletonReviews';
import SkeletonMilestones from 'components/SkeletonMilestones';

interface ProductSkeletonProps {
  matches?: boolean;
}

const ProductSkeleton = ({ matches }: ProductSkeletonProps) => {
  const [imgageList] = useState([1, 2, 3, 4, 5]);

  return (
    <AppLayoutSkeleton matches={matches}>
      <SkeletonLoader width="100%" height="auto" background="transparent">
        <>
          <SkeletonLoader
            width="100%"
            height="auto"
            display="grid"
            gridTemplate={
              matches ? '1fr / 1fr' : '1fr / calc(100% - 24.5rem) 23rem'
            }
            flex={{
              gap: '0 1.5rem',
            }}
            background="transparent"
            overflow="hidden"
          >
            <>
              <SkeletonLoader
                width="100%"
                height="auto"
                background="transparent"
              >
                <SkeletonSectionTitle />
                <SkeletonProofs matches={matches} />
                <SkeletonSeals />
                <SkeletonDescription />
                <SkeletonLoader
                  width="100%"
                  height="auto"
                  padding="0 0 1rem"
                  margin="0 0 1rem"
                  display="flex"
                  flex={{
                    wrap: 'nowrap',
                  }}
                  background="transparent"
                  overflow="hidden"
                >
                  {imgageList.map((image, index) => (
                    <SkeletonLoader
                      key={`${index}-image`}
                      width="16rem"
                      height="16rem"
                      display="block"
                      margin="0 1rem 0 0"
                      flex={{
                        flexGroup: '1 0 auto;',
                      }}
                    />
                  ))}
                </SkeletonLoader>
                {!matches && <SkeletonShare />}
                {!matches && <SkeletonReviews />}
              </SkeletonLoader>
              {!matches && (
                <SkeletonLoader
                  width="100%"
                  height="100%"
                  padding="0 0 2.5rem 2.5rem"
                  flex={{
                    flexGroup: '1',
                  }}
                  background="transparent"
                  border={{
                    left: '1px solid #EDEDEE',
                  }}
                >
                  <SkeletonSectionTitle />
                  <SkeletonMilestones />
                </SkeletonLoader>
              )}
            </>
          </SkeletonLoader>
        </>
      </SkeletonLoader>
    </AppLayoutSkeleton>
  );
};

export default ProductSkeleton;
