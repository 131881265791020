import * as React from 'react';

function CloseIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={32}
      height={32}
      viewBox="0 0 24 24"
      aria-labelledby="closeIconTitle"
      stroke="#100316"
      strokeWidth={1.5}
      strokeLinecap="round"
      fill="none"
      {...props}
    >
      <title>{'Close'}</title>
      <path d="M6.343 6.343l11.314 11.314m-11.314 0L17.657 6.343" />
    </svg>
  );
}

export default CloseIcon;
