import gql from 'graphql-tag';

export const shortUrlCreate = gql`
  mutation($shortUrlInput: ShortUrlCreateInput!) {
    shortUrlCreate(input: $shortUrlInput) {
      secureShortURL
      shortURL
    }
  }
`;
