import * as React from 'react';

function ArrowDoubleRightIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={32}
      height={32}
      strokeWidth={1.5}
      strokeLinecap="round"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        d="M15.5 5H11L16 12L11 19H15.5L20.5 12L15.5 5Z"
        fill="currentColor"
      />
      <path d="M8.5 5H4L9 12L4 19H8.5L13.5 12L8.5 5Z" fill="currentColor" />
    </svg>
  );
}

export default ArrowDoubleRightIcon;
