import { configureStore } from '@reduxjs/toolkit';
import reviewSlice from './reviews';
import productSlice from './product';
import proofPointModalSlice from './proofPointModal';
import proofPointDetailModalSlice from './proofPointDetailModal';
import mediaEvidenceModalSlice from './mediaEvidenceModal';
import evidenceModalSlice from './evidenceModal';
import cycleLifeModalSlice from './cycleLifeModal';
import reviewModalSlice from './reviewModal';
import blockchainDetailModalSlice from './blockchainDetailModal';
import organizationDataSlice from './organization';
import blocknitiveSlice from './blocknitive';
import mobileNavSlice from './mobilenav';
import linkSlice from './link';
import translateModalSlice from './tramslateModal';

const store = configureStore({
  reducer: {
    review: reviewSlice.reducer,
    product: productSlice.reducer,
    proofPointModal: proofPointModalSlice.reducer,
    translateModal: translateModalSlice.reducer,
    proofPointDetailModal: proofPointDetailModalSlice.reducer,
    mediaEvidenceModal: mediaEvidenceModalSlice.reducer,
    evidenceModal: evidenceModalSlice.reducer,
    cycleLifeModal: cycleLifeModalSlice.reducer,
    reviewModal: reviewModalSlice.reducer,
    blockchainDetailModal: blockchainDetailModalSlice.reducer,
    organization: organizationDataSlice.reducer,
    blockStats: blocknitiveSlice.reducer,
    mobileNav: mobileNavSlice.reducer,
    link: linkSlice.reducer,
  },
});

export type AppState = ReturnType<typeof store.getState>;

export default store;
