import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useMediaQuery } from '@react-hook/media-query';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { useDispatch, useSelector } from 'react-redux';
import ReactToPrint from 'react-to-print';
//QUERIES
import { stepsLotQuery } from 'queries/lot';
//STORE
import { AppState } from 'store';
import { setMobileNav } from 'store/mobilenav';
import { openModal as openBlockchainDetailModal } from 'store/blockchainDetailModal';
//PROPS TYPE
import { LotData } from 'types/lot';
import { LotVars } from 'screens/Lot/Lot.props';
import { MetaHeadProps } from 'components/MetaHead/MetaHead';
//ASSETS
import placeholderImage from 'assets/images/placeholder255x255.png';
//COMPONENTS
import BlockchainDetailModalLot from 'components/BlockchainDetailModal/BlockchainDetailModalLot';
import AppLayoutWine from 'module/AppLayoutWine';
import Share from 'screens/Wine/components/Share';
import Transitions from './components/Transitions';
import ProductSkeleton from 'screens/Product/components/ProductSkeleton';
//STYLES
import { AppLayoutWineWrapper } from 'module/AppLayoutWine/AppLayoutWine.styled';
import {
  WineContent,
  WineContentAction,
  WineContentBodyInformation,
  WineContentTitle,
  WineContentiSeparator,
  WineContentShareList,
  WineContentShareItem,
  WineContentBody,
  WineContentShare,
} from 'screens/Wine/Wine.styled';
import {
  CertificatePrintIcon,
  CertificateBottle,
  WineContentBodyCertificate,
  CertificateInformation,
  CertificateLogo,
  CertificatePrintArea,
  CertificateBottleDates,
  CertificateBottleDate,
  CertificateBottleDescriptionTitle,
  CertificateBottleDescription,
  CertificateBottleDescriptionLink,
} from './Certificate.styled';
import { ProductExtraField } from 'types/product';
import moment from 'moment';

const SOCIALS: Record<string, string> = {
  facebook: 'fb',
  twitter: 'tw',
  instagram: 'in',
  youtube: 'yb',
  linkedin: 'ln',
};

const Certificate = () => {
  const { certificateId } = useParams();
  const dispatch = useDispatch();
  const printArea = useRef<HTMLDivElement>(null);

  const matches = useMediaQuery('only screen and (max-width: 920px)');
  const matchesMd = useMediaQuery('only screen and (max-width: 768px)');
  const matchesXs = useMediaQuery('only screen and (max-width: 575px)');

  const [metaInformation, setMetaInformation] = useState<MetaHeadProps>({});

  const { translateSelect } = useSelector(
    (state: AppState) => state.translateModal
  );
  const { blockStats } = useSelector((state: AppState) => state.blockStats);

  const { data: resStepsProduct, loading, error, refetch } = useQuery<
    LotData,
    LotVars
  >(stepsLotQuery, {
    variables: {
      pk: certificateId,
    },
  });

  const lotData = resStepsProduct && resStepsProduct.productLot.data;
  const lotResult = resStepsProduct && resStepsProduct.productLot;
  const stepsWithDates =
    lotData &&
    lotData.steps.length > 0 &&
    lotData.steps
      .filter(
        (step) =>
          step.name.toLowerCase().includes('tir') ||
          step.name.toLowerCase().includes('deg')
      )
      .map((step) => {
        const extraFields =
          !!step.extraFields && JSON.parse(step.extraFields).length > 0
            ? JSON.parse(step.extraFields)
            : [];
        const findLookExtrafield: ProductExtraField | null = extraFields.find(
          (item: ProductExtraField) =>
            item.internal_id === 'tiraje_date' ||
            item.internal_id === 'deguelle_date'
        );

        if (!!findLookExtrafield) {
          return `${findLookExtrafield.name.replace('Fecha de ', '')} ${moment(
            findLookExtrafield.value
          ).format('MM / YYYY')}`;
        }
        return step.name;
      });

  useEffect(() => {
    if (lotData && lotData.product) {
      dispatch(setMobileNav('info'));
      setMetaInformation({
        title: lotData.product.name,
        description: lotData.product.description,
        image:
          lotData.product.images.length > 0
            ? lotData.product.images[0].url
            : '',
        url: window.location.href,
      });
    }
  }, [lotData, dispatch]);

  const handleOpenBlochainDetailModal = useCallback(() => {
    console.log('here');
    if (lotData) {
      dispatch(
        openBlockchainDetailModal({
          lotId: lotData.pk,
          lotData: lotData,
        })
      );
    }
  }, [dispatch, lotData]);

  return (
    <AppLayoutWine
      loading={loading}
      skeletonLoader={<ProductSkeleton matches={matchesMd} />}
      metas={metaInformation}
      errorType={lotResult && lotResult.error}
      errorUrl={lotResult && lotResult.url}
      type="organization"
      id={certificateId}
    >
      <WineContent>
        <AppLayoutWineWrapper>
          <WineContentBody>
            {!matchesXs &&
              lotData &&
              lotData.product &&
              lotData.product.organization &&
              lotData.product.organization.socialChannels &&
              lotData.product.organization.socialChannels.length > 0 && (
                <WineContentShare>
                  <WineContentShareList>
                    {lotData.product.organization.socialChannels.map(
                      (social) => (
                        <WineContentShareItem
                          key={social.pk}
                          href={social.url}
                          target="_blank"
                        >
                          {SOCIALS[social.type.toLowerCase()]}
                        </WineContentShareItem>
                      )
                    )}
                  </WineContentShareList>
                </WineContentShare>
              )}
            {lotData && lotData.product && lotData.product.organization && (
              <WineContentBodyCertificate>
                <CertificatePrintArea ref={printArea}>
                  <WineContentBodyInformation certificate>
                    <CertificateInformation>
                      <WineContentTitle style={{ margin: '0 0 2rem' }}>
                        {lotData.product.name}
                      </WineContentTitle>
                      {lotData.workflow && (
                        <CertificateBottle>
                          {lotData.workflow.name}
                        </CertificateBottle>
                      )}
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '0 0.5rem',
                        }}
                      >
                        <CertificateBottleDate>
                          {`Identificador único ${lotData.internalId}`}
                        </CertificateBottleDate>
                        {stepsWithDates && stepsWithDates.length > 0 && (
                          <CertificateBottleDates>
                            {stepsWithDates.map((step, index) => (
                              <CertificateBottleDate
                                key={index}
                                withBorder={true}
                              >
                                {step}
                              </CertificateBottleDate>
                            ))}
                          </CertificateBottleDates>
                        )}
                      </div>
                      <WineContentiSeparator style={{ maxWidth: '345px' }} />
                      <div>
                        {lotData && (
                          <CertificateBottleDescriptionTitle>
                            Certificado de calidad de{' '}
                            {lotData.workflow.verifier.name}
                          </CertificateBottleDescriptionTitle>
                        )}

                        {lotData && (
                          <CertificateBottleDescription>
                            {lotData.workflow.description}
                          </CertificateBottleDescription>
                        )}

                        <CertificateBottleDescriptionLink
                          disabled={
                            !!blockStats.errors && blockStats.errors.length > 0
                          }
                          onClick={() => {
                            if (!blockStats.errors) {
                              handleOpenBlochainDetailModal();
                            }
                          }}
                        >
                          Ver registro en blockchain
                        </CertificateBottleDescriptionLink>
                      </div>
                    </CertificateInformation>
                    <CertificateLogo
                      src={
                        lotData.workflow.verifier.logo.url
                          ? lotData.workflow.verifier.logo.url
                          : placeholderImage
                      }
                    />
                  </WineContentBodyInformation>
                  <WineContentiSeparator style={{ maxWidth: '100%' }} />
                  {lotData && lotData.steps.length > 0 && (
                    <Transitions steps={lotData.steps} />
                  )}
                </CertificatePrintArea>

                <WineContentAction justify="space-between" margin="0 0 2rem">
                  <Share type="product" product={lotData.product} />
                  <ReactToPrint
                    trigger={() => <CertificatePrintIcon iconName="print" />}
                    content={() => printArea.current}
                  />
                </WineContentAction>
              </WineContentBodyCertificate>
            )}
          </WineContentBody>
          <BlockchainDetailModalLot type="lot" />
        </AppLayoutWineWrapper>
      </WineContent>
    </AppLayoutWine>
  );
};

export default Certificate;
