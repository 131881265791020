import React, { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

//UTILS
import { getPercentage } from 'utils/get-percentage';

//PROPS TYPE
import { CustomerReviewsProps } from './CustomerReviews.props';

//STORE
import { AppState } from 'store';
import { openModal } from 'store/reviewModal';

//COMPONENTS
import StarRating from 'components/StarRating';
import Button from 'components/Button';
import CustomerReview from './CustomerReview';

//STYLES
import {
  CustomerReviewsContainer,
  CustomerReviewsTitle,
  CustomerReviewsTotal,
  CustomerReviewsTotalCount,
  CustomerReviewsValue,
  CustomerReviewsPercentages,
  CustomerReviewsPercentage,
  CustomerReviewsBar,
  CustomerReviewsNumber,
  CustomerReviewsAction,
  CustomerReviewsList,
  CustomerReviewsEmpty,
} from './CustomerReviews.styled';

const CustomerReviews = ({ reviewStats, reviews }: CustomerReviewsProps) => {
  const { t } = useTranslation('commons');
  const { t: productTranslation } = useTranslation('product');
  const { t: organizationTranslation } = useTranslation('organization');

  const location = useLocation();
  const dispatch = useDispatch();

  const hasBeenReviewed = useSelector<AppState, boolean>(
    ({ review, product, organization }) => {
      const reviewedItem: string | undefined = review.find(
        (productReviewId) => {
          if (location.pathname.includes('/lot/')) {
            return product.lotId === productReviewId;
          }
          if (location.pathname.includes('/products/')) {
            return product.product.pk === productReviewId;
          }
          if (location.pathname.includes('/organizations/')) {
            return organization.organization.pk === productReviewId;
          }

          return false;
        }
      );
      return Boolean(reviewedItem);
    }
  );

  const PERCENTAGES = useMemo(
    () => [
      {
        id: `five-hearts`,
        percentage: reviewStats.percentageCountFive,
        starRatingValue: 100,
      },
      {
        id: `four-hearts`,
        percentage: reviewStats.percentageCountFour,
        starRatingValue: 80,
      },
      {
        id: `three-hearts`,
        percentage: reviewStats.percentageCountThree,
        starRatingValue: 60,
      },
      {
        id: `two-hearts`,
        percentage: reviewStats.percentageCountTwo,
        starRatingValue: 40,
      },
      {
        id: `one-hearts`,
        percentage: reviewStats.percentageCountOne,
        starRatingValue: 20,
      },
    ],
    [reviewStats]
  );

  const handleOpenReviewModal = useCallback(() => {
    dispatch(openModal());
  }, [dispatch]);

  return (
    <CustomerReviewsContainer>
      <CustomerReviewsTitle>
        {t('content.customerReviews')}
      </CustomerReviewsTitle>
      <CustomerReviewsTotal>
        <StarRating rating={getPercentage(reviewStats.averageRating, 5)} />
        <CustomerReviewsValue>
          {`${reviewStats.averageRating.toFixed(1)} ${t('content.of')}`} 5.0
        </CustomerReviewsValue>
      </CustomerReviewsTotal>
      <CustomerReviewsTotalCount>
        {reviewStats.totalCount} {t('content.reviews')}
      </CustomerReviewsTotalCount>

      <CustomerReviewsPercentages>
        {PERCENTAGES.map((percentage) => (
          <CustomerReviewsPercentage key={percentage.id}>
            <StarRating rating={percentage.starRatingValue} />
            <CustomerReviewsBar percentage={percentage.percentage} />
            <CustomerReviewsNumber>
              {Number(percentage.percentage).toFixed(1)}%{' '}
            </CustomerReviewsNumber>
          </CustomerReviewsPercentage>
        ))}
      </CustomerReviewsPercentages>
      <CustomerReviewsAction>
        {!hasBeenReviewed && (
          <Button
            onClick={handleOpenReviewModal}
            text={t('content.addYours')}
          />
        )}
      </CustomerReviewsAction>
      <CustomerReviewsList>
        {reviews && reviews.length > 0 ? (
          reviews.map((review) => (
            <CustomerReview key={review.node.id} review={review} />
          ))
        ) : (
          <CustomerReviewsEmpty>
            {location.pathname.includes('/products/') ||
            location.pathname.includes('/lot/')
              ? productTranslation('content.productEmptyReviews')
              : organizationTranslation('content.organizationEmptyReviews')}
          </CustomerReviewsEmpty>
        )}
      </CustomerReviewsList>
    </CustomerReviewsContainer>
  );
};

export default CustomerReviews;
