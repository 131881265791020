import React, { useState, useEffect } from 'react';
import ReactGA from 'react-ga4';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import useGetQuery from 'hooks/useGetQuery';

//STORE
import { AppState } from 'store';

//PROPS TYPE
import { AppHeaderProps } from './AppHeader.props';

//COMPONENTS
import AppMobileMenu from 'module/AppMobileMenu';
import AppMobileNav from 'module/AppMobileNav';
import TranslateSelector from 'components/TranslateSelector';
import Avatar from 'components/Avatar';
import Link from 'components/Link';
import Icon from 'components/Icon';

//STYLES
import {
  AppHeaderContainer,
  AppHeaderWrapper,
  AppHeaderLInformation,
  AppHeaderName,
  AppHeaderSeparator,
  AppHeaderMenuButton,
} from './AppHeader.styled';

const AppHeader = ({ type, id }: AppHeaderProps) => {
  const { t } = useTranslation('header');
  const query = useGetQuery();

  const product = useSelector((state: AppState) => state.product.product);
  const organization = useSelector(
    (state: AppState) => state.organization.organization
  );

  const [isOpenMenu, setIsOpenMenu] = useState<boolean>(false);

  useEffect(() => {
    if (!_.isEmpty(product)) {
      const LABEL_TYPE = {
        organization: id,
        product: `${product.organization.pk}/${id}`,
        lot: id,
        wine: id,
        wineBottle: id,
      };

      let firstInit = localStorage.getItem('firstInit');

      const visited: Array<string> = JSON.parse(firstInit ? firstInit : '[]');
      const existVisited = visited.find((visit) => visit === `${type}-${id}`);

      if (!existVisited) {
        const firstInitArray = [...visited, `${type}-${id}`];
        ReactGA.event({
          category: 'New user',
          action: 'First Visit',
          label: LABEL_TYPE[type],
          nonInteraction: true,
        });
        localStorage.setItem('firstInit', JSON.stringify(firstInitArray));
      }
    }
  }, [id, type, product]);

  const toggleMenu = () => {
    setIsOpenMenu(!isOpenMenu);
    let $root = document.querySelector('#root');
    if ($root) $root.classList.toggle('hidden-overflow');
  };

  return (
    <AppHeaderContainer>
      <AppHeaderWrapper>
        <AppHeaderLInformation>
          {(type === 'product' || type === 'lot') && product && (
            <>
              <Avatar
                size={45}
                url={(product.thumbnail && product.thumbnail.url) || undefined}
                text={product.name || ''}
              />
              <AppHeaderName>
                {product.name || ''}
                <AppHeaderSeparator>|</AppHeaderSeparator>
                {product.organization && (
                  <Link
                    to={{
                      pathname: `/organizations/${product.organization.pk}/`,
                      search: `?productId=${product.pk}`,
                    }}
                  >
                    {product.organization.name}
                  </Link>
                )}
              </AppHeaderName>
            </>
          )}
          {type === 'organization' && organization && (
            <>
              <Avatar
                size={45}
                url={(organization.logo && organization.logo.url) || undefined}
                text={product.name || ''}
              />
              <AppHeaderName>
                {organization.name || ''}
                {query.get('productId') && (
                  <AppHeaderSeparator>|</AppHeaderSeparator>
                )}

                {query.get('productId') && (
                  <Link
                    to={{
                      pathname: `/products/${query.get('productId')}/`,
                    }}
                  >
                    <>{t('content.backToProduct')}</>
                  </Link>
                )}
              </AppHeaderName>
            </>
          )}
        </AppHeaderLInformation>
        <TranslateSelector device="desktop" type={type} id={id} />
        <AppHeaderMenuButton onClick={toggleMenu}>
          <Icon iconName="dotMenu" />
        </AppHeaderMenuButton>
      </AppHeaderWrapper>
      <AppMobileMenu
        active={isOpenMenu}
        toggleMenu={toggleMenu}
        type={type}
        id={id}
      />
      <AppMobileNav />
    </AppHeaderContainer>
  );
};

export default AppHeader;
