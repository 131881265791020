import React from 'react';

//PROPS TYPE
import { StarRatingProps } from './StarRating.props';

//STYLES
import { StarRatingContainer } from './StarRating.styled';

const StarRating = ({ rating }: StarRatingProps) => {
  return <StarRatingContainer rating={rating} />;
};

export default StarRating;
