import { createSlice } from '@reduxjs/toolkit';

import { BlocknitiveStatsData } from 'types/blocknitive';

interface organizationStateProps {
  blockStats: BlocknitiveStatsData;
}

const initialState: organizationStateProps = {
  blockStats: {} as BlocknitiveStatsData,
};

const blocknitiveSlice = createSlice({
  name: 'blocknitive',
  initialState,
  reducers: {
    setBlockState: (state: typeof initialState, action) => {
      return {
        ...state,
        blockStats: action.payload,
      };
    },
  },
});

export const { setBlockState } = blocknitiveSlice.actions;

export default blocknitiveSlice;
