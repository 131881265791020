import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

//ASSETS
import FlagEnglish from 'assets/images/en.png';
import FlagSpanish from 'assets/images/es.png';
import FlagGermany from 'assets/images/de.png';
import FlagItaly from 'assets/images/it.png';
import FlagFrance from 'assets/images/fr.png';
import FlagSweden from 'assets/images/sv.png';
import FlagPortugal from 'assets/images/pr.png';
import FlagCatalan from 'assets/images/ca.png';
import FlagBulgarian from 'assets/images/bg.png';
import FlagCzech from 'assets/images/cs.png';
import FlagCroatian from 'assets/images/hr.png';
import FlagDanish from 'assets/images/da.png';
import FlagSlovak from 'assets/images/sk.png';
import FlagSlovenian from 'assets/images/sl.png';
import FlagEstonian from 'assets/images/et.png';
import FlagFinnish from 'assets/images/fi.png';
import FlagGreek from 'assets/images/el.png';
import FlagHungarian from 'assets/images/hu.png';
import FlagIrish from 'assets/images/ga.png';
import FlagLatvian from 'assets/images/lv.png';
import FlagLithuanian from 'assets/images/lt.png';
import FlagMaltese from 'assets/images/mt.png';
import FlagDutch from 'assets/images/nl.png';
import FlagPolish from 'assets/images/pl.png';
import FlagRomanian from 'assets/images/ro.png';
import FlagSwedish from 'assets/images/sv.png';
import FlagBasque from 'assets/images/eu.png';
import FlagGalician from 'assets/images/gl.png';
import FlagJapon from 'assets/images/ja.png';

//PROPS TYPE
import { TranslateModalProps } from './TranslateModal.props';

//STORE
import { AppState } from 'store';
import { closeModal, setTranslateSelect } from 'store/tramslateModal';

//COMPONENTS
import Modal from 'components/Modal';

//STYLES
import {
  ModalWrapper,
  ModalHeading,
  ModalContent,
} from 'components/Modal/Modal.styled';
import {
  TranslateModalLenguage,
  TranslateModalLenguageMeta,
  TranslateModalLenguageActions,
  TranslateModalLenguageAction,
  TranslateModalLenguageAvatar,
  TranslateModalLenguageName,
  TranslateModalLenguageStatus,
} from './TranslateModal.styled';

interface TranslateMap {
  name: string;
  status: string;
}

export const LANGUAGE_MAP: Record<string, { icon: string; lang: string }> = {
  fr: {
    icon: FlagFrance,
    lang: 'en-US',
  },
  es: {
    icon: FlagSpanish,
    lang: 'es-ES',
  },
  ca: {
    icon: FlagCatalan,
    lang: 'es-ES',
  },
  pr: {
    icon: FlagPortugal,
    lang: 'en-US',
  },
  de: {
    icon: FlagGermany,
    lang: 'en-US',
  },
  it: {
    icon: FlagItaly,
    lang: 'en-US',
  },
  en: {
    icon: FlagEnglish,
    lang: 'en-US',
  },
  sv: {
    icon: FlagSweden,
    lang: 'en-US',
  },
  bg: {
    icon: FlagBulgarian,
    lang: 'en-US',
  },
  cs: {
    icon: FlagCzech,
    lang: 'en-US',
  },
  hr: {
    icon: FlagCroatian,
    lang: 'en-US',
  },
  da: {
    icon: FlagDanish,
    lang: 'en-US',
  },
  sk: {
    icon: FlagSlovak,
    lang: 'en-US',
  },
  sl: {
    icon: FlagSlovenian,
    lang: 'en-US',
  },
  et: {
    icon: FlagEstonian,
    lang: 'en-US',
  },
  fi: {
    icon: FlagFinnish,
    lang: 'en-US',
  },
  el: {
    icon: FlagGreek,
    lang: 'en-US',
  },
  hu: {
    icon: FlagHungarian,
    lang: 'en-US',
  },
  ga: {
    icon: FlagIrish,
    lang: 'en-US',
  },
  lv: {
    icon: FlagLatvian,
    lang: 'en-US',
  },
  lt: {
    icon: FlagLithuanian,
    lang: 'en-US',
  },
  mt: {
    icon: FlagMaltese,
    lang: 'en-US',
  },
  nl: {
    icon: FlagDutch,
    lang: 'en-US',
  },
  pl: {
    icon: FlagPolish,
    lang: 'en-US',
  },
  ro: {
    icon: FlagRomanian,
    lang: 'en-US',
  },
  eu: {
    icon: FlagBasque,
    lang: 'en-US',
  },
  gl: {
    icon: FlagGalician,
    lang: 'en-US',
  },
  ja: {
    icon: FlagJapon,
    lang: 'en-US',
  },
};

export default function TranslateModal({
  refetch,
  type,
  id,
}: TranslateModalProps) {
  const { t, i18n } = useTranslation('general');

  const dispatch = useDispatch();

  const { pk } = useSelector((state: AppState) => state.product.product);

  const { isOpen, translations, translateSelect, isHiddenClose } = useSelector(
    (state: AppState) => state.translateModal
  );

  const close = useCallback(() => {
    if (translateSelect) {
      dispatch(closeModal());
    }
  }, [dispatch, translateSelect]);

  const handleSelectLanguage = async (name: string) => {
    i18n.changeLanguage(LANGUAGE_MAP[name].lang);
    const findProduct = translateSelect.find(
      (lang) => lang.idProduct === id || lang.idOrganization === id
    );

    const translateSelectList = findProduct
      ? translateSelect.map((lang) => {
          return lang.idProduct === id || lang.idOrganization === id
            ? {
                short_name: name,
                long_name: t(`${name}`),
                idProduct: type === 'product' ? id : '',
                idOrganization: type === 'organization' ? id : '',
              }
            : lang;
        })
      : [
          ...translateSelect,
          {
            short_name: name,
            long_name: t(`${name}`),
            idProduct: type === 'product' ? id : '',
            idOrganization: type === 'organization' ? id : '',
          },
        ];

    await dispatch(
      setTranslateSelect({
        translateSelect: translateSelectList,
      })
    );
    await dispatch(closeModal());

    if (refetch) refetch();
  };

  return (
    <Modal
      isOpen={isOpen}
      close={close}
      size="small"
      hiddeClose={isHiddenClose}
    >
      <ModalWrapper>
        <ModalHeading between>{t('translations')}</ModalHeading>
        <ModalContent style={{ padding: '1rem 0' }}>
          {translations &&
            Object.entries(translations).map((translate) => ({
              name: translate[0],
              statusTranslate: translate[1],
            })).length > 0 && (
              <>
                {Object.entries(translations)
                  .map((translate) => ({
                    name: translate[0],
                    statusTranslate: translate[1],
                  }))
                  .map((translate) => {
                    const selection =
                      translateSelect &&
                      translateSelect.find(
                        (lang) =>
                          lang.idProduct === id || lang.idOrganization === id
                      );
                    return (
                      <TranslateModalLenguage key={translate.name}>
                        <TranslateModalLenguageMeta>
                          <TranslateModalLenguageAvatar
                            src={LANGUAGE_MAP[translate.name].icon}
                          />
                          <TranslateModalLenguageName>
                            {t(`${translate.name}`)}
                            {!!selection &&
                              selection.short_name === translate.name && (
                                <TranslateModalLenguageStatus status="completed">
                                  {t('selected')}
                                </TranslateModalLenguageStatus>
                              )}
                          </TranslateModalLenguageName>
                        </TranslateModalLenguageMeta>
                        <TranslateModalLenguageActions>
                          {((!!selection &&
                            selection.short_name !== translate.name) ||
                            selection === undefined) && (
                            <TranslateModalLenguageAction
                              onClick={() => {
                                handleSelectLanguage(translate.name);
                              }}
                              text={t('select')}
                            />
                          )}
                        </TranslateModalLenguageActions>
                      </TranslateModalLenguage>
                    );
                  })}
              </>
            )}
        </ModalContent>
      </ModalWrapper>
    </Modal>
  );
}
