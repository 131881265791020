import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
//STYLES
import { PicturesContainer, PicturesItem } from './Pictures.styled';

interface PicturesProps {
  images: string[];
}

const Pictures = ({ images }: PicturesProps) => {
  return (
    <PicturesContainer sizeArray={images.length}>
      <Swiper
        spaceBetween={0}
        slidesPerView={1}
        onSlideChange={() => {}}
        onSwiper={(swiper) => {}}
        pagination={true}
        loop
      >
        {images.map((image, index) => (
          <SwiperSlide key={`${image}-${index}`}>
            <PicturesItem src={image} key={index} />
          </SwiperSlide>
        ))}
      </Swiper>
    </PicturesContainer>
  );
};

export default Pictures;
