import styled from "styled-components"
import { Text } from "components/Text"

export const TagsContainer = styled.div<{margin?: string;}>`
  display: inline-flex;
  gap: 1.5rem;
  flex-wrap: wrap;
  margin: ${({ margin }) => margin ? margin : "0"};
`

export const TagsItemIcon = styled.img`
  width: 1rem;
  height: 1rem;
`
export const TagsItemText = styled.span`
  color: ${({ theme }) => theme.colors.wineBlack};
`

export const TagsItem = styled.div`
  align-items: center;
  display: flex;
  gap: 0 0.5rem;
  padding: 0.25rem 0.625rem;
  font-family: ${({ theme }) => theme.fonts.main};
  font-size: 0.75rem;
  background-color: ${({ theme }) => theme.colors.backgroundGrayLight};
  border-radius: 40px;
  cursor: pointer;
  transition: all 0.25s ease-in-out;
  &:hover {
    background-color: ${({ theme }) => theme.colors.winePrimary};
    ${TagsItemIcon} {
      filter: invert(100%) sepia(15%) saturate(0%) hue-rotate(40deg) brightness(103%) contrast(102%);
    }
    ${TagsItemText} {
      color: ${({ theme }) => theme.colors.white};
    }
  }
`