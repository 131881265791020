import React from 'react';

//PROPS TYPE
import { OrganizationLinksProps } from './OrganizationLinks.props';
import { Icons } from 'components/Icon/Icon.props';

//COMPONETNS
import Icon from 'components/Icon';

//STYLES
import {
  OrganizationLinksContainer,
  OrganizationLinksAction,
} from './OrganizationLinks.styled';

const OrganizationLinks = ({ socials }: OrganizationLinksProps) => {
  return (
    <OrganizationLinksContainer>
      {socials.map((social) => {
        return (
          <OrganizationLinksAction
            key={social.pk}
            href={social.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon iconName={social.type.toLowerCase() as Icons} />
          </OrganizationLinksAction>
        );
      })}
    </OrganizationLinksContainer>
  );
};

export default OrganizationLinks;
