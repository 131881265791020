import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactGA from 'react-ga4';

//PROPS TYPE
import { OrganizationInfoProps } from './OrganizationInfo.props';

//COMPONENTS
import RenderHtml from 'components/RenderHtml';
import OrganizationLinks from '../OrganizationLinks';

//STYLES
import {
  OrganizationInfoContainer,
  OrganizationInfoImage,
  OrganizationInfoContent,
  OrganizationInfoDetails,
  OrganizationInfoDetailsColumn,
  OrganizationInfoLabel,
  OrganizationInfoText,
  OrganizationInfoLink,
  OrganizationInfoTelephones,
  OrganizationInfoTelephonesItem,
  OrganizationInfoTelephonesItemLabel,
  OrganizationInfoTelephonesItemName,
} from './OrganizationInfo.styled';

const OrganizationInfo = ({ organization }: OrganizationInfoProps) => {
  const { t } = useTranslation('organization');

  const eventMailAnalytic = () => {
    ReactGA.event({
      category: 'Page Click',
      action: 'Organization Mail Click',
      label: organization.pk,
    });
  };
  const eventTelephoneAnalytic = () => {
    ReactGA.event({
      category: 'Page Click',
      action: 'Organization Telephone Click',
      label: organization.pk,
    });
  };
  const eventAnalytic = () => {
    ReactGA.event({
      category: 'Social Sharing',
      action: 'Organization Page View',
      label: organization.pk,
    });
  };

  return (
    <OrganizationInfoContainer>
      <OrganizationInfoImage src={organization.headImage.url} />
      <OrganizationInfoContent>
        <OrganizationInfoLabel>
          {t('content.description')}
        </OrganizationInfoLabel>
        {organization && organization.description && (
          <RenderHtml text={organization.description} />
        )}
        <OrganizationInfoDetails>
          <OrganizationInfoDetailsColumn>
            <OrganizationInfoLabel>
              {t('content.organizationId')}
            </OrganizationInfoLabel>
            <OrganizationInfoText>{organization.pk}</OrganizationInfoText>
          </OrganizationInfoDetailsColumn>

          {organization && organization.taxId && (
            <OrganizationInfoDetailsColumn>
              <OrganizationInfoLabel>
                {t('content.taxId')}
              </OrganizationInfoLabel>
              <OrganizationInfoText>{organization.taxId}</OrganizationInfoText>
            </OrganizationInfoDetailsColumn>
          )}
          {organization && organization.url && (
            <OrganizationInfoDetailsColumn>
              <OrganizationInfoLabel>
                {t('content.website')}
              </OrganizationInfoLabel>
              <OrganizationInfoLink
                href={organization.url}
                onClick={eventAnalytic}
                className="link font-bold"
                target="_blank"
                rel="noopener noreferrer"
              >
                {organization.url}
              </OrganizationInfoLink>
            </OrganizationInfoDetailsColumn>
          )}
          {organization && organization.email && (
            <OrganizationInfoDetailsColumn>
              <OrganizationInfoLabel>
                {t('content.email')}
              </OrganizationInfoLabel>
              <OrganizationInfoLink
                href={`mailto:${organization.email}`}
                onClick={eventMailAnalytic}
                className="link font-bold"
              >
                {organization.email}
              </OrganizationInfoLink>
            </OrganizationInfoDetailsColumn>
          )}
          {organization &&
            organization.telephones &&
            organization.telephones.length > 0 && (
              <OrganizationInfoDetailsColumn>
                <OrganizationInfoLabel>
                  {t('content.phone')}
                </OrganizationInfoLabel>
                <OrganizationInfoTelephones>
                  {organization.telephones.map((telephone) => (
                    <OrganizationInfoLink
                      href={`tel:${telephone.telephone}`}
                      onClick={eventTelephoneAnalytic}
                      className="link font-bold"
                      key={telephone.pk}
                    >
                      <OrganizationInfoTelephonesItem>
                        <OrganizationInfoTelephonesItemLabel>
                          {telephone.description}
                        </OrganizationInfoTelephonesItemLabel>
                        <OrganizationInfoTelephonesItemName>
                          {telephone.telephone}
                        </OrganizationInfoTelephonesItemName>
                      </OrganizationInfoTelephonesItem>
                    </OrganizationInfoLink>
                  ))}
                </OrganizationInfoTelephones>
              </OrganizationInfoDetailsColumn>
            )}
          {organization && organization.socialChannels.length > 0 && (
            <OrganizationInfoDetailsColumn>
              <OrganizationInfoLabel>
                {t('content.socialLinks')}
              </OrganizationInfoLabel>
              <OrganizationLinks socials={organization.socialChannels} />
            </OrganizationInfoDetailsColumn>
          )}
        </OrganizationInfoDetails>
      </OrganizationInfoContent>
    </OrganizationInfoContainer>
  );
};

export default OrganizationInfo;

// <div>
//   <SocialLinks socials={organization?.socialChannels} />
// </div>
