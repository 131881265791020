import React, { createContext, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { themes } from './Theme.styled';

//PROPS TYPE
import { ThemeProps, THEME } from './Theme.props';

export const ChangeThemeContext = createContext((_: THEME) => {});

const Theme = ({ children }: ThemeProps) => {
  const [themeName, changeThemeName] = useState(THEME.Light);
  const theme = themes[themeName];

  return (
    <ChangeThemeContext.Provider value={changeThemeName}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ChangeThemeContext.Provider>
  );
};

export default Theme;
