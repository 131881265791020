import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
//PROP TYPES
import { ProductProofPoint } from 'types/product';
//STORE
import { openModal } from 'store/proofPointModal';
//STYLES
import { SealsContainer, SealsItem, SealsItemImage } from './Seals.styled';

interface SealsProsp {
  margin?: string;
  proofs: ProductProofPoint[];
}

const Seals = ({ margin, proofs }: SealsProsp) => {
  const dispatch = useDispatch();

  const SEALS_LIST =
    proofs &&
    proofs.filter(
      (proof) =>
        proof && proof.icon && proof.icon.icon && proof.icon.icon.includes('c_')
    );

  const handleOpenProofPointModal = useCallback(
    (proof: ProductProofPoint) => {
      dispatch(
        openModal({
          proof,
        })
      );
    },
    [dispatch]
  );

  return (
    <SealsContainer margin={margin}>
      {SEALS_LIST.map(
        (proof) =>
          proof &&
          proof.pk && (
            <SealsItem
              key={proof.pk}
              onClick={() => {
                handleOpenProofPointModal(proof);
              }}
            >
              <SealsItemImage src={proof.icon.icon} />
            </SealsItem>
          )
      )}
    </SealsContainer>
  );
};

export default Seals;
