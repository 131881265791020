import styled from 'styled-components';

export const TextAreaContainer = styled.div``;
export const TextAreaStyled = styled.textarea`
  width: 100%;
  appearance: none;
  background-color: ${({ theme }) => theme.colors.white};
  border-color: ${({ theme }) => theme.colors.border};
  border-width: 1px;
  border-radius: 0;
  padding-top: 0.5rem;
  padding-right: 0.75rem;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
  font-size: 0.875rem;
  outline: none;
  line-height: 1.5;
  color: ${({ theme }) => theme.colors.black};
  padding: 0.75rem 1rem;
  outline: 0;
  resize: none;
  &:focus {
    outline: none;
  }
  ::placeholder {
    color: ${({ theme }) => theme.colors.placeholder};
  }
`;
