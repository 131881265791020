import React from 'react';

//PROPS TYPE
import { SealsProps } from './Seals.props';

//COMPONENTS
import SealItem from './SealItem';

//STYLED
import { SealsContainer } from './Seals.styled';

const Seals = ({ featuredProofs }: SealsProps) => {
  const SEALS_LIST =
    featuredProofs &&
    featuredProofs.filter(
      (proof) =>
        proof && proof.icon && proof.icon.icon && proof.icon.icon.includes('c_')
    );

  return (
    <>
      {SEALS_LIST.length > 0 ? (
        <SealsContainer>
          {SEALS_LIST.map(
            (proof) =>
              proof && proof.pk && <SealItem key={proof.pk} proof={proof} />
          )}
        </SealsContainer>
      ) : (
        <></>
      )}
    </>
  );
};

export default Seals;
