import React from 'react';

//PROPS TYPE
import { LabelProps } from './Label.props';

//STYLES
import { LabelContainer } from './Label.styled';

const Label = ({ name, children, tx }: LabelProps) => {
  const content = children ? children : tx;
  return <LabelContainer htmlFor={name}>{content}</LabelContainer>;
};

export default Label;
