import styled, { css } from 'styled-components';

import { Colors, isColor, fontsMapped, Font } from 'theme/Theme.styled';

export const defaultTextStyles = (
  fontSize: string,
  letterSpacing: string,
  css: string = ''
) =>
  styled.div<{
    align: string;
    weight: string;
    color: Colors | string;
    gradient: boolean;
    italic: boolean;
    inline: boolean;
    textTransform: string;
    fontFamily: Font;
    preset: string;
  }>`
    font-size: ${fontSize};
		letter-spacing: ${letterSpacing};
		text-align: ${({ align }) => align};
		font-weight: ${({ weight }) => weight};
		${({ gradient }) => gradient && gradientText}
		${({ italic }) => italic && 'font-style: italic;'}
    ${({ inline }) => inline && 'display: inline;'}
    color: ${({ theme, color }) =>
      isColor(color) ? theme.colors[color] : color};
		font-family: ${({ fontFamily }) => fontsMapped[fontFamily]};
		text-transform: ${({ textTransform }) => (textTransform ? textTransform : '')};
    ${css}

    ${({ preset, theme }) => {
      return (
        preset === 'link' &&
        `
      color: ${theme.colors.brand};
      &:hover {
        color: ${theme.colors.active};
      }
    `
      );
    }}
	`;

export const gradientText = css`
  background: transparent linear-gradient(199deg, #00a79c 0%, #38b44b 100%) 0%
    0% no-repeat padding-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;
