
//ASSETS
import FlagSpanish from 'assets/images/es.png';
import FlagGermany from 'assets/images/de.png';
import FlagBulgarian from 'assets/images/bg.png';
import FlagCzech from 'assets/images/cs.png';
import FlagCroatian from 'assets/images/hr.png';
import FlagDanish from 'assets/images/da.png';
import FlagSlovak from 'assets/images/sk.png';
import FlagSlovenian from 'assets/images/sl.png';
import FlagEstonian from 'assets/images/et.png';
import FlagFinnish from 'assets/images/fi.png';
import FlagFrance from 'assets/images/fr.png';
import FlagGreek from 'assets/images/el.png';
import FlagHungarian from 'assets/images/hu.png';
import FlagEnglish from 'assets/images/en.png';
import FlagIrish from 'assets/images/ga.png';
import FlagItaly from 'assets/images/it.png';
import FlagLatvian from 'assets/images/lv.png';
import FlagLithuanian from 'assets/images/lt.png';
import FlagMaltese from 'assets/images/mt.png';
import FlagDutch from 'assets/images/nl.png';
import FlagPolish from 'assets/images/pl.png';
import FlagPortugal from 'assets/images/pr.png';
import FlagRomanian from 'assets/images/ro.png';
import FlagSwedish from 'assets/images/sv.png';
import FlagCatalan from 'assets/images/ca.png';
import FlagBasque from 'assets/images/eu.png';
import FlagGalician from 'assets/images/gl.png';
import FlagJapon from 'assets/images/ja.png';

export const languagueslistObjectFormat = {
  es: {
    name: 'Español',
    ingredient: 'Ingredientes',
    value: 'Valor energético (por 100 ml)',
    usage: 'Porción media de consumo: 100 ml',
  },
  de: {
    name: 'Aleman',
    ingredient: 'Inhaltsstoffe',
    value: 'Energiewert (pro 100 ml Einheit)',
    usage: 'Durchschnittliche Verzehrmenge: 100 ml',
  },
  bg: {
    name: 'Búlgaro',
    ingredient: 'Съставки',
    value: 'Енергийна стойност (за 100 мл) ',
    usage: 'Средна порция за консумация: 100 мл',
  },
  cs: {
    name: 'Checo',
    ingredient: 'Složení',
    value: 'Energetická hodnota (na 100 ml)',
    usage: 'Průměrná spotřeba: 100 ml',
  },
  hr: {
    name: 'Croata',
    ingredient: 'Sastojci',
    value: 'Energetska vrijednost (po jedinici od 100 ml)',
    usage: 'Prosječna porcija: 100 ml',
  },
  da: {
    name: 'Danés',
    ingredient: 'Ingredienser',
    value: 'Energiindhold (pr. 100 ml)',
    usage: 'Gennemsnitlig portion af forbrug: 100 ml',
  },
  sk: {
    name: 'Eslovaco',
    ingredient: 'Zložky',
    value: 'Energetická hodnota (na 100 ml jednotky)',
    usage: 'Priemerná porcia spotreby: 100 ml',
  },
  sl: {
    name: 'Esloveno',
    ingredient: 'Sestavine',
    value: 'Energijska vrednost (na 100 ml enoto)',
    usage: 'Povprečna porcija: 100 ml',
  },
  et: {
    name: 'Estonio',
    ingredient: 'Koostisosad',
    value: 'Energiaväärtus (100 ml kohta)',
    usage: 'Keskmine tarbimiskogus: 100 ml',
  },
  fi: {
    name: 'Finés',
    ingredient: 'Ainesosat',
    value: 'Energia-arvo (100 ml:n yksikköä kohti)',
    usage: 'Keskimääräinen annos: 100 ml',
  },
  fr: {
    name: 'Francés',
    ingredient: 'Ingrédients',
    value: 'Valeur énergétique (par unité de 100 ml)',
    usage: 'Portion moyenne de consommation : 100 ml',
  },
  el: {
    name: 'Griego',
    ingredient: 'Συστατικά',
    value: 'Ενεργειακή αξία (ανά 100 ml)',
    usage: 'Μέση μερίδα κατανάλωσης: 100 ml',
  },
  hu: {
    name: 'Húngaro',
    ingredient: 'Összetevők',
    value: 'Energiaérték (100 ml egységenként)',
    usage: 'Átlagos fogyasztás: 100 ml',
  },
  en: {
    name: 'Inglés',
    ingredient: 'Ingredients',
    value: 'Energy value (per 100 ml)',
    usage: 'Average portion of consumption: 100 ml',
  },
  ga: {
    name: 'Irlandés',
    ingredient: 'Comhábhair',
    value: 'Luach fuinniúil (in aghaidh an aonaid 100 ml)',
    usage: 'Meánmhéid de thomhaltas: 100 ml',
  },
  it: {
    name: 'Italiano',
    ingredient: 'Ingredienti',
    value: 'Valore energetico (per 100 ml)',
    usage: 'Porzione media di consumo: 100 ml',
  },
  lv: {
    name: 'Letón',
    ingredient: 'Sastāvdaļas',
    value: 'Enerģētiskā vērtība (100 ml vienībā)',
    usage: 'Vidējā patēriņa daļa: 100 ml',
  },
  lt: {
    name: 'Lituano',
    ingredient: 'Sudedamosios dalys',
    value: 'Energinė vertė (100 ml vieneto)',
    usage: 'Vidutinė suvartojimo dalis: 100 ml',
  },
  mt: {
    name: 'Maltés',
    ingredient: 'Ingredjenti',
    value: 'Valur enerġetiku (għal kull 100 ml unità)',
    usage: 'Porzjon medju tal-konsum: 100 ml',
  },
  nl: {
    name: 'Neerlandés',
    ingredient: 'Ingrediënten',
    value: 'Energie (per 100 ml)',
    usage: 'Gemiddelde portie consumptie: 100 ml',
  },
  pl: {
    name: 'Polaco',
    ingredient: 'Składniki',
    value: 'Wartość energetyczna (na 100 ml jednostki)',
    usage: 'Średnia porcja spożycia: 100 ml',
  },
  pt: {
    name: 'Portugués',
    ingredient: 'Ingredientes',
    value: 'Valor energético (por unidade de 100 ml)',
    usage: 'Porção média de consumo: 100 ml',
  },
  ro: {
    name: 'Rumano',
    ingredient: 'Ingrediente',
    value: 'Valoare energetică (per unitate de 100 ml)',
    usage: 'Porția medie de consum: 100 ml',
  },
  ca: {
    name: 'Catalán',
    ingredient: 'Ingredients',
    value: 'Valor energètic (per 100 ml unitat)',
    usage: 'Porció mitjana de consum: 100 ml',
  },
  eu: {
    name: 'Euskera',
    ingredient: 'Osagaiak',
    value: 'Balio energetikoa (100 ml unitateko)',
    usage: 'Kontsumoaren batez besteko zatia: 100 ml',
  },
  gl: {
    name: 'Gallego',
    ingredient: 'Ingredientes',
    value: 'Valor enerxético (por unidade de 100 ml)',
    usage: 'Porción media de consumo: 100 ml',
  },
  sv: {
    name: 'Sueco',
    ingredient: 'Ingredienser',
    value: 'Energivärde (per 100 ml enhet)',
    usage: 'Genomsnittlig del av konsumtionen: 100 ml',
  },
  ja: {
    name: 'Japones',
    ingredient: '原材料',
    value: 'エネルギー値（100ml当たり）',
    usage: '平均的な量: 100 ml',
  },
};

export interface LenguageMapProp {
  flag: string,
  shortName: string,
  name: string,
  ingredient: string,
  value: string,
  usage: string,
}

export const LANGUAGE_MAP: LenguageMapProp[] = [
  {
    flag: FlagSpanish,
    shortName: "es",
    name: 'Español',
    ingredient: 'Ingredientes',
    value: 'Valor energético (por 100 ml)',
    usage: 'Porción media de consumo: 100 ml',
  },
  {
    flag: FlagGermany,
    shortName: "de",
    name: 'Aleman',
    ingredient: 'Inhaltsstoffe',
    value: 'Energiewert (pro 100 ml Einheit)',
    usage: 'Durchschnittliche Verzehrmenge: 100 ml',
  },
  {
    flag: FlagBulgarian,
    shortName: "bg",
    name: 'Búlgaro',
    ingredient: 'Съставки',
    value: 'Енергийна стойност (за 100 мл) ',
    usage: 'Средна порция за консумация: 100 мл',
  },
  {
    flag: FlagCzech,
    shortName: "cs",
    name: 'Checo',
    ingredient: 'Složení',
    value: 'Energetická hodnota (na 100 ml)',
    usage: 'Průměrná spotřeba: 100 ml',
  },
  {
    flag: FlagCroatian,
    shortName: "hr",
    name: 'Croata',
    ingredient: 'Sastojci',
    value: 'Energetska vrijednost (po jedinici od 100 ml)',
    usage: 'Prosječna porcija: 100 ml',
  },
  {
    flag: FlagDanish,
    shortName: "da",
    name: 'Danés',
    ingredient: 'Ingredienser',
    value: 'Energiindhold (pr. 100 ml)',
    usage: 'Gennemsnitlig portion af forbrug: 100 ml',
  },
  {
    flag: FlagSlovak,
    shortName: "sk",
    name: 'Eslovaco',
    ingredient: 'Zložky',
    value: 'Energetická hodnota (na 100 ml jednotky)',
    usage: 'Priemerná porcia spotreby: 100 ml',
  },
  {
    flag: FlagSlovenian,
    shortName: "sl",
    name: 'Esloveno',
    ingredient: 'Sestavine',
    value: 'Energijska vrednost (na 100 ml enoto)',
    usage: 'Povprečna porcija: 100 ml',
  },
  {
    flag: FlagEstonian,
    shortName: "et",
    name: 'Estonio',
    ingredient: 'Koostisosad',
    value: 'Energiaväärtus (100 ml kohta)',
    usage: 'Keskmine tarbimiskogus: 100 ml',
  },
  {
    flag: FlagFinnish,
    shortName: "fi",
    name: 'Finés',
    ingredient: 'Ainesosat',
    value: 'Energia-arvo (100 ml:n yksikköä kohti)',
    usage: 'Keskimääräinen annos: 100 ml',
  },
  {
    flag: FlagFrance,
    shortName: "fr",
    name: 'Francés',
    ingredient: 'Ingrédients',
    value: 'Valeur énergétique (par unité de 100 ml)',
    usage: 'Portion moyenne de consommation : 100 ml',
  },
  {
    flag: FlagGreek,
    shortName: "el",
    name: 'Griego',
    ingredient: 'Συστατικά',
    value: 'Ενεργειακή αξία (ανά 100 ml)',
    usage: 'Μέση μερίδα κατανάλωσης: 100 ml',
  },
  {
    flag: FlagHungarian,
    shortName: "hu",
    name: 'Húngaro',
    ingredient: 'Összetevők',
    value: 'Energiaérték (100 ml egységenként)',
    usage: 'Átlagos fogyasztás: 100 ml',
  },
  {
    flag: FlagEnglish,
    shortName: "en",
    name: 'Inglés',
    ingredient: 'Ingredients',
    value: 'Energy value (per 100 ml)',
    usage: 'Average portion of consumption: 100 ml',
  },
  {
    flag: FlagIrish,
    shortName: "ga",
    name: 'Irlandés',
    ingredient: 'Comhábhair',
    value: 'Luach fuinniúil (in aghaidh an aonaid 100 ml)',
    usage: 'Meánmhéid de thomhaltas: 100 ml',
  },
  {
    flag: FlagItaly,
    shortName: "it",
    name: 'Italiano',
    ingredient: 'Ingredienti',
    value: 'Valore energetico (per 100 ml)',
    usage: 'Porzione media di consumo: 100 ml',
  },
  {
    flag: FlagLatvian,
    shortName: "lv",
    name: 'Letón',
    ingredient: 'Sastāvdaļas',
    value: 'Enerģētiskā vērtība (100 ml vienībā)',
    usage: 'Vidējā patēriņa daļa: 100 ml',
  },
  {
    flag: FlagLithuanian,
    shortName: "lt",
    name: 'Lituano',
    ingredient: 'Sudedamosios dalys',
    value: 'Energinė vertė (100 ml vieneto)',
    usage: 'Vidutinė suvartojimo dalis: 100 ml',
  },
  {
    flag: FlagMaltese,
    shortName: "mt",
    name: 'Maltés',
    ingredient: 'Ingredjenti',
    value: 'Valur enerġetiku (għal kull 100 ml unità)',
    usage: 'Porzjon medju tal-konsum: 100 ml',
  },
  {
    flag: FlagDutch,
    shortName: "nl",
    name: 'Neerlandés',
    ingredient: 'Ingrediënten',
    value: 'Energie (per 100 ml)',
    usage: 'Gemiddelde portie consumptie: 100 ml',
  },
  {
    flag: FlagPolish,
    shortName: "pl",
    name: 'Polaco',
    ingredient: 'Składniki',
    value: 'Wartość energetyczna (na 100 ml jednostki)',
    usage: 'Średnia porcja spożycia: 100 ml',
  },
  {
    flag: FlagPortugal,
    shortName: "pt",
    name: 'Portugués',
    ingredient: 'Ingredientes',
    value: 'Valor energético (por unidade de 100 ml)',
    usage: 'Porção média de consumo: 100 ml',
  },
  {
    flag: FlagRomanian,
    shortName: "ro",
    name: 'Rumano',
    ingredient: 'Ingrediente',
    value: 'Valoare energetică (per unitate de 100 ml)',
    usage: 'Porția medie de consum: 100 ml',
  },
  {
    flag: FlagSwedish,
    shortName: "sv",
    name: 'Sueco',
    ingredient: 'Ingredienser',
    value: 'Energivärde (per 100 ml enhet)',
    usage: 'Genomsnittlig del av konsumtionen: 100 ml',
  },
  {
    flag: FlagJapon,
    shortName: "ja",
    name: 'Japones',
    ingredient: '原材料',
    value: 'エネルギー値（100ml当たり）',
    usage: '平均的な量: 100 ml',
  },
  {
    flag: FlagCatalan,
    shortName: "ca",
    name: 'Catalán',
    ingredient: 'Ingredients',
    value: 'Valor energètic (per 100 ml unitat)',
    usage: 'Porció mitjana de consum: 100 ml',
  },
  {
    flag: FlagBasque,
    shortName: "eu",
    name: 'Euskera',
    ingredient: 'Osagaiak',
    value: 'Balio energetikoa (100 ml unitateko)',
    usage: 'Kontsumoaren batez besteko zatia: 100 ml',
  },
  {
    flag: FlagGalician,
    shortName: "gl",
    name: 'Gallego',
    ingredient: 'Ingredientes',
    value: 'Valor enerxético (por unidade de 100 ml)',
    usage: 'Porción media de consumo: 100 ml',
  },
];

export const _LANGUAGES: LenguageMapProp[] = [
  {
    flag: FlagSpanish,
    shortName: "es",
    name: 'Español',
    ingredient: 'Ingredientes',
    value: 'Valor energético (por 100 ml)',
    usage: 'Porción media de consumo: 100 ml',
  },
  {
    flag: FlagCatalan,
    shortName: "ca",
    name: 'Catalán',
    ingredient: 'Ingredients',
    value: 'Valor energètic (per 100 ml unitat)',
    usage: 'Porció mitjana de consum: 100 ml',
  },
  {
    flag: FlagEnglish,
    shortName: "en",
    name: 'Inglés',
    ingredient: 'Ingredients',
    value: 'Energy value (per 100 ml)',
    usage: 'Average portion of consumption: 100 ml',
  },
]