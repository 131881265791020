import styled from 'styled-components';

export const SocialShareLink = styled.a`
  width: 2rem;
  height: 2rem;
  background-color: ${({ theme }) => theme.colors.brand};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 9999px;
  margin: 0 0.75rem 1rem 0;
  transition: all 0.25s ease-in-out;
  box-shadow: 0 4px 10px 0 rgb(16 3 22 / 10%);
  &:last-child {
    margin: 0 0 1rem;
  }
  &:hover {
    background-color: ${({ theme }) => theme.colors.active};
    transform: translateY(-0.25rem);
    box-shadow: 0 4px 10px 0 rgb(16 3 22 / 20%);
  }
  svg {
    width: 1rem;
    color: ${({ theme }) => theme.colors.white};
  }
`;

export const SocialShareCopiedtext = styled.p`
  margin: 0;
  color: ${({ theme }) => theme.colors.brand};
`;
export const SocialShareContainer = styled.div<{ mobile?: boolean }>`
  display: flex;
  align-items: center;
  margin: 0 0 1rem;
  flex-wrap: wrap;
  ${({ mobile }) =>
    mobile &&
    `
    display: grid;
    grid-template: 3.25rem / 1fr;
    grid-auto-rows: 3.25rem;
    align-items: center;
    justify-content: center;
    grid-gap:0;
    margin: 0;
    ${SocialShareLink} {
      align-self: center;
      justify-self: center;
      margin: 0 ;
    }
  `}
`;
