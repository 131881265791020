import React from 'react';

//PROPS TYPE
import { IconProps } from './Icon.props';

//CONSTANTS
import ICON_MAP from './Icon.contants';

//STYLED
import { IconContainer } from './Icon.styled';

const Icon = ({ iconName, ...props }: IconProps) => {
  const isColor = (iconName: string) => {
    return Object.keys(ICON_MAP).includes(iconName);
  };

  return (
    <IconContainer {...props}>
      {isColor(iconName) ? ICON_MAP[iconName].icon : ''}
    </IconContainer>
  );
};

export default Icon;
