import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@react-hook/media-query';
//ASSETS
import logoWine from 'assets/images/logowine.svg';
import logoFooter from 'assets/images/brand-icon-wine.svg';
//PROPS
import { AppLayoutWineProps } from './AppLayoutWine.props';
//COMPONENTS
import Error404 from 'screens/Error404';
import Loader from 'components/Loader';
import MetaHead from 'components/MetaHead';
import Icon from 'components/Icon';
//STYLES
import {
  AppLayoutWineContaienr,
  AppLayoutWineContainer,
  AppLayoutWineHeader,
  AppLayoutWineHeaderLogo,
  AppLayoutWineHeaderTranslate,
  AppLayoutWineHeaderTranslateLang,
  AppLayoutWineFooter,
  AppLayoutWineFooterText,
  AppLayoutWineFooterCopy,
  AppLayoutWineFooterCopyLogo,
  AppLayoutWineContent,
  AppLayoutWineWrapper,
} from './AppLayoutWine.styled';
import { AppBlocker, AppBlockerText } from 'module/AppLayout/AppLayout.styled';
import TranslateSelector from 'components/TranslateSelector/TranslateSelector';
import { ERRORS } from 'constants/errors';

const AppLayoutWine = ({
  children,
  loading,
  skeletonLoader,
  metas,
  errorType,
  errorUrl,
  logo,
  id,
  hideOrgData = false,
}: AppLayoutWineProps) => {
  const { t: tFooter } = useTranslation('footer');

  const matches = useMediaQuery('only screen and (max-width: 768px)');

  const year = new Date().getFullYear();

  if (errorType === ERRORS.private) {
    return <Error404 isPrivate errorUrl={errorUrl} />;
  }

  if (errorType === ERRORS.pending_payment) {
    return <Error404 issetProduct />;
  }

  if (
    errorType === ERRORS.not_registered ||
    errorType === ERRORS.invalid_uuid
  ) {
    return <Error404 />;
  }

  if (
    !!errorType &&
    errorType !== ERRORS.not_registered &&
    errorType !== ERRORS.invalid_uuid &&
    errorType !== ERRORS.private &&
    errorType !== ERRORS.pending_payment
  ) {
    return <Error404 />;
  }

  return loading ? (
    <>{skeletonLoader ? skeletonLoader : <Loader />}</>
  ) : (
    <AppLayoutWineContaienr>
      {matches && (
        <AppBlocker>
          <AppBlockerText>
            <Icon iconName="rotatePhone" />
            Por favor rote el telefono
          </AppBlockerText>
        </AppBlocker>
      )}
      {metas && (
        <MetaHead
          title={metas.title}
          description={metas.description}
          image={metas.image}
          url={metas.url}
        />
      )}
      <AppLayoutWineContainer>
        <AppLayoutWineWrapper>
          <AppLayoutWineHeader>
            {!hideOrgData ? (
              <AppLayoutWineHeaderLogo
                minSize={!!logo}
                src={logo ? logo : logoWine}
              />
            ) : (
              <div />
            )}

            <TranslateSelector device="both" type="wine" id={id} />
            {/* <AppLayoutWineHeaderTranslate>
              <AppLayoutWineHeaderTranslateLang active>
                en
              </AppLayoutWineHeaderTranslateLang>
              <AppLayoutWineHeaderTranslateLang>
                es
              </AppLayoutWineHeaderTranslateLang>
            </AppLayoutWineHeaderTranslate> */}
          </AppLayoutWineHeader>
        </AppLayoutWineWrapper>
        <AppLayoutWineContent>{children}</AppLayoutWineContent>
        <AppLayoutWineWrapper>
          <AppLayoutWineFooter>
            <AppLayoutWineFooterCopy>
              <AppLayoutWineFooterCopyLogo src={logoFooter} />©{year}
            </AppLayoutWineFooterCopy>
            <AppLayoutWineFooterText
              href="mail:hello@swearit.io"
              target="_blank"
            >
              hello@swearit.io
            </AppLayoutWineFooterText>
            <AppLayoutWineFooterText
              href="https://swearit.io/legal"
              target="_blank"
            >
              Legal
            </AppLayoutWineFooterText>
          </AppLayoutWineFooter>
        </AppLayoutWineWrapper>
      </AppLayoutWineContainer>
    </AppLayoutWineContaienr>
  );
};

export default AppLayoutWine;
