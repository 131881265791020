import React from 'react';

//ASSETS
import loader from 'assets/images/loader.gif';

//COMPONENTS
import { LoaderContainer, LoaderImage } from './Loader.styled';

const Loader = () => {
  return (
    <LoaderContainer>
      <LoaderImage src={loader} alt="loader" />
    </LoaderContainer>
  );
};

export default Loader;
