import React from 'react';
import { ProductTabsIngredients } from 'types/product';
//COMPONENTS
import RenderHtml from 'components/RenderHtml/RenderHtml';
//STYLES
import {
  TemplateIngredient,
  TemplateIngredientItem,
  TemplateIngredientImage,
  TemplateTechDetail,
  TemplateLabel,
  TemplateText,
} from './Template.styled';

interface ProductTabsIngredientProp {
  ingredients: ProductTabsIngredients;
  wine?: boolean;
}

const ProductTabsIngredient = ({
  ingredients,
  wine,
}: ProductTabsIngredientProp) => {
  return (
    <TemplateIngredient wine={wine}>
      {ingredients.ingredients.map((ingredient, index) => (
        <TemplateIngredientItem
          key={index}
          as={ingredient.link === '' ? 'div' : 'a'}
          href={ingredient.link ? ingredient.link : '#'}
          target="_blank"
          isLink={ingredient.link !== ''}
        >
          <TemplateIngredientImage src={ingredient.image} />
          <TemplateTechDetail>
            <TemplateLabel>{ingredient.title}</TemplateLabel>
            <TemplateText>
              <RenderHtml text={ingredient.description} noOverlay />
            </TemplateText>
          </TemplateTechDetail>
        </TemplateIngredientItem>
      ))}
    </TemplateIngredient>
  );
};

export default ProductTabsIngredient;
