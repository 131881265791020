import { createSlice } from '@reduxjs/toolkit';

import { Organization } from 'types/organization';

interface organizationStateProps {
  organization: Organization;
  organizationName: string;
  organizationLogo: string;
}

const initialState: organizationStateProps = {
  organization: {} as Organization,
  organizationName: '',
  organizationLogo: '',
};

const organizationSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    setOrganization: (state: typeof initialState, action) => {
      return {
        ...state,
        organization: action.payload,
      };
    },
    setOrganizationInfo: (state: typeof initialState, action) => {
      return {
        ...state,
        organizationName: action.payload.name,
        organizationLogo: action.payload.logo,
      };
    },
  },
});

export const {
  setOrganization,
  setOrganizationInfo,
} = organizationSlice.actions;

export default organizationSlice;
