import styled from "styled-components"
import { Text } from "components/Text"
import { breakpoints } from "theme/Theme.breackoints";

export const TabsContainer = styled.div`
  margin-bottom: 2rem;
`
export const TabsHeader = styled.div<{size: number;}>`
  display: flex;
  list-style: none;
  margin: 0 0 1.25rem;
  @media screen and (max-width: ${breakpoints.xs}px) {
    margin: 0;
  }
`

export const TabsHeadIcon = styled.div`
  svg {
    width: 12px;
    height: 12px;
  }
`

export const TabsHead = styled(Text)<{active: boolean; fullWidth?: boolean;}>`
  font-size: 1rem;
  line-height: 1rem;
  text-transform: Capitalize;
  cursor: pointer;
  position: relative;
  padding: 1rem 0.625rem;
  &:hover {
    color: ${({ theme }) => theme.colors.winePrimary};
  }

  &::after {
    content: "";
    position: absolute;
    background-color: ${({ theme, active }) => active ? theme.colors.winePrimary : theme.colors.wineBlack};
    width: 100%;
    height: ${({ theme, active }) => active ? "8px" : "1px"};
    z-index: 9;
    left: 0;
    right: 0;
    bottom: 0;
    @media screen and (max-width: ${breakpoints.xs}px) {
      background-color: ${({ theme, active }) => active ? theme.colors.winePrimary : "transparent"};
    }
  }
  
  ${({ active, theme }) => active && `
    color: ${theme.colors.winePrimary};
    ${TabsHeadIcon} {
      transform: rotate(180deg);
    }
  `}

  ${({ fullWidth }) => fullWidth && `
    width: 100%;
  `}
  @media screen and (max-width: ${breakpoints.xs}px) {
    padding: 1rem 0.625rem 1rem  0;
    display: flex;
    gap: 0 0.625rem;
  }
`
export const TabsBody = styled.div``
export const TabsItem = styled.div<{active: boolean;}>`
  ${({ active }) => !active && `
    display: none;
  `}
  @media screen and (max-width: ${breakpoints.xs}px) {
    padding: 1rem 0 0;
  }
`
export const TabsMobileContainer = styled.div``
export const TabsMobileItem = styled.div``
export const TabNutrientContainer = styled.div<{maxWidth: string;}>`
  width: 100%;
  max-width: ${({ maxWidth }) => maxWidth};
  font-family: ${({theme }) => theme.fonts.main};
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.wineGray};
`
export const TabNutrientHeader = styled.div`
  display: grid;
  grid-template: 1fr / repeat(2, 1fr);
  @media screen and (max-width: ${breakpoints.xs}px) {
    grid-template: 1fr / 1fr 150px;
  }
`
export const TabNutrientHead = styled.div<{hiddenRight?: boolean; hiddenTop?: boolean; align?: string;}>`
  padding: 0.5rem;
  border-right: 1px solid ${({ theme }) => theme.colors.wineBlack};
  border-top: 1px solid ${({ theme }) => theme.colors.wineBlack};
  word-break: break-word;
  ${({ hiddenRight }) => hiddenRight && `
    border-right: none;
  `}

  ${({ hiddenTop }) => hiddenTop && `
    border-top: none;
  `}
   ${({ align }) => align && `
    text-align: ${align}; 
  `}
`
export const TabNutrientList = styled.div`
  margin: 0 0 1.5rem;
`
export const TabNutrientItem = styled.div`
  display: grid;
  grid-template: 1fr / repeat(2, 1fr);
  @media screen and (max-width: ${breakpoints.xs}px) {
    grid-template: 1fr / 1fr 150px;
  }
`
export const TabNutrientItemBody = styled.div<{hiddenRight?: boolean; hiddenTop?: boolean; child?: boolean; align?: string; }>`
  padding: 0.5rem;
  border-right: 1px solid ${({ theme }) => theme.colors.wineBlack};
  border-top: 1px solid ${({ theme }) => theme.colors.wineBlack};
  word-break: break-word;
  ${({ hiddenRight }) => hiddenRight && `
    border-right: none;
  `}

  ${({ hiddenTop }) => hiddenTop && `
    border-top: none;
  `}
  
  ${({ child }) => child && `
    padding: 0 0 1rem 2rem; 
  `}

  ${({ align }) => align && `
    text-align: ${align}; 
  `}
`
export const TabNutrientText = styled.p`
  margin: 0 0 1.5rem;
  font-size: 0.875rem;
  color: ${({theme }) => theme.colors.wineGray};
`