export interface GetTypeFile {
  name: string;
  contentType: string;
}

export const getTypeFile: (type: string) => GetTypeFile | undefined = (
  type: string
) => {
  if (type.includes('pdf')) {
    return {
      name: 'pdf',
      contentType: type,
    };
  } else if (
    type.includes('png') ||
    type.includes('jpg') ||
    type.includes('jpeg') ||
    type.includes('gif')
  ) {
    return {
      name: 'image',
      contentType: type,
    };
  } else if (
    type.includes('mp4') ||
    type.includes('ogg') ||
    type.includes('webm')
  ) {
    return {
      name: 'video',
      contentType: type,
    };
  }
};
