import React from 'react';

//COMPONENTS
import { FeatureProofPointsProps } from './FeatureProofPoints.props';
import ProofPoint from 'components/ProofPoint';

//STYLES
import { FeatureProofPointsContainer } from './FeatureProofPoints.styled';

const FeatureProofPoints = ({ featuredProofs }: FeatureProofPointsProps) => {
  const PROOFPOINTS_LIST = featuredProofs.filter(
    (proof) =>
      proof && proof.icon && proof.icon.icon && !proof.icon.icon.includes('c_')
  );

  return (
    <>
      {PROOFPOINTS_LIST.length > 0 ? (
        <FeatureProofPointsContainer>
          {PROOFPOINTS_LIST.map((proof) => (
            <ProofPoint key={proof.pk} proof={proof} />
          ))}
        </FeatureProofPointsContainer>
      ) : (
        <></>
      )}
    </>
  );
};

export default FeatureProofPoints;
